import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import Adminnav from '../admin nav/Adminnav'
import Sidebar from '../sidebar/Sidebar'
import './Newregistrants.css'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import Loading from '../../Loading'

const Newregistrants = () => {
    const [toggle, setToggle] = useState(true);
    const token = localStorage.getItem('admintokenglobal')
    const [loadersubmit, setloadersubmit] = useState(false)
    const navigate = useNavigate();
    const Toggle = () => {
        setToggle(!toggle);
    };

    useEffect(() => {
        getagent()
    }, [])

    const [approvaldata, setapprovaldata] = useState([]);
    const getagent = () => {
        // setloadersubmit(true)
        axios.post("https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/consultant/ChannelPartnerProfileForApproval", {},{
            headers: {
                Authorization: token,
            },
        })
            .then((res) => {
                console.log(res.data)
                if (res.data.message === "Channel Partner detail found") {
                    setapprovaldata(res.data.data)
                    setloadersubmit(false)
                } else {
                    navigate("/")
                }

            }).catch((e) => {
                console.log(e)
            })
    }

    // const pendingfilter = () => {
    //     axios.post("https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/manageaccommodation/ViewServiceRequestPending", { accommodationType, startDate, endDate }, {
    //         headers: {
    //             Authorization: token,
    //         },
    //     })
    //         .then((res) => {
    //             console.log(res.data)
    //             if (res.data.message === "Channel Partner detail found") {
    //                 // setorderdata(res.data.data)

    //             } else {
    //                 // setorderdata()

    //             }

    //         }).catch((e) => {
    //             console.log(e)
    //         })
    // }

    if (loadersubmit) {
        return (
            <Loading />
        )
    }

    const handleapproval = (channelPartnerId) =>{
        console.log(channelPartnerId)
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, approved it!"
          }).then((result) => {
            if (result.isConfirmed) {
              axios.put(`https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/consultant/Toapprovechannelpartner?channelPartnerId=` + channelPartnerId,{}, {
                headers: {
                  Authorization: token,
                },
              }).then((res) => {
                console.log(res.data)
                if (res.data.message === "Approved successfully") {
                  Swal.fire({
                    title: "Deleted!",
                    text: "Approved successfully",
                    icon: "success"
                  });
                  getagent()
                }
              }).catch((e) => {
                console.log(e)
              })
      
            }
          });
      
    }



  return (
    <div className="container-fluid">
    <div className="row">
        {toggle && (
            <div
                className="col-12 col-md-3 vh-100 position-fixed"
                style={{ zIndex: 1000 }}
            >
                <Sidebar Toggle={Toggle} />
            </div>
        )}

        {toggle && <div className="col-12 col-lg-3"></div>}
        <div className="col">
            <Adminnav Toggle={Toggle} />

            <div className='container-fluid registrants-main'>
                <div className='row mt-3 text-start'>
                    <h2>Agents {">"} New registrants</h2>
                </div>
                {/* <div>
                    <div className='row text-start mt-4 acc-form'>
                        <div className='col-lg-4 col-12'>
                            <div className="mb-3">
                                <label for="exampleInputEmail1" className="form-label">Accommodation type </label>
                                <select className="form-select"
                                    value={accommodationType}
                                    // onChange={(e) => setaccommodationType(e.target.value)}
                                    aria-label="Default select example">
                                    <option selected>Select type</option>
                                    <option value="share">Shared</option>
                                    <option value="independent">Independent</option>
                                </select>
                            </div>
                        </div>
                        <div className='col-lg-4 col-12'>
                            <div className="mb-3">
                                <label for="people" className="form-label">Check in date</label>
                                <input type="date" className="form-control" id="people"
                                    // value={startDate} onChange={(e) => setstartDate(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='col-lg-4 col-12'>
                            <div className="mb-3">
                                <label for="people" className="form-label">Check out date</label>
                                <input type="date" className="form-control" id="people"
                                    // value={endDate} onChange={(e) => setendDate(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* <div className='row mt-3 btn-row'>
                    <div className='col-lg-1 col-6 d-flex align-items-start justify-content-start'>
                        <button className='btn btn-primary' >Filter</button>
                    </div>
                    <div className='col-lg-1 col-6 d-flex align-items-start justify-content-start'>
                        <button className='btn btn-primary' >Clear</button>
                    </div>
                    <div className='col-lg'></div>
                </div> */}

                <div className='row mt-3'>
                    <div className='col table11'>
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th scope="col">Sr.</th>
                                    {/* <th scope="col">Role</th> */}
                                    <th scope="col">Name</th>
                                    <th scope="col">Email</th>
                                    <th scope="col">Phone</th>
                                    <th scope="col">Gender</th>
                                    <th scope="col">Business country</th>
                                    <th scope="col">Business city</th>
                                    <th scope="col">Organization name</th>
                                    <th scope="col">Organization #</th>
                                    <th scope="col">Organization type</th>
                                    <th scope="col">Password</th>
                                    <th scope="col">Approval</th>
                                </tr>
                            </thead>
                            <tbody>
                            {approvaldata ?
                                    approvaldata.map((item, index) => {
                                        return <tr key={item._id}>
                                            <th scope="row">{index + 1}</th>
                                            <td>{item.personalInfo?.firstName ? item.personalInfo.firstName :"N/A"}</td>
                                            <td>{item.userAccountInfo?.email ? item.userAccountInfo.email :"N/A"}</td>
                                            <td>{item.userAccountInfo?.phone ? item.userAccountInfo.phone :"N/A"}</td>
                                            <td>{item.personalInfo?.gender ? item.personalInfo.gender :"N/A"}</td>
                                            <td>{item.businessAddress?.country ? item.businessAddress.country :"N/A"}</td>
                                            <td>{item.businessAddress?.city ? item.businessAddress.city :"N/A"}</td>
                                            <td>{item.organizationInfo?.organizationName ? item.organizationInfo.organizationName :"N/A"}</td>
                                            <td>{item.organizationInfo?.organizationRegistrationNumber ? item.organizationInfo.organizationRegistrationNumber :"N/A"}</td>
                                            <td>{item.organizationInfo?.organizationType ? item.organizationInfo.organizationType :"N/A"}</td>  
                                            <td>{item.userAccountInfo?.password ? item.userAccountInfo.password :"N/A"}</td>
                                            <td onClick={() => handleapproval(item._id)}><button className="btn btn-primary" style={{ backgroundColor: "#296013", border: "none" }}><i className="fa-solid fa-check" style={{color: "white"}}></i></button></td>
                                            {/* <td>
                                                <li className="nav-item dropdown" style={{ listStyle: "none" }}>
                                                    <button className="btn btn-primary dropdown-toggle" style={{ backgroundColor: "#296013", border: "none", height: "30px" }} role="button" data-bs-toggle="dropdown" aria-expanded="false">

                                                    </button>
                                                    <ul className="dropdown-menu">
                                                        <li style={{ cursor: "pointer" }} onClick={() => localdelete(item._id)}><td className="dropdown-item"><i class="fa-solid fa-trash me-2" style={{ color: "#B63A3A" }}></i> Delete</td></li>
                                                        <li style={{ cursor: "pointer" }}
                                                            onClick={() => handleShow(item._id, item.bankName, item.accountTitle, item.accountNumber, item.IBAN)}
                                                        ><td className="dropdown-item"><i className="fa-solid fa-pen-to-square me-2"
                                                            style={{ color: "#198BEC" }}></i> Update</td></li>
                                                    </ul>
                                                </li>
                                            </td> */}
                                         </tr>
                                    }) : ""

                                } 


                            </tbody>
                        </table>
                    </div>
                </div>

            </div>

        </div>
    </div>
</div>

  )
}

export default Newregistrants
