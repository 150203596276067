import React, { useEffect, useState } from 'react'
import Ssidebar from '../Sidebar/Ssidebar';
import Studentnav from '../Sidebar/Studentnav';
import Swal from 'sweetalert2';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'
import 'react-tagsinput/react-tagsinput.css'
import './Socialcontact.css'
import Navbar from '../../compunent/Navbar';

const Socialcontact = () => {
    const [toggle, setToggle] = useState(true);
    const navigate = useNavigate();
    const Toggle = () => {
        setToggle(!toggle);
    };

    const token = localStorage.getItem('studenttoken')

    useEffect(() => {
        getstudents()
    }, [])

    const [facebookUrl, setfacebookUrl] = useState("")
    const [skypeId, setskypeId] = useState("")

    const handlesave = () => {
        // console.warn(bankName, accountNumber, accountTitle, IBAN)
        if (facebookUrl&&skypeId) {
            axios.post("https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/student/StudentSocialContactInfo", { facebookUrl,skypeId }, {
                headers: {
                    Authorization: token,
                },
            }).then((res) => {
                if (res.data.message === "socialContact information is added") {
                    Swal.fire({
                        title: "Thank you",
                        text: "Social contact information is added",
                        icon: "success"
                    });

                    getstudents()
                } else {
                    console.log(res.data)

                }
            }).catch((e) => {
                console.log(e)
            })
        } else {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "please fill input",
            });
        }
    }

    const getstudents = () => {
        axios.get("https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/student/StudentProfile", {
            headers: {
                Authorization: token,
            },
        })
            .then((res) => {
                console.log(res.data.data)
                if (res.data.message === "Student profile") {

                    setfacebookUrl(res.data.data[0].socialContactInfo.facebookUrl)
                    setskypeId(res.data.data[0].socialContactInfo.skypeId)
                

                } else {
                    navigate("/")
                }

            }).catch((e) => {
                console.log(e)
            })
    }
    return (
       <div>
        <Navbar/>
         <div className="container-fluid">
            <div className="row">
                {toggle && (
                    <div
                        className="col-12 col-md-3 vh-100 position-fixed"
                        style={{ zIndex: 1000 }}
                    >
                        <Ssidebar Toggle={Toggle} />
                    </div>
                )}

                {toggle && <div className="col-12 col-lg-3"></div>}
                <div className="col">
                    {/* <Studentnav Toggle={Toggle} /> */}
                    <div className='container-fluid social-main'>
                        <div className='row mt-3 text-start'>
                            <h2>{toggle?"":<i className="fa-solid fa-bars" onClick={Toggle} style={{cursor: "pointer"}}></i>} Social contact details</h2>
                        </div>
                        <div>
                            <div className='row text-start mt-4 acc-form'>
                                <div className='col-lg-6 col-12'>
                                    <div className="mb-3">
                                        <label for="people" className="form-label">Facebook url</label>
                                        <input type="text" className="form-control" id="people"
                                            value={facebookUrl} onChange={(e) => setfacebookUrl(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className='col-lg-6 col-12'>
                                    <div className="mb-3">
                                        <label for="people" className="form-label">Skype id</label>
                                        <input type="text" className="form-control" id="people"
                                            value={skypeId} onChange={(e) => setskypeId(e.target.value)}
                                        />
                                    </div>
                                </div>

                            </div>




                        </div>

                        <div className='row mt-3 btn-row'>
                            <div className='col d-flex align-items-start justify-content-start'>
                                <button className='btn btn-primary' onClick={handlesave}>Save</button>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </div>
       </div>

    )
}

export default Socialcontact
