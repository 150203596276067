import React from 'react'
import './Contactus.css'
import Navbar from '../../compunent/Navbar'
import Footer from '../../compunent/Footer'

const Contact = () => {
  return (
    <div className='contact-main'>
      <Navbar />
      <div className='container py-lg-5 py-4'>
        <div className='row'>
          <div className='col-lg-6'>
            <div className='form-box'>
              <form className='text-start py-4 px-4'>
                <h2 className=''>Send Message</h2>
                <div className='input-box2'>
                  <input type='text' name='' required="required"></input>
                  <span>Full Name</span>
                </div>
                <div className='input-box2'>
                  <input type='email' name='' required="required"></input>
                  <span>Email</span>
                </div>
                <div className='input-box2'>
                  <input type='number' name='' required="required"></input>
                  <span>Phone</span>
                </div>
                <div className='input-box2'>
                  <textarea required="required"></textarea>
                  <span>Type your Message...</span>
                </div>
                <div className='input-box2 '>
                  <input type='submit' name='' value="Send"></input>
                </div>
              </form>
            </div>
          </div>
          <div className='col-lg-6'>
            <div >
              <iframe className='map' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d158857.83988756716!2d-0.26640578112070873!3d51.528739804788714!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47d8a00baf21de75%3A0x52963a5addd52a99!2sLondon%2C%20UK!5e0!3m2!1sen!2s!4v1701948948220!5m2!1sen!2s"
                allowfullscreen=""
                loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Contact
