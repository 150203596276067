import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import Adminnav from '../admin nav/Adminnav'
import Sidebar from '../sidebar/Sidebar'
import './AdminBid.css'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import Loading from '../../Loading'
import Swal from 'sweetalert2'

const AdminBid = () => {
    const [toggle, setToggle] = useState(true);
    const token = localStorage.getItem('admintokenglobal')
    const [loadersubmit, setloadersubmit] = useState(false)
    const navigate = useNavigate();
    const Toggle = () => {
        setToggle(!toggle);
    };

    useEffect(() => {
        getdata()
    }, [])

    const [orderdata, setorderdata] = useState([]);
    const [accommodationType, setaccommodationType] = useState("");
    const [toWhichCity, setcityName] = useState("")
    const [serviceType, setserviceType] = useState("")


    const getdata = () => {
        // setloadersubmit(true)
        axios.post("https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/BidRequest/GetBidPost", {}, {
            headers: {
                Authorization: token,
            },
        })
            .then((res) => {
                console.log(res.data)
                if (res.data.message === "Bid found") {
                    const reversedData = res.data.data.reverse()
                    setorderdata(reversedData)
                    setloadersubmit(false)
                } else {
                    navigate("/")
                }

            }).catch((e) => {
                console.log(e)
            })
    }


    const pendingfilter = () => {
        axios.post("https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/lead/GetLeadForWhichCity", { accommodationType, toWhichCity, serviceType }, {
            headers: {
                Authorization: token,
            },
        })
            .then((res) => {
                console.log(res.data)
                // if (res.data.data._id) {
                setorderdata(res.data.data)

                // } else {
                //     setorderdata()

                // }

            }).catch((e) => {
                console.log(e)
            })
    }

    if (loadersubmit) {
        return (
            <Loading />
        )
    }


    const bidpost = (city, serviceType, accommodationType, travelPlanDate) => {
        console.log(city, serviceType[0], accommodationType, travelPlanDate)

        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes"
        }).then((result) => {
            if (result.isConfirmed) {
                axios.post("https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/BidRequest/AddBidPost", { city, serviceType: serviceType[0], accommodationType, travelPlanDate }, {
                    headers: {
                        Authorization: token,
                    },
                }).then((res) => {
                    console.log(res.data)
                    if (res.data.message === "Successfully request is added") {
                        Swal.fire({
                            title: "Thank you",
                            text: "Successfully request is added",
                            icon: "success"
                        });
                        // window.location.reload(false);
                        // Filterid()
                    }
                }).catch((e) => {
                    console.log(e)
                })

            }
        });

    }

    const clearfilter = () => {
        getdata()
        setaccommodationType("")
        setserviceType("")
        setcityName("")
    }

    const formatDate = (mongoDBDate) => {
        const date = new Date(mongoDBDate);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();

        return `${year}/${month}/${day}`;
    };
    return (
        <div className="container-fluid">
            <div className="row">
                {toggle && (
                    <div
                        className="col-12 col-md-3 vh-100 position-fixed"
                        style={{ zIndex: 1000 }}
                    >
                        <Sidebar Toggle={Toggle} />
                    </div>
                )}

                {toggle && <div className="col-12 col-lg-3"></div>}
                <div className="col">
                    <Adminnav Toggle={Toggle} />

                    <div className='container-fluid adminbid-main'>
                        <div className='row mt-3 text-start'>
                            <h2>Total Bid</h2>
                        </div>
                        <div>
                            <div className='row text-start mt-4 acc-form'>
                                <div className='col-lg-4 col-12'>
                                    <div className="mb-3">
                                        <label for="exampleInputEmail1" className="form-label">City </label>
                                        <select className="form-select"
                                            value={toWhichCity}
                                            onChange={(e) => setcityName(e.target.value)}
                                            aria-label="Default select example">
                                            <option selected>Select type</option>
                                            <option value="London">London</option>
                                            <option value="City of London">City of London</option>
                                        </select>
                                    </div>
                                </div>
                                <div className='col-lg-4 col-12'>
                                    <div className="mb-3">
                                        <label for="exampleInputEmail1" className="form-label">Service type </label>
                                        <select className="form-select"
                                            value={serviceType}
                                            onChange={(e) => setserviceType(e.target.value)}
                                            aria-label="Default select example">
                                            <option selected>Select type</option>
                                            <option value="accommodation">Accommodation</option>
                                            {/* <option value="independent">Independent</option> */}
                                        </select>
                                    </div>
                                </div>
                                <div className='col-lg-4 col-12'>
                                    <div className="mb-3">
                                        <label for="exampleInputEmail1" className="form-label">Accommodation type </label>
                                        <select className="form-select"
                                            value={accommodationType}
                                            onChange={(e) => setaccommodationType(e.target.value)}
                                            aria-label="Default select example">
                                            <option selected>Select type</option>
                                            <option value="share">Shared</option>
                                            <option value="independent">Independent</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row mt-3 btn-row'>
                            <div className='col-lg-1 col-6 d-flex align-items-start justify-content-start'>
                                <button className='btn btn-primary' onClick={pendingfilter}>Filter</button>
                            </div>
                            <div className='col-lg-1 col-6 d-flex align-items-start justify-content-start'>
                                <button className='btn btn-primary' onClick={clearfilter}>Clear</button>
                            </div>
                            <div className='col-lg'></div>
                        </div>

                        <div className='row mt-3'>
                            <div className='col table11'>
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">Sr.</th>
                                            <th scope="col">City</th>
                                            <th scope="col">Travel date</th>
                                            <th scope="col">Total property</th>
                                            <th scope="col">Accommodation details</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {orderdata ?
                                            orderdata.map((item, index) => {
                                                return <tr key={item._id}>
                                                    <th scope="row">{index + 1}</th>
                                                    <td>{item.city ? item.city : "N/A"}</td>
                                                    <td>{formatDate(item.travelPlanDate)}</td>
                                                    <td>{item.bitBylettingAgents.length ? item.bitBylettingAgents.length : 0}</td>
                                                    <td>
                                                        <table class="table  table-2">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col">Sr.</th>
                                                                    <th scope="col">Contact info</th>
                                                                    <th scope="col">Postal code</th>
                                                                    <th scope="col">Room #</th>
                                                                    <th scope="col">Independent (£)</th>
                                                                    <th scope="col">Shared (£)</th>
                                                                    <th scope="col">Independent (By Admin £)</th>
                                                                    <th scope="col">Shared (By Admin £)</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    item.bitBylettingAgents.map((data, index) => {
                                                                        return <tr>
                                                                            <th scope="row">1</th>
                                                                            <td>{data.lettingAgentId.email}</td>
                                                                            <td>{data.lettingLivingdetailsId.accommodationId.postCode}</td>
                                                                            <td>{data.lettingLivingdetailsId.roomNumber}</td>
                                                                            <td>{data.lettingLivingdetailsId.livingIndLongStayPriceByLettingAgent}</td>
                                                                            <td>{data.lettingLivingdetailsId.livingShareLongStayPriceByLettingAgent}</td>
                                                                            <td>{data.lettingLivingdetailsId.livingIndLongStayPrice}</td>
                                                                            <td>{data.lettingLivingdetailsId.livingShareLongStayPrice}</td>
                                                                        </tr>
                                                                    })
                                                                }

                                                            </tbody>
                                                        </table>
                                                    </td>

                                                    {/* <td>
                                                        <li className="nav-item dropdown" style={{ listStyle: "none" }}>
                                                            <button className="btn btn-primary dropdown-toggle" style={{ backgroundColor: "#296013", border: "none", height: "30px" }} role="button" data-bs-toggle="dropdown" aria-expanded="false">

                                                            </button>
                                                            <ul className="dropdown-menu">
                                                                <li style={{ cursor: "pointer" }} onClick={() => localdelete(item._id)}><td className="dropdown-item"><i class="fa-solid fa-trash me-2" style={{ color: "#B63A3A" }}></i> Delete</td></li>
                                                                <li style={{ cursor: "pointer" }}
                                                                    onClick={() => handleShow(item._id, item.bankName, item.accountTitle, item.accountNumber, item.IBAN)}
                                                                ><td className="dropdown-item"><i className="fa-solid fa-pen-to-square me-2"
                                                                    style={{ color: "#198BEC" }}></i> Update</td></li>
                                                            </ul>
                                                        </li>
                                                    </td> */}
                                                </tr>
                                            }) : ""

                                        }


                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    )
}

export default AdminBid
