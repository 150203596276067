import React, { useEffect, useState } from 'react'
import Ssidebar from '../Sidebar/Ssidebar';
import Swal from 'sweetalert2';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'
import 'react-tagsinput/react-tagsinput.css'
import './Accountdetail.css'
import Navbar from '../../compunent/Navbar';

const Accountdetail = () => {
    const [toggle, setToggle] = useState(true);
    const navigate = useNavigate();
    const Toggle = () => {
        setToggle(!toggle);
    };

    const token = localStorage.getItem('studenttoken')

    useEffect(() => {
        getstudents()
    }, [])

    const [bankName, setbankName] = useState("")
    const [accountNumber, setaccountNumber] = useState("")
    const [IBAN, setIBAN] = useState("")
    const [accountTitle, setaccountTitle] = useState("")


    const handlesave = () => {
        console.warn(bankName,accountNumber,accountTitle,IBAN)
        if (bankName && accountNumber&& accountTitle&& IBAN) {
        axios.post("https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/student/StudentAccountInfo", {bankName,accountNumber,accountTitle,IBAN }, {
            headers: {
                Authorization: token,
            },
        }).then((res) => {
            if (res.data.message === "account information is added") {
                Swal.fire({
                    title: "Thank you",
                    text: "Account information is added",
                    icon: "success"
                });

                getstudents()
            } else {
                console.log(res.data)

            }
        }).catch((e) => {
            console.log(e)
        })
        } else {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "please fill input",
            });
        }
    }

    const getstudents = () => {
        axios.get("https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/student/StudentProfile", {
            headers: {
                Authorization: token,
            },
        })
            .then((res) => {
                console.log(res.data.data)
                if (res.data.message === "Student profile") {
                  
                    setbankName(res.data.data[0].accountInfo.bankName)
                    setaccountNumber(res.data.data[0].accountInfo.accountNumber)
                    setaccountTitle(res.data.data[0].accountInfo.accountTitle)
                    setIBAN(res.data.data[0].accountInfo.IBAN)         
         
                } else {
                    navigate("/")
                }

            }).catch((e) => {
                console.log(e)
            })
    }
  return (
   <div>
    <Navbar/>
     <div className="container-fluid">
            <div className="row">
                {toggle && (
                    <div
                        className="col-12 col-md-3 vh-100 position-fixed"
                        style={{ zIndex: 1000 }}
                    >
                        <Ssidebar Toggle={Toggle} />
                    </div>
                )}

                {toggle && <div className="col-12 col-lg-3"></div>}
                <div className="col">
                    {/* <Studentnav Toggle={Toggle} /> */}
                    <div className='container-fluid account-main'>
                        <div className='row mt-3 text-start'>
                            <h2>{toggle?"":<i className="fa-solid fa-bars" onClick={Toggle} style={{cursor: "pointer"}}></i>} Bank account details</h2>
                        </div>
                        <div>
                            <div className='row text-start mt-4 acc-form'>
                                <div className='col-lg-4 col-12'>
                                <div className="mb-3">
                                        <label for="people" className="form-label">Bank name</label>
                                        <input type="text" className="form-control" id="people"
                                            value={bankName} onChange={(e) => setbankName(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className='col-lg-4 col-12'>
                                <div className="mb-3">
                                        <label for="people" className="form-label">Account title</label>
                                        <input type="text" className="form-control" id="people"
                                            value={accountTitle} onChange={(e) => setaccountTitle(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className='col-lg-4 col-12'>
                                    <div className="mb-3">
                                        <label for="people" className="form-label">Account #</label>
                                        <input type="text" className="form-control" id="people"
                                            value={accountNumber} onChange={(e) => setaccountNumber(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className='row text-start acc-form'>
                                <div className='col-lg-4 col-12'>
                                <div className="mb-3">
                                        <label for="people" className="form-label">IBAN</label>
                                        <input type="text" className="form-control" id="people"
                                            value={IBAN} onChange={(e) => setIBAN(e.target.value)}
                                        />
                                    </div>
                                </div>
                                
                               
                            </div>

                            
                        </div>

                        <div className='row mt-3 btn-row'>
                            <div className='col d-flex align-items-start justify-content-start'>
                                <button className='btn btn-primary' onClick={handlesave}>Save</button>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </div>
   </div>

  )
}

export default Accountdetail
