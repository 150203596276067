import React, { useEffect, useState } from 'react'
// import Ssidebar from '../Sidebar/Ssidebar';
import Agentnav from '../sideandnav/Agentnav';
import Agentside from '../sideandnav/Agentside';
import './Agentpersonal.css'
import Swal from 'sweetalert2';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'
import './DocumentAgent.css'

const DocumentAgent = () => {
    const [toggle, setToggle] = useState(true);
    const navigate = useNavigate();
    const Toggle = () => {
        setToggle(!toggle);
    };

    const token = localStorage.getItem('agenttoken')

    useEffect(() => {
        getagent()
    }, [])


    const [firstName, setfirstName] = useState("")
    const [lastName, setlastName] = useState("")
    const [gender, setgender] = useState("")

    const [businessLicenses, setbusinessLicenses] = useState()
    const [taxIdentification, settaxIdentification] = useState()


    const handlesave = () => {
        // console.warn(firstName,lastName,gender)

        // if (firstName && lastName && gender) {
        const formdataa = new FormData();
        formdataa.append("businessLicenses", businessLicenses);
        formdataa.append("taxIdentification", taxIdentification);
        axios.post("https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/consultant/ChannelPartnerDocumentsAndAttachments", formdataa, {
            headers: {
                Authorization: token,
            },
        }).then((res) => {
            if (res.data.message === "Personal information is added") {
                Swal.fire({
                    title: "Thank you",
                    text: "Personal details is added",
                    icon: "success"
                });
            } else {
                console.log(res.data)

            }
        }).catch((e) => {
            console.log(e)
        })
        // } else {
        //     Swal.fire({
        //         icon: "error",
        //         title: "Oops...",
        //         text: "please fill input",
        //     });
        // }
    }

    const getagent = () => {
        axios.get("https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/consultant/ChannelPartnerProfile", {
            headers: {
                Authorization: token,
            },
        })
            .then((res) => {
                console.log(res.data)
                if (res.data.message === "Channel Parnter profile") {
                    setfirstName(res.data.data.personalInfo.firstName)
                    setlastName(res.data.data.personalInfo.lastName)
                    setgender(res.data.data.personalInfo.gender)

                } else {
                    navigate("/")
                }

            }).catch((e) => {
                console.log(e)
            })
    }

 
    return (
        <div className="container-fluid">
            <div className="row">
                {toggle && (
                    <div
                        className="col-12 col-md-3 vh-100 position-fixed"
                        style={{ zIndex: 1000 }}
                    >
                        <Agentside Toggle={Toggle} />
                    </div>
                )}

                {toggle && <div className="col-12 col-lg-3"></div>}
                <div className="col">
                    <Agentnav Toggle={Toggle} />
                    <div className='container-fluid documentAgent-main'>
                        <div className='row mt-3 text-start'>
                            <h2>Document and attachments</h2>
                        </div>
                        <div>
                            <div className='row text-start mt-4 acc-form'>
                                <div className='col-lg-4 col-12'>
                                    <div className="mb-3">
                                        <label for="people" className="form-label">Business licenses</label>
                                        <input type="file" className="form-control" id="people"
                                            onChange={(e) => setbusinessLicenses(e.target.files[0])}
                                        />
                                    </div>
                                </div>
                                <div className='col-lg-4 col-12'>
                                    <div className="mb-3">
                                        <label for="people" className="form-label">Tax identification </label>
                                        <input type="file" className="form-control" id="people"
                                            onChange={(e) => settaxIdentification(e.target.files[0])}
                                        />
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className='row mt-3 btn-row'>
                            <div className='col d-flex align-items-start justify-content-start'>
                                <button className='btn btn-primary' onClick={handlesave}>Save</button>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </div>
    )
}

export default DocumentAgent
