
import './Agent.css';


function Agent() {
    return (
        <div className='main-agent mt-4'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-7'>
                        <div className='agent-contant pt-5 ps-2 text-start'>
                            <div className='row'>
                                <h4 className='agent-h4'>Global Learn And Earn
                                </h4>
                            </div>

                            <div className='row'>
                                <h2 className='agent-h2'>To Become a Business Associates
                                </h2>
                            </div> 

                            <div className='row'>
                                <div className='col-md-6'>
                                    <p className='agent-p'>Were Trusted by 68000 Satisfied Clients Across the World for Best Visa & Immigration</p>
                                </div>
                                <div className='col-md-6'></div>
                            </div>
                        </div>

                        <div className='row ps-3 mb-5'>
                            <button className='agent-btn2'>Apply Now</button>
                        </div>
                    </div>
                    <div className='col-md-5'>
                        <div className='row'>

                        </div>
                    </div>

                </div>
            </div>

        </div>
    );
}

export default Agent;