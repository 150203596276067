import React, { useEffect, useState } from 'react'
import { Routes, Route } from 'react-router-dom'
import Register from '../pages/register/Register'
import Linkhome from '../compunent/Linkhome'
import About from '../pages/about/About'
import Contact from '../pages/contact/Contact'
import Acmodation from '../pages/accommodation/Acmodation'
import Airport from '../pages/airportpickup/Airport'
import Jobfinding from '../pages/job finding/Jobfinding'
import Mdash from '../admin/Dasboardpage/Mdash'
import Local from '../admin/Add local/Local'
import Aaccodation from '../admin/add accomodation/Aaccodation'
import Allocate from '../admin/allocate accmodation/Allocate'
import Profile from '../admin/profile/Profile'
import Term from '../admin/Terms and condition/Term'
import Adminroute from '../admin/Adminroute'
import Login from '../pages/login/Login'
import Addroom from '../admin/add rooms/Addroom'
import Addbank from '../admin/add bank/Addbank'
import Commissionformula from '../admin/commission formula/Commissionformula'
import Student from '../admin/student/Student'
import Bookings from '../admin/bookings/Bookings'
import Approval from '../admin/approval/Approval'
import Confirmed from '../admin/bookings/Confirmed'
import Detached from '../admin/bookings/Detached'
import Allagent from '../admin/agent/Allagent'
import Newregistrants from '../admin/agent/Newregistrants'
// import Studentprofile from '../student panel/pages/Studentprofile'
import Studentroute from '../student panel/Studentroute'
import Personalinformation from '../student panel/pages/Personalinformation'
import Address from '../student panel/pages/Address'
import Academic from '../student panel/pages/Academic'
import Accountdetail from '../student panel/pages/Accountdetail'
import Admissioninfo from '../student panel/pages/Admissioninfo'
import Studentexperience from '../student panel/pages/Studentexperience'
import Traveldetail from '../student panel/pages/Traveldetail'
import Visadetail from '../student panel/pages/Visadetail'
import Emergency from '../student panel/pages/Emergency'
import Socialcontact from '../student panel/pages/Socialcontact'
import Document from '../student panel/pages/Document'
import Accommodation from '../student panel/accommodation student/accommodation'
import PromoCode from '../student panel/pages/PromoCode'
import Leads from '../admin/agent/Leads'
import Viewdetail from '../student panel/accommodation student/Viewdetail'
import Myorder from '../student panel/pages/Myorder'
import History from '../student panel/accommodation student/History'
import Agentpersonal from '../ageny panel/pages/Agentpersonal'
import Agentroute from '../ageny panel/Agentroute'
import Businessaddress from '../ageny panel/pages/Businessaddress'
import Organizationdetails from '../ageny panel/pages/Organizationdetails'
import Agentaccount from '../ageny panel/pages/Agentaccount'
import DealInstitute from '../ageny panel/pages/DealInstitute'
import DocumentAgent from '../ageny panel/pages/DocumentAgent'
import Locations from '../pages/location/Locations'
import Citydetail from '../pages/location/Citydetail'
import LettingRegister from '../pages/register/LettingRegister'
import Bidpost from '../Letting Agent Panel/pages/Bidpost'
import Lettingroute from '../Letting Agent Panel/Lettingroute'
import AddADT from '../Letting Agent Panel/pages/AddADT'
import AdminLogin from '../pages/login/AdminLogin'
import LettingAddroom from '../Letting Agent Panel/pages/Addroom'
import AdminBid from '../admin/agent/AdminBid'
import ScrollToTop from '../compunent/ScrollTop'
import StudentRegister from '../pages/register/StudentRegister'

const Routers = () => {
    const [toggle, setToggle] = useState(true);
    const [item1, setitem] = useState("");

    const Toggle = () => {
        setToggle(!toggle);
    };

    useEffect(() => {
        const item = localStorage.getItem("admin")
        
    }, [])

    return (
        <div>
            <ScrollToTop />
            <Routes>
                <Route path='/' element={<Linkhome />}></Route>
                <Route path='/agent-register' element={<Register />}></Route>
                <Route path='/student-register' element={<StudentRegister />}></Route>
                <Route path='/letting-agent-register' element={<LettingRegister />}></Route>
                <Route path='/sign' element={<Login />}></Route>
                <Route path='/about' element={<About />}></Route>
                <Route path='/contact' element={<Contact />}></Route>
                <Route path='/accommodation' element={<Acmodation />}></Route>
                <Route path='/airport-pickup' element={<Airport />}></Route>
                <Route path='/job-finding' element={<Jobfinding />}></Route>
                <Route path='/locations' element={<Locations />}></Route>
                <Route path='/locations/:id' element={<Citydetail />}></Route>

                <Route path='/admin' element={<AdminLogin />}></Route>
                <Route element={<Adminroute />}>
                    <Route path='/admin-dashboard' element={<Mdash />}></Route>
                    <Route path='/add-locals' element={<Local />}></Route>
                    <Route path='/add-accommodation' element={<Aaccodation />}></Route>
                    <Route path='/allocate-accommodation' element={<Allocate />}></Route>
                    <Route path='/profile' element={<Profile />}></Route>
                    <Route path='/terms-&-conditions' element={<Term />}></Route>
                    <Route path='/add-rooms' element={<Addroom />}></Route>
                    <Route path='/add-bankaccount' element={<Addbank />}></Route>
                    <Route path='/commission-formula' element={<Commissionformula />}></Route>
                    <Route path='/students' element={<Student />}></Route>
                    <Route path='/bookings' element={<Bookings />}></Route>
                    <Route path='/waiting-for-approval' element={<Approval />}></Route>
                    <Route path='/confirmed' element={<Confirmed />}></Route>
                    <Route path='/detached' element={<Detached />}></Route>
                    <Route path='/all-agents' element={<Allagent />}></Route>
                    <Route path='/new-registrants' element={<Newregistrants />}></Route>
                    <Route path='/leads' element={<Leads />}></Route>
                    <Route path='/admin-bit' element={<AdminBid />}></Route>
                </Route>


                <Route element={<Studentroute />}>
                    <Route path='/personal-information' element={<Personalinformation />}></Route>
                    <Route path='/address-information' element={<Address />}></Route>
                    <Route path='/academic-details' element={<Academic />}></Route>
                    <Route path='/account-details' element={<Accountdetail />}></Route>
                    <Route path='/admission-details' element={<Admissioninfo />}></Route>
                    <Route path='/student-experience' element={<Studentexperience />}></Route>
                    <Route path='/travel-details' element={<Traveldetail />}></Route>
                    <Route path='/visa-details' element={<Visadetail />}></Route>
                    <Route path='/emergency-contact' element={<Emergency />}></Route>
                    <Route path='/social-contact' element={<Socialcontact />}></Route>
                    <Route path='/document-&-attachments' element={<Document />}></Route>
                    <Route path='/view-accommodation' element={<Accommodation />}></Route>
                    <Route path='/view-accommodation/:id' element={<Viewdetail />}></Route>
                    <Route path='/promo-code' element={<PromoCode />}></Route>
                    <Route path='/my-orders' element={<Myorder />}></Route>
                    <Route path='/history' element={<History />}></Route>
                </Route>


                <Route element={<Agentroute />}>
                    <Route path='/personal-details' element={<Agentpersonal />}></Route>
                    <Route path='/business-address' element={<Businessaddress />}></Route>
                    <Route path='/organization-details' element={<Organizationdetails />}></Route>
                    <Route path='/account-detail' element={<Agentaccount />}></Route>
                    <Route path='/deal-institute' element={<DealInstitute />}></Route>
                    <Route path='/document-and-attachments' element={<DocumentAgent />}></Route>
                </Route>

                <Route element={<Lettingroute />}>
                    <Route path='/bid-post' element={<Bidpost />}></Route>
                    <Route path='/letting-accommodation' element={<AddADT />}></Route>
                    <Route path='/letting-room' element={<LettingAddroom />}></Route>
                </Route>

            </Routes>


        </div>
    )
}

export default Routers
