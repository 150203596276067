import React, { useEffect } from 'react'
import './Citydetail.css'
import Navbar from '../../compunent/Navbar'
import Footer from '../../compunent/Footer'
import line from '../../assiet/img/line.png'
import lettingagent from '../../assiet/img/view-details.jpg'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
// import required modules
import { Pagination } from 'swiper/modules';
import temp from '../../assiet/img/weather_14182895.png'
import people from '../../assiet/img/people.png'
import univerty from '../../assiet/img/university.png'

const Citydetail = () => {
    useEffect(() =>{
        window.scrollTo({top: 0, left: 0, behavior: "smooth"})
    },[])

    return (
        <div>
            <Navbar />
            <div className='Citydetail-main'>
                <div className='banner-box py-3'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col'>
                                <h1>Accommodation Solutions Tailored Just For You</h1>
                            </div>
                        </div>
                        <div className='row px-md-5'>
                            <div className='col px-md-5'>
                                <h4>It is a long established fact that a reader will be distracted by the readable content
                                    of a page when looking at its layout. The point of using Lorem Ipsum is that it has
                                    a more-or-less normal distribution of letters,
                                    as opposed to using 'Content here, content here', making it look like readable English. </h4>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='container my-5'>
                    <div className='row post-first'>
                        <div className='row'>
                            <div className='col-12'>
                                <h2>Accommodation in London</h2>
                            </div>
                            <div className='col-12'>
                                <img src={line} alt='' className='img-fluid'></img>
                            </div> 
                        </div>

                        <div className='row mt-4'>
                            <div className='col-md-6 ms-3 ms-lg-0'>
                                <img src={lettingagent} alt='' className='img-fluid img-1'></img>
                            </div>
                            <div className='col-md-6 mt-3 mt-lg-0'>
                                <div className='rignt-content text-md-start px-md-5'>
                                    <div className='row mt-2'>
                                        <p className=''>It is a long established fact that a reader will be distracted by
                                            the readable content of a page when looking at its layout. The point of using Lorem Ipsum
                                            is that it has a more-or-less normal distribution of letters, as opposed to using 'Content
                                            here, content here', making it look like readable English. Many desktop publishing packages
                                            and web page editors now use Lorem Ipsum as their default model text.</p>
                                    </div>

                                    <div className='row mt-2'>
                                        <div className='col'>
                                            <button className='btn btn-primary'>Book Now</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='row post-first mt-5'>
                        <div className='row'>
                            <div className='col-12'>
                                <h2>Cost of living in london</h2>
                            </div>
                            <div className='col-12'>
                                <img src={line} alt='' className='img-fluid'></img>
                            </div>
                        </div>

                        <div className='row mt-4'>

                            <div className='col-md-6'>
                                <div className='rignt-content text-md-start px-md-5'>
                                    <div className='row mt-2'>
                                        <p className=''>It is a long established fact that a reader will be distracted by
                                            the readable content of a page when looking at its layout. The point of using Lorem Ipsum
                                            is that it has a more-or-less normal distribution of letters, as opposed to using 'Content
                                            here, content here', making it look like readable English. Many desktop publishing packages
                                            and web page editors now use Lorem Ipsum as their default model text.</p>
                                    </div>

                                    <div className='row mt-2'>
                                        <div className='col'>
                                            <button className='btn btn-primary'>Book Now</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='col-md-6 mt-3 mt-lg-0 ms-3 ms-lg-0'>
                                <img src={lettingagent} alt='' className='img-fluid img-1'></img>
                            </div>
                        </div>
                    </div>

                    <div className='row post-3'>
                        <div className='row mt-4'>
                            <div className='col'>
                                <h2>Key facts of london</h2>
                            </div>
                        </div>

                        <div className='row slider-card mt-2'>
                            <div>
                                <Swiper
                                    slidesPerView={1}
                                    spaceBetween={10}
                                    autoplay={{
                                        delay: 2500,
                                        disableOnInteraction: false,
                                    }}
                                    pagination={{
                                        clickable: true,
                                    }}

                                    breakpoints={{
                                        640: {
                                            slidesPerView: 2,
                                            spaceBetween: 20,
                                        },
                                        768: {
                                            slidesPerView: 4,
                                            spaceBetween: 40,
                                        },
                                        1024: {
                                            slidesPerView: 3,
                                            spaceBetween: 50,
                                        },
                                    }}
                                    modules={[Pagination]}
                                    className="mySwiper"
                                >
                                    <SwiperSlide className='px-md-4'>
                                        <div className='card-1 pb-5 pt-4'>
                                            <div className='row text-center'>
                                                <img src={temp} className='rounded mx-auto d-block'></img>
                                            </div>
                                            <div className='row mt-3'>
                                                <h2>63/40°F</h2>
                                            </div>

                                            <div className='row mt-3'>
                                                <h4>Summer/winter temperature</h4>
                                            </div>
                                        </div>
                                    </SwiperSlide>

                                    <SwiperSlide className='px-md-4'>
                                        <div className='card-1 pb-5 pt-4'>
                                            <div className='row text-center'>
                                                <img src={people} className='rounded mx-auto d-block'></img>
                                            </div>
                                            <div className='row mt-3'>
                                                <h2>8 million</h2>
                                            </div>

                                            <div className='row mt-3'>
                                                <h4>Population</h4>
                                            </div>
                                        </div>
                                    </SwiperSlide>

                                    <SwiperSlide className='px-md-4'>
                                        <div className='card-1 pb-5 pt-4'>
                                            <div className='row text-center'>
                                                <img src={univerty} className='rounded mx-auto d-block'></img>
                                            </div>
                                            <div className='row mt-3'>
                                                <h2>200</h2>
                                            </div>

                                            <div className='row mt-3'>
                                                <h4>Universities</h4>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                </Swiper>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Citydetail
