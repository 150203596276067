import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import Adminnav from '../admin nav/Adminnav'
import Sidebar from '../sidebar/Sidebar'
import './Approval.css'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2'
import Loading from '../../Loading'

const Approval = () => {

    const [toggle, setToggle] = useState(true);
    const token = localStorage.getItem('admintokenglobal')
    const navigate = useNavigate();
    const [loadersubmit, setloadersubmit] = useState(false)
    const Toggle = () => {
        setToggle(!toggle);
    };

    useEffect(() => {
        getapproval()
    }, [])

    const [orderdata, setorderdata] = useState([]);
    const [accommodationType, setaccommodationType] = useState("");
    const [startDate, setstartDate] = useState("");
    const [endDate, setendDate] = useState("");

    const getapproval = () => {
        // setloadersubmit(true)
        axios.post("https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/manageaccommodation/ViewServiceRequestApproval", {}, {
            headers: {
                Authorization: token,
            },
        })
            .then((res) => {
                console.log(res.data)
                if (res.data.message === "Services are found") {
                    setorderdata(res.data.data)
                    setloadersubmit(false)
                } else {
                    navigate("/")
                }

            }).catch((e) => {
                console.log(e)
            })
    }

    const approvalfilter = () => {
        axios.post("https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/manageaccommodation/ViewServiceRequestApproval", { accommodationType, startDate, endDate }, {
            headers: {
                Authorization: token,
            },
        })
            .then((res) => {
                console.log(res.data)
                // if (res.data.data._id) {
                setorderdata(res.data.data)

                // } else {
                //     setorderdata()

                // }

            }).catch((e) => {
                console.log(e)
            })
    }

    const clearfilter = () => {
        getapproval()
        setaccommodationType("")
        setstartDate("")
        setendDate("")
    }

    const formatDate = (mongoDBDate) => {
        const date = new Date(mongoDBDate);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();

        return `${day}/${month}/${year}`;
    };

    const handleClose = () => setShow(false);
    const [show, setShow] = useState(false);
    const [requestId, setrequestId] = useState("")
    const [message, setmessage] = useState("")
    const handleShow = (id, n,) => {
        // console.warn(id)
        setrequestId(id)
        setmessage(n)
        setShow(true)
    };
    const handleupdate = () => {
        // if(uuserRole === "")
        axios.put(`https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/manageaccommodation/NotifyToStudent?requestId=` + requestId, { message }, {
            headers: {
                Authorization: token,
            },
        }).then((res) => {
            console.log(res.data)
            if (res.data.message === "Successfully notified") {
                Swal.fire({
                    title: "Thank you",
                    text: "Successfully message send",
                    icon: "success"
                });
                handleClose()
                getapproval()
            }
        }).catch((e) => {
            console.log(e)
            if (e.response.data.message === "userRole already exist") {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "User role already exist",
                });
            } else {
                console.log(e)

            }
        })
    }

    const [viewImages, setviewImages] = useState("");
    const [lgShow, setLgShow] = useState(false);
    const changehandleClose = () => setShow(false);
    const handleimg = (data) => {
        console.log(data)
        setviewImages(data)
        setLgShow(true)
    }

    const [changelgShow, setchangeLgShow] = useState(false);
    const [changeID, setchangeID] = useState("")
    const [accommodation, setaccommodation] = useState([])
    const [livingId, setlivingId] = useState("");

    const openchangemodel = (id) => {
        setchangeID(id)
        setchangeLgShow(true)
        axios.post("https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/accommodation/GetAccommodation", {}, {
            headers: {
                Authorization: token,
            },
        })
            .then((res) => {
                //  console.log(res.data)
                if (res.data.message === "Accommodation found") {
                    setaccommodation(res.data.data)
                } else {
                    navigate("/")
                }

            }).catch((e) => {
                console.log(e)
            })
    }

    const [filterroomdata, setfilterroomdata] = useState([])

    const filterroom = (e) => {
        e.preventDefault()
        const postCode = e.target.value;
        console.log(postCode)
        if (postCode === "Select accommodation") {
            setfilterroomdata([])
        } else {
            axios.post("https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/accommodation/GetAccommodation", { postCode }, {
                headers: {
                    Authorization: token,
                },
            })
                .then((res) => {
                    // console.log(res.data.data)
                    setfilterroomdata(res.data.data[0].livingDetails)
                }).catch((e) => {
                    console.log(e)
                })
        }

    }


    const Changeaccommodationhandle = () =>{
        console.warn(livingId,changeID)
        if(livingId){
            axios.put(`https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/manageaccommodation/AllocateOtherAccommodationToStudent?requestId=` + changeID, { livingId }, {
                headers: {
                    Authorization: token,
                },
            }).then((res) => {
                console.log(res.data)
                if (res.data.message === "Successfully Share living allocated") {
                    Swal.fire({
                        title: "Thank you",
                        text: "Successfully room allocated",
                        icon: "success"
                    });
                    setchangeLgShow(false)
                    getapproval()
                }
            }).catch((e) => {
                console.log(e)
                if (e.response.data.message === "Sorry Houseful") {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Sorry houseful",
                    });
                } else {
                    console.log(e)
                   
                }
            })
        }else{
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Please fill input",
            });
        }
    }

    const allocatehandle = (requestId) => {
        console.warn(requestId)
        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, allocate!"
        }).then((result) => {
          if (result.isConfirmed) {
            axios.put(`https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/manageaccommodation/AllocateToStudent?requestId=` + requestId,{}, {
              headers: {
                Authorization: token,
              },
            }).then((res) => {
              console.log(res.data)
              if (res.data.message === "Successfully Share living allocated") {
                Swal.fire({
                  title: "Deleted!",
                  text: "Successfully room allocated",
                  icon: "success"
                });
                getapproval()
              }
            }).catch((e) => {
              if (e.response.data.message === "Sorry Houseful") {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Sorry houseful",
                });
            } else {
                console.log(e)
               
            }
            })
    
          }
        });
    
      }

      if (loadersubmit) {
        return (
            <Loading />
        )
    }

    return (
        <div className="container-fluid">
            <div className="row">
                {toggle && (
                    <div
                        className="col-12 col-md-3 vh-100 position-fixed"
                        style={{ zIndex: 1000 }}
                    >
                        <Sidebar Toggle={Toggle} />
                    </div>
                )}

                {toggle && <div className="col-12 col-lg-3"></div>}
                <div className="col">
                    <Adminnav Toggle={Toggle} />

                    <div>
                        <Modal
                            size="lg"
                            show={changelgShow}
                            onHide={() => setchangeLgShow(false)}
                            aria-labelledby="example-modal-sizes-title-lg"
                        >
                            <Modal.Header closeButton>
                                <Modal.Title id="example-modal-sizes-title-lg">
                                    Change accommodation
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body style={{ height: "550px", overflow: "hidden", padding: "10px" }}>
                                <div className='container-fluid modal-btn'>
                                    <div className='row mt-3 btn-row'>
                                        <div className='col-lg-4 col-12'>
                                            <div className="">
                                                <select className="form-select"
                                                    // value={filterid}
                                                    onChange={filterroom}
                                                    aria-label="Default select example">
                                                    <option selected>Select accommodation</option>
                                                    {
                                                        accommodation.map((item) => {
                                                            return <option value={item.postCode} key={item._id}> {item.accommodationCity}  ({item.postCode})</option>
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-12 mt-2 mt-lg-0'>
                                            <div className="mb-3">
                                                <select className="form-select"
                                                    value={livingId}
                                                    onChange={(e) => setlivingId(e.target.value)}
                                                    aria-label="Default select example">
                                                    <option selected>Select room identification #</option>
                                                    {filterroomdata ?
                                                        filterroomdata.map((item) => {
                                                            return <option value={item._id} key={item._id}> {item.roomNumber}</option>
                                                        }) : ""
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className='col-lg-3'>
                                            <button className='btn btn-primary fw-bold' onClick={Changeaccommodationhandle} style={{ background: "#F1BB44", color: "#296013", border: "none" }} >Save changes</button>
                                        </div>
                                        {/* <div className='col'>
                                            <button className='btn btn-primary ms-2 fw-bold' style={{ background: "#F1BB44", color: "#296013", border: "none" }} onClick={clearfilter}>Clear</button>
                                        </div> */}

                                    </div>
                                    <div className='row mt-3'>
                                        <div className='col table11' style={{ overflowX: "scroll", height: "450px", overflowY: "scroll" }}>
                                            {filterroomdata ? (
                                                <table className="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Sr.</th>
                                                            <th scope="col">Room id #</th>
                                                            <th scope="col">Room capacity</th>
                                                            <th scope="col">Room availability</th>
                                                            <th scope="col">Independent long stay (£) </th>
                                                            <th scope="col">Shared long stay (£) </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {filterroomdata.map((item, index) => {

                                                            return <tr key={item._id}>
                                                                <th scope="row">{index + 1}</th>
                                                                <td>{item.roomNumber}</td>
                                                                <td>{item.livingCapacity}</td>
                                                                <td>{item.livingAvailability}</td>
                                                                {/* <td>{item.livingIndShortStayPrice}</td> */}
                                                                <td>{item.livingIndLongStayPrice}</td>
                                                                {/* <td>{item.livingShareShortStayPrice}</td> */}
                                                                <td>{item.livingShareLongStayPrice}</td>

                                                            </tr>

                                                        })}
                                                    </tbody>
                                                </table>
                                            ) :""

                                            }
                                        </div>
                                    </div>

                                </div>
                            </Modal.Body>
                        </Modal>
                    </div>

                    <div>
                        <Modal
                            size="lg"
                            show={lgShow}
                            onHide={() => setLgShow(false)}
                            aria-labelledby="example-modal-sizes-title-lg"
                        >
                            <Modal.Header closeButton>
                                <Modal.Title id="example-modal-sizes-title-lg">
                                    View proof
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body style={{ height: "550px", overflow: "hidden", padding: "10px" }}>
                                <div className='container-fluid'>

                                    <div className='row mt-3'>
                                        <div className='col'>
                                            <img src={`https://globallearnandearnapi.doctorsforhealth.co.uk/` + viewImages} alt='img' className='img-fluid'></img>

                                        </div>
                                    </div>


                                </div>
                            </Modal.Body>
                        </Modal>
                    </div>

                    <Modal show={show} onHide={changehandleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title style={{ color: "#296013" }}>Message to student</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form>
                                <div className='container-fluid'>
                                    <div className='row'>
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg col-12'>
                                            <div class="mb-3">
                                                <label for="exampleFormControlTextarea1" class="form-label fw-bold" style={{ color: "gray" }}>Message to student</label>
                                                <textarea style={{ height: "300px" }} className="form-control"
                                                    value={message}
                                                    onChange={(e) => setmessage(e.target.value)}
                                                    id="exampleFormControlTextarea1" rows="3"></textarea>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" className='fw-bold' style={{ backgroundColor: "#F1BB44", border: "none", color: "black" }} onClick={handleClose}>
                                Close
                            </Button>
                            <Button variant="primary" onClick={handleupdate} style={{ backgroundColor: "#296013", border: "none" }}>
                                Save
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <div className='container-fluid approval-main'>
                        <div className='row mt-3 text-start'>
                            <h2>Bookings {">"} Waiting for approvals</h2>
                        </div>
                        <div>
                            <div className='row text-start mt-4 acc-form'>
                                <div className='col-lg-4 col-12'>
                                    <div className="mb-3">
                                        <label for="exampleInputEmail1" className="form-label">Accommodation type </label>
                                        <select className="form-select"
                                            value={accommodationType}
                                            onChange={(e) => setaccommodationType(e.target.value)}
                                            aria-label="Default select example">
                                            <option selected>Select type</option>
                                            <option value="share">Shared</option>
                                            <option value="independent">Independent</option>
                                        </select>
                                    </div>
                                </div>
                                <div className='col-lg-4 col-12'>
                                    <div className="mb-3">
                                        <label for="people" className="form-label">Check in date</label>
                                        <input type="date" className="form-control" id="people"
                                            value={startDate} onChange={(e) => setstartDate(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className='col-lg-4 col-12'>
                                    <div className="mb-3">
                                        <label for="people" className="form-label">Check out date</label>
                                        <input type="date" className="form-control" id="people"
                                            value={endDate} onChange={(e) => setendDate(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row mt-3 btn-row'>
                            <div className='col-lg-1 col-6 d-flex align-items-start justify-content-start'>
                                <button className='btn btn-primary' onClick={approvalfilter}>Filter</button>
                            </div>
                            <div className='col-lg-1 col-6 d-flex align-items-start justify-content-start'>
                                <button className='btn btn-primary' onClick={clearfilter}>Clear</button>
                            </div>
                            <div className='col-lg'></div>
                        </div>

                        <div className='row'>
                            <div className='col table11'>
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">Sr.</th>
                                            <th scope="col">Role</th>
                                            <th scope="col">Name</th>
                                            <th scope="col">Email</th>
                                            <th scope="col">Phone</th>
                                            <th scope="col">Check in</th>
                                            <th scope="col">Check out</th>
                                            <th scope="col">Accommodation type</th>
                                            <th scope="col">Room availability</th>
                                            <th scope="col">Booking status</th>
                                            <th scope="col">Total amount</th>
                                            <th scope="col">Discount amount</th>
                                            <th scope="col">View proof</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {orderdata ?
                                            orderdata.map((item, index) => {
                                                return <tr key={item._id}>
                                                    <th scope="row">{index + 1}</th>
                                                    <td>{item.userRole}</td>
                                                    <td>{item.name}</td>
                                                    <td>{item.email}</td>
                                                    <td>{item.phone}</td>
                                                    <td>{formatDate(item.checkInDate)}</td>
                                                    <td>{formatDate(item.checkOutDate)}</td>
                                                    <td>{item.accommodationType}</td>
                                                    <td>{item.livingId.livingAvailability}</td>
                                                    <td>{item.bookingStatus}</td>
                                                    <td>{item.totalAmountPrice}</td>
                                                    <td>{item.discountAmount}</td>
                                                    <td style={{ cursor: "pointer" }} onClick={() => handleimg(item.proof)}><i className="fa-solid fa-eye"></i></td>
                                                    <td>
                                                        <li className="nav-item dropdown" style={{ listStyle: "none" }}>
                                                            <button className="btn btn-primary dropdown-toggle" style={{ backgroundColor: "#296013", border: "none", height: "30px" }} role="button" data-bs-toggle="dropdown" aria-expanded="false">

                                                            </button>
                                                            <ul className="dropdown-menu">
                                                                <li style={{ cursor: "pointer" }} onClick={() => allocatehandle(item._id)}><td className="dropdown-item"><i class="fa-solid fa-circle-check me-2" style={{ color: "#A5DC86" }}></i> Allocate</td></li>
                                                                <li style={{ cursor: "pointer" }}
                                                                    onClick={() => handleShow(item._id, item.notifyByAdmin)}
                                                                ><td className="dropdown-item"><i className="fa-solid fa-pen-to-square me-2"
                                                                    style={{ color: "#198BEC" }}></i> Message student</td></li>
                                                                <li style={{ cursor: "pointer" }}
                                                                    onClick={() => openchangemodel(item._id)}
                                                                ><td className="dropdown-item"><i className="fa-solid fa-pen-to-square me-2"
                                                                    style={{ color: "#198BEC" }}></i>Change accommodation</td></li>
                                                            </ul>
                                                        </li>
                                                    </td>
                                                </tr>
                                            }) : ""

                                        }


                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    )
}

export default Approval
