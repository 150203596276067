import React, { useEffect } from 'react'
import './Allocate.css'
import { useState } from 'react'
import Adminnav from '../admin nav/Adminnav'
import Sidebar from '../sidebar/Sidebar'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Loading from '../../Loading'

const Allocate = () => {
    const [toggle, setToggle] = useState(true);
    const [Dnumber, setDnumber] = useState(0);
    const token = localStorage.getItem('admintokenglobal')
    const [Allocate, setAllocate] = useState([])
    const [localdata, setlocaldata] = useState([])
    const [accommodation, setaccommodation] = useState([]);
    const [loadersubmit, setloadersubmit] = useState(false)
    const navigate = useNavigate();

    const Toggle = () => {
        setToggle(!toggle);
    };

    const handlestay = (e) => {
        e.preventDefault()
        const value = e.target.value;
        console.log(value)
        if (value === "short") {
            setDnumber(1)
            setaccommodationStay("short")
        } else if (value === "long") {
            setDnumber(2)
            setaccommodationStay("long")
        } else {
            setDnumber(0)
        }
    }

    useEffect(() => {
        getlocals()
        getlocalid()
        getaccommodation()
        getrooms()
    }, [])

    const getlocalid = () => {
        axios.post("https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/manualuser/GetManualUser",{}, {
            headers: {
                Authorization: token,
            },
        })
            .then((res) => {
                // console.warn(res.data)
                if (res.data.message === "manual user profiles") {
                    setlocaldata(res.data.data)
                } else {
                    navigate("/")
                }

            }).catch((e) => {
                console.log(e)
            })
    }

    const getaccommodation = () => {
        axios.post("https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/accommodation/GetAccommodation", {}, {
            headers: {
                Authorization: token,
            },
        })
            .then((res) => {
                //  console.log(res.data)
                if (res.data.message === "Accommodation found") {
                    setaccommodation(res.data.data)
                } else {
                    navigate("/")
                }

            }).catch((e) => {
                console.log(e)
            })
    }


    const getlocals = () => {
        // setloadersubmit(true)
        axios.post("https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/manageaccommodation/ViewReport", {}, {
            headers: {
                Authorization: token,
            },
        })
            .then((res) => {
                console.warn(res.data)
                if (res.data.message === "Report found") {
                    setAllocate(res.data.data)
                    setloadersubmit(false)
                } else {
                    navigate("/")
                }

            }).catch((e) => {
                console.log(e)
            })
    }

    const [filterroomdata, setfilterroomdata] = useState([])

    const filterroom = (e) => {
        e.preventDefault()
        const postCode = e.target.value;
        console.log(postCode)
        if (postCode === "Select accommodation") {
            setfilterroomdata([])
        } else {
            axios.post("https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/accommodation/GetAccommodation", { postCode }, {
                headers: {
                    Authorization: token,
                },
            })
                .then((res) => {
                    // console.log(res.data.data)
                    setfilterroomdata(res.data.data[0].livingDetails)
                }).catch((e) => {
                    console.log(e)
                })
        }

    }

    // Allocate post api 
    const [livingId, setlivingId] = useState("");
    const [userId, setuserId] = useState("");
    const [accommodationType, setaccommodationType] = useState("");
    const [accommodationStay, setaccommodationStay] = useState("");
    const [checkInDate, setcheckInDate] = useState();
    const [checkOutDate, setcheckOutDate] = useState();
    const [duration, setduration] = useState();
    const [totalAmountPrice, settotalAmountPrice] = useState();

    const handleallocatepost = () => {
        console.warn(livingId, userId, accommodationStay, accommodationType, checkInDate, checkOutDate, duration, totalAmountPrice)
        if (accommodationStay && accommodationType && checkInDate && checkOutDate && duration && totalAmountPrice) {
            setloadersubmit(true)
            axios.post("https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/manageaccommodation/Allocate", { livingId, userId, accommodationStay, accommodationType, checkInDate, checkOutDate, duration, totalAmountPrice }, {
                headers: {
                    Authorization: token,
                },
            }).then((res) => {
                console.log(res.data)
                if (res.data.message === "Successfully Share living allocated") {
                    Swal.fire({
                        title: "Thank you",
                        text: "Successfully shared room allocated",
                        icon: "success"
                    });
                    setloadersubmit(false)

                } else if (res.data.message === "Successfully Independent living allocated") {
                    Swal.fire({
                        title: "Thank you",
                        text: "Successfully independent room allocated",
                        icon: "success"
                    });
                    setloadersubmit(false)
                    
                }
                setuserId("")
                setlivingId("")
                setaccommodationStay("")
                setaccommodationType("")
                setcheckInDate("")
                setcheckOutDate("")
                setduration("")
                settotalAmountPrice("")
                getlocals()
                setloadersubmit(false)
            }).catch((e) => {
                console.log(e)
                if (e.response.data.message === "Sorry Houseful") {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "House ful",
                    });
                    setloadersubmit(false)
                } else {
                    console.log(e)
                    setloadersubmit(false)
                }
            })

        } else {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Please fill input",
            });
        }
    }

    // update allocate post api
    const [name, setname] = useState("");
    const [phone, setphone] = useState("");
    const [paymentStatus, setpaymentStatus] = useState("")
    const [bookingStatus, setbookingStatus] = useState("")
    const [utotalAmountPrice, setutotalAmountPrice] = useState();
    const [reportid, setreportid] = useState("")
    const handleClose = () => setShow(false);
    const [show, setShow] = useState(false);

    const handleShow = (id, n, e, p, c, ad, co,) => {
        setreportid(id)
        setname(n)
        setphone(e)
        setutotalAmountPrice(p)
        setbookingStatus(c)
        setpaymentStatus(ad)
        setShow(true)
    };

    const handleupdate = () => {

        const totalAmountPrice = utotalAmountPrice;
        setloadersubmit(true)
        axios.put(`https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/manageaccommodation/UpdateReport/?reportId=` + reportid,
            { name, phone, totalAmountPrice, bookingStatus, paymentStatus }
            , {
                headers: {
                    Authorization: token,
                },
            }).then((res) => {
                console.log(res.data)
                if (res.data.message === "Report is updated Successfully") {
                    setloadersubmit(false)
                    Swal.fire({
                        title: "Thank you",
                        text: "Report is updated Successfully",
                        icon: "success"
                    });
                    getlocals()
                    handleClose()
                }
            }).catch((e) => {
                console.log(e)
                //   if (e.response.data.message === "Email already exist") {
                //     Swal.fire({
                //       icon: "error",
                //       title: "Oops...",
                //       text: "Email already exist",
                //     });
                //   }else if(e.response.data.message === "NI already exist") {
                //     Swal.fire({
                //       icon: "error",
                //       title: "Oops...",
                //       text: "NI already exist",
                //     });
                //   } else {
                //     console.log(e)

                //   }
                setloadersubmit(false)
            })

    }

    const deallocatehandle = (livingId, id) => {
        console.warn(livingId, id)
        if (livingId && id) {
            Swal.fire({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, Deallocate"
            }).then((result) => {
                if (result.isConfirmed) {
                    axios.post("https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/manageaccommodation/deAllocate", { livingId, id }, {
                        headers: {
                            Authorization: token,
                        },
                    }).then((res) => {
                        console.log(res.data)
                        if (res.data.message === "Successfully deallocated") {
                            Swal.fire({
                                title: "Thank you",
                                text: "Successfully Deallocated",
                                icon: "success"
                            });
                            getlocals()
                        }
                    }).catch((e) => {
                        console.log(e)
                        if (e.response.data.message === "Not found") {
                            Swal.fire({
                                icon: "error",
                                title: "Oops...",
                                text: "Not found",
                            });
                        }else if(e.response.data.message === "Manual user not found"){
                            Swal.fire({
                                icon: "error",
                                title: "Oops...",
                                text: "Manual user not found",
                            });
                        } else {
                            console.log(e)

                        }
                    })

                }
            });
        } else {
            console.warn("living id not found")
        }
    }
    const [lgShow, setLgShow] = useState(false);
    const [filterid, setfilterid] = useState("");
    const [roonsdata, setroonsdata] = useState([])
    const [filterdata, setfilterdata] = useState()
    const getrooms = () => {
        axios.post("https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/livingdetails/GetLivingDetails", {}, {
            headers: {
                Authorization: token,
            },
        })
            .then((res) => {
                console.log(res.data)
                if (res.data.message === "Living detail found") {
                    setroonsdata(res.data.data)
                } else {
                    navigate("/")
                }

            }).catch((e) => {
                console.log(e)
            })
    }

    const Filterid = () => {
        const postCode = filterid;

        axios.post("https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/accommodation/GetAccommodation", { postCode }, {
            headers: {
                Authorization: token,
            },
        })
            .then((res) => {
                // console.log(res.data.data[0].livingDetails)
                // if (res.data.message === "Accommodation found") {
                setfilterdata(res.data.data[0].livingDetails)
                // } else {
                //     navigate("/")
                // }

            }).catch((e) => {
                console.log(e)
            })
    }

    const clearfilter = () => {
        setfilterid("")
        getrooms()
        setfilterdata("")
    }

    const formatDate = (mongoDBDate) => {
        const date = new Date(mongoDBDate);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();

        return `${day}/${month}/${year}`;
    };

    const [Shortshared, setShortshared] = useState("")

    const handlestatetotal = (e) => {
        e.preventDefault()
        const values = e.target.value;
        setduration(values)
        // console.log(values,accommodationType,accommodationStay,livingId)
        axios.get(`https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/livingdetails/GetSpecificLivingDetails/?id=` + livingId, {
            headers: {
                Authorization: token,
            },
        }).then((res) => {
            if (accommodationType === "share" && accommodationStay === "short") {
                const totalamout = res.data.data.livingShareShortStayPrice;
                const totalvalue = totalamout * values
                // console.log(totalvalue)
                setShortshared(totalvalue)
                settotalAmountPrice(totalvalue)
            } else if (accommodationType === "share" && accommodationStay === "long") {
                const totalamout = res.data.data.livingShareLongStayPrice;
                const totalvalue = totalamout * values
                // console.log(totalvalue)
                setShortshared(totalvalue)
                settotalAmountPrice(totalvalue)
            } else if (accommodationType === "independent" && accommodationStay === "short") {
                const totalamout = res.data.data.livingIndShortStayPrice;
                const totalvalue = totalamout * values
                // console.log(totalvalue)
                settotalAmountPrice(totalvalue)
                setShortshared(totalvalue)
            } else if (accommodationType === "independent" && accommodationStay === "long") {
                const totalamout = res.data.data.livingIndLongStayPrice;
                const totalvalue = totalamout * values
                // console.log(totalvalue)
                setShortshared(totalvalue)
                settotalAmountPrice(totalvalue)
            }
        }).catch((e) => {
            console.log(e)
        })
    }

    
    if (loadersubmit) {
        return (
            <Loading />
        )
    }


    return (
        <div className="container-fluid">
            <div className="row">
                {toggle && (
                    <div
                        className="col-12 col-md-3 vh-100 position-fixed"
                        style={{ zIndex: 1000 }}
                    >
                        <Sidebar Toggle={Toggle} />
                    </div>
                )}

                {toggle && <div className="col-12 col-lg-3"></div>}
                <div className="col">
                    <Adminnav Toggle={Toggle} />

                    <div>
                        <Modal
                            size="lg"
                            show={lgShow}
                            onHide={() => setLgShow(false)}
                            aria-labelledby="example-modal-sizes-title-lg"
                        >
                            <Modal.Header closeButton>
                                <Modal.Title id="example-modal-sizes-title-lg">
                                    Search vacant property
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body style={{ height: "550px", overflow: "hidden", padding: "10px" }}>
                                <div className='container-fluid modal-btn'>
                                    <div className='row mt-3 btn-row'>
                                        <div className='col-lg-4 col-12'>
                                            <div className="">
                                                <select className="form-select"
                                                    value={filterid}
                                                    onChange={(e) => setfilterid(e.target.value)}
                                                    aria-label="Default select example">
                                                    <option selected>Select accommodation</option>
                                                    {
                                                        accommodation.map((item) => {
                                                            return <option value={item.postCode} key={item._id}> {item.accommodationCity}  ({item.postCode})</option>
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className='col-1'>
                                            <button className='btn btn-primary fw-bold' style={{ background: "#F1BB44", color: "#296013", border: "none" }} onClick={Filterid}>Filter</button>
                                        </div>
                                        <div className='col'>
                                            <button className='btn btn-primary ms-2 fw-bold' style={{ background: "#F1BB44", color: "#296013", border: "none" }} onClick={clearfilter}>Clear</button>
                                        </div>

                                    </div>
                                    <div className='row mt-3'>
                                        <div className='col table11' style={{ overflowX: "scroll", height: "450px", overflowY: "scroll" }}>
                                            {filterdata ? (
                                                <table className="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Sr.</th>
                                                            <th scope="col">Room id #</th>
                                                            <th scope="col">Room capacity</th>
                                                            <th scope="col">Room availability</th>
                                                            <th scope="col">Independent short stay (£) </th>
                                                            <th scope="col">Independent long stay (£) </th>
                                                            <th scope="col">Shared short stay (£)</th>
                                                            <th scope="col">Shared long stay (£) </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {filterdata.map((item, index) => {

                                                            return <tr key={item._id}>
                                                                <th scope="row">{index + 1}</th>
                                                                <td>{item.roomNumber}</td>
                                                                <td>{item.livingCapacity}</td>
                                                                <td>{item.livingAvailability}</td>
                                                                <td>{item.livingIndShortStayPrice}</td>
                                                                <td>{item.livingIndLongStayPrice}</td>
                                                                <td>{item.livingShareShortStayPrice}</td>
                                                                <td>{item.livingShareLongStayPrice}</td>

                                                            </tr>

                                                        })}
                                                    </tbody>
                                                </table>
                                            ) : (
                                                <table className="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Sr.</th>
                                                            <th scope="col">City</th>
                                                            <th scope="col">Postal code</th>
                                                            <th scope="col">Room id #</th>
                                                            <th scope="col">Room capacity</th>
                                                            <th scope="col">Room availability</th>
                                                            <th scope="col">Independent short stay (£) </th>
                                                            <th scope="col">Independent long stay (£) </th>
                                                            <th scope="col">Shared short stay (£)</th>
                                                            <th scope="col">Shared long stay (£) </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {roonsdata.map((item, index) => {

                                                            return <tr key={item._id}>
                                                                <th scope="row">{index + 1}</th>
                                                                <td>{item.accommodationId.accommodationCity}</td>
                                                                <td>{item.accommodationId.postCode}</td>
                                                                <td>{item.roomNumber}</td>
                                                                <td>{item.livingCapacity}</td>
                                                                <td>{item.livingAvailability}</td>
                                                                <td>{item.livingIndShortStayPrice}</td>
                                                                <td>{item.livingIndLongStayPrice}</td>
                                                                <td>{item.livingShareShortStayPrice}</td>
                                                                <td>{item.livingShareLongStayPrice}</td>

                                                            </tr>

                                                        })}
                                                    </tbody>
                                                </table>

                                            )

                                            }
                                        </div>
                                    </div>

                                </div>
                            </Modal.Body>
                        </Modal>
                    </div>

                    <div>
                        <Modal show={show} onHide={handleClose}>
                            <Modal.Header closeButton>
                                <Modal.Title style={{ color: "#296013" }}>Update allocate accommodation</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form>
                                    <div className='container-fluid'>
                                        <div className='row'>
                                            <div className='col-lg col-12'>
                                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                    <Form.Label className='fw-bold' style={{ color: "gray" }}>Name</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        autoFocus
                                                        value={name}
                                                        onChange={(e) => setname(e.target.value)}
                                                    />
                                                </Form.Group>
                                            </div>
                                            <div className='col'>
                                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                    <Form.Label className='fw-bold' style={{ color: "gray" }}>Phone</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        autoFocus
                                                        value={phone}
                                                        onChange={(e) => setphone(e.target.value)}
                                                    />
                                                </Form.Group>
                                            </div>
                                        </div>

                                        <div className='row'>
                                            <div className='col-lg col-12'>
                                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                    <Form.Label className='fw-bold' style={{ color: "gray" }}>Booking status</Form.Label>
                                                    <select className="form-select" aria-label="Default select example" value={bookingStatus} onChange={(e) => setbookingStatus(e.target.value)}>
                                                        <option selected>Select booking status</option>
                                                        <option value="confirmed">Confirmed</option>
                                                        <option value="canceled">Canceled</option>
                                                        <option value="pending">Pending</option>
                                                    </select>
                                                </Form.Group>
                                            </div>
                                            <div className='col'>
                                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                    <Form.Label className='fw-bold' style={{ color: "gray" }}>Payment status</Form.Label>
                                                    <select className="form-select" aria-label="Default select example" value={paymentStatus} onChange={(e) => setpaymentStatus(e.target.value)}>
                                                        <option selected>Select payment status</option>
                                                        <option value="paid">Paid</option>
                                                        <option value="pending">Pending</option>
                                                    </select>
                                                </Form.Group>
                                            </div>

                                        </div>

                                        <div className='row'>
                                            <div className='col-lg col-12'>
                                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                    <Form.Label className='fw-bold' style={{ color: "gray" }}>Total amount</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        autoFocus
                                                        value={utotalAmountPrice}
                                                        onChange={(e) => setutotalAmountPrice(e.target.value)}
                                                    />
                                                </Form.Group>
                                            </div>
                                            {/* <div className='col'>
                                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                    <Form.Label className='fw-bold' style={{ color: "gray" }}>Select image </Form.Label>
                                                    <input multiple type="file" className="form-control" id="exampleInputEmail1"
                                                        onChange={(e) => setuaccommodationImages(e.target.files)}
                                                        aria-describedby="emailHelp" />
                                                </Form.Group>
                                            </div> */}

                                        </div>
                                    </div>
                                </Form>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" className='fw-bold' style={{ backgroundColor: "#F1BB44", border: "none", color: "black" }} onClick={handleClose}>
                                    Close
                                </Button>
                                <Button variant="primary" onClick={handleupdate} style={{ backgroundColor: "#296013", border: "none" }}>
                                    Save Changes
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                    <div className='container-fluid allo-main'>
                        <div className='row mt-3 text-start'>
                            <div className='col-lg col-12'>
                                <h2>Allocate accommodation</h2>
                            </div>

                            <div className='col d-flex align-items-start justify-content-end btn-search'>
                                <button className='btn btn-primary' onClick={() => setLgShow(true)}>Search vacant property</button>
                            </div>
                        </div>

                        <div>
                            <div className='row text-start mt-4 acc-form'>
                                <div className='col-lg-4 col-12'>
                                    <div className="mb-3">
                                        <label for="exampleInputEmail1" className="form-label">Select local (UK tenants)</label>
                                        <select className="form-select" aria-label="Default select example" value={userId} onChange={(e) => setuserId(e.target.value)} >
                                            <option selected>Select local</option>
                                            {localdata ?
                                                localdata.map((item) => {
                                                    return <option value={item._id} key={item.id}>{item.name} ({item.ni})</option>
                                                }) : ""
                                            }

                                        </select>
                                    </div>
                                </div>
                                <div className='col-lg-4 col-12'>
                                    <div className="mb-3">
                                        <label for="exampleInputEmail1" className="form-label">Select accommodation</label>
                                        <select className="form-select"
                                            onChange={filterroom}
                                            aria-label="Default select example">
                                            <option selected>Select accommodation</option>
                                            {accommodation ?
                                                accommodation.map((item) => {
                                                    return <option value={item.postCode} key={item._id}> {item.accommodationCity}  ({item.postCode})</option>
                                                }) : ""
                                            }
                                        </select>
                                    </div>
                                </div>

                                <div className='col-lg-4 col-12'>
                                    <div className="mb-3">
                                        <label for="exampleInputEmail1" className="form-label">Select room identification #</label>
                                        <select className="form-select"
                                            value={livingId}
                                            onChange={(e) => setlivingId(e.target.value)}
                                            aria-label="Default select example">
                                            <option selected>Select room identification #</option>
                                            {filterroomdata ?
                                                filterroomdata.map((item) => {
                                                    return <option value={item._id} key={item._id}> {item.roomNumber}</option>
                                                }) : ""
                                            }
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className='row text-start acc-form'>
                                <div className='col-lg-4 col-12'>
                                    <div className="mb-3">
                                        <label for="exampleInputEmail1" className="form-label">Accommodation type</label>
                                        <select className="form-select" aria-label="Default select example" value={accommodationType} onChange={(e) => setaccommodationType(e.target.value)}>
                                            <option selected>Select type</option>
                                            <option value="share">Shared</option>
                                            <option value="independent">Independent</option>
                                        </select>
                                    </div>
                                </div>
                                <div className='col-lg-4 col-12'>
                                    <div className="mb-3">
                                        <label for="exampleInputEmail1" className="form-label">Accommodation Stay</label>
                                        <select className="form-select" aria-label="Default select example" value={accommodationStay} onChange={handlestay}>
                                            <option selected>Select stay</option>
                                            <option value="short">Short</option>
                                            <option value="long">Long</option>
                                        </select>
                                    </div>
                                </div>
                                <div className='col-lg-4 col-12'>
                                    <div className="mb-3">
                                        <label for="duration" className="form-label">Accommodation duration</label>
                                        <input type="number" className="form-control" id="duration" placeholder={Dnumber === 1 ? "days" : Dnumber === 2 ? "month" : ""}
                                            //  value={duration}
                                            //   onChange={(e) => setduration(e.target.value)}
                                            onChange={handlestatetotal}
                                        />
                                    </div>
                                </div>
                            </div>


                            <div className='row text-start mt-1 acc-form'>
                                <div className='col-lg-4 col-12'>
                                    <div className="mb-3">
                                        <label for="people" className="form-label">Check in date</label>
                                        <input type="date" className="form-control" id="people"
                                            value={checkInDate} onChange={(e) => setcheckInDate(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className='col-lg-4 col-12'>
                                    <div className="mb-3">
                                        <label for="date" className="form-label">Check out date</label>
                                        <input type="date" className="form-control" id="date"
                                            value={checkOutDate} onChange={(e) => setcheckOutDate(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className='col-lg-4 col-12'>
                                    <div className="mb-3">
                                        <label for="duration" className="form-label">Total amount</label>
                                        <input type="number" className="form-control" id="duration"
                                            value={totalAmountPrice} onChange={(e) => settotalAmountPrice(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='row mt-3 btn-row'>
                            <div className='col d-flex align-items-start justify-content-start'>
                                <button onClick={handleallocatepost} className='btn btn-primary'>Submit</button>
                            </div>
                        </div>

                        <div className='row mt-3'>
                            <div className='col table11'>
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">Sr.</th>
                                            <th scope="col">Room id #</th>
                                            <th scope="col">User role</th>
                                            <th scope="col">Name</th>
                                            <th scope="col">Email</th>
                                            <th scope="col">Phone</th>
                                            <th scope="col">Type</th>
                                            <th scope="col">Check in </th>
                                            <th scope="col">Check out</th>
                                            <th scope="col">Status</th>
                                            <th scope="col">Payment status</th>
                                            <th scope="col">Total amount</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Allocate ?
                                            Allocate.map((item, index) => {
                                                return <tr key={item._id}>
                                                    <th scope="row">{index + 1}</th>
                                                    <td>{item.livingId.roomNumber}</td>
                                                    <td>{item.userRole}</td>
                                                    <td>{item.name}</td>
                                                    <td>{item.email}</td>
                                                    <td>{item.phone}</td>
                                                    <td>{item.accommodationType}</td>
                                                    <td>{formatDate(item.checkInDate)}</td>
                                                    <td>{formatDate(item.checkOutDate)}</td>
                                                    <td>{item.bookingStatus}</td>
                                                    <td>{item.paymentStatus}</td>
                                                    <td>{item.totalAmountPrice}</td>

                                                    <td>
                                                        <li className="nav-item dropdown" style={{ listStyle: "none" }}>
                                                            <button className="btn btn-primary dropdown-toggle" style={{ backgroundColor: "#296013", border: "none", height: "30px" }} role="button" data-bs-toggle="dropdown" aria-expanded="false">

                                                            </button>
                                                            <ul className="dropdown-menu">
                                                                {/* <li className='' onClick={() => localdelete(item._id)}><td className="dropdown-item"><i class="fa-solid fa-trash me-2" style={{ color: "#B63A3A" }}></i> Delete</td></li> */}
                                                                <li style={{ cursor: "pointer" }} onClick={() => deallocatehandle(item.livingId._id, item.userId)}><td className="dropdown-item"><i class="fa-solid fa-xmark me-2" style={{ color: "#B63A3A" }}></i> Deallocate</td></li>

                                                                <li style={{ cursor: "pointer" }}
                                                                    onClick={() => handleShow(item._id, item.name, item.phone, item.totalAmountPrice, item.bookingStatus, item.paymentStatus)}
                                                                ><td className="dropdown-item"><i className="fa-solid fa-pen-to-square me-2"
                                                                    style={{ color: "#198BEC" }}></i> Update</td></li>
                                                            </ul>
                                                        </li>
                                                    </td>
                                                </tr>
                                            }) : ""

                                        }


                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    )
}

export default Allocate
