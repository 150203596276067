import React, { useEffect } from 'react'
import { useState } from 'react'
import Adminnav from '../admin nav/Adminnav'
import Sidebar from '../sidebar/Sidebar'
import { useNavigate } from 'react-router-dom'
import './Student.css'
import axios from 'axios'
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import Loading from '../../Loading'

const Student = () => {

    const [toggle, setToggle] = useState(true);
    const token = localStorage.getItem('admintokenglobal')
    const [studentdata, setstudentdata] = useState([])
    const [singlestudentdata, setsinglestudentdata] = useState({})
    const [selectnumber, setselectnumber] = useState("1")
    const [loadersubmit, setloadersubmit] = useState(false)
    const [viewfront, setviewfront] = useState("")
    const [viewback, setviewback] = useState("")
    const navigate = useNavigate();
    const Toggle = () => {
        setToggle(!toggle);
    };

    useEffect(() => {
        getstudents()
    }, [])

    const getstudents = () => {
        setloadersubmit(true)
        axios.get("https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/student/StudentProfile", {
            headers: {
                Authorization: token,
            },
        })
            .then((res) => {
                console.warn(res.data)
                if (res.data.message === "Student profile") {
                    setstudentdata(res.data.data)
                    setloadersubmit(false)
                } else {
                    navigate("/")
                }

            }).catch((e) => {
                console.log(e)
                setloadersubmit(false)
            })
    }

    const formatDate = (mongoDBDate) => {
        const date = new Date(mongoDBDate);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        // console.warn(`${day}/${month}/${year}`)


        return `${year}-${month}-${day}`;
    };

    const [lgShow, setLgShow] = useState(false);

    const Viewdetail = (data) => {
        setLgShow(true)
        setsinglestudentdata(data)
        try {
            if (data.documentsAndAttachments.passportScan[0].frontPage) {
                setviewfront(data.documentsAndAttachments.passportScan[0].frontPage)
                setviewback(data.documentsAndAttachments.passportScan[0].backPage)
            } else if (data.documentsAndAttachments.passportScan[0].backPage) {
                setviewfront(data.documentsAndAttachments.passportScan[0].frontPage)
                setviewback(data.documentsAndAttachments.passportScan[0].backPage)
            } else {
                console.log("hello")

            }
        } catch (error) {
            console.log(error)
        }


    }

    const modalclose = () => {
        setselectnumber("1")
        setLgShow(false)
    }


    if (loadersubmit) {
        return (
            <Loading />
        )
    }

    return (
        <div className="container-fluid">
            <div className="row">
                {toggle && (
                    <div
                        className="col-12 col-md-3 vh-100 position-fixed"
                        style={{ zIndex: 1000 }}
                    >
                        <Sidebar Toggle={Toggle} />
                    </div>
                )}

                {toggle && <div className="col-12 col-lg-3"></div>}
                <div className="col">
                    <Adminnav Toggle={Toggle} />
                    <div>
                        <Modal
                            size="lg"
                            show={lgShow}
                            onHide={modalclose}
                            aria-labelledby="example-modal-sizes-title-lg"
                        >
                            <Modal.Header closeButton>
                                <Modal.Title id="example-modal-sizes-title-lg">
                                    View details
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body style={{ height: "550px", overflow: "hidden", padding: "10px" }}>
                                <div className='container-fluid'>
                                    <div className='row mt-2'>
                                        <div className='col-lg-6'>
                                            <select className="form-select" aria-label="Default select example"
                                                onChange={(e) => setselectnumber(e.target.value)}
                                            >
                                                {/* <option selected>Open this select menu</option> */}
                                                <option value="1">Personal info</option>
                                                <option value="2">Address</option>
                                                <option value="3">Account info</option>
                                                <option value="4">Academic info</option>
                                                <option value="5">User account info</option>
                                                <option value="6">Admission</option>
                                                <option value="7">Experience</option>
                                                <option value="8">Emergency contact</option>
                                                <option value="9">Travel info</option>
                                                <option value="10">Visa info</option>
                                                <option value="11">Documents and attachments</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className='row mt-2' style={{ height: "450px", overflow: "hidden", overflowY: "scroll" }}>
                                        {
                                            selectnumber === "1" ?
                                                <>
                                                    <div className='col-12'>
                                                        <table className="table table-striped">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col" style={{ fontSize: "25px" }}>Personal info</th>

                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <th scope="row">Fore name</th>
                                                                    <td>{singlestudentdata?.personalInfo?.foreName ? singlestudentdata.personalInfo.foreName : "N/A"}</td>

                                                                </tr>
                                                                <tr>
                                                                    <th scope="row">Sur name</th>
                                                                    <td>{singlestudentdata?.personalInfo?.surName ? singlestudentdata.personalInfo.surName : "N/A"}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th scope="row">Gender</th>
                                                                    <td>{singlestudentdata?.personalInfo?.gender ? singlestudentdata.personalInfo.gender : "N/A"}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th scope="row">Date of birth</th>
                                                                    <td>{formatDate(singlestudentdata?.personalInfo?.dateOfBirth ? singlestudentdata.personalInfo.dateOfBirth : "N/A")}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th scope="row">NIC</th>
                                                                    <td>{singlestudentdata?.personalInfo?.NIC ? singlestudentdata.personalInfo.NIC : "N/A"}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th scope="row">Nationality</th>
                                                                    <td>{singlestudentdata?.personalInfo?.nationality ? singlestudentdata.personalInfo.nationality : "N/A"}</td>
                                                                </tr> <tr>
                                                                    <th scope="row">Passport ID</th>
                                                                    <td>{singlestudentdata?.personalInfo?.passportID ? singlestudentdata.personalInfo.passportID : "N/A"}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>

                                                    <div className='col-12'>
                                                        <table className="table table-striped">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col" style={{ fontSize: "25px" }}>Contact info</th>

                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <th scope="row">Email</th>
                                                                    <td>{singlestudentdata?.contactInfo?.email ? singlestudentdata.contactInfo.email : "N/A"}</td>

                                                                </tr>
                                                                <tr>
                                                                    <th scope="row">Phone</th>
                                                                    <td>{singlestudentdata?.contactInfo?.phone ? singlestudentdata.contactInfo.phone : "N/A"}</td>
                                                                </tr>

                                                            </tbody>
                                                        </table>
                                                    </div>

                                                    <div className='col'>
                                                        <table className="table table-striped">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col" style={{ fontSize: "25px" }}>Social contact info</th>

                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <th scope="row">Facebook url</th>
                                                                    <td>{singlestudentdata?.socialContactInfo?.facebookUrl ? singlestudentdata.socialContactInfo.facebookUrl : "N/A"}</td>

                                                                </tr>
                                                                <tr>
                                                                    <th scope="row">Skype Id</th>
                                                                    <td>{singlestudentdata?.socialContactInfo?.skypeId ? singlestudentdata.socialContactInfo.skypeId : "N/A"}</td>
                                                                </tr>

                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </>
                                                : selectnumber === "2" ? <>
                                                    <div className='col'>
                                                        <table className="table table-striped">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col" style={{ fontSize: "25px" }}>Address</th>

                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <th scope="row">Country</th>
                                                                    <td>{singlestudentdata?.address?.country ? singlestudentdata.address.country : "N/A"}</td>

                                                                </tr>
                                                                <tr>
                                                                    <th scope="row">City</th>
                                                                    <td>{singlestudentdata?.address?.city ? singlestudentdata.address.city : "N/A"}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th scope="row">Province</th>
                                                                    <td>{singlestudentdata?.address?.province ? singlestudentdata.address.province : "N/A"}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th scope="row">Postal code</th>
                                                                    <td>{singlestudentdata?.address?.postCode ? singlestudentdata.address.postCode : "N/A"}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th scope="row">Region</th>
                                                                    <td>{singlestudentdata?.address?.region ? singlestudentdata.address.region : "N/A"}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th scope="row">street</th>
                                                                    <td>{singlestudentdata?.address?.street ? singlestudentdata.address.street : "N/A"}</td>
                                                                </tr>

                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </> : selectnumber === "3" ? <>
                                                    <div className='col'>
                                                        <table className="table table-striped">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col" style={{ fontSize: "25px" }}>Account info</th>

                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <th scope="row">Bank name</th>
                                                                    <td>{singlestudentdata?.accountInfo?.bankName ? singlestudentdata.accountInfo.bankName : "N/A"}</td>

                                                                </tr>
                                                                <tr>
                                                                    <th scope="row">Account title</th>
                                                                    <td>{singlestudentdata?.accountInfo?.accountTitle ? singlestudentdata.accountInfo.accountTitle : "N/A"}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th scope="row">Account number</th>
                                                                    <td>{singlestudentdata?.accountInfo?.accountNumber ? singlestudentdata.accountInfo.accountNumber : "N/A"}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th scope="row">IBAN</th>
                                                                    <td>{singlestudentdata?.accountInfo?.IBAN ? singlestudentdata.accountInfo.IBAN : "N/A"}</td>
                                                                </tr>


                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </> : selectnumber === "4" ? <>
                                                    <div className='col'>
                                                        <table className="table table-striped">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col" style={{ fontSize: "25px" }}>Academic info</th>

                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <th scope="row">Last education country</th>
                                                                    <td>{singlestudentdata?.academicInfo?.academicBackground?.lastEducationCountry ? singlestudentdata.academicInfo.academicBackground.lastEducationCountry : "N/A"}</td>

                                                                </tr>
                                                                <tr>
                                                                    <th scope="row">Last qualification</th>
                                                                    <td>{singlestudentdata?.academicInfo?.academicBackground?.lastQualification ? singlestudentdata.academicInfo.academicBackground.lastQualification : "N/A"}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th scope="row">Last subject</th>
                                                                    <td>{singlestudentdata?.academicInfo?.academicBackground?.lastSubject ? singlestudentdata.academicInfo.academicBackground.lastSubject : "N/A"}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th scope="row">Country applying</th>
                                                                    <td>{singlestudentdata?.academicInfo?.countryApplying ? singlestudentdata.academicInfo.countryApplying : "N/A"}</td>
                                                                </tr>

                                                                <tr>
                                                                    <th scope="row">Case creation date</th>
                                                                    <td>{formatDate(singlestudentdata?.academicInfo?.englishProficiency?.caseCreationDate ? singlestudentdata.academicInfo.englishProficiency.caseCreationDate : "N/A")}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th scope="row">English test</th>
                                                                    <td>{singlestudentdata?.academicInfo?.englishProficiency?.englishTest ? singlestudentdata.academicInfo.englishProficiency.englishTest : "N/A"}</td>

                                                                </tr>
                                                                <tr>
                                                                    <th scope="row">Score</th>
                                                                    <td>{singlestudentdata?.academicInfo?.englishProficiency?.score ? singlestudentdata.academicInfo.englishProficiency.score : "N/A"}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th scope="row">University applying</th>
                                                                    <td>{singlestudentdata?.academicInfo?.universityApplying[0] ? singlestudentdata.academicInfo.universityApplying[0] : "N/A"}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </> : selectnumber === "5" ? <>
                                                    <div className='col'>
                                                        <table className="table table-striped">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col" style={{ fontSize: "25px" }}>User account info</th>

                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <th scope="row">Email</th>
                                                                    <td>{singlestudentdata?.userAccountInfo?.email ? singlestudentdata.userAccountInfo.email : "N/A"}</td>

                                                                </tr>
                                                                <tr>
                                                                    <th scope="row">Phone</th>
                                                                    <td>{singlestudentdata?.userAccountInfo?.phone ? singlestudentdata.userAccountInfo.phone : "N/A"}</td>

                                                                </tr>
                                                                <tr>
                                                                    <th scope="row">Password</th>
                                                                    <td>{singlestudentdata?.userAccountInfo?.password ? singlestudentdata.userAccountInfo.password : "N/A"}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th scope="row">Is agree</th>
                                                                    <td>{singlestudentdata?.userAccountInfo?.isAgree ? singlestudentdata.userAccountInfo.isAgree : "N/A"}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th scope="row">Lead status</th>
                                                                    <td>{singlestudentdata?.userAccountInfo?.leadStatus ? singlestudentdata.userAccountInfo.leadStatus : "N/A"}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th scope="row">Service type</th>
                                                                    <td>{singlestudentdata?.userAccountInfo?.serviceType[0] ? singlestudentdata.userAccountInfo.serviceType[0] : "N/A"}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th scope="row">To which city</th>
                                                                    <td>{singlestudentdata?.userAccountInfo?.toWhichCity ? singlestudentdata.userAccountInfo.toWhichCity : "N/A"}</td>
                                                                </tr>

                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </> : selectnumber === "6" ? <>
                                                    <div className='col'>
                                                        <table className="table table-striped">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col" style={{ fontSize: "25px" }}>Admission</th>

                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <th scope="row">Final university applied</th>
                                                                    <td>{singlestudentdata?.admission?.finalUniversityApplied ? singlestudentdata.admission.finalUniversityApplied : "N/A"}</td>

                                                                </tr>
                                                                <tr>
                                                                    <th scope="row">Admission status </th>
                                                                    <td>{singlestudentdata?.admission?.admissionStatus ? singlestudentdata.admission.admissionStatus : "N/A"}</td>

                                                                </tr>
                                                                <tr>
                                                                    <th scope="row">Is deposited fees</th>
                                                                    <td>{singlestudentdata?.admission?.isDepositedFees ? singlestudentdata.admission.isDepositedFees : "N/A"}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th scope="row">Session applied</th>
                                                                    <td>{singlestudentdata?.admission?.sessionApplied ? singlestudentdata.admission.sessionApplied : "N/A"}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </> : selectnumber === "7" ? <>
                                                    <div className='col'>
                                                        <table className="table table-striped">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col" style={{ fontSize: "25px" }}>Experience</th>

                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <th scope="row">Industry</th>
                                                                    <td>{singlestudentdata?.experience?.selectIndustry ? singlestudentdata.experience.selectIndustry : "N/A"}</td>

                                                                </tr>
                                                                <tr>
                                                                    <th scope="row">All skills </th>
                                                                    <td>
                                                                        {
                                                                            singlestudentdata.experience.allSkills?.map((item, index) => {
                                                                                return <div className='row' key={index}>
                                                                                    <div className='col'>{item}</div>
                                                                                </div>
                                                                            })
                                                                        }
                                                                    </td>

                                                                </tr>
                                                                <tr>
                                                                    <th scope="row">Is experience</th>
                                                                    <td>{singlestudentdata?.experience?.isExperience ? singlestudentdata.experience.isExperience : "N/A"}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th scope="row">Job type</th>
                                                                    <td>{singlestudentdata?.experience?.previousJobs[0]?.jobType ? singlestudentdata.experience.previousJobs[0].jobType : "N/A"}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th scope="row">Job experience start</th>
                                                                    <td>{formatDate(singlestudentdata?.experience?.previousJobs[0]?.jobExperienceStart ? singlestudentdata.experience.previousJobs[0].jobExperienceStart : "N/A")}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th scope="row">Job experience end</th>
                                                                    <td>{formatDate(singlestudentdata?.experience?.previousJobs[0]?.jobExperienceEnd ? singlestudentdata.experience.previousJobs[0].jobExperienceEnd : "N/A")}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </> : selectnumber === "8" ? <>
                                                    <div className='col'>
                                                        <table className="table table-striped">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col" style={{ fontSize: "25px" }}>Emergency contact</th>

                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <th scope="row">Nok name</th>
                                                                    <td>{singlestudentdata?.emergencyContact?.nokName ? singlestudentdata.emergencyContact.nokName : "N/A"}</td>

                                                                </tr>
                                                                <tr>
                                                                    <th scope="row">Nok email</th>
                                                                    <td>{singlestudentdata?.emergencyContact?.nokEmail ? singlestudentdata.emergencyContact.nokEmail : "N/A"}</td>

                                                                </tr>
                                                                <tr>
                                                                    <th scope="row">Nok phone number</th>
                                                                    <td>{singlestudentdata?.emergencyContact?.nokPhoneNumber ? singlestudentdata.emergencyContact.nokPhoneNumber : "N/A"}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th scope="row">Nok relationship</th>
                                                                    <td>{singlestudentdata?.emergencyContact?.nokRelationship ? singlestudentdata.emergencyContact.nokRelationship : "N/A"}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th scope="row">Any Uk number</th>
                                                                    <td>{singlestudentdata?.emergencyContact?.anyUkNumber ? singlestudentdata.emergencyContact.anyUkNumber : "N/A"}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th scope="row">Any Uk person name</th>
                                                                    <td>{singlestudentdata?.emergencyContact?.anyUkPersonName ? singlestudentdata.emergencyContact.anyUkPersonName : "N/A"}</td>
                                                                </tr>


                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </> : selectnumber === "9" ? <>
                                                    <div className='col'>
                                                        <table className="table table-striped">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col" style={{ fontSize: "25px" }}>Travel info</th>

                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <th scope="row">Arrival airport</th>
                                                                    <td>{singlestudentdata?.travelInfo?.arrivalAirport ? singlestudentdata.travelInfo.arrivalAirport : "N/A"}</td>

                                                                </tr>
                                                                <tr>
                                                                    <th scope="row">Airline</th>
                                                                    <td>{singlestudentdata?.travelInfo?.flightDetails?.airline ? singlestudentdata.travelInfo.flightDetails.airline : "N/A"}</td>

                                                                </tr>
                                                                <tr>
                                                                    <th scope="row">Flight number</th>
                                                                    <td>{singlestudentdata?.travelInfo?.flightDetails?.flightNumber ? singlestudentdata.travelInfo.flightDetails.flightNumber : "N/A"}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th scope="row">Is booked flight</th>
                                                                    <td>{singlestudentdata?.travelInfo?.isBookedFlight ? singlestudentdata.travelInfo.isBookedFlight : "N/A"}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th scope="row">Arrival date</th>
                                                                    <td>{formatDate(singlestudentdata?.travelInfo?.travelDates?.arrivalDate ? singlestudentdata.travelInfo.travelDates.arrivalDate : "N/A")}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th scope="row">Arrival time</th>
                                                                    <td>{singlestudentdata?.travelInfo?.travelDates?.arrivalTime ? singlestudentdata.travelInfo.travelDates.arrivalTime : "N/A"}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th scope="row">Departure date</th>
                                                                    <td>{singlestudentdata?.travelInfo?.anyUkPersonName ? singlestudentdata.travelInfo.anyUkPersonName : "N/A"}</td>
                                                                </tr>


                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </> : selectnumber === "10" ? <>
                                                    <div className='col'>
                                                        <table className="table table-striped">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col" style={{ fontSize: "25px" }}>Visa info</th>

                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <th scope="row">Is visa endorsement</th>
                                                                    <td>{singlestudentdata?.visaInfo?.isVisaEndorsement ? singlestudentdata.visaInfo.isVisaEndorsement : "N/A"}</td>

                                                                </tr>
                                                                <tr>
                                                                    <th scope="row">Visa issue date</th>
                                                                    <td>{formatDate(singlestudentdata?.visaInfo?.visaIssueDate ? singlestudentdata.visaInfo.visaIssueDate : "N/A")}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th scope="row">Visa expiration date</th>
                                                                    <td>{formatDate(singlestudentdata?.visaInfo?.visaExpirationDate ? singlestudentdata.visaInfo.visaExpirationDate : "N/A")}</td>

                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </> : selectnumber === "11" ?
                                                    <>
                                                        <div className='row'>
                                                            <div className='row'>

                                                                <tr>
                                                                    <th scope="col" style={{ fontSize: "25px" }}>Documents and attachments</th>
                                                                </tr>

                                                            </div>

                                                            <div className='row' style={{ marginTop: "-70px" }}>
                                                                <div className='col-lg-6'>
                                                                    {
                                                                        viewfront ? <>
                                                                            <div className='pdfbox' style={{
                                                                                height: "60px",
                                                                                boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
                                                                                borderRadius: "10px",
                                                                                overflow: "hidden"
                                                                            }}>
                                                                                <div className='row'>
                                                                                    <div className='col py-3 text-center'>
                                                                                        <h3 style={{ fontSize: "1.2rem" }}>Front page</h3>
                                                                                    </div>
                                                                                    <div className='col py-3 ps-5'>
                                                                                        <a className='px-3 py-2' style={{ background: '#296013', color: 'white', borderRadius: "10px", textDecoration: "none" }}
                                                                                            href={`https://globallearnandearnapi.doctorsforhealth.co.uk/` + viewfront}
                                                                                            target='blank'><i className="fa-solid fa-eye"></i> view</a>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </> : ""
                                                                    }

                                                                </div>
                                                                <div className='col-lg-6'>
                                                                    {
                                                                        viewback ? <>
                                                                            <div className='pdfbox' style={{
                                                                                height: "60px",
                                                                                boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
                                                                                borderRadius: "10px",
                                                                                overflow: "hidden"
                                                                            }}>
                                                                                <div className='row'>
                                                                                    <div className='col py-3 text-center'>
                                                                                        <h3 style={{ fontSize: "1.2rem" }}>Back page</h3>
                                                                                    </div>
                                                                                    <div className='col py-3 ps-5'>
                                                                                        <a className='px-3 py-2' style={{ background: '#296013', color: 'white', borderRadius: "10px", textDecoration: "none" }}
                                                                                            href={`https://globallearnandearnapi.doctorsforhealth.co.uk/` + viewback}
                                                                                            target='blank'><i className="fa-solid fa-eye"></i> view</a>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </> : ""
                                                                    }

                                                                </div>
                                                                <div className='col-lg-6'>
                                                                    {
                                                                        singlestudentdata?.documentsAndAttachments?.visaCopy ? <>
                                                                            <div className='pdfbox' style={{
                                                                                height: "60px",
                                                                                boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
                                                                                borderRadius: "10px",
                                                                                overflow: "hidden"
                                                                            }}>
                                                                                <div className='row'>
                                                                                    <div className='col py-3 text-center'>
                                                                                        <h3 style={{ fontSize: "1.2rem" }}>Visa copy</h3>
                                                                                    </div>
                                                                                    <div className='col py-3 ps-5'>
                                                                                        <a className='px-3 py-2' style={{ background: '#296013', color: 'white', borderRadius: "10px", textDecoration: "none" }}
                                                                                            href={`https://globallearnandearnapi.doctorsforhealth.co.uk/` + singlestudentdata?.documentsAndAttachments?.visaCopy}
                                                                                            target='blank'><i className="fa-solid fa-eye"></i> view</a>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </> : ""
                                                                    }

                                                                </div>
                                                                <div className='col-lg-6'>
                                                                    {
                                                                        singlestudentdata?.documentsAndAttachments?.resumeCV ? <>
                                                                            <div className='pdfbox' style={{
                                                                                height: "60px",
                                                                                boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
                                                                                borderRadius: "10px",
                                                                                overflow: "hidden"
                                                                            }}>
                                                                                <div className='row'>
                                                                                    <div className='col py-3 text-center'>
                                                                                        <h3 style={{ fontSize: "1.2rem" }}>Resume copy</h3>
                                                                                    </div>
                                                                                    <div className='col py-3 ps-5'>
                                                                                        <a className='px-3 py-2' style={{ background: '#296013', color: 'white', borderRadius: "10px", textDecoration: "none" }}
                                                                                            href={`https://globallearnandearnapi.doctorsforhealth.co.uk/` + singlestudentdata?.documentsAndAttachments?.resumeCV}
                                                                                            target='blank'><i className="fa-solid fa-eye"></i> view</a>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </> : ""
                                                                    }

                                                                </div>
                                                                <div className='col-lg-6'>
                                                                    {
                                                                        singlestudentdata?.documentsAndAttachments?.IELTSCopy ? <>
                                                                            <div className='pdfbox' style={{
                                                                                height: "60px",
                                                                                boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
                                                                                borderRadius: "10px",
                                                                                overflow: "hidden"
                                                                            }}>
                                                                                <div className='row'>
                                                                                    <div className='col py-3 text-center'>
                                                                                        <h3 style={{ fontSize: "1.2rem" }}>IELTS result</h3>
                                                                                    </div>
                                                                                    <div className='col py-3 ps-5'>
                                                                                        <a className='px-3 py-2' style={{ background: '#296013', color: 'white', borderRadius: "10px", textDecoration: "none" }}
                                                                                            href={`https://globallearnandearnapi.doctorsforhealth.co.uk/` + singlestudentdata?.documentsAndAttachments?.IELTSCopy}
                                                                                            target='blank'><i className="fa-solid fa-eye"></i> view</a>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </> : ""
                                                                    }

                                                                </div>
                                                                <div className='col-lg-6'>
                                                                    {
                                                                        singlestudentdata?.documentsAndAttachments?.CASLetter ? <>
                                                                            <div className='pdfbox' style={{
                                                                                height: "60px",
                                                                                boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
                                                                                borderRadius: "10px",
                                                                                overflow: "hidden"
                                                                            }}>
                                                                                <div className='row'>
                                                                                    <div className='col py-3 text-center'>
                                                                                        <h3 style={{ fontSize: "1.2rem" }}>CAS letter</h3>
                                                                                    </div>
                                                                                    <div className='col py-3 ps-5'>
                                                                                        <a className='px-3 py-2' style={{ background: '#296013', color: 'white', borderRadius: "10px", textDecoration: "none" }}
                                                                                            href={`https://globallearnandearnapi.doctorsforhealth.co.uk/` + singlestudentdata?.documentsAndAttachments?.CASLetter}
                                                                                            target='blank'><i className="fa-solid fa-eye"></i> view</a>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </> : ""
                                                                    }

                                                                </div>

                                                                <div className='col-lg-9'>
                                                                    {
                                                                        singlestudentdata?.documentsAndAttachments?.covidCertificate ? <>
                                                                            <div className='pdfbox' style={{
                                                                                height: "60px",
                                                                                boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
                                                                                borderRadius: "10px",
                                                                                overflow: "hidden"
                                                                            }}>
                                                                                <div className='row'>
                                                                                    <div className='col py-3 text-center'>
                                                                                        <h3 style={{ fontSize: "1.2rem" }}>Covid certificate</h3>
                                                                                    </div>
                                                                                    <div className='col py-3 ps-5'>
                                                                                        <a className='px-3 py-2' style={{ background: '#296013', color: 'white', borderRadius: "10px", textDecoration: "none" }}
                                                                                            href={`https://globallearnandearnapi.doctorsforhealth.co.uk/` + singlestudentdata?.documentsAndAttachments?.covidCertificate}
                                                                                            target='blank'><i className="fa-solid fa-eye"></i> view</a>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </> : ""
                                                                    }

                                                                </div>
                                                                <div className='col-lg-9'>
                                                                    {
                                                                        singlestudentdata?.documentsAndAttachments?.TBCertificate ? <>
                                                                            <div className='pdfbox' style={{
                                                                                height: "60px",
                                                                                boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
                                                                                borderRadius: "10px",
                                                                                overflow: "hidden"
                                                                            }}>
                                                                                <div className='row'>
                                                                                    <div className='col py-3 text-center'>
                                                                                        <h3 style={{ fontSize: "1.2rem" }}>TB certificate</h3>
                                                                                    </div>
                                                                                    <div className='col py-3 ps-5'>
                                                                                        <a className='px-3 py-2' style={{ background: '#296013', color: 'white', borderRadius: "10px", textDecoration: "none" }}
                                                                                            href={`https://globallearnandearnapi.doctorsforhealth.co.uk/` + singlestudentdata?.documentsAndAttachments?.TBCertificate}
                                                                                            target='blank'><i className="fa-solid fa-eye"></i> view</a>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </> : ""
                                                                    }

                                                                </div>




                                                            </div>


                                                        </div>
                                                    </> : ""
                                        }

                                    </div>
                                </div>
                            </Modal.Body>
                        </Modal>
                    </div>

                    <div className='container-fluid student-main'>
                        <div className='row mt-3 text-start'>
                            <h2>Students</h2>
                        </div>
                        <div>
                            <div className='row text-start mt-4 acc-form'>
                                <div className='col-lg-4 col-12'>
                                    <div className="mb-3">
                                        <label for="exampleInputEmail1" className="form-label">Email</label>
                                        <select className="form-select"
                                            // value={email}
                                            // onChange={(e) => setemail(e.target.value)}
                                            aria-label="Default select example">
                                            <option selected>Select email</option>
                                            {/* {filteremail ?
                                                filteremail.map((item, index) => {
                                                    return <option value={item.userAccountInfo?.email ? item.userAccountInfo.email : "N/A"} key={index}>{item.userAccountInfo?.email ? item.userAccountInfo.email : "N/A"}</option>

                                                }) : ""
                                            } */}
                                        </select>
                                    </div>
                                </div>
                                <div className='col-lg-4 col-12'>
                                    <div className="mb-3">
                                        <label for="exampleInputEmail1" className="form-label">Country</label>
                                        <Select

                                            // onChange={handlecityfilter}
                                            // options={countryname}
                                            isSearchable
                                        />
                                    </div>
                                </div>
                                <div className='col-lg-4 col-12'>
                                    <div className="mb-3">
                                        <label for="exampleInputEmail1" className="form-label">City</label>
                                        <Select
                                            // onChange={setcityname}
                                            // options={filtercity}
                                            isSearchable
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row mt-3 btn-row'>
                            <div className='col-lg-1 col-6 d-flex align-items-start justify-content-start'>
                                <button className='btn btn-primary'
                                // onClick={agentfilter}
                                >Filter</button>
                            </div>
                            <div className='col-lg-1 col-6 d-flex align-items-start justify-content-start'>
                                <button className='btn btn-primary'
                                //  onClick={clearfilter}
                                >Clear</button>
                            </div>
                            <div className='col-lg'></div>
                        </div>

                        <div className='row mt-3'>
                            <div className='col table11'>
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">Sr.</th>
                                            <th scope="col">Name</th>
                                            <th scope="col">Last name</th>
                                            <th scope="col">Email</th>
                                            <th scope="col">Phone</th>
                                            <th scope="col">Gender</th>
                                            <th scope="col">Country</th>
                                            <th scope="col">City</th>
                                            <th scope="col">Postal code</th>
                                            <th scope="col">Nationality</th>
                                            <th scope="col">Action</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {studentdata ?
                                            studentdata.map((item, index) => {
                                                return <tr key={item._id}>
                                                    <th scope="row">{index + 1}</th>

                                                    <td>{item.personalInfo?.foreName ? item.personalInfo.foreName : "N/A"}</td>
                                                    <td>{item.personalInfo?.surName ? item.personalInfo.surName : "N/A"}</td>
                                                    <td>{item.userAccountInfo.email}</td>
                                                    <td>{item.contactInfo?.phone ? item.contactInfo.phone : "N/A"}</td>
                                                    <td>{item.personalInfo?.gender ? item.personalInfo.gender : "N/A"}</td>
                                                    <td>{item.address?.country ? item.address.country : "N/A"}</td>
                                                    <td>{item.address?.city ? item.address.city : "N/A"}</td>
                                                    <td>{item.address?.postCode ? item.address.postCode : "N/A"}</td>
                                                    <td>{item.personalInfo?.nationality ? item.personalInfo.nationality : "N/A"}</td>

                                                    <td>
                                                        <li className="nav-item dropdown" style={{ listStyle: "none" }}>
                                                            <button className="btn btn-primary " style={{ backgroundColor: "#296013", border: "none", }}
                                                                // role="button" data-bs-toggle="dropdown" aria-expanded="false"
                                                                onClick={() => Viewdetail(item)}
                                                            >
                                                                View
                                                            </button>
                                                            {/* <ul className="dropdown-menu">
                                                                <li style={{ cursor: "pointer" }} onClick={() => localdelete(item._id)}><td className="dropdown-item"><i className="fa-solid fa-trash me-2" style={{ color: "#B63A3A" }}></i> Delete</td></li>
                                                                <li style={{ cursor: "pointer" }}
                                                                    onClick={() => handleShow(item._id, item.bankName, item.accountTitle, item.accountNumber, item.IBAN)}
                                                                ><td className="dropdown-item"><i className="fa-solid fa-pen-to-square me-2"
                                                                    style={{ color: "#198BEC" }}></i> Update</td></li>
                                                            </ul> */}
                                                        </li>
                                                    </td>
                                                </tr>
                                            }) : ""

                                        }


                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    )
}

export default Student
