import React from 'react'
import './Agentside.css'
import { NavLink } from 'react-router-dom'
import logo from '../../assiet/img/learn-and-earn-logofdff.png'

const Agentside = ({ Toggle }) => {
    return (
        <div className='Agentsidebar pt-3'>
            <div className='py-0 my-0 '>
                <div className='container'>
                    <div className='row text-center logo-row'
                        onClick={Toggle}
                    >
                        <img src={logo} className='img-fluid' alt='logo'></img>
                        {/* <h2>Global learn and earn</h2> */}
                    </div>

                    <div className='row text-start mt-4 ps-2 pt-3'>
                        {/* <div className='row row-link'>
            <NavLink to="/admin-dashboard" className="nav-l"><i className="fa-solid fa-gauge me-2"></i> Dashboard</NavLink>
        </div> */}
                        <div className='row row-link mt-2'>
                            <NavLink to="/personal-details" className="nav-l"><i className="fa-solid fa-user me-2"></i>Personal details</NavLink>
                        </div>

                        <div className="row mt-2">
                            <span className='accordion-header' id="flush-headingOne" >
                                <button className="accordion-button collapsed row-link" style={{ fontSize: "19px", color: "white" }} type="button"
                                    data-bs-toggle="collapse" data-bs-target="#flush-collapseOne"
                                    aria-expanded="false" aria-controls="flush-collapseOne"
                                >
                                    <i className="fa-solid fa-user-tie me-2"></i>Business information <i class="fa-solid fa-chevron-down ms-2"></i>
                                </button>

                                <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                    <div className="accordion-body ps-lg- mt-2">
                                        <ul>
                                            <li className="nav-item" style={{ color: "#F1BB44" }}>
                                                <NavLink to="/business-address" className="nav-link" style={{ color: "white" }}>Business address</NavLink>
                                            </li>
                                            <li className="nav-item mt-2" style={{ color: "#F1BB44" }}>
                                                <NavLink to="/organization-details" className="nav-link" style={{ color: "white" }}>Organization detail</NavLink>
                                            </li>
                                            <li className="nav-item mt-2" style={{ color: "#F1BB44" }}>
                                                <NavLink to="/account-detail" className="nav-link" style={{ color: "white" }}>Account detail</NavLink>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </span>
                        </div>


                        <div className='row row-link mt-2'>
                            <NavLink to="/deal-institute" className="nav-l"><i className="fa-solid fa-building-columns me-2"></i>Deal institute</NavLink>
                        </div>
                        <div className='row row-link mt-2'>
                            <NavLink to="/document-and-attachments" className="nav-l"><i className="fa-solid fa-file me-2"></i>Documents</NavLink>
                        </div>
                        {/* <div className='row row-link mt-2'>
                            <NavLink to="/history" className="nav-l"><i className="fa-solid fa-clock-rotate-left me-2"></i> History</NavLink>
                        </div>
                        <div className='row row-link mt-2'>
                            <NavLink to="/promo-code" className="nav-l"><i className="fa-solid fa-code me-2"></i> Promo code</NavLink>
                        </div> */}
                    </div>

                </div>
            </div>



        </div>
    )
}

export default Agentside
