import React, { useEffect } from 'react'
import './Locations.css'
import Navbar from '../../compunent/Navbar'
import Footer from '../../compunent/Footer'
import Select from 'react-select';
import londonimg from '../../assiet/img/london-card.jpg'
import { useNavigate } from 'react-router-dom'

const Locations = () => {
    const navigate = useNavigate();
    useEffect(() =>{
        window.scrollTo({top: 0, left: 0, behavior: "smooth"})
    },[])

    return (
        <div>
            <Navbar />
            <div className='Locations-main mb-4'>
                <div className='location-banner'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col'>
                                {/* <h1>Tentative accommodation in the United Kingdom</h1> */}
                                <h1>Search property and pricing</h1>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='container mt-4'>
                    <div className='row'>
                        <div className='col-md-4 text-start'>
                            <div className="mb-3">
                                <label for="sdfsdfds" className="form-label">Find your city</label>
                                <Select
                                    isSearchable
                                // options={typeArrange}
                                // onChange={settoWhichCity}
                                />
                            </div>
                        </div>
                    </div>

                    <div className='row mt-3'>
                        <div className='col-md-4 px-md-4 mb-3'>
                            <div className='city-card'>
                                <div className='row'>
                                    <div className='col'>
                                        <img src={londonimg} className='img-fluid' alt=''></img>
                                    </div>
                                </div>

                                <div className='row mt-2 px-2'>
                                    <h2>London</h2>
                                </div>
                                <div className='row px-2'>
                                    <h3 onClick={() => navigate(`/locations/` + "sada")} style={{ cursor: "pointer" }}>Tentative Accommodation Pricing</h3>
                                </div>
                                <div className='row mt-2'>
                                    <div className='col'>
                                        <h5>Type</h5>
                                    </div>
                                    <div className='col'>
                                        <h5>Price</h5>

                                    </div>
                                </div>
                                <div className='row row-back mt-1'>
                                    <div className='col'><h4>Independent</h4></div>
                                    <div className='col'><h4 style={{ color: "#00490D" }}>£ 300</h4></div>

                                </div>
                                <div className='row row-back mt-2'>
                                    <div className='col'><h4>Shared</h4></div>
                                    <div className='col'><h4 style={{ color: "#00490D" }}>£ 400</h4></div>

                                </div>

                                <div className='row mt-3'>
                                    <div className='col'>
                                        <button className='btn btn-primary'>Book Now</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4 px-md-4 mb-3'>
                            <div className='city-card'>
                                <div className='row'>
                                    <div className='col'>
                                        <img src={londonimg} className='img-fluid' alt=''></img>
                                    </div>
                                </div>

                                <div className='row mt-2 px-2'>
                                    <h2>London</h2>
                                </div>
                                <div className='row px-2'>
                                    <h3 onClick={() => navigate(`/locations/` + "sada")} style={{ cursor: "pointer" }}>Tentative Accommodation Pricing</h3>
                                </div>
                                <div className='row mt-2'>
                                    <div className='col'>
                                        <h5>Type</h5>
                                    </div>
                                    <div className='col'>
                                        <h5>Price</h5>

                                    </div>
                                </div>
                                <div className='row row-back mt-1'>
                                    <div className='col'><h4>Independent</h4></div>
                                    <div className='col'><h4 style={{ color: "#00490D" }}>£ 300</h4></div>

                                </div>
                                <div className='row row-back mt-2'>
                                    <div className='col'><h4>Shared</h4></div>
                                    <div className='col'><h4 style={{ color: "#00490D" }}>£ 400</h4></div>

                                </div>

                                <div className='row mt-3'>
                                    <div className='col'>
                                        <button className='btn btn-primary'>Book Now</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-md-4 px-md-4 mb-3'>
                            <div className='city-card'>
                                <div className='row'>
                                    <div className='col'>
                                        <img src={londonimg} className='img-fluid' alt=''></img>
                                    </div>
                                </div>

                                <div className='row mt-2 px-2'>
                                    <h2>London</h2>
                                </div>
                                <div className='row px-2'>
                                    <h3 onClick={() => navigate(`/locations/` + "sada")} style={{ cursor: "pointer" }}>Tentative Accommodation Pricing</h3>
                                </div>
                                <div className='row mt-2'>
                                    <div className='col'>
                                        <h5>Type</h5>
                                    </div>
                                    <div className='col'>
                                        <h5>Price</h5>

                                    </div>
                                </div>
                                <div className='row row-back mt-1'>
                                    <div className='col'><h4>Independent</h4></div>
                                    <div className='col'><h4 style={{ color: "#00490D" }}>£ 300</h4></div>

                                </div>
                                <div className='row row-back mt-2'>
                                    <div className='col'><h4>Shared</h4></div>
                                    <div className='col'><h4 style={{ color: "#00490D" }}>£ 400</h4></div>

                                </div>

                                <div className='row mt-3'>
                                    <div className='col'>
                                        <button className='btn btn-primary'>Book Now</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-md-4 px-md-4 mb-3'>
                            <div className='city-card'>
                                <div className='row'>
                                    <div className='col'>
                                        <img src={londonimg} className='img-fluid' alt=''></img>
                                    </div>
                                </div>

                                <div className='row mt-2 px-2'>
                                    <h2>London</h2>
                                </div>
                                <div className='row px-2'>
                                    <h3 onClick={() => navigate(`/locations/` + "sada")} style={{ cursor: "pointer" }}>Tentative Accommodation Pricing</h3>
                                </div>
                                <div className='row mt-2'>
                                    <div className='col'>
                                        <h5>Type</h5>
                                    </div>
                                    <div className='col'>
                                        <h5>Price</h5>

                                    </div>
                                </div>
                                <div className='row row-back mt-1'>
                                    <div className='col'><h4>Independent</h4></div>
                                    <div className='col'><h4 style={{ color: "#00490D" }}>£ 300</h4></div>

                                </div>
                                <div className='row row-back mt-2'>
                                    <div className='col'><h4>Shared</h4></div>
                                    <div className='col'><h4 style={{ color: "#00490D" }}>£ 400</h4></div>

                                </div>

                                <div className='row mt-3'>
                                    <div className='col'>
                                        <button className='btn btn-primary'>Book Now</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Locations
