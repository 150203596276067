import React, { useEffect } from 'react'
import Hero from './Hero'
import Hero1 from './Hero1'
import Quest from './Quest'
import Agent from './Agent'
import Easysteps from './Easysteps'
import Contactus from './Contactus'
import Navbar from './Navbar'
import Footer from './Footer'
import Cityslider from './Cityslider'
import Content1 from './Content1'
import Content2 from './Content2'

const Linkhome = () => {

  return (
    <div>
      <Navbar />
      <Hero />
      <Cityslider/>
      <Content1/>
      <Content2/>
      <Quest />
      <Hero1 />
      {/* <Agent /> */}
      <Easysteps />
      <Contactus />
      <Footer />
    </div>
  )
}

export default Linkhome
