import Swal from 'sweetalert2';
import React, { useEffect, useState } from 'react'
import Agentnav from '../sideandnav/Agentnav';
import Agentside from '../sideandnav/Agentside';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'
import './Organizationdetails.css'
import TagsInput from 'react-tagsinput';

const Organizationdetails = () => {
    const [toggle, setToggle] = useState(true);
    const navigate = useNavigate();
    const Toggle = () => {
        setToggle(!toggle);
    };

    const token = localStorage.getItem('agenttoken')

    useEffect(() => {
        getagent()
    }, [])

    const [services, setservices] = useState([])
    const [isOtherOffices, setisOtherOffices] = useState("")
    const [organizationName, setorganizationName] = useState("")
    const [organizationRegistrationNumber, setorganizationRegistrationNumber] = useState("")
    const [organizationType, setorganizationType] = useState("")
    const [inWichCities, setinWichCities] = useState([])

    const handlesave = () => {
        console.warn(isOtherOffices,inWichCities)

        // const inWichCities = [inWichCities,]

        if (services && organizationName && organizationRegistrationNumber && organizationType) {
            axios.post("https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/consultant/ChannelPartnerOrganizationInfo", { services, organizationName, organizationRegistrationNumber, organizationType, isOtherOffices, inWichCities:[inWichCities] }, {
                headers: {
                    Authorization: token,
                },
            }).then((res) => {
                if (res.data.message === "Organization information is added") {
                    Swal.fire({
                        title: "Thank you",
                        text: "Organization details is added",
                        icon: "success"
                    });
                } else {
                    console.log(res.data)

                }
            }).catch((e) => {
                console.log(e)
            })
        } else {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "please fill input",
            });
        }
    }

    const getagent = () => {
        axios.get("https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/consultant/ChannelPartnerProfile", {
            headers: {
                Authorization: token,
            },
        })
            .then((res) => {
                console.log(res.data)
                if (res.data.message === "Channel Parnter profile") {
                    setorganizationName(res.data.data.organizationInfo.organizationName)
                    setorganizationRegistrationNumber(res.data.data.organizationInfo.organizationRegistrationNumber)
                    setorganizationType(res.data.data.organizationInfo.organizationType)
                    setservices(res.data.data.organizationInfo.services)
                    setinWichCities(res.data.data.organizationInfo.inWichCities[0])
                    setisOtherOffices(res.data.data.organizationInfo.isOtherOffices)

                } else {
                    navigate("/")
                }

            }).catch((e) => {
                console.log(e)
            })
    }

    const formatDate = (mongoDBDate) => {
        const date = new Date(mongoDBDate);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        console.warn(`${day}/${month}/${year}`)


        return `${year}-${month}-${day}`;
    };

    return (
        <div className="container-fluid">
            <div className="row">
                {toggle && (
                    <div
                        className="col-12 col-md-3 vh-100 position-fixed"
                        style={{ zIndex: 1000 }}
                    >
                        <Agentside Toggle={Toggle} />
                    </div>
                )}

                {toggle && <div className="col-12 col-lg-3"></div>}
                <div className="col">
                    <Agentnav Toggle={Toggle} />
                    <div className='container-fluid organization-main'>
                        <div className='row mt-3 text-start'>
                            <h2>Organization details</h2>
                        </div>
                        <div>
                            <div className='row text-start mt-4 acc-form'>
                                <div className='col-lg-4 col-12'>
                                    <div className="mb-3">
                                        <label for="people" className="form-label">Name</label>
                                        <input type="text" className="form-control" id="people"
                                            value={organizationName} onChange={(e) => setorganizationName(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className='col-lg-4 col-12'>
                                    <div className="mb-3">
                                        <label for="people" className="form-label">Registration number #</label>
                                        <input type="text" className="form-control" id="people"
                                            value={organizationRegistrationNumber} onChange={(e) => setorganizationRegistrationNumber(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className='col-lg-4 col-12'>
                                    <div className="mb-3">
                                        <label for="people" className="form-label">Organization type</label>
                                        <input type="text" className="form-control" id="people"
                                            value={organizationType} onChange={(e) => setorganizationType(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className='row text-start mt- acc-form'>

                                <div className='col-lg-4 col-12'>
                                    <div className="mb-3">
                                        <label for="people" className="form-label">Services</label>

                                        <TagsInput
                                            value={services} onChange={setservices}
                                        />
                                    </div>
                                </div>
                                <div className='col-lg-4 col-12'>
                                    <div className="mb-3">
                                        <label for="exampleInputEmail1" className="form-label">Is other offices?</label>
                                        <select className="form-select"
                                            value={isOtherOffices} onChange={(e) => setisOtherOffices(e.target.value)}
                                            aria-label="Default select example">
                                            <option selected>Select</option>
                                            <option value="yes">Yes</option>
                                            <option value="no">No</option>
                                        </select>
                                    </div>
                                </div>

                                {
                                    isOtherOffices === "yes" ? <>
                                        <div className='col-lg-4 col-12'>
                                            <div className="mb-3">
                                                <label for="exampleInputEmail1" className="form-label">In which cities</label>
                                                <select className="form-select"
                                                    value={inWichCities} onChange={(e) => setinWichCities(e.target.value)}
                                                    aria-label="Default select example">
                                                    <option selected>Select</option>
                                                    <option value="Karachi">Karachi</option>
                                                    <option value="Islamabad">Islamabad</option>
                                                    <option value="Lahore">Lahore</option>
                                                </select>
                                            </div>
                                        </div>
                                    </> : ""
                                }



                            </div>

                        </div>

                        <div className='row mt-3 btn-row'>
                            <div className='col d-flex align-items-start justify-content-start'>
                                <button className='btn btn-primary' onClick={handlesave}>Save</button>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </div>

    )
}

export default Organizationdetails
