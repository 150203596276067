import React from 'react'
import { useNavigate } from 'react-router-dom'
import './Agentnav.css'

const Agentnav = ({Toggle}) => {

    const navigate = useNavigate();

    const logout = () => {
        localStorage.clear()
        navigate("/")
    }
  return (
    <div className='agent22-main'>
            <nav className="navbar navbar-expand-sm navbar-light">
                <div className="container-fluid">
                    <i className="navbar-brand bi bi-justify-left fs-4 gap-2 pe-2" onClick={Toggle}>
                        {/* {time.toLocaleTimeString()} */}
                        {/* Welcome ({nameadmin}) */}
                    </i>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    {/* <i className='bi bi-justify'></i> */}
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                            <li className="nav-item dropdown me-2 mt-3 mt-lg-0">
                                <button className="btn btn-primary fw-bold" id="navbarDropdown" role="button" onClick={() => navigate("/")}>
                                    <i className="fa-solid fa-eye me-2"></i>
                                    View website
                                </button>

                            </li>
                            <li className="nav-item mt-2 mt-lg-0">
                                <button type="button" className="btn btn-primary fw-bold" onClick={logout}><i className="fa-solid fa-right-from-bracket me-2"></i> Logout</button>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>

        </div>
  )
}

export default Agentnav
