import React, { useEffect, useState } from 'react'
import Ssidebar from '../Sidebar/Ssidebar';
import Studentnav from '../Sidebar/Studentnav';
import Swal from 'sweetalert2';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'
import 'react-tagsinput/react-tagsinput.css'
import './Traveldetail.css'
import Navbar from '../../compunent/Navbar';

const Traveldetail = () => {

    const [toggle, setToggle] = useState(true);
    const navigate = useNavigate();
    const Toggle = () => {
        setToggle(!toggle);
    };

    const token = localStorage.getItem('studenttoken')

    useEffect(() => {
        getstudents()
    }, [])


    const [isBookedFlight, setisBookedFlight] = useState("")
    const [departureDate, setdepartureDate] = useState("")
    const [arrivalDate, setarrivalDate] = useState("")
    const [arrivalTime, setarrivalTime] = useState("")
    const [arrivalAirport, setarrivalAirport] = useState("")
    const [airline, setairline] = useState("")
    const [flightNumber, setflightNumber] = useState("")
    const [travelPlanDate, settravelPlanDate] = useState("")

    const handlesave = () => {
        // console.warn(bankName, accountNumber, accountTitle, IBAN)
        if (isBookedFlight) {
            axios.post("https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/student/StudentTravelInfo", { isBookedFlight,departureDate,arrivalAirport,arrivalDate,airline,flightNumber,travelPlanDate,arrivalTime }, {
                headers: {
                    Authorization: token,
                },
            }).then((res) => {
                if (res.data.message === "travel information is added") {
                    Swal.fire({
                        title: "Thank you",
                        text: "Travel information is added",
                        icon: "success"
                    });

                    getstudents()
                } else {
                    console.log(res.data)

                }
            }).catch((e) => {
                console.log(e)
            })
        } else {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "please fill input",
            });
        }
    }

    const formatDate = (mongoDBDate) => {
        const date = new Date(mongoDBDate);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        console.warn(`${day}/${month}/${year}`)


        return `${year}-${month}-${day}`;
    };

    const getstudents = () => {
        axios.get("https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/student/StudentProfile", {
            headers: {
                Authorization: token,
            },
        })
            .then((res) => {
                console.log(res.data.data)
                if (res.data.message === "Student profile") {

                    setarrivalAirport(res.data.data[0].travelInfo.arrivalAirport)
                    setisBookedFlight(res.data.data[0].travelInfo.isBookedFlight)
                    settravelPlanDate(formatDate(res.data.data[0].travelInfo.travelPlanDate))
                    setairline(res.data.data[0].travelInfo.flightDetails.airline)
                    setflightNumber(res.data.data[0].travelInfo.flightDetails.airline)
                    setarrivalDate(formatDate(res.data.data[0].travelInfo.travelDates.arrivalDate))
                    setarrivalTime(formatDate(res.data.data[0].travelInfo.travelDates.arrivalTime))
                    setdepartureDate(formatDate(res.data.data[0].travelInfo.travelDates.departureDate))

                } else {
                    navigate("/")
                }

            }).catch((e) => {
                console.log(e)
            })
    }

    return (
      <div>
        <Navbar/>
          <div className="container-fluid">
            <div className="row">
                {toggle && (
                    <div
                        className="col-12 col-md-3 vh-100 position-fixed"
                        style={{ zIndex: 1000 }}
                    >
                        <Ssidebar Toggle={Toggle} />
                    </div>
                )}

                {toggle && <div className="col-12 col-lg-3"></div>}
                <div className="col">
                    {/* <Studentnav Toggle={Toggle} /> */}
                    <div className='container-fluid travel-main'>
                        <div className='row mt-3 text-start'>
                            <h2>{toggle?"":<i className="fa-solid fa-bars" onClick={Toggle} style={{cursor: "pointer"}}></i>} Travel details</h2>
                        </div>
                        <div>
                            <div className='row text-start mt-4 acc-form'>
                                <div className='col-lg-4 col-12'>
                                    <div className="mb-3">
                                        <label for="exampleInputEmail1" className="form-label">Is your flight already booked?</label>
                                        <select className="form-select"
                                            value={isBookedFlight} onChange={(e) => setisBookedFlight(e.target.value)}
                                            aria-label="Default select example">
                                            <option selected>Select</option>
                                            <option value="yes">Yes</option>
                                            <option value="no">No</option>
                                        </select>
                                    </div>
                                </div>

                            </div>

                            {
                                isBookedFlight === "yes" ? <>
                                    <div className='row text-start acc-form'>
                                        <div className='col-lg-4 col-12'>
                                            <div className="mb-3">
                                                <label for="people" className="form-label">Departure date (Pakistan)</label>
                                                <input type="date" className="form-control" id="people"
                                                    value={departureDate} onChange={(e) => setdepartureDate(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-12'>
                                            <div className="mb-3">
                                                <label for="people" className="form-label">Arrival date (UK)</label>
                                                <input type="date" className="form-control" id="people"
                                                    value={arrivalDate} onChange={(e) => setarrivalDate(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-12'>
                                            <div className="mb-3">
                                                <label for="people" className="form-label">Arrival time (UK)</label>
                                                <input type="time" className="form-control" id="people"
                                                    value={arrivalTime} onChange={(e) => setarrivalTime(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                  
                                    </div>
                                    <div className='row text-start acc-form'>
                                    <div className='col-lg-4 col-12'>
                                            <div className="mb-3">
                                                <label for="people" className="form-label">Arrival airport</label>
                                                <input type="text" className="form-control" id="people"
                                                    value={arrivalAirport} onChange={(e) => setarrivalAirport(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-12'>
                                            <div className="mb-3">
                                                <label for="people" className="form-label">Airline</label>
                                                <input type="text" className="form-control" id="people"
                                                    value={airline} onChange={(e) => setairline(e.target.value)}
                                                />
                                            </div>
                                        </div>

                                        <div className='col-lg-4 col-12'>
                                            <div className="mb-3">
                                                <label for="people" className="form-label">Flight number</label>
                                                <input type="text" className="form-control" id="people"
                                                    value={flightNumber} onChange={(e) => setflightNumber(e.target.value)}
                                                />
                                            </div>
                                        </div>

                                    </div>
                                </> : isBookedFlight === "no"?<>
                                <div className='row text-start acc-form'>
                                        
                                        <div className='col-lg-4 col-12'>
                                            <div className="mb-3">
                                                <label for="people" className="form-label">Travel plan date</label>
                                                <input type="date" className="form-control" id="people"
                                                    value={travelPlanDate} onChange={(e) => settravelPlanDate(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-12'>
                                            <div className="mb-3">
                                                <label for="people" className="form-label">Arrival airport</label>
                                                <input type="text" className="form-control" id="people"
                                                    value={arrivalAirport} onChange={(e) => setarrivalAirport(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                       
                                    </div>
                                </>:""
                            }





                        </div>

                        <div className='row mt-3 btn-row'>
                            <div className='col d-flex align-items-start justify-content-start'>
                                <button className='btn btn-primary' onClick={handlesave}>Save</button>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </div>
      </div>


    )
}

export default Traveldetail
