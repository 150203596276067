import React, { useEffect, useState } from 'react'
import Ssidebar from '../Sidebar/Ssidebar';
import Studentnav from '../Sidebar/Studentnav';
import Swal from 'sweetalert2';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'
import 'react-tagsinput/react-tagsinput.css'
import './Admissioninfo.css'
import Navbar from '../../compunent/Navbar';

const Admissioninfo = () => {
    const [toggle, setToggle] = useState(true);
    const navigate = useNavigate();
    const Toggle = () => {
        setToggle(!toggle);
    };

    const token = localStorage.getItem('studenttoken')

    useEffect(() => {
        getstudents()
    }, [])

    const [finalUniversityApplied, setfinalUniversityApplied] = useState("")
    const [admissionStatus, setadmissionStatus] = useState("")
    const [sessionApplied, setsessionApplied] = useState("")
    const [isDepositedFees, setisDepositedFees] = useState("")


    const handlesave = () => {
        console.warn(finalUniversityApplied, admissionStatus, isDepositedFees, sessionApplied)
        if (finalUniversityApplied && admissionStatus && isDepositedFees && sessionApplied) {
            axios.post("https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/student/StudentAdmissionInfo", { finalUniversityApplied, admissionStatus, isDepositedFees, sessionApplied }, {
                headers: {
                    Authorization: token,
                },
            }).then((res) => {
                if (res.data.message === "admission information is added") {
                    Swal.fire({
                        title: "Thank you",
                        text: "Admission information is added",
                        icon: "success"
                    });

                    getstudents()
                } else {
                    console.log(res.data)

                }
            }).catch((e) => {
                console.log(e)
            })
        } else {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "please fill input",
            });
        }
    }

    const getstudents = () => {
        axios.get("https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/student/StudentProfile", {
            headers: {
                Authorization: token,
            },
        })
            .then((res) => {
                console.log(res.data.data)
                if (res.data.message === "Student profile") {
                    setadmissionStatus(res.data.data[0].admission.admissionStatus)
                    setsessionApplied(res.data.data[0].admission.sessionApplied)
                    setfinalUniversityApplied(res.data.data[0].admission.finalUniversityApplied)
                    setisDepositedFees(res.data.data[0].admission.isDepositedFees)


                } else {
                    navigate("/")
                }

            }).catch((e) => {
                console.log(e)
            })
    }
    return (
        <div>
            <Navbar/>
            <div className="container-fluid">
            <div className="row">
                {toggle && (
                    <div
                        className="col-12 col-md-3 vh-100 position-fixed"
                        style={{ zIndex: 1000 }}
                    >
                        <Ssidebar Toggle={Toggle} />
                    </div>
                )}

                {toggle && <div className="col-12 col-lg-3"></div>}
                <div className="col">
                    {/* <Studentnav Toggle={Toggle} /> */}
                    <div className='container-fluid ADMISSION-main'>
                        <div className='row mt-3 text-start'>
                            <h2>{toggle?"":<i className="fa-solid fa-bars" onClick={Toggle} style={{cursor: "pointer"}}></i>} Admission details</h2>
                        </div>
                        <div>
                            <div className='row text-start mt-4 acc-form'>
                                <div className='col-lg-4 col-12'>
                                    <div className="mb-3">
                                        <label for="people" className="form-label">Final university applied</label>
                                        <input type="text" className="form-control" id="people"
                                            value={finalUniversityApplied} onChange={(e) => setfinalUniversityApplied(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className='col-lg-4 col-12'>
                                    <div className="mb-3">
                                        <label for="exampleInputEmail1" className="form-label">Admission status</label>
                                        <select className="form-select"
                                            value={admissionStatus} onChange={(e) => setadmissionStatus(e.target.value)}
                                            aria-label="Default select example">
                                            <option selected>Select</option>
                                            <option value="pending">Pending</option>
                                            <option value="completed">Completed</option>
                                        </select>
                                    </div>
                                </div>
                                <div className='col-lg-4 col-12'>
                                    <div className="mb-3">
                                        <label for="people" className="form-label">Session applied</label>
                                        <input type="text" className="form-control" id="people"
                                            value={sessionApplied} onChange={(e) => setsessionApplied(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className='row text-start acc-form'>
                                <div className='col-lg-4 col-12'>
                                    <div className="mb-3">
                                        <label for="exampleInputEmail1" className="form-label">Is fees deposited ?</label>
                                        <select className="form-select"
                                            value={isDepositedFees} onChange={(e) => setisDepositedFees(e.target.value)}
                                            aria-label="Default select example">
                                            <option selected>Select</option>
                                            <option value="yes">Yes</option>
                                            <option value="no">No</option>
                                        </select>
                                    </div>
                                </div>


                            </div>


                        </div>

                        <div className='row mt-3 btn-row'>
                            <div className='col d-flex align-items-start justify-content-start'>
                                <button className='btn btn-primary' onClick={handlesave}>Save</button>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </div>
        </div>


    )
}

export default Admissioninfo
