import React from 'react'
import './Ssidebar.css'
import { NavLink } from 'react-router-dom'
// import logo from '../../assiet/img/learn-and-earn-logofdff.png'

const Ssidebar = ({ Toggle }) => {
    return (
        <div className='studentsidebar pt-3'>
            <div className='py-0 my-0 '>
                <div className='container'>
                    <div className='row text-end logo-row'

                    >
                        {/* <img src={logo} className='img-fluid' alt='logo'></img> */}
                        <h2 style={{ cursor: "pointer" }} onClick={Toggle}><i class="fa-solid fa-circle-xmark"></i></h2>
                    </div>

                    <div className='row text-start ps-2'>
                        <>
                            {/* <div className="row mt-2">
                            <span className='accordion-header' id="flush-headingOne" >
                                <button className="accordion-button collapsed row-link" style={{ fontSize: "19px", color: "white" }} type="button"
                                    data-bs-toggle="collapse" data-bs-target="#flush-collapseOne"
                                    aria-expanded="false" aria-controls="flush-collapseOne"
                                >
                                    <i className="fa-solid fa-user me-3"></i>  Profile <i class="fa-solid fa-chevron-down ms-2"></i>
                                </button>

                                <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                    <div className="accordion-body ps-lg- mt-2">
                                        <ul>
                                            <li className="nav-item" style={{ color: "#F1BB44" }}>
                                                <NavLink to="/personal-information" className="nav-link" style={{ color: "white" }}>Personal information</NavLink>
                                            </li>
                                            <li className="nav-item mt-2" style={{ color: "#F1BB44" }}>
                                                <NavLink to="/address-information" className="nav-link" style={{ color: "white" }}>Address information</NavLink>
                                            </li>
                                            <li className="nav-item mt-2" style={{ color: "#F1BB44" }}>
                                                <NavLink to="/academic-details" className="nav-link" style={{ color: "white" }}>Academic details</NavLink>
                                            </li><li className="nav-item mt-2" style={{ color: "#F1BB44" }}>
                                                <NavLink to="/account-details" className="nav-link" style={{ color: "white" }}>Account details</NavLink>
                                            </li><li className="nav-item mt-2" style={{ color: "#F1BB44" }}>
                                                <NavLink to="/admission-details" className="nav-link" style={{ color: "white" }}>Admission details</NavLink>
                                            </li><li className="nav-item mt-2" style={{ color: "#F1BB44" }}>
                                                <NavLink to="/student-experience" className="nav-link" style={{ color: "white" }}>Student experience</NavLink>
                                            </li>
                                            <li className="nav-item mt-2" style={{ color: "#F1BB44" }}>
                                                <NavLink to="/travel-details" className="nav-link" style={{ color: "white" }}>Travel details</NavLink>
                                            </li>
                                            <li className="nav-item mt-2" style={{ color: "#F1BB44" }}>
                                                <NavLink to="/visa-details" className="nav-link" style={{ color: "white" }}>Visa details</NavLink>
                                            </li>
                                            <li className="nav-item mt-2" style={{ color: "#F1BB44" }}>
                                                <NavLink to="/emergency-contact " className="nav-link" style={{ color: "white" }}>Emergency contact</NavLink>
                                            </li>
                                             <li className="nav-item mt-2" style={{ color: "#F1BB44" }}>
                                                <NavLink to="/social-contact" className="nav-link" style={{ color: "white" }}>Social contact</NavLink>
                                            </li>
                                            <li className="nav-item mt-2" style={{ color: "#F1BB44" }}>
                                                <NavLink to="/document-&-attachments" className="nav-link" style={{ color: "white" }}>Document & attachments</NavLink>
                                            </li>

                                        </ul>
                                    </div>
                                </div>
                            </span>
                        </div> */}
                        </>


                        <div className='row row-link mt-2'>
                            <NavLink to="/personal-information" className="nav-l"><i className="fa-solid fa-file-invoice me-2"></i> Personal information</NavLink>
                        </div>
                        <div className='row row-link mt-2'>
                            <NavLink to="/address-information" className="nav-l"><i className="fa-solid fa-file-invoice me-2"></i> Address information</NavLink>
                        </div>
                        <div className='row row-link mt-2'>
                            <NavLink to="/academic-details" className="nav-l"><i className="fa-solid fa-file-invoice me-2"></i> Academic details</NavLink>
                        </div>
                        <div className='row row-link mt-2'>
                            <NavLink to="/account-details" className="nav-l"><i className="fa-solid fa-file-invoice me-2"></i>Bank account details</NavLink>
                        </div>
                        <div className='row row-link mt-2'>
                            <NavLink to="/admission-details" className="nav-l"><i className="fa-solid fa-file-invoice me-2"></i> Admission details</NavLink>
                        </div>
                        <div className='row row-link mt-2'>
                            <NavLink to="/student-experience" className="nav-l"><i className="fa-solid fa-file-invoice me-2"></i> Student experience</NavLink>
                        </div>
                        <div className='row row-link mt-2'>
                            <NavLink to="/travel-details" className="nav-l"><i className="fa-solid fa-file-invoice me-2"></i> Travel details</NavLink>
                        </div>
                        <div className='row row-link mt-2'>
                            <NavLink to="/visa-details" className="nav-l"><i className="fa-solid fa-file-invoice me-2"></i> Visa details</NavLink>
                        </div>
                        <div className='row row-link mt-2'>
                            <NavLink to="/emergency-contact" className="nav-l"><i className="fa-solid fa-file-invoice me-2"></i> Emergency contact</NavLink>
                        </div>
                        <div className='row row-link mt-2'>
                            <NavLink to="/social-contact" className="nav-l"><i className="fa-solid fa-file-invoice me-2"></i> Social contact</NavLink>
                        </div>
                        <div className='row row-link mt-2'>
                            <NavLink to="/document-&-attachments" className="nav-l"><i className="fa-solid fa-file-invoice me-2"></i> Document & attachments</NavLink>
                        </div>


                    </div>

                </div>
            </div>



        </div>
    )
}

export default Ssidebar