import React, { useEffect, useState } from 'react'
// import Swal from 'sweetalert2';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'
import 'react-tagsinput/react-tagsinput.css'
import './accommodation.css'
import Navbar from '../../compunent/Navbar';
import Footer from '../../compunent/Footer';

const Accommodation = () => {
    const [toggle, setToggle] = useState(true);
    const navigate = useNavigate();
    const Toggle = () => {
        setToggle(!toggle);
    };

    const token = localStorage.getItem('studenttoken')
    const [accommodationdata,setaccommodationdata] = useState([])
    const [filtercity,setfiltercity] = useState([])
    const [city,setcityvalue] = useState("")

    useEffect(() => {
        getaccommodation();
    }, [])

    const getaccommodation = () => {
        axios.post("https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/accommodation/GetAccommodation", {}, {
            headers: {
                Authorization: token,
            },
        })
            .then((res) => {
                // console.log(res.data)
                // setfiltercity(res.data.data.accommodationCity)
                if (res.data.message === "Accommodation found") {
                    setaccommodationdata(res.data.data)
                    setfiltercity(res.data.data)
                } else {
                    navigate("/")
                }

            }).catch((e) => {
                console.log(e)
            })
    }

    const filter = () => {
        axios.post("https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/accommodation/GetAccommodation", {city}, {
            headers: {
                Authorization: token,
            },
        })
            .then((res) => {
                console.log(res.data)
                // setfiltercity(res.data.data.accommodationCity)
                // if (res.data.message === "Accommodation found") {
                    setaccommodationdata(res.data.data)
                    // setfiltercity(res.data.data)
                // } else {
                //     navigate("/")
                // }

            }).catch((e) => {
                console.log(e)
            })
    }

    const filterclear =() =>{
        getaccommodation()
        setcityvalue("")
    }

    const getID = (id) =>{
        // console.warn(id)
        navigate(`/view-accommodation/${id}`)
    }

  
  return (
    <div>
        <Navbar/>
           <div className="container">
            <div className="row">
                <div className="col">
                    <div className='container-fluid accommodation-main'>
                        <div className='row mt-3 text-start'>
                            <h2>Accommodation</h2>
                        </div>

                        <div>
                            <div className='row text-start mt-4 acc-form'>
                                <div className='col-lg-4 col-12'>
                                    <div className="mb-">
                                        <label for="exampleInputEmail1" className="form-label">City</label>
                                        <select className="form-select"
                                            value={city}
                                            onChange={(e) => setcityvalue(e.target.value)}
                                            aria-label="Default select example">
                                            <option selected>Select city</option>
                                            {
                                                filtercity.map((item,index) => {

                                               return <option value={item.accommodationCity} key={index}>{item.accommodationCity}</option>
                                                    
                                                })
                                            }
                                 
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row mt-3 btn-row'>
                            <div className='col-lg-1 col-6 d-flex align-items-start justify-content-start'>
                                <button className='btn btn-primary' onClick={filter}>Filter</button>
                            </div>
                            <div className='col-lg-1 col-6 d-flex align-items-start justify-content-start'>
                                <button className='btn btn-primary' onClick={filterclear}>Clear</button>
                            </div>
                            <div className='col-lg'></div>
                        </div>
                       
                        <div className='row mt-4 accom-cart'>
                            {
                                accommodationdata?
                                accommodationdata.map((item) => {
                                   return <div className='col-lg-4 mb-4' key={item._id} style={{cursor: "pointer"}} onClick={() => getID(item.postCode)}>
                                    <div className='box-cart'>
                                        <div className='row'>
                                            <img src={`https://globallearnandearnapi.doctorsforhealth.co.uk/`+item.accommodationImages[0]} alt='' className='img-fluid'></img>
                                        </div>
    
                                        <div className='row px-3 pt-2'>
                                            <div className='col text-start'><h3>City</h3> </div>
                                            <div className='col text-end'><h4>{item.accommodationCity}</h4> </div>
                                        </div>
                                        <div className='row px-3 pt-1'>
                                            <div className='col text-start'><h3>Postal code</h3> </div>
                                            <div className='col text-end'><h4>{item.postCode}</h4> </div>
                                        </div>
                                    </div>
                                </div>
                                }):""
                            }
                           
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <Footer/>
    </div>
  )
}

export default Accommodation
