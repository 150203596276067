import React, { useEffect, useState } from 'react'
import Ssidebar from '../Sidebar/Ssidebar';
import Studentnav from '../Sidebar/Studentnav';
import Swal from 'sweetalert2';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'
import 'react-tagsinput/react-tagsinput.css'
import './Visadetail.css'
import Navbar from '../../compunent/Navbar';

const Visadetail = () => {
    const [toggle, setToggle] = useState(true);
    const navigate = useNavigate();
    const Toggle = () => {
        setToggle(!toggle);
    };

    const token = localStorage.getItem('studenttoken')

    useEffect(() => {
        getstudents()
    }, [])

    const [isVisaEndorsement, setisVisaEndorsement] = useState("")
    const [visaExpirationDate, setvisaExpirationDate] = useState("")
    const [visaIssueDate, setvisaIssueDate] = useState("")

    const handlesave = () => {
        // console.warn(bankName, accountNumber, accountTitle, IBAN)
        if (isVisaEndorsement && visaExpirationDate && visaIssueDate) {
            axios.post("https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/student/StudentVisaInfo", { isVisaEndorsement, visaExpirationDate, visaIssueDate }, {
                headers: {
                    Authorization: token,
                },
            }).then((res) => {
                if (res.data.message === "visaInfo information is added") {
                    Swal.fire({
                        title: "Thank you",
                        text: "Visa information is added",
                        icon: "success"
                    });

                    getstudents()
                } else {
                    console.log(res.data)

                }
            }).catch((e) => {
                console.log(e)
            })
        } else {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "please fill input",
            });
        }
    }

    const formatDate = (mongoDBDate) => {
        const date = new Date(mongoDBDate);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        console.warn(`${day}/${month}/${year}`)


        return `${year}-${month}-${day}`;
    };

    const getstudents = () => {
        axios.get("https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/student/StudentProfile", {
            headers: {
                Authorization: token,
            },
        })
            .then((res) => {
                console.log(res.data.data)
                if (res.data.message === "Student profile") {
                    setisVisaEndorsement(res.data.data[0].visaInfo.isVisaEndorsement)
                    setvisaExpirationDate(formatDate(res.data.data[0].visaInfo.visaExpirationDate))
                    setvisaIssueDate(formatDate(res.data.data[0].visaInfo.visaIssueDate))

                } else {
                    navigate("/")
                }

            }).catch((e) => {
                console.log(e)
            })
    }
    return (
        <div>
            <Navbar />
            <div className="container-fluid">
                <div className="row">
                    {toggle && (
                        <div
                            className="col-12 col-md-3 vh-100 position-fixed"
                            style={{ zIndex: 1000 }}
                        >
                            <Ssidebar Toggle={Toggle} />
                        </div>
                    )}

                    {toggle && <div className="col-12 col-lg-3"></div>}
                    <div className="col">
                        {/* <Studentnav Toggle={Toggle} /> */}
                        <div className='container-fluid visa-main'>
                            <div className='row mt-3 text-start'>
                                <h2>{toggle?"":<i className="fa-solid fa-bars" onClick={Toggle} style={{cursor: "pointer"}}></i>} Visa details</h2>
                            </div>
                            <div>
                                <div className='row text-start mt-4 acc-form'>
                                    <div className='col-lg-4 col-12'>
                                        <div className="mb-3">
                                            <label for="exampleInputEmail1" className="form-label">Have you got visa endorsment?</label>
                                            <select className="form-select"
                                                value={isVisaEndorsement} onChange={(e) => setisVisaEndorsement(e.target.value)}
                                                aria-label="Default select example">
                                                <option selected>Select</option>
                                                <option value="yes">Yes</option>
                                                <option value="no">No</option>
                                            </select>
                                        </div>
                                    </div>


                                </div>

                                {
                                    isVisaEndorsement === "yes" ? <>
                                        <div className='row text-start acc-form'>
                                            <div className='col-lg-4 col-12'>
                                                <div className="mb-3">
                                                    <label for="people" className="form-label">visa issue date</label>
                                                    <input type="date" className="form-control" id="people"
                                                        value={visaIssueDate} onChange={(e) => setvisaIssueDate(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-lg-4 col-12'>
                                                <div className="mb-3">
                                                    <label for="people" className="form-label">Visa expiration date</label>
                                                    <input type="date" className="form-control" id="people"
                                                        value={visaExpirationDate} onChange={(e) => setvisaExpirationDate(e.target.value)}
                                                    />
                                                </div>
                                            </div>


                                        </div>

                                        <div className='row mt-3 btn-row'>
                                            <div className='col d-flex align-items-start justify-content-start'>
                                                <button className='btn btn-primary' onClick={handlesave}>Save</button>
                                            </div>
                                        </div>

                                    </> : ""
                                }




                            </div>



                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Visadetail
