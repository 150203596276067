import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'

const Lettingroute = () => {

    const auth = localStorage.getItem('lettingtoken')
    return auth ? <Outlet /> : <Navigate to="/" />

}

export default Lettingroute
