import React, { useEffect, useState } from 'react'
import Ssidebar from '../Sidebar/Ssidebar';
import './Personalinformation.css'
import Swal from 'sweetalert2';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'
import Navbar from '../../compunent/Navbar'
import Loading from '../../Loading'


const Personalinformation = () => {
    const [toggle, setToggle] = useState(true);
    const [loadersubmit, setloadersubmit] = useState(false)
    const navigate = useNavigate();
    const Toggle = () => {
        setToggle(!toggle);
    };

    const token = localStorage.getItem('studenttoken')

    useEffect(() => {
        getstudents()
    }, [])

    const [foreName, setforeName] = useState("")
    const [surName, setsurName] = useState("")
    const [dateOfBirth, setdateOfBirth] = useState("")
    const [gender, setgender] = useState("")
    const [nationality, setnationality] = useState("")
    const [passportID, setpassportID] = useState("")
    const [NIC, setNIC] = useState("")

    const handlesave = () => {
        console.warn(foreName, surName, dateOfBirth, gender, nationality, passportID, NIC)

        if (foreName && surName && dateOfBirth && gender && nationality && passportID && NIC) {
            setloadersubmit(true)
            axios.post("https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/student/StudentPersonalInfo", { foreName, surName, dateOfBirth, gender, nationality, passportID, NIC }, {
                headers: {
                    Authorization: token,
                },
            }).then((res) => {
                if (res.data.message === "Personal information is added") {
                    Swal.fire({
                        title: "Thank you",
                        text: "Personal information is added",
                        icon: "success"
                    });
                    setloadersubmit(false)
                } else {
                    console.log(res.data)
                    setloadersubmit(false)
                }
            }).catch((e) => {
                console.log(e)
                setloadersubmit(false)
            })
        } else {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "please fill input",
            });
        }
    }

    const getstudents = () => {
        setloadersubmit(true)
        axios.get("https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/student/StudentProfile", {
            headers: {
                Authorization: token,
            },
        })
            .then((res) => {
                console.log(res.data.data)
                if (res.data.message === "Student profile") {
                    // setstudentdata(res.data.data)
                    setforeName(res.data.data[0].personalInfo.foreName)
                    setsurName(res.data.data[0].personalInfo.surName)
                    setdateOfBirth(formatDate(res.data.data[0].personalInfo.dateOfBirth))
                    setgender(res.data.data[0].personalInfo.gender)
                    setnationality(res.data.data[0].personalInfo.nationality)
                    setpassportID(res.data.data[0].personalInfo.passportID)
                    setNIC(res.data.data[0].personalInfo.NIC)
                    setloadersubmit(false)
                } else {
                    navigate("/")
                }

            }).catch((e) => {
                console.log(e)
                setloadersubmit(false)

            })
    }

    console.log(dateOfBirth)

    const formatDate = (mongoDBDate) => {
        const date = new Date(mongoDBDate);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        console.warn(`${day}/${month}/${year}`)


        return `${year}-${month}-${day}`;
    };

    if (loadersubmit) {
        return (
            <Loading />
        )
    }


    return (
        <div>
            <Navbar />

            <div className="container-fluid">

                <div className="row">
                    {toggle && (
                        <div
                            className="col-12 col-md-3 vh-90 position-fixed"
                            style={{ zIndex: 1000 }}
                        >
                            <Ssidebar Toggle={Toggle} />
                        </div>
                    )}

                    {toggle && <div className="col-12 col-lg-3"></div>}
                    <div className="col">
                        {/* <Studentnav Toggle={Toggle} /> */}
                        <div className='container-fluid personal-main'>
                            <div className='row mt-3 text-start'>
                                <h2>{toggle?"":<i className="fa-solid fa-bars" onClick={Toggle} style={{cursor: "pointer"}}></i>} Personal information</h2>
                            </div>
                            <div>
                                <div className='row text-start mt-4 acc-form'>
                                    <div className='col-lg-4 col-12'>
                                        <div className="mb-3">
                                            <label for="people" className="form-label">Name</label>
                                            <input type="text" className="form-control" id="people"
                                                value={foreName} onChange={(e) => setforeName(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-lg-4 col-12'>
                                        <div className="mb-3">
                                            <label for="people" className="form-label">Surname</label>
                                            <input type="text" className="form-control" id="people"
                                                value={surName} onChange={(e) => setsurName(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-lg-4 col-12'>
                                        <div className="mb-3">
                                            <label for="people" className="form-label">Date of birth</label>
                                            <input type="date" className="form-control" id="people"
                                                value={dateOfBirth} onChange={(e) => setdateOfBirth(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className='row text-start acc-form'>
                                    <div className='col-lg-4 col-12'>
                                        <div className="mb-3">
                                            <label for="exampleInputEmail1" className="form-label">Gender</label>
                                            <select className="form-select"
                                                value={gender} onChange={(e) => setgender(e.target.value)}
                                                aria-label="Default select example">
                                                <option selected>Select gender</option>
                                                <option value="male">Male</option>
                                                <option value="female">Female</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 col-12'>
                                        <div className="mb-3">
                                            <label for="people" className="form-label">Nationality</label>
                                            <input type="text" className="form-control" id="people"
                                                value={nationality} onChange={(e) => setnationality(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-lg-4 col-12'>
                                        <div className="mb-3">
                                            <label for="people" className="form-label">Passport</label>
                                            <input type="text" className="form-control" id="people"
                                                value={passportID} onChange={(e) => setpassportID(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className='row text-start acc-form'>
                                    <div className='col-lg-4 col-12'>
                                        <div className="mb-3">
                                            <label for="people" className="form-label">NIC #</label>
                                            <input type="number" className="form-control" id="people"
                                                value={NIC} onChange={(e) => setNIC(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div></div>

                            <div className='row mt-3 btn-row'>
                                <div className='col d-flex align-items-start justify-content-start'>
                                    <button className='btn btn-primary' onClick={handlesave}>Save</button>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
            {/* <Footer/> */}
        </div>
    )
}

export default Personalinformation
