import React, { useEffect } from 'react'
import { useState } from 'react'
import Adminnav from '../admin nav/Adminnav'
import Sidebar from '../sidebar/Sidebar'
import './Commissionformula.css'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'

const Commissionformula = () => {
    const [toggle, setToggle] = useState(true);
    const token = localStorage.getItem('admintokenglobal')
    const navigate = useNavigate();
    const [Servicetype , setServicetype] = useState("")
    const [discountPercentage , setdiscountPercentage] = useState("")
    const [commissionPercentage , setcommissionPercentage] = useState("")
    const [id , setid] = useState("")
    const Toggle = () => {
        setToggle(!toggle);
    };

    useEffect(() => {
        getcommission();
    }, [])

    const getcommission = () => {
        axios.get("https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/commissiondiscount/GetCommissionDiscount", {
            headers: {
                Authorization: token,
            },
        }).then((res) => {
            // console.warn(res.data.data[0]._id)
            if (res.data.message === "Successfully Comission Discount found") {
                setServicetype(res.data.data[0].serviceType)
                setdiscountPercentage(res.data.data[0].discountPercentage)
                setcommissionPercentage(res.data.data[0].commissionPercentage)
                setid(res.data.data[0]._id)
            } else {
                navigate("/")
            }

        }).catch((e) => {
            console.log("GetCommissionDiscount", e)
        })

    }

    const updateprofile = () => {
        axios.put(`https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/commissiondiscount/UpdateCommissionDiscount/?id=`+id, { discountPercentage,commissionPercentage }, {
            headers: {
                Authorization: token,
            },
        }).then((res) => {
            console.log(res.data)
            if (res.data.message === "ComissionDiscount data updated successfully") {
                Swal.fire({
                    title: "Thank you",
                    text: "Commission formula updated successfully",
                    icon: "success"
                });

                getcommission()
            }
        }).catch((e) => {
            console.log(e)
        })
    }
    return (
        <div className="container-fluid">
            <div className="row">
                {toggle && (
                    <div
                        className="col-12 col-md-3 vh-100 position-fixed"
                        style={{ zIndex: 1000 }}
                    >
                        <Sidebar Toggle={Toggle} />
                    </div>
                )}

                {toggle && <div className="col-12 col-lg-3"></div>}
                <div className="col">
                    <Adminnav Toggle={Toggle} />
                    <div className='container-fluid commission-main'>
                        <div className='row mt-3 text-start'>
                            <h2>Commission formula</h2>
                        </div>

                        <div>
                            <div className='row text-start mt-4 acc-form'>
                                <div className='col-lg-6 col-12'>
                                    <div class="mb-3">
                                        <label for="duration" class="form-label">Service type</label>
                                        <input type="text" class="form-control" id="duration" disabled
                                        value={Servicetype} onChange={(e) => setServicetype(e.target.value)}
                                         />
                                    </div>
                                </div>
                            </div>
                            <div className='row text-start mt-1 acc-form'>
                                <div className='col-lg-6 col-12'>
                                    <div class="mb-3">
                                        <label for="people" className="form-label">Discount to students in (%)</label>
                                        <input type="number" className="form-control" id="people" 
                                        value={discountPercentage} onChange={(e) => setdiscountPercentage(e.target.value)} 
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className='row text-start acc-form'>
                                <div className='col-lg-6 col-12'>
                                    <div class="mb-3">
                                        <label for="people" className="form-label">Commission to consultant in % (from total paid by student )</label>
                                        <input type="number" className="form-control" id="people" 
                                        value={commissionPercentage} onChange={(e) => setcommissionPercentage(e.target.value)}
                                         />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='row mt-3 btn-row'>
                            <div className='col d-flex align-items-start justify-content-start'>
                                <button className='btn btn-primary' onClick={updateprofile}>Update</button>
                            </div>
                        </div>

                    </div>


                </div>
            </div>
        </div>

    )
}

export default Commissionformula
