
import React from 'react'
import './About.css'
import aboutimg from '../../assiet/img/about-img.jpg'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import about1 from '../../assiet/img/about-1.png'
import about2 from '../../assiet/img/about-2.png'
import about3 from '../../assiet/img/about-3.png'
import Navbar from '../../compunent/Navbar';
import Footer from '../../compunent/Footer';

const About = () => {
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <div className='about-main'>
      <Navbar/>
      <div className='container py-lg-5 py-4'>
        <div className='row'>
          <div className='col-lg-6'>
            <div className='box-2 text-lg-start pe-3'>
              <div className='row row-about'>
                <h3>About us</h3>
              </div>

              <div className='row'>
                <h1>Global Learn And Earn</h1>
              </div>

              <div className='row pe-lg-5 pt-2 p-text'>
                <p className='pe-lg-5'>Boost your UK letting agency's growth by partnering with Global Learn & Earn, tapping into new property market opportunities. Join our educational network, collaborate on projects that innovate learning, and connect globally to make a significant community impact. Enhance your professional reach in both the real estate and educational sectors, starting today.</p>
              </div>
              <button className='btn btn-primary mt-lg-3 btn-learn'>Learn More</button>
            </div>
          </div>
          <div className='col-lg-6 mt-4 mt-lg-0'>
            <div className='form-img'>
              <img src={aboutimg} alt='' className='img-fluid'></img>
            </div>
          </div>
        </div>

        <div className='row mt-4 mb-2'>
          <div className='row'>
            <div className='col-lg-4'>
              <div className='row team-row text-lg-start'>
                <h2>Team Members</h2>
              </div>
            </div>
          </div>

          <div className='row mt-3'>
            <div className='slider-about px-'>
              <Slider {...settings}>
                {/* <div className='px-5 py-3'>
                  <div className='slider-box'>
                    <img src={about1} className='img-fluid'></img>
                    <h3 className='mt-2'>Tahir Munj</h3>
                    <h4>Founder</h4>
                  </div>
                </div> */}
                
                <div className='px-5 py-3'>
                  <div className='slider-box'>
                    <img src={about2} className='img-fluid'></img>
                    <h3 className='mt-2'>Dr. Muhmmad Adrees</h3>
                    <h4>Member BOD</h4>
                  </div>
                </div>

                <div className='px-5 py-3'>
                  <div className='slider-box'>
                    <img src={about3} className='img-fluid'></img>
                    <h3 className='mt-2'>Dr. Ahmed Zoha</h3>
                    <h4>CTO</h4>
                  </div>
                </div>

                {/* <div>
                  <h3>4</h3>
                </div>
                <div>
                  <h3>5</h3>
                </div> */}
              </Slider>
            </div>
          </div>
        </div>
      </div>
      <div>
      <Footer/>

      </div>
    </div>
  )
}

export default About
