import React from 'react'
import { useEffect, useState } from 'react'
import './LettingRegister.css'
import Navbar from '../../compunent/Navbar'
import Footer from '../../compunent/Footer'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
import Swal from 'sweetalert2'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { allCountries } from '../../result'
import Select from 'react-select';

const LettingRegister = () => {

    useEffect(() => {
        if (token) {
            navigate("/")
        } else {

        }
    }, [])


    const [name, setname] = useState("");
    const [email, setemail] = useState("");
    const [password, setpassword] = useState("");
    const [phone, setphone] = useState();
    // const [AisAgree, setAisAgree] = useState("");
    const [isAgree, setisAgree] = useState("");
    const [city, setcity] = useState("");
    const [a, seta] = useState(0);
    const navigate = useNavigate();
    const token = localStorage.getItem('studenttoken')

    const typeArrange = allCountries[145].cities.map((row) => {
        return {
            value: `${row}`,
            label: `${row}`,
        };
    });

    const handlesubmit = (e) => {
        e.preventDefault()
        console.log(isAgree, name, email, phone,city)
        if (name && email && isAgree && phone && password) {
            axios.post("https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/lettingagent/LettingAgentSignup", { email, phone, password, isAgree, name,city:city.value})
                .then((res) => {
                    if (res.data.token) {
                        Swal.fire({
                            title: "Thank you",
                            text: "Successful",
                            icon: "success"
                        })
                        navigate("/sign")
                        console.log(res.data)
                    } else {
                        console.log(res.data)
                    }
                }).catch((e) => {
                    if (e.response.data.message === "Letting Agent is already exist") {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "Letting Agent is already exist"
                        });
                        console.log(e)
                    } else {
                        console.log(e)
                    }
                })
        } else {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "please fill input",
            });
        }
    }

    return (
        <div className='letting-main'>
            <Navbar />
            <div className='container py-lg-5 py-4'>
                <div className='row'>
                    <div className='col-lg-6 px-lg-5'>
                        <div className='form-box p-4'>
                            <div className='row text-start'>
                                <h2>Create your letting agent account</h2>
                            </div>

                            <div className='row text-start mt-4 mb-2'>
                                <form>
                                    <div className="mb-3">
                                        <label for="exampleInputEmail1" className="form-label">Name</label>
                                        <input required type="text" className="form-control" id="exampleInputEmail1"
                                            value={name} onChange={(e) => setname(e.target.value)}
                                            aria-describedby="emailHelp" />
                                    </div>
                                    <div className="mb-3">
                                        <label for="exampleInputEmail1" className="form-label">Email address</label>
                                        <input required type="email" className="form-control" id="exampleInputEmail1"
                                            value={email} onChange={(e) => setemail(e.target.value)}
                                            aria-describedby="emailHelp" />
                                    </div>
                                    <div className="mb-3">
                                        <label for="exampleInputEmail1" className="form-label">Phone number</label>
                                        <PhoneInput
                                            className=""
                                            country={'pk'}
                                            value={phone}
                                            onChange={(value) => setphone(value)}
                                        />
                                    </div>

                                    <div className="mb-3">
                                        <label for="sdfsdfds" className="form-label">City</label>
                                        <Select
                                            isSearchable
                                            options={typeArrange}
                                        onChange={setcity}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label for="exampleInputPassword1" className="form-label">Password</label>
                                        <input required type="password" className="form-control"
                                            value={password} onChange={(e) => setpassword(e.target.value)}
                                            id="exampleInputPassword1" />
                                    </div>
                                    <div className="mb-3 form-check">
                                        <input required type="checkbox" className="form-check-input"
                                            value="yes"
                                            checked={isAgree}
                                            onChange={(e) => setisAgree(e.target.value)}
                                            id="exampleCheck1" />
                                        <label className="form-check-label ms-2" for="exampleCheck1" style={{ fontSize: "1rem" }}>Agree to terms and conditions</label>
                                    </div>

                                    <button className="btn btn-primary" onClick={handlesubmit}>Create account</button>
                                </form>
                            </div>


                        </div>
                    </div>


                    <div className='col-lg-6 px-lg-5 mt-3 mt-lg-0'>
                        <div className='form-box p-4 text-start'>
                            <div className='row'>
                                <h2>Already have an account?</h2>
                            </div>
                            <div className='row'>
                                <h4>Sign in and continue your learn & earn journey</h4>
                            </div>
                            <button className="btn btn-primary mt-3" onClick={() => navigate("/sign")}>Sign in</button>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>

    )
}

export default LettingRegister
