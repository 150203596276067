import React, { useEffect, useState } from 'react'
import { allCountries } from '../../result'
import Agentnav from '../sideandnav/Agentnav';
import Agentside from '../sideandnav/Agentside';
import './DealInstitute.css'
import Swal from 'sweetalert2';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'
import Select from 'react-select';
import TagsInput from 'react-tagsinput';

const DealInstitute = () => {

    const [toggle, setToggle] = useState(true);
    const navigate = useNavigate();
    const Toggle = () => {
        setToggle(!toggle);
    };

    const token = localStorage.getItem('agenttoken')

    useEffect(() => {
        getagent()
    }, [])

    const typeArrange = allCountries.map((row) => {
        return {
            value: `${row.name}`,
            label: `${row.name}`,
            name: `${row.name}`,
        };
    });

    const universities = [
        { value: 'Diploma', label: 'Diploma' },
        { value: 'Bachelors', label: 'Bachelors' },
        { value: 'Masters', label: 'Masters' },
        { value: 'Phd', label: 'Phd' },
    ];


    const [dealInWhichUniversities, setdealInWhichUniversities] = useState([])
    const [dealInWhichCountrie, setdealInWhichCountrie] = useState("")
    const [levelOfProgram, setlevelOfProgram] = useState()
    const [levelOfProgramaa, setlevelOfProgramaa] = useState([])


    const handlesave = () => {
        // setlevelOfProgramaa(levelOfProgram)
        console.warn(levelOfProgram)

        const dealWithWhichInstitute = [
            {
                dealInWhichCountrie: dealInWhichCountrie.value,
                dealInWhichUniversities: dealInWhichUniversities,
                levelOfProgram: selectedPrograms,
            },
        ]

        if (selectedPrograms && dealInWhichUniversities && dealInWhichCountrie) {
        axios.post("https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/consultant/ChannelPartnerdealWithWhichInstitute", { dealWithWhichInstitute }, {
            headers: {
                Authorization: token,
            },
        }).then((res) => {
            if (res.data.message === "Deal With Which Institute is added") {
                Swal.fire({
                    title: "Thank you",
                    text: "Deal with which institute is added",
                    icon: "success"
                });
            } else {
                console.log(res.data)

            }
        }).catch((e) => {
            console.log(e)
        })
        } else {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "please fill input",
            });
        }
    }

    const getagent = () => {
        axios.get("https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/consultant/ChannelPartnerProfile", {
            headers: {
                Authorization: token,
            },
        })
            .then((res) => {
                console.log(res.data)
                if (res.data.message === "Channel Parnter profile") {
                    console.log(res.data.data.dealWithWhichInstitute[0].dealInWhichCountrie)
                    const countryvalue = res.data.data.dealWithWhichInstitute[0].dealInWhichCountrie;
                    // console.warn(countryvalue)
                    setdealInWhichCountrie(
                        {
                            value: countryvalue,
                            label: countryvalue,
                            name: countryvalue,
                        }
                    )
                    setdealInWhichUniversities(res.data.data.dealWithWhichInstitute[0].dealInWhichUniversities)

                    setSelectedPrograms(res.data.data.dealWithWhichInstitute[0].levelOfProgram
                    )

                } else {
                    navigate("/")
                }

            }).catch((e) => {
                console.log(e)
            })
    }

    // const formatDate = (mongoDBDate) => {
    //     const date = new Date(mongoDBDate);
    //     const day = String(date.getDate()).padStart(2, '0');
    //     const month = String(date.getMonth() + 1).padStart(2, '0');
    //     const year = date.getFullYear();
    //     console.warn(`${day}/${month}/${year}`)


    //     return `${year}-${month}-${day}`;
    // };

    const [selectedPrograms, setSelectedPrograms] = useState([]);

    const handleLevelChange = (e) => {
        const selectedOptions = Array.from(e.target.selectedOptions, option => option.value);
        setSelectedPrograms(selectedOptions);
    };

    console.warn(selectedPrograms)

    return (
        <div className="container-fluid">
            <div className="row">
                {toggle && (
                    <div
                        className="col-12 col-md-3 vh-100 position-fixed"
                        style={{ zIndex: 1000 }}
                    >
                        <Agentside Toggle={Toggle} />
                    </div>
                )}

                {toggle && <div className="col-12 col-lg-3"></div>}
                <div className="col">
                    <Agentnav Toggle={Toggle} />
                    <div className='container-fluid Deallnstitute-main'>
                        <div className='row mt-3 text-start'>
                            <h2>Deal with which institute</h2>
                        </div>
                        <div>
                            <div className='row text-start mt-4 acc-form'>
                                <div className='col-lg-4 col-12'>
                                    <div className="mb-3">
                                        <label for="people" className="form-label">In which Country</label>
                                        <Select
                                            value={dealInWhichCountrie}
                                            onChange={setdealInWhichCountrie}
                                            options={typeArrange}
                                            isSearchable
                                        />
                                    </div>
                                </div>
                                <div className='col-lg-4 col-12'>
                                    <div className="mb-3">
                                        <label for="people" className="form-label">In which universities</label>
                                        <TagsInput
                                            value={dealInWhichUniversities} onChange={setdealInWhichUniversities}
                                        />
                                    </div>
                                </div>
                                <div className='col-lg-4 col-12'>
                                    <div className="mb-3">
                                        <label for="exampleInputEmail1" className="form-label">Level of program</label>
                                        {/* <Select
                                            value={levelOfProgram}
                                            onChange={setlevelOfProgram}
                                            isMulti
                                            options={universities}
                                            isSearchable
                                        /> */}
                                        <select
                                            className="form-select"
                                            multiple={true}
                                            onChange={handleLevelChange}
                                            value={selectedPrograms}
                                            aria-label="Default select example"
                                        >
                                            <option value="Diploma">Diploma</option>
                                            <option value="Bachelors">Bachelors</option>
                                            <option value="Masters">Masters</option>
                                            <option value="Phd">Phd</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='row mt-3 btn-row'>
                            <div className='col d-flex align-items-start justify-content-start'>
                                <button className='btn btn-primary' onClick={handlesave}>Save</button>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </div>
    )
}

export default DealInstitute
