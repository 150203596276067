import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import Adminnav from '../admin nav/Adminnav'
import Sidebar from '../sidebar/Sidebar'
import './Confirmed.css'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import Loading from '../../Loading'

const Confirmed = () => {
    const [toggle, setToggle] = useState(true);
    const token = localStorage.getItem('admintokenglobal')
    const [loadersubmit, setloadersubmit] = useState(false)
    const navigate = useNavigate();
    const Toggle = () => {
        setToggle(!toggle);
    };

    useEffect(() => {
        getorders()
    }, [])

    const [orderdata, setorderdata] = useState([]);
    const [accommodationType, setaccommodationType] = useState("");
    const [startDate, setstartDate] = useState("");
    const [endDate, setendDate] = useState("");

    const getorders = () => {
        // setloadersubmit(true)
        axios.post("https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/manageaccommodation/ViewServiceRequestConfirmed", {}, {
            headers: {
                Authorization: token,
            },
        })
            .then((res) => {
                console.log(res.data)
                if (res.data.message === "Services are found") {
                    setorderdata(res.data.data)
                    setloadersubmit(false)
                } else {
                    navigate("/")
                }

            }).catch((e) => {
                console.log(e)
            })
    }

    const confirmedfilter = () => {
        axios.post("https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/manageaccommodation/ViewServiceRequestConfirmed", { accommodationType, startDate, endDate }, {
            headers: {
                Authorization: token,
            },
        })
            .then((res) => {
                console.log(res.data)
                // if (res.data.data._id) {
                setorderdata(res.data.data)

                // } else {
                //     setorderdata()

                // }

            }).catch((e) => {
                console.log(e)
            })
    }

    const clearfilter = () => {
        getorders()
        setaccommodationType("")
        setstartDate("")
        setendDate("")
    }

    const formatDate = (mongoDBDate) => {
        const date = new Date(mongoDBDate);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();

        return `${day}/${month}/${year}`;
    };

    const deallocatehandle = (requestId) => {
        console.warn(requestId)
        if (requestId) {
            Swal.fire({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, Deallocate"
            }).then((result) => {
                if (result.isConfirmed) {
                    axios.post("https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/manageaccommodation/DeallocateToStudent", { requestId}, {
                        headers: {
                            Authorization: token,
                        },
                    }).then((res) => {
                        console.log(res.data)
                        if (res.data.message === "Successfully Share living deallocated") {
                            Swal.fire({
                                title: "Thank you",
                                text: "Successfully room deallocated",
                                icon: "success"
                            });
                            getorders()
                        }
                    }).catch((e) => {
                        console.log(e)
                        if (e.response.data.message === "Not found") {
                            Swal.fire({
                                icon: "error",
                                title: "Oops...",
                                text: "Not found",
                            });
                        }else if(e.response.data.message === "Manual user not found"){
                            Swal.fire({
                                icon: "error",
                                title: "Oops...",
                                text: "Manual user not found",
                            });
                        } else {
                            console.log(e)

                        }
                    })

                }
            });
        } else {
            console.warn("living id not found")
        }
    }

    if (loadersubmit) {
        return (
            <Loading />
        )
    }

    return (
        <div className="container-fluid">
            <div className="row">
                {toggle && (
                    <div
                        className="col-12 col-md-3 vh-100 position-fixed"
                        style={{ zIndex: 1000 }}
                    >
                        <Sidebar Toggle={Toggle} />
                    </div>
                )}

                {toggle && <div className="col-12 col-lg-3"></div>}
                <div className="col">
                    <Adminnav Toggle={Toggle} />

                    <div className='container-fluid Confirmed-main'>
                        <div className='row mt-3 text-start'>
                            <h2>Bookings {">"} Confirmeds</h2>
                        </div>
                        <div>
                            <div className='row text-start mt-4 acc-form'>
                                <div className='col-lg-4 col-12'>
                                    <div className="mb-3">
                                        <label for="exampleInputEmail1" className="form-label">Accommodation type </label>
                                        <select className="form-select"
                                            value={accommodationType}
                                            onChange={(e) => setaccommodationType(e.target.value)}
                                            aria-label="Default select example">
                                            <option selected>Select type</option>
                                            <option value="share">Shared</option>
                                            <option value="independent">Independent</option>
                                        </select>
                                    </div>
                                </div>
                                <div className='col-lg-4 col-12'>
                                    <div className="mb-3">
                                        <label for="people" className="form-label">Check in date</label>
                                        <input type="date" className="form-control" id="people"
                                            value={startDate} onChange={(e) => setstartDate(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className='col-lg-4 col-12'>
                                    <div className="mb-3">
                                        <label for="people" className="form-label">Check out date</label>
                                        <input type="date" className="form-control" id="people"
                                            value={endDate} onChange={(e) => setendDate(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row mt-3 btn-row'>
                            <div className='col-lg-1 col-6 d-flex align-items-start justify-content-start'>
                                <button className='btn btn-primary' onClick={confirmedfilter}>Filter</button>
                            </div>
                            <div className='col-lg-1 col-6 d-flex align-items-start justify-content-start'>
                                <button className='btn btn-primary' onClick={clearfilter}>Clear</button>
                            </div>
                            <div className='col-lg'></div>
                        </div>

                        <div className='row'>
                            <div className='col table11'>
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">Sr.</th>
                                            <th scope="col">Role</th>
                                            <th scope="col">Name</th>
                                            <th scope="col">Email</th>
                                            <th scope="col">Phone</th>
                                            <th scope="col">Check in</th>
                                            <th scope="col">Check out</th>
                                            <th scope="col">Accommodation type</th>
                                            <th scope="col">Room availability</th>
                                            <th scope="col">Booking status</th>
                                            <th scope="col">Total amount</th>
                                            <th scope="col">Discount amount</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {orderdata ?
                                            orderdata.map((item, index) => {
                                                return <tr key={item._id}>
                                                    <th scope="row">{index + 1}</th>
                                                    <td>{item.userRole}</td>
                                                    <td>{item.name}</td>
                                                    <td>{item.email}</td>
                                                    <td>{item.phone}</td>
                                                    <td>{formatDate(item.checkInDate)}</td>
                                                    <td>{formatDate(item.checkOutDate)}</td>
                                                    <td>{item.accommodationType}</td>
                                                    <td>{item.livingId.livingAvailability}</td>
                                                    <td>{item.bookingStatus}</td>
                                                    <td>{item.totalAmountPrice}</td>
                                                    <td>{item.discountAmount}</td>
                                                    <td>
                                                <li className="nav-item dropdown" style={{ listStyle: "none" }}>
                                                    <button className="btn btn-primary dropdown-toggle" style={{ backgroundColor: "#296013", border: "none", height: "30px" }} role="button" data-bs-toggle="dropdown" aria-expanded="false">

                                                    </button>
                                                    <ul className="dropdown-menu">
                                                    <li style={{ cursor: "pointer" }} onClick={() => deallocatehandle(item._id,)}>
                                                        <td className="dropdown-item"><i class="fa-solid fa-xmark me-2" style={{ color: "#B63A3A" }}></i> Deallocate</td>
                                                        </li>
                                                        {/* <li style={{ cursor: "pointer" }} onClick={() => localdelete(item._id)}><td className="dropdown-item"><i class="fa-solid fa-trash me-2" style={{ color: "#B63A3A" }}></i> Delete</td></li>
                                                        <li style={{ cursor: "pointer" }}
                                                            onClick={() => handleShow(item._id, item.bankName, item.accountTitle, item.accountNumber, item.IBAN)}
                                                        ><td className="dropdown-item"><i className="fa-solid fa-pen-to-square me-2"
                                                            style={{ color: "#198BEC" }}></i> Update</td></li> */}
                                                    </ul>
                                                </li>
                                            </td>
                                                </tr>
                                            }) : ""

                                        }


                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>

    )
}

export default Confirmed
