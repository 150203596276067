import React, { useEffect, useState } from 'react'
import './Sign.css'
import Navbar from '../../compunent/Navbar'
import Footer from '../../compunent/Footer'
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import Loading from '../../Loading'

const Login = () => {

    const [email, setemail] = useState("");
    const [password, setpassword] = useState("");
    const [a, seta] = useState(0);
    const navigate = useNavigate();
    const [loadersubmit, setloadersubmit] = useState(false)

    const token = localStorage.getItem('studenttoken')
    const token2 = localStorage.getItem('agenttoken')
    const token3 = localStorage.getItem('lettingtoken')

    useEffect(() => {
        if (token || token2 || token3) {
            navigate("/")
        } else {
            // navigate("")
        }
    })

    const handletroggle = (e) => {
        e.preventDefault()
        const value = e.target.value;
        if (value === "Student") {
            seta(0)
        } else if (value === "Agent") {
            seta(1)
        } else if (value === "letting") {
            seta(2)
        } else {
            seta(0)

        }
    }

    const studentlogin = (e) => {
        e.preventDefault()
        if (email && password) {
            setloadersubmit(true)
            axios.post("https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/student/studentsignin", { email, password })
                .then((res) => {
                    console.log(res.data)
                    if (res.data.token) {
                        localStorage.setItem("studenttoken", res.data.token)
                        setloadersubmit(false)
                        navigate("/")
                    }
                }).catch((e) => {
                    if (e.response.data.message === "password is wrong") {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "Password is wrong",
                        });
                        setloadersubmit(false)

                    } else if (e.response.data.message === "user does not exist") {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "User does not exist",
                        });
                        setloadersubmit(false)
                    } else {
                        console.log(e)
                        setloadersubmit(false)
                    }
                })
        } else {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "please fill input",
            });
        }
    }



    const [Aemail, setAemail] = useState("");
    const [Apassword, setApassword] = useState("");

    const agentlogin = (e) => {
        e.preventDefault()
        const email = Aemail;
        const password = Apassword;
        if (email && password) {
            setloadersubmit(true)
            axios.post("https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/consultant/ChannelPartnerSignin", { email, password })
                .then((res) => {
                    console.log(res.data)
                    if (res.data.token) {
                        localStorage.setItem("agenttoken", res.data.token)
                        navigate("/")
                        setloadersubmit(false)
                    }
                }).catch((e) => {
                    if (e.response.data.message === "password is wrong") {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "Password is wrong",
                        });
                        setloadersubmit(false)
                    } else if (e.response.data.message === "user does not exist") {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "User does not exist",
                        });
                        setloadersubmit(false)
                    } else {
                        console.log(e)
                        setloadersubmit(false)
                    }
                })
        } else {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "please fill input",
            });
        }
    }

    const [lemail, setlemail] = useState("");
    const [lpassword, setlpassword] = useState("");

    const lettinglogin = (e) => {
        e.preventDefault()
        const email = lemail;
        const password = lpassword;
        if (email && password) {
            setloadersubmit(true)
            axios.post("https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/lettingagent/LettingAgentSignin", { email, password })
                .then((res) => {
                    console.log("letting-login",res.data)
                    if (res.data.token) {
                        localStorage.setItem("lettingtoken", res.data.token)
                        navigate("/bid-post")
                        setloadersubmit(false)
                    }
                }).catch((e) => {
                    if (e.response.data.message === "password is wrong") {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "Password is wrong",
                        });
                        setloadersubmit(false)
                    } else if (e.response.data.message === "user does not exist") {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "User does not exist",
                        });
                        setloadersubmit(false)
                    } else {
                        console.log("letting",e)
                        setloadersubmit(false)
                    }
                })
        } else {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "please fill input",
            });
        }
    }

    if (loadersubmit) {
        return (
            <Loading />
        )
    }

    return (
        <div className='sign-main'>
            <Navbar />
            <div className='container py-lg-5 py-4'>
                <div className='row'>
                    <div className='col-lg-6 px-lg-5'>
                        <div className='form-box p-4'>
                            <div className='row text-start'>
                                <h2>Sign in to your account</h2>
                            </div>

                            <div className='row text-start mt-4 mb-2'>
                                <form>
                                    <div className="mb-3">
                                        <label for="exampleInputEmail1" className="form-label">Select type</label>
                                        <select className="form-select"
                                            onChange={handletroggle}
                                            aria-label="Default select example">
                                            {/* <option selected>Select country</option> */}
                                            <option value="Student">Student</option>
                                            <option value="Agent">Agent</option>
                                            <option value="letting">Letting agent</option>
                                        </select>
                                    </div>
                                    {
                                        a === 1 ?
                                            <>
                                                <div className="mb-3">
                                                    <label for="exampleInputEmail1" className="form-label">Email address</label>
                                                    <input required type="email" className="form-control" id="exampleInputEmail1"
                                                        value={Aemail}
                                                        onChange={(e) => setAemail(e.target.value)}
                                                        aria-describedby="emailHelp" />
                                                </div>
                                                <div className="mb-3">
                                                    <label for="exampleInputPassword1" className="form-label">Password</label>
                                                    <input required type="password" className="form-control"
                                                        value={Apassword}
                                                        onChange={(e) => setApassword(e.target.value)}
                                                        id="exampleInputPassword1" />
                                                </div>
                                                <button onClick={agentlogin} className="btn btn-primary mt-3"
                                                >Sign in</button>
                                            </> :

                                            a === 2 ? <>
                                                <div className="mb-3">
                                                    <label for="exampleInputEmail1" className="form-label">letting Email address</label>
                                                    <input required type="email" className="form-control" id="exampleInputEmail1"
                                                        value={lemail}
                                                        onChange={(e) => setlemail(e.target.value)}
                                                        aria-describedby="emailHelp" />
                                                </div>
                                                <div className="mb-3">
                                                    <label for="exampleInputPassword1" className="form-label">Password</label>
                                                    <input required type="password" className="form-control"
                                                        value={lpassword}
                                                        onChange={(e) => setlpassword(e.target.value)}
                                                        id="exampleInputPassword1" />
                                                </div>
                                                <button onClick={lettinglogin} className="btn btn-primary mt-3"
                                                >Sign in</button>
                                            </> :
                                                <>
                                                    <div className="mb-3">
                                                        <label for="exampleInputEmail1" className="form-label">Email address</label>
                                                        <input required type="email" className="form-control" id="exampleInputEmail1"
                                                            value={email}
                                                            onChange={(e) => setemail(e.target.value)}
                                                            aria-describedby="emailHelp" />
                                                    </div>
                                                    <div className="mb-3">
                                                        <label for="exampleInputPassword1" className="form-label">Password</label>
                                                        <input required type="password" className="form-control"
                                                            value={password}
                                                            onChange={(e) => setpassword(e.target.value)}
                                                            id="exampleInputPassword1" />
                                                    </div>
                                                    <button className="btn btn-primary mt-3"
                                                        onClick={studentlogin}
                                                    >Sign in</button>
                                                </>
                                    }


                                </form>
                            </div>
                        </div>
                    </div>


                    <div className='col-lg-6 px-lg-5 mt-4 mt-lg-0'>
                        <div className='form-box p-4 text-start'>
                            <div className='row'>
                                <h2>Don't have an account?</h2>
                            </div>
                            <div className='row'>
                                <h4>Register for free today and start your learn & earn journey</h4>
                            </div>
                            <button className="btn btn-primary mt-3">Register for free</button>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Login
