
import React from 'react'
import './airport.css'
import Navbar from '../../compunent/Navbar'
import Footer from '../../compunent/Footer'

const Airport = () => {
  return (
    <div className='air-main'>
        <Navbar/>
    <div className='banner-a'>
        {/* <div className='container'>
            <div className='row'>
                <div className='col'>
                    <h2>Book your stay with learn and earn </h2>
                </div>
            </div>
        </div> */}
    </div>

    <div className='container my-4'>
        <div className='row'>
            <div className='col-lg-7 form-a'>
                <div className='row row-g text-lg-start'>
                    <h3>Services</h3>
                </div>

                <div className='row form-h2 text-lg-start'>
                    <h2>Airport Pick up</h2>
                </div>

                <div className='row text-lg-start mt-2'>
                    <p className='fw-bold'>Meet & Greet Services:</p>
                    <p>
                    We will offer a hassle-free pick-up and drop-off service to students / Travelers arriving in the UK.
                     By allowing students / Travelers to book these services before their arrival,
                     we ensure a warm welcome and a smooth transfer to their accommodation or educational
                      institutions.
                    </p>
                    <p>
                        <p className='fw-bold' >Short Letting Services: </p>
                        Many students / Travelers face the challenge of finding temporary accommodation 
                        upon arrival in the UK. Our platform will arrange short-term rentals, providing a
                         secure and comfortable place
                         to stay while students search for long term accommodation.
                    </p>
                    
                </div>
            </div>
        </div>
    </div>
    <Footer/>
</div>
  )
}

export default Airport
