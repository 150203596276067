import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import Adminnav from '../admin nav/Adminnav'
import Sidebar from '../sidebar/Sidebar'
import './Allagent.css'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import Modal from 'react-bootstrap/Modal';
import { allCountries } from '../../result'
import Select from 'react-select';
import Loading from '../../Loading'

const Allagent = () => {
    const [toggle, setToggle] = useState(true);
    const token = localStorage.getItem('admintokenglobal')
    const [loadersubmit, setloadersubmit] = useState(false)
    const navigate = useNavigate();
    const Toggle = () => {
        setToggle(!toggle);
    };

    const [countryname, setcountryname] = useState([]);

    useEffect(() => {
        getallagent()
        const typeArrange = allCountries.map((row) => {
            return {
                value: `${row.name}`,
                label: `${row.name}`,
                name: `${row.name}`,
            };
        });
        //   console.warn(typeArrange)
        setcountryname(typeArrange)

    }, [])

    const [allagentdata, setallagentdata] = useState([]);
    const [filteremail, setfilteremail] = useState([]);
    const [filtercity, setfiltercity] = useState([]);
    const [country, setcountry] = useState("");
    const [cityname, setcityname] = useState("");

    const getallagent = () => {
        // setloadersubmit(true)
        axios.post("https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/consultant/ChannelPartnerProfileApproved", {}, {
            headers: {
                Authorization: token,
            },
        })
            .then((res) => {
                // console.log(res.data)
                if (res.data.message === "Channel Partner detail found") {
                    setallagentdata(res.data.data)
                    setfilteremail(res.data.data)
                    setloadersubmit(false)
                } else {
                    navigate("/")
                }
            }).catch((e) => {
                console.log(e)
            })
    }

    const [email, setemail] = useState("")

    const agentfilter = () => {
        const city = cityname.value;
        axios.post("https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/consultant/ChannelPartnerProfileApproved", { email, country, city }, {
            headers: {
                Authorization: token,
            },
        })
            .then((res) => {
                // console.log(res.data)
                // if (res.data.data._id) {
                setallagentdata(res.data.data)


                // } else {
                //     setorderdata()

                // }

            }).catch((e) => {
                if (e.response.data.message === "Consultant Not found") {
                    setallagentdata("")
                } else {
                    console.log(e)

                }
            })
    }

    const clearfilter = () => {
        getallagent()
        setemail("")
        setcountry("")

    }

    const handlecityfilter = (e) => {
        const value = e.value;
        setcountry(value)
        const cities = allCountries.find(ctr => ctr.name === value).cities
        // console.warn(filtercity)
        const typeArrange = cities.map((row) => {
            return {
                value: `${row}`,
                label: `${row}`,
                name: `${row}`,
            };
        });
        setfiltercity(typeArrange)

    }

    // const formatDate = (mongoDBDate) => {
    //     const date = new Date(mongoDBDate);
    //     const day = String(date.getDate()).padStart(2, '0');
    //     const month = String(date.getMonth() + 1).padStart(2, '0');
    //     const year = date.getFullYear();

    //     return `${day}/${month}/${year}`;
    // };
    const [lgShow, setLgShow] = useState(false);
    const [Viewdata, setViewdata] = useState();
    const [Tabno, setTabno] = useState("0");

    const Viewdetail = (data) => {
        setLgShow(true)
        console.warn(data)
        setViewdata(data)

        console.log(Viewdata)

    }

    if (loadersubmit) {
        return (
            <Loading />
        )
    }


    return (
        <div className="container-fluid">
            <div className="row">
                {toggle && (
                    <div
                        className="col-12 col-md-3 vh-100 position-fixed"
                        style={{ zIndex: 1000 }}
                    >
                        <Sidebar Toggle={Toggle} />
                    </div>
                )}

                {toggle && <div className="col-12 col-lg-3"></div>}
                <div className="col">
                    <Adminnav Toggle={Toggle} />
                    <div>
                        <Modal
                            size="lg"
                            show={lgShow}
                            onHide={() => setLgShow(false)}
                            aria-labelledby="example-modal-sizes-title-lg"
                        >
                            <Modal.Header closeButton>
                                <Modal.Title id="example-modal-sizes-title-lg">
                                    View all details
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body style={{ height: "550px", overflow: "hidden", overflowY: "scroll", padding: "10px" }}>
                                <div className='container-fluid'>
                                    <div className='row mt-2'>
                                        <div className='col-lg-6'>
                                            <select class="form-select" aria-label="Default select example"
                                                onChange={(e) => setTabno(e.target.value)}
                                            >
                                                {/* <option selected>Open this select menu</option> */}
                                                <option value="0">Personal info</option>
                                                <option value="1">Agent account info</option>
                                                <option value="2">Business address</option>
                                                <option value="3">Organization info</option>
                                                <option value="4">Account info</option>
                                                <option value="5">Deal which institute</option>
                                                <option value="6">Documents and attachments</option>

                                            </select>
                                        </div>
                                        {/* <div className='col'>
                                            <button className='btn btn-primary' onClick={() => setTabno(0)} style={{ backgroundColor: "#296013", color: "white", border: "none" }}>Personal info</button>
                                        </div>
                                        <div className='col'>
                                            <button className='btn btn-primary' onClick={() => setTabno(1)} style={{ backgroundColor: "#296013", color: "white", border: "none" }}>Agent account info</button>
                                        </div>
                                        <div className='col'>
                                            <button className='btn btn-primary' onClick={() => setTabno(2)} style={{ backgroundColor: "#296013", color: "white", border: "none" }}>Business address</button>
                                        </div>
                                        <div className='col'>
                                            <button className='btn btn-primary' onClick={() => setTabno(3)} style={{ backgroundColor: "#296013", color: "white", border: "none" }}>Organization info</button>
                                        </div> */}

                                    </div>
                                    {/* <div className='row mt-3'>
                                        <div className='col'>
                                            <button className='btn btn-primary' onClick={() => setTabno(4)} style={{ backgroundColor: "#296013", color: "white", border: "none" }}>Account info</button>
                                        </div>
                                        <div className='col'>
                                            <button className='btn btn-primary' onClick={() => setTabno(5)} style={{ backgroundColor: "#296013", color: "white", border: "none" }}>Deal which institute</button>
                                        </div>
                                        <div className='col'>
                                            <button className='btn btn-primary' onClick={() => setTabno(6)} style={{ backgroundColor: "#296013", color: "white", border: "none" }}>Documents and attachments</button>
                                        </div>
                                    </div> */}

                                    {
                                        Viewdata ? <div className='row mt-4'>
                                            {Tabno === "0" ? (
                                                <div className='col'>
                                                    <table class="table table-striped">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">Sr.</th>
                                                                <th scope="col">First name</th>
                                                                <th scope="col">Last name</th>
                                                                <th scope="col">Gender</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <th scope="row">1</th>
                                                                <td>{Viewdata.personalInfo?.firstName ? Viewdata.personalInfo.firstName : "N/A"}</td>
                                                                <td>{Viewdata.personalInfo?.lastName ? Viewdata.personalInfo.lastName : "N/A"}</td>
                                                                <td>{Viewdata.personalInfo?.gender ? Viewdata.personalInfo.gender : "N/A"}</td>
                                                            </tr>

                                                        </tbody>
                                                    </table>
                                                </div>
                                            ) : Tabno === "1" ? (
                                                <div className='col'>
                                                    <table class="table table-striped">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">Sr.</th>
                                                                <th scope="col">Email</th>
                                                                <th scope="col">Phone</th>
                                                                <th scope="col">Promo code</th>
                                                                <th scope="col">Password</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <th scope="row">1</th>
                                                                <td>{Viewdata.userAccountInfo?.email ? Viewdata.userAccountInfo.email : "N/A"}</td>
                                                                <td>{Viewdata.userAccountInfo?.phone ? Viewdata.userAccountInfo.phone : "N/A"}</td>
                                                                <td>{Viewdata.userAccountInfo?.promocode ? Viewdata.userAccountInfo.promocode : "N/A"}</td>
                                                                <td>{Viewdata.userAccountInfo?.password ? Viewdata.userAccountInfo.password : "N/A"}</td>
                                                            </tr>

                                                        </tbody>
                                                    </table>
                                                </div>
                                            ) : Tabno === "2" ? (
                                                <div className='col'>
                                                    <table class="table table-striped">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">Sr.</th>
                                                                <th scope="col">Country</th>
                                                                <th scope="col">City</th>
                                                                <th scope="col">Postal code</th>
                                                                <th scope="col">Province</th>
                                                                <th scope="col">Street</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <th scope="row">1</th>
                                                                <td>{Viewdata.businessAddress?.country ? Viewdata.businessAddress.country : "N/A"}</td>
                                                                <td>{Viewdata.businessAddress?.city ? Viewdata.businessAddress.city : "N/A"}</td>
                                                                <td>{Viewdata.businessAddress?.postCode ? Viewdata.businessAddress.postCode : "N/A"}</td>
                                                                <td>{Viewdata.businessAddress?.province ? Viewdata.businessAddress.province : "N/A"}</td>
                                                                <td>{Viewdata.businessAddress?.street ? Viewdata.businessAddress.street : "N/A"}</td>
                                                            </tr>

                                                        </tbody>
                                                    </table>
                                                </div>
                                            ) : Tabno === "3" ? (
                                                <div className='col'>
                                                    <table class="table table-striped">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">Sr.</th>
                                                                <th scope="col">Name</th>
                                                                <th scope="col">Registration number</th>
                                                                <th scope="col">Type</th>
                                                                <th scope="col">Other offices</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <th scope="row">1</th>
                                                                <td>{Viewdata.organizationInfo?.organizationName ? Viewdata.organizationInfo.organizationName : "N/A"}</td>
                                                                <td>{Viewdata.organizationInfo?.organizationRegistrationNumber ? Viewdata.organizationInfo.organizationRegistrationNumber : "N/A"}</td>
                                                                <td>{Viewdata.organizationInfo?.organizationType ? Viewdata.organizationInfo.organizationType : "N/A"}</td>
                                                                <td>{Viewdata.organizationInfo?.isOtherOffices ? Viewdata.organizationInfo.isOtherOffices : "N/A"}</td>
                                                                {/* <td>{Viewdata.organizationInfo?.street ? Viewdata.organizationInfo.street : "N/A"}</td> */}
                                                            </tr>

                                                        </tbody>
                                                    </table>
                                                    <h2 style={{ fontSize: "25px" }}>Which cities</h2>
                                                    <table class="table table-striped">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">Sr.</th>
                                                                <th scope="col">City</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                Viewdata.organizationInfo?.inWichCities.map((item, index) => {
                                                                    return <>
                                                                        <tr>
                                                                            <th scope="row">{index + 1}</th>
                                                                            <td> {item} </td>
                                                                        </tr>
                                                                    </>

                                                                })
                                                            }

                                                        </tbody>
                                                    </table>

                                                    <h2 style={{ fontSize: "25px" }}>Services</h2>
                                                    <table class="table table-striped">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">Sr.</th>
                                                                <th scope="col">Services</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                Viewdata.organizationInfo?.services.map((item, index) => {
                                                                    return <>
                                                                        <tr>
                                                                            <th scope="row">{index + 1}</th>
                                                                            <td> {item} </td>
                                                                        </tr>
                                                                    </>

                                                                })
                                                            }

                                                        </tbody>
                                                    </table>
                                                </div>
                                            ) : Tabno === "4" ? (
                                                <div className='col'>
                                                    <table class="table table-striped">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">Sr.</th>
                                                                <th scope="col">Bank name</th>
                                                                <th scope="col">Account title</th>
                                                                <th scope="col">Account number</th>
                                                                <th scope="col">IBAN</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <th scope="row">1</th>
                                                                <td>{Viewdata.accountInfo?.bankName ? Viewdata.accountInfo.bankName : "N/A"}</td>
                                                                <td>{Viewdata.accountInfo?.accountTitle ? Viewdata.accountInfo.accountTitle : "N/A"}</td>
                                                                <td>{Viewdata.accountInfo?.accountNumber ? Viewdata.accountInfo.accountNumber : "N/A"}</td>
                                                                <td>{Viewdata.accountInfo?.IBAN ? Viewdata.accountInfo.IBAN : "N/A"}</td>
                                                            </tr>

                                                        </tbody>
                                                    </table>
                                                </div>
                                            ) : Tabno === "5" ? (
                                                <div className='col'>
                                                    <table class="table table-striped">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">Sr.</th>
                                                                <th scope="col">Deal in which countrie</th>

                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                Viewdata.dealWithWhichInstitute?.map((item, index) => {
                                                                    return <>
                                                                        <tr key={index}>
                                                                            <th scope="row">{index + 1}</th>
                                                                            <td className=''>{item.dealInWhichCountrie ? item.dealInWhichCountrie : "N/A"}
                                                                                <table class="table table-striped">
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th scope="col">Sr.</th>
                                                                                            <th scope="col">Deal in which universities</th>

                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {
                                                                                            item.dealInWhichUniversities.map((item, index) => {
                                                                                                return <>
                                                                                                    <tr key={index}>
                                                                                                        <th scope="row">{index + 1}</th>
                                                                                                        <td>{item ? item : "N/A"}

                                                                                                        </td>
                                                                                                    </tr>
                                                                                                </>

                                                                                            })
                                                                                        }

                                                                                    </tbody>
                                                                                </table>
                                                                                <table class="table table-striped">
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th scope="col">Sr.</th>
                                                                                            <th scope="col">Level Of program</th>

                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {
                                                                                            item.levelOfProgram.map((item, index) => {
                                                                                                return <>
                                                                                                    <tr key={index}>
                                                                                                        <th scope="row">{index + 1}</th>
                                                                                                        <td>{item ? item : "N/A"}

                                                                                                        </td>
                                                                                                    </tr>
                                                                                                </>

                                                                                            })
                                                                                        }

                                                                                    </tbody>
                                                                                </table>

                                                                            </td>
                                                                        </tr>
                                                                    </>

                                                                })
                                                            }

                                                        </tbody>
                                                    </table>



                                                </div>
                                            ) : Tabno === "6" ? (
                                                <div className='col'>
                                                    <h2 style={{ fontSize: "25px" }} className='fw-bold'>Business licenses</h2>

                                                    {/* <img src={`https://globallearnandearnapi.doctorsforhealth.co.uk/` + Viewdata.documentsAndAttachments?.businessLicenses?Viewdata.documentsAndAttachments.businessLicenses : ""} alt='' className='img-fluid'></img> */}
                                                </div>
                                            ) : ""

                                            }


                                        </div> : ""
                                    }
                                </div>

                            </Modal.Body>
                        </Modal>
                    </div>

                    <div className='container-fluid agent-main'>
                        <div className='row mt-3 text-start'>
                            <h2>Agents {">"} All agents</h2>
                        </div>


                        <div>
                            <div className='row text-start mt-4 acc-form'>
                                <div className='col-lg-4 col-12'>
                                    <div className="mb-3">
                                        <label for="exampleInputEmail1" className="form-label">Email</label>
                                        <select className="form-select"
                                            value={email}
                                            onChange={(e) => setemail(e.target.value)}
                                            aria-label="Default select example">
                                            <option selected>Select email</option>
                                            {filteremail ?
                                                filteremail.map((item, index) => {
                                                    return <option value={item.userAccountInfo?.email ? item.userAccountInfo.email : "N/A"} key={index}>{item.userAccountInfo?.email ? item.userAccountInfo.email : "N/A"}</option>

                                                }) : ""
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className='col-lg-4 col-12'>
                                    <div className="mb-3">
                                        <label for="exampleInputEmail1" className="form-label">Country</label>
                                        <Select

                                            onChange={handlecityfilter}
                                            options={countryname}
                                            isSearchable
                                        />
                                    </div>
                                </div>
                                <div className='col-lg-4 col-12'>
                                    <div className="mb-3">
                                        <label for="exampleInputEmail1" className="form-label">City</label>
                                        <Select
                                            onChange={setcityname}
                                            options={filtercity}
                                            isSearchable
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className='row mt-3 btn-row'>
                            <div className='col-lg-1 col-6 d-flex align-items-start justify-content-start'>
                                <button className='btn btn-primary' onClick={agentfilter}>Filter</button>
                            </div>
                            <div className='col-lg-1 col-6 d-flex align-items-start justify-content-start'>
                                <button className='btn btn-primary' onClick={clearfilter}>Clear</button>
                            </div>
                            <div className='col-lg'></div>
                        </div>
                        <div className='row mt-3'>
                            <div className='col table11'>
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">Sr.</th>
                                            {/* <th scope="col">Role</th> */}
                                            <th scope="col">Name</th>
                                            <th scope="col">Email</th>
                                            <th scope="col">Phone</th>
                                            <th scope="col">Gender</th>
                                            <th scope="col">Business country</th>
                                            <th scope="col">Business city</th>
                                            <th scope="col">Organization name</th>
                                            <th scope="col">Organization #</th>
                                            <th scope="col">Organization type</th>
                                            <th scope="col">Password</th>
                                            <th scope="col">View details</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {allagentdata ?
                                            allagentdata.map((item, index) => {
                                                return <tr key={item._id}>
                                                    <th scope="row">{index + 1}</th>
                                                    <td>{item.personalInfo?.firstName ? item.personalInfo.firstName : "N/A"}</td>
                                                    <td>{item.userAccountInfo?.email ? item.userAccountInfo.email : "N/A"}</td>
                                                    <td>{item.userAccountInfo?.phone ? item.userAccountInfo.phone : "N/A"}</td>
                                                    <td>{item.personalInfo?.gender ? item.personalInfo.gender : "N/A"}</td>
                                                    <td>{item.businessAddress?.country ? item.businessAddress.country : "N/A"}</td>
                                                    <td>{item.businessAddress?.city ? item.businessAddress.city : "N/A"}</td>
                                                    <td>{item.organizationInfo?.organizationName ? item.organizationInfo.organizationName : "N/A"}</td>
                                                    <td>{item.organizationInfo?.organizationRegistrationNumber ? item.organizationInfo.organizationRegistrationNumber : "N/A"}</td>
                                                    <td>{item.organizationInfo?.organizationType ? item.organizationInfo.organizationType : "N/A"}</td>
                                                    <td>{item.userAccountInfo?.password ? item.userAccountInfo.password : "N/A"}</td>
                                                    <td><button className="btn btn-primary " style={{ backgroundColor: "#296013", border: "none", }}
                                                        onClick={() => Viewdetail(item)}
                                                    >
                                                        View
                                                    </button></td>
                                                    {/* <td onClick={() => handleapproval(item._id)}><button className="btn btn-primary" style={{ backgroundColor: "#296013", border: "none" }}><i className="fa-solid fa-check" style={{color: "white"}}></i></button></td> */}
                                                    {/* <td>
                                                <li className="nav-item dropdown" style={{ listStyle: "none" }}>
                                                    <button className="btn btn-primary dropdown-toggle" style={{ backgroundColor: "#296013", border: "none", height: "30px" }} role="button" data-bs-toggle="dropdown" aria-expanded="false">

                                                    </button>
                                                    <ul className="dropdown-menu">
                                                        <li style={{ cursor: "pointer" }} onClick={() => localdelete(item._id)}><td className="dropdown-item"><i class="fa-solid fa-trash me-2" style={{ color: "#B63A3A" }}></i> Delete</td></li>
                                                        <li style={{ cursor: "pointer" }}
                                                            onClick={() => handleShow(item._id, item.bankName, item.accountTitle, item.accountNumber, item.IBAN)}
                                                        ><td className="dropdown-item"><i className="fa-solid fa-pen-to-square me-2"
                                                            style={{ color: "#198BEC" }}></i> Update</td></li>
                                                    </ul>
                                                </li>
                                            </td> */}
                                                </tr>
                                            }) : ""

                                        }


                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    )
}

export default Allagent
