import React from 'react'
import './Content1.css'
import line from '../assiet/img/line.png'
import lettingagent from '../assiet/img/letting-agent.jpg'

const Content1 = () => {
    return (
        <div className='Content1-main my-4'>
            <div className='container'>
                <div className='row'>
                    <div className='col-12'>
                        <h2>Become a business associate</h2>
                    </div>
                    <div className='col-12'>
                        <img src={line} alt='' className='img-fluid'></img>
                    </div>
                </div>

                <div className='row mt-4'>
                    <div className='col-md-5'>
                        <img src={lettingagent} alt='' className='img-fluid img-1'></img>
                    </div>
                    <div className='col-md-7 mt-4 mt-lg-0'>
                        <div className='rignt-content text-md-start px-md-5'>
                            <div className='row'>
                                <h3>Unlock Success as a</h3>
                            </div>
                            <div className='row'>
                                <h2>Elevate Your Letting Agency's Potential in the UK: Partner with Global Learn & Earn!
                                </h2>
                            </div>
                            <div className='row mt-2'>
                                <p className='pe-md-5'>If you operate a letting agency in the UK, seize the opportunity to redefine success by becoming a service partner with Global Learn & Earn. Dive into a world of new business prospects within the property market. Register today to broaden your scope and refine your offerings. Explore our website and unlock the possibilities awaiting you with our partnership. Begin your journey towards amplified growth and service enhancement with us now!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Content1
