import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import './Myorder.css'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Navbar from '../../compunent/Navbar';
import Footer from '../../compunent/Footer'
import Loading from '../../Loading'

const Myorder = () => {
    const [toggle, setToggle] = useState(true);
    const token = localStorage.getItem('studenttoken')
    const [loadersubmit, setloadersubmit] = useState(false)
    const navigate = useNavigate();
    const Toggle = () => {
        setToggle(!toggle);
    };

    useEffect(() => {
        getorder()
    }, [])

    const [cartdata, setcartdata] = useState([]);
 

    const getorder = () => {
        setloadersubmit(true)
        axios.get("https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/cart/StudentCart", {
            headers: {
                Authorization: token,
            },
        })
            .then((res) => {
                console.log(res.data)
                if (res.data.message === "Services are found") {
                    setcartdata(res.data.data)
                    setloadersubmit(false)
                } else {
                    navigate("/")
                }

            }).catch((e) => {
                console.log(e)
                setloadersubmit(false)
            })
    }



    const formatDate = (mongoDBDate) => {
        const date = new Date(mongoDBDate);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${year}-${month}-${day}`;
    };

    const [cartId, setcartId] = useState("");
    const [accommodationType, setaccommodationType] = useState("");
    const [checkInDate, setcheckInDate] = useState();
    const [checkOutDate, setcheckOutDate] = useState();
    const [duration, setduration] = useState(0);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);

    const handleShow = (id, type, cin, cout, dura) => {
        setcartId(id)
        setaccommodationType(type)
        setcheckInDate(formatDate(cin))
        setcheckOutDate(formatDate(cout))
        setduration(dura)
        console.log(dura)
        setShow(true)
    };



    const booknow = () => {

        // if(accommodationType && checkInDate && checkOutDate && duration){
        axios.put(`https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/cart/UpdateCart?cartId=` + cartId, { accommodationType, checkInDate, checkOutDate, duration }, {
            headers: {
                Authorization: token,
            },
        })
            .then((res) => {
                console.log(res.data)

                if (res.data.message === "Successfully updated to cart") {
                    Swal.fire({
                        title: "Thank you",
                        text: "Successfully updated to cart",
                        icon: "success"
                    });
                    handleClose()
                    getorder()
                }

            }).catch((e) => {
                if (e.response.data.message === "You can't add it into cart due to Houseful") {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "House full",
                    });
                } else {
                    console.log(e)

                }
            })

        // }else{
        //     Swal.fire({
        //         icon: "error",
        //         title: "Oops...",
        //         text: "please fill input",
        //     });
        // }

    }

    const cartdelete = (cartId) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then((result) => {
            if (result.isConfirmed) {
                axios.delete(`https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/cart/DeleteCartItem?cartId=` + cartId, {
                    headers: {
                        Authorization: token,
                    },
                }).then((res) => {
                    console.log(res.data)
                    if (res.data.message === "Successfully deleted") {
                        Swal.fire({
                            title: "Deleted!",
                            text: "Successfully deleted'",
                            icon: "success"
                        });
                        getorder()
                    }
                }).catch((e) => {
                    console.log(e)
                })

            }
        });

    }

    const [lgShow, setLgShow] = useState(false);
    const [proof, setprocart] = useState();
    const [proofid, setproofid] = useState("");
    const [viewimg, setviewimg] = useState("");

    const handleimg = (data, proof) => {
        // console.log(data)
        setproofid(data)
        setviewimg(proof)
        setLgShow(true)
    }

    const handleproof = () => {
        const formdata = new FormData();
        formdata.append("proof", proof);
        axios.put(`https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/cart/UploadProof?cartId=` + proofid, formdata, {
            headers: {
                Authorization: token,
            },
        }).then((res) => {
            console.log(res.data)
            if (res.data.message === "Successfully uploaded to cart") {
                Swal.fire({
                    title: "Thank you",
                    text: "Accommodation successfully updated",
                    icon: "success"
                });
                getorder()
                setLgShow(false)
                // handleClose()
            }
        }).catch((e) => {
            console.log(e)
            if (e.response.data.message === "PostCode already exist or added in past") {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "This postal code already exist",
                });
            } else if (e.response.data.message === "NI already exist") {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "NI already exist",
                });
            } else {
                console.log(e)

            }
        })
    }

    const changehandleClose = () => setShow(false);
    const [messageshow, setmessageShow] = useState(false);

    const [message, setmessage] = useState("")

    if (loadersubmit) {
        return (
            <Loading />
        )
    }



    return (
        <div className='order-main'>
            <Navbar />
            <div className="container mb-4 orderbox">
                <div className="row">
                    {/* {toggle && (
                    <div
                        className="col-12 col-md-3 vh-100 position-fixed"
                        style={{ zIndex: 1000 }}
                    >
                        <Ssidebar Toggle={Toggle} />
                    </div>
                )}

                {toggle && <div className="col-12 col-lg-3"></div>} */}
                    <div className="col">
                        {/* <Studentnav Toggle={Toggle} /> */}

                        <Modal show={messageshow} onHide={changehandleClose}>
                            <Modal.Header closeButton>
                                <Modal.Title style={{ color: "#296013" }}>Message to student</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form>
                                    <div className='container-fluid'>
                                        <div className='row'>
                                        </div>
                                        <div className='row'>
                                            <div className='col-lg col-12'>
                                                <div class="mb-3">
                                                    <label for="exampleFormControlTextarea1" class="form-label fw-bold" style={{ color: "gray" }}>Message to student</label>
                                                    <textarea style={{ height: "300px" }} className="form-control"
                                                        value={message}
                                                        onChange={(e) => setmessage(e.target.value)}
                                                        id="exampleFormControlTextarea1" rows="3"></textarea>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </Form>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" className='fw-bold' style={{ backgroundColor: "#F1BB44", border: "none", color: "black" }} onClick={handleClose}>
                                    Close
                                </Button>
                                <Button variant="primary" style={{ backgroundColor: "#296013", border: "none" }}>
                                    Save
                                </Button>
                            </Modal.Footer>
                        </Modal>

                        <div>
                            <Modal
                                size="lg"
                                show={lgShow}
                                onHide={() => setLgShow(false)}
                                aria-labelledby="example-modal-sizes-title-lg"
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title id="example-modal-sizes-title-lg">
                                        Upload proof
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body style={{ height: "550px", overflow: "hidden", overflowY: "scroll", padding: "10px" }}>
                                    <div className='container-fluid'>
                                        <div className='row mt-3'>
                                            <div className='col'>
                                                <div className="mb-3">
                                                    {/* <label for="formFile" class="form-label">Select image</label> */}
                                                    <input className="form-control" type="file" id="formFile"
                                                        onChange={(e) => setprocart(e.target.files[0])}
                                                    />
                                                </div>
                                            </div>
                                            <div className='col'>
                                                <button className='btn btn-primary' onClick={handleproof} style={{ backgroundColor: "#296013", border: "none" }}>Upload</button>
                                            </div>
                                        </div>

                                        <div className='row mt-2'>
                                            <div className='col'>
                                                <img src={`https://globallearnandearnapi.doctorsforhealth.co.uk/` + viewimg} style={{ height: "400px" }} alt='' className='img-fluid'></img>
                                            </div>
                                        </div>
                                    </div>
                                </Modal.Body>
                            </Modal>
                        </div>


                        <div>
                            <Modal show={show} onHide={handleClose}>
                                <Modal.Header closeButton>
                                    <Modal.Title style={{ color: "#296013" }}>Update cart</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Form>
                                        <div className='container-fluid'>
                                            <div>
                                                <div className='row text-start acc-form'>
                                                    <div className='col-lg-12'>
                                                        <div className="mb-3">
                                                            <label for="exampleInputEmail1" className="form-label fw-bold" style={{ color: "gray" }}>Accommodation type</label>
                                                            <select className="form-select"
                                                                value={accommodationType}
                                                                onChange={(e) => setaccommodationType(e.target.value)}
                                                                aria-label="Default select example">
                                                                <option selected>Select type</option>
                                                                <option value="share">Shared</option>
                                                                <option value="independent">Independent</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className='col-12'>
                                                        <div className="mb-3">
                                                            <label for="people" className="form-label fw-bold" style={{ color: "gray" }}>Check in date</label>
                                                            <input type="date" className="form-control" id="people"
                                                                value={checkInDate} onChange={(e) => setcheckInDate(e.target.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='col-12'>
                                                        <div className="mb-3">
                                                            <label for="people" className="form-label fw-bold" style={{ color: "gray" }}>Check out date</label>
                                                            <input type="date" className="form-control" id="people"
                                                                value={checkOutDate} onChange={(e) => setcheckOutDate(e.target.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='row text-start acc-form'>
                                                    <div className='col-12'>
                                                        <div className="mb-3">
                                                            <label for="people" className="form-label fw-bold" style={{ color: "gray" }}>Duration (in months)</label>
                                                            <input type="number" className="form-control" id="people" placeholder='month'
                                                                value={duration} onChange={(e) => setduration(e.target.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Form>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" className='fw-bold' style={{ backgroundColor: "#F1BB44", border: "none", color: "black" }} onClick={handleClose}>
                                        Close
                                    </Button>
                                    <Button variant="primary" onClick={booknow} style={{ backgroundColor: "#296013", border: "none" }}>
                                        Save Changes
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </div>


                        <div className='container-fluid'>
                            <div className='row mt-2 text-start'>
                                <h2>My orders</h2>
                            </div>

                            <div className='row mt-2'>
                                <div className='col table11'>
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th scope="col">Sr.</th>
                                                {/* <th scope="col">Role</th> */}
                                                <th scope="col">Name</th>
                                                <th scope="col">Accommodation type</th>
                                                <th scope="col">Duration</th>
                                                <th scope="col">Room capacity</th>
                                                <th scope="col">Check in date</th>
                                                <th scope="col">Check out date</th>
                                                <th scope="col">Booking status</th>
                                                <th scope="col">Discount amount</th>
                                                <th scope="col">Total amount</th>
                                                <th scope="col">Upload proof</th>
                                                <th scope="col">Action</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {cartdata ?
                                                cartdata.map((item, index) => {
                                                    return <tr key={item._id}>
                                                        <th scope="row">{index + 1}</th>
                                                        <td>{item.name ? item.name : "N/A"}</td>
                                                        <td>{item.accommodationType ? item.accommodationType : "N/A"}</td>
                                                        <td>{item.duration ? item.duration : "N/A"}</td>
                                                        <td>{item.livingId.livingCapacity ? item.livingId.livingCapacity : "N/A"}</td>
                                                        <td>{formatDate(item.checkInDate ? item.checkInDate : "N/A")}</td>
                                                        <td>{formatDate(item.checkOutDate ? item.checkOutDate : "N/A")}</td>
                                                        <td>{item.bookingStatus ? item.bookingStatus : "N/A"}</td>
                                                        <td>{item.discountAmount ? item.discountAmount : "N/A"}</td>
                                                        <td>{item.totalAmountPrice ? item.totalAmountPrice : "N/A"}</td>
                                                        <td style={{ cursor: "pointer" }} >
                                                            <button className='btn btn-primary' onClick={() => handleimg(item._id, item.proof)} style={{ backgroundColor: "#296013", border: "none", fontSize: "1rem" }}>Click</button>
                                                        </td>

                                                        <td>
                                                            <li className="nav-item dropdown" style={{ listStyle: "none" }}>
                                                                <button className="btn btn-primary dropdown-toggle" style={{ backgroundColor: "#296013", border: "none", height: "30px" }} role="button" data-bs-toggle="dropdown" aria-expanded="false">

                                                                </button>
                                                                <ul className="dropdown-menu">
                                                                    {/* <li style={{ cursor: "pointer" }}
                                                                    // onClick={() => handleShow(item._id, item.notifyByAdmin)}
                                                                ><td className="dropdown-item"><i className="fa-solid fa-pen-to-square me-2"
                                                                    style={{ color: "#198BEC" }} onClick={handle}></i> Message</td></li> */}
                                                                    <li style={{ cursor: "pointer" }} onClick={() => cartdelete(item._id)}><td className="dropdown-item"><i class="fa-solid fa-trash me-2" style={{ color: "#B63A3A" }}></i> Delete</td></li>
                                                                    <li style={{ cursor: "pointer" }}
                                                                        onClick={() => handleShow(item._id, item.accommodationType, item.checkInDate, item.checkOutDate, item.duration)}
                                                                    ><td className="dropdown-item"><i className="fa-solid fa-pen-to-square me-2"
                                                                        style={{ color: "#198BEC" }}></i> Update</td></li>
                                                                </ul>
                                                            </li>
                                                        </td>
                                                    </tr>
                                                }) : ""

                                            }


                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
            <Footer/>
        </div>

    )
}

export default Myorder
