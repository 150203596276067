import React, { useEffect } from 'react'
import './Addroom.css'
import { useState } from 'react'
import Adminnav from '../admin nav/Adminnav'
import Sidebar from '../sidebar/Sidebar'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Loading from '../../Loading'

const Addroom = () => {

    const [toggle, setToggle] = useState(true);
    const token = localStorage.getItem('admintokenglobal')
    const [accommodation, setaccommodation] = useState([]);
    const [loadersubmit, setloadersubmit] = useState(false)
    const navigate = useNavigate();
    const Toggle = () => {
        setToggle(!toggle);
    };

    useEffect(() => {
        getrooms()
        getaccommodation()
    }, [])

    const [roonsdata, setroonsdata] = useState([])



    const getaccommodation = () => {
        axios.post("https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/accommodation/GetAccommodation", {}, {
            headers: {
                Authorization: token,
            },
        })
            .then((res) => {
                //  console.log("accomodation data",res.data)
                if (res.data.message === "Accommodation found") {
                    setaccommodation(res.data.data)
                } else {
                    navigate("/")
                }

            }).catch((e) => {
                console.log(e)
            })
    }

    const getrooms = () => {
        // setloadersubmit(true)
        axios.post("https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/livingdetails/GetLivingDetails", {}, {
            headers: {
                Authorization: token,
            },
        })
            .then((res) => {
                // console.log(res.data)
                if (res.data.message === "Living detail found") {
                    setroonsdata(res.data.data)
                    setloadersubmit(false)
                } else {
                    navigate("/")
                }

            }).catch((e) => {
                console.log(e)
            })
    }

    const roomdelete = (id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then((result) => {
            if (result.isConfirmed) {
                axios.delete(`https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/livingdetails/DeleteLivingDetails?id=` + id, {
                    headers: {
                        Authorization: token,
                    },
                }).then((res) => {
                    console.log(res.data)
                    if (res.data.message === "LivingDetails Deleted") {
                        Swal.fire({
                            title: "Deleted!",
                            text: "Room Deleted",
                            icon: "success"
                        });
                        // window.location.reload(false);
                        Filterid()
                    }
                }).catch((e) => {
                    console.log(e)
                })

            }
        });

    }

    // update rooms 
    const [ulivingCapacity, setulivingCapacity] = useState();
    const [ulivingIndShortStayPrice, setulivingIndShortStayPrice] = useState();
    const [ulivingIndLongStayPrice, setulivingIndLongStayPrice] = useState();
    const [ulivingShareShortStayPrice, setulivingShareShortStayPrice] = useState();
    const [ulivingShareLongStayPrice, setulivingShareLongStayPrice] = useState();
    const [ulivingImages, setulivingImages] = useState([]);
    const [uroomid, seturoomid] = useState("");
    const [uroomNumber, seturoomNumber] = useState();
    const handleClose = () => setShow(false);
    const [show, setShow] = useState(false);

    const handleShow = (id, n, e, p, c, ad, co,) => {
        setShow(true)
        seturoomNumber("")
        seturoomid(id)
        setulivingCapacity(n)
        setulivingIndShortStayPrice(e)
        setulivingIndLongStayPrice(p)
        setulivingShareShortStayPrice(c)
        setulivingShareLongStayPrice(ad)
    };

    const handleupdate = () => {
        setloadersubmit(true)
        const formdata = new FormData();
        formdata.append("livingCapacity", ulivingCapacity);
        formdata.append("livingIndShortStayPrice", ulivingIndShortStayPrice);
        formdata.append("livingIndLongStayPrice", ulivingIndLongStayPrice);
        formdata.append("livingShareShortStayPrice", ulivingShareShortStayPrice);
        formdata.append("livingShareLongStayPrice", ulivingShareLongStayPrice);
        formdata.append("roomNumber", uroomNumber);
        for (let i = 0; i < ulivingImages.length; i++) {
            formdata.append("livingImages", ulivingImages[i]);
        }
        axios.put(`https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/livingdetails/updateLivingDetails?id=` + uroomid, formdata, {
            headers: {
                Authorization: token,
            },
        }).then((res) => {
            if (res.data.message === "Living detail successfully updated") {
                Swal.fire({
                    title: "Thank you",
                    text: "Room successfully updated",
                    icon: "success"
                });
                getrooms()
                handleClose()
                setloadersubmit(false)

            } else if (res.data.message === "Room number already present in this accommodation ") {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Room number already present",
                });
                setloadersubmit(false)

            } else {
                console.log(res.data)
                setloadersubmit(false)

            }
        }).catch((e) => {
            console.log(e)
            if (e.response.data.message === "Address already exist or added in past") {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Already already exist",
                });
                setloadersubmit(false)

            } else if (e.response.data.message === "NI already exist") {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "NI already exist",
                });
                setloadersubmit(false)
            } else {
                console.log(e)

            }
        })

    }

    const [filterdata, setfilterdata] = useState()

    const Filterid = () => {
        const postCode = filterid;

        axios.post("https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/accommodation/GetAccommodation", { postCode }, {
            headers: {
                Authorization: token,
            },
        })
            .then((res) => {
                console.log(res.data.data)
                // if (res.data.message === "Accommodation found") {
                setfilterdata(res.data.data[0].livingDetails)
                // } else {
                //     navigate("/")
                // }

            }).catch((e) => {
                console.log(e)
            })
    }

    const clearfilter = () => {
        setfilterid("")
        getrooms()
        setfilterdata("")
    }


    // add rooms api and state
    const [livingCapacity, setlivingCapacity] = useState();
    const [livingIndShortStayPrice, setlivingIndShortStayPrice] = useState();
    const [livingIndLongStayPrice, setlivingIndLongStayPrice] = useState();
    const [livingShareShortStayPrice, setlivingShareShortStayPrice] = useState();
    const [livingShareLongStayPrice, setlivingShareLongStayPrice] = useState();
    const [livingImages, setlivingImages] = useState([]);
    const [accommodationid, setaccommodationid] = useState("");
    const [roomNumber, setroomNumber] = useState(0);
    const [filterid, setfilterid] = useState("");

    const postrooms = () => {
        console.log(livingCapacity, livingIndLongStayPrice, livingIndShortStayPrice, livingShareLongStayPrice, livingShareShortStayPrice, livingImages, accommodationid, roomNumber)
        if (livingCapacity && livingIndShortStayPrice && livingIndLongStayPrice && livingShareShortStayPrice && livingShareLongStayPrice && livingImages, roomNumber) {
            setloadersubmit(true)
            const formdata = new FormData();
            formdata.append("livingCapacity", livingCapacity);
            formdata.append("livingIndShortStayPrice", livingIndShortStayPrice);
            formdata.append("livingIndLongStayPrice", livingIndLongStayPrice);
            formdata.append("livingShareShortStayPrice", livingShareShortStayPrice);
            formdata.append("livingShareLongStayPrice", livingShareLongStayPrice);
            formdata.append("roomNumber", roomNumber);
            for (let i = 0; i < livingImages.length; i++) {
                formdata.append("livingImages", livingImages[i]);
            }
            console.log(formdata)
            axios.post(`https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/livingdetails/AddLivingDetails?id=` + accommodationid, formdata, {
                headers: {
                    Authorization: token,
                },
            }).then((res) => {
                console.log(res.data)
                if (res.data.message === "livingdetail successfully added") {
                    Swal.fire({
                        title: "Thank you",
                        text: "Room successfully added",
                        icon: "success"
                    });
                    setlivingCapacity("")
                    setlivingIndLongStayPrice("")
                    setaccommodationid("")
                    setlivingIndShortStayPrice("")
                    setlivingShareLongStayPrice("")
                    setlivingShareShortStayPrice("")
                    setlivingImages("")
                    setroomNumber("")
                    getrooms()
                setloadersubmit(false)
                } else if (res.data.message === "Room number already present in this accommodation ") {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "This room number already exist",
                    });
                setloadersubmit(false)
                }
            }).catch((e) => {
                console.log(e)
                if (e.response.data.message === "This address already added in past") {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "This address already exist",
                    });
                setloadersubmit(false)

                } else {
                    console.log(e)
                setloadersubmit(false)
                }
            })
        } else {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Please fill input",
            });
        }
    }

    const [viewImages, setviewImages] = useState([]);
    const [lgShow, setLgShow] = useState(false);
    const handleimg = (data) => {
        console.log(data)
        setviewImages(data)
        setLgShow(true)
    }

    if (loadersubmit) {
        return (
            <Loading />
        )
    }


    return (
        <div className="container-fluid">
            <div className="row">
                {toggle && (
                    <div
                        className="col-12 col-md-3 vh-100 position-fixed"
                        style={{ zIndex: 1000 }}
                    >
                        <Sidebar Toggle={Toggle} />
                    </div>
                )}

                {toggle && <div className="col-12 col-lg-3"></div>}
                <div className="col">
                    <Adminnav Toggle={Toggle} />

                    <div>
                        <Modal
                            size="lg"
                            show={lgShow}
                            onHide={() => setLgShow(false)}
                            aria-labelledby="example-modal-sizes-title-lg"
                        >
                            <Modal.Header closeButton>
                                <Modal.Title id="example-modal-sizes-title-lg">
                                    Room images
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body style={{ height: "550px", overflow: "hidden", overflowY: "scroll", padding: "10px" }}>
                                <div className='container-fluid'>
                                    {
                                        viewImages.map((item, index) => {
                                            return <div className='row mt-3'>
                                                <div className='col'>
                                                    <img src={`https://globallearnandearnapi.doctorsforhealth.co.uk/` + item} alt='img' className='img-fluid'></img>

                                                </div>
                                            </div>
                                        })
                                    }

                                </div>
                            </Modal.Body>
                        </Modal>
                    </div>

                    <div>
                        <Modal show={show} onHide={handleClose}>
                            <Modal.Header closeButton>
                                <Modal.Title style={{ color: "#296013" }}>Update room</Modal.Title>
                            </Modal.Header>
                            <Modal.Body style={{ height: "500px", overflow: "hidden", overflowY: "scroll", padding: "10px" }}>
                                <Form>
                                    <div className='container-fluid'>
                                        <div className='row'>
                                            <div className='col-lg col-12'>
                                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                    <Form.Label className='fw-bold' style={{ color: "gray" }}>Room identification #</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        autoFocus
                                                        value={uroomNumber}
                                                        onChange={(e) => seturoomNumber(e.target.value)}
                                                    />
                                                </Form.Group>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-lg col-12'>
                                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                    <Form.Label className='fw-bold' style={{ color: "gray" }}>Room capacity</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        autoFocus
                                                        value={ulivingCapacity}
                                                        onChange={(e) => setulivingCapacity(e.target.value)}
                                                    />
                                                </Form.Group>
                                            </div>
                                        </div>

                                        <div className='row'>
                                            <div className='col-lg col-12'>
                                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                    <Form.Label className='fw-bold' style={{ color: "gray" }}>Independent short stay price </Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        autoFocus
                                                        value={ulivingIndShortStayPrice}
                                                        onChange={(e) => setulivingIndShortStayPrice(e.target.value)}
                                                    />
                                                </Form.Group>
                                            </div>
                                        </div>

                                        <div className='row'>
                                            <div className='col-lg col-12'>
                                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                    <Form.Label className='fw-bold' style={{ color: "gray" }}>Independent long stay price  </Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        autoFocus
                                                        value={ulivingIndLongStayPrice}
                                                        onChange={(e) => setulivingIndLongStayPrice(e.target.value)}
                                                    />
                                                </Form.Group>
                                            </div>
                                        </div>

                                        <div className='row'>
                                            <div className='col-lg col-12'>
                                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                    <Form.Label className='fw-bold' style={{ color: "gray" }}>Shared short stay price</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        autoFocus
                                                        value={ulivingShareShortStayPrice}
                                                        onChange={(e) => setulivingShareShortStayPrice(e.target.value)}
                                                    />
                                                </Form.Group>
                                            </div>
                                        </div>

                                        <div className='row'>
                                            <div className='col-lg col-12'>
                                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                    <Form.Label className='fw-bold' style={{ color: "gray" }}>Shared long stay price</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        autoFocus
                                                        value={ulivingShareLongStayPrice}
                                                        onChange={(e) => setulivingShareLongStayPrice(e.target.value)}
                                                    />
                                                </Form.Group>
                                            </div>


                                        </div>

                                        <div className='row'>
                                            <div className='col'>
                                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                    <Form.Label className='fw-bold' style={{ color: "gray" }}>Select image </Form.Label>
                                                    <input multiple type="file" className="form-control" id="exampleInputEmail1"
                                                        onChange={(e) => setulivingImages(e.target.files)}
                                                        aria-describedby="emailHelp" />
                                                </Form.Group>
                                            </div>

                                        </div>
                                    </div>
                                </Form>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" className='fw-bold' style={{ backgroundColor: "#F1BB44", border: "none", color: "black" }} onClick={handleClose}>
                                    Close
                                </Button>
                                <Button variant="primary" onClick={handleupdate} style={{ backgroundColor: "#296013", border: "none" }}>
                                    Save Changes
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </div>

                    <div className='container-fluid addroom-main pb-4'>
                        <div className='row mt-3 text-start'>
                            <h2>Add rooms</h2>
                        </div>

                        <div className='row text-start mt-4 local-form'>
                            <div className='col-lg-4 col-12'>
                                <div className="mb-3">
                                    <label for="exampleInputEmail1" className="form-label">Select accommodation<i className="fa-solid fa-star-of-life " style={{ fontSize: "9px", color: "red" }}></i></label>
                                    <select className="form-select"
                                        value={accommodationid}
                                        onChange={(e) => setaccommodationid(e.target.value)}
                                        aria-label="Default select example">
                                        <option selected>Select accommodation</option>
                                        {
                                            accommodation.map((item) => {
                                                return <option value={item._id} key={item._id}> {item.accommodationCity}  ({item.postCode})</option>
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className='col-lg-4 col-12'>
                                <div className="mb-3">
                                    <label for="exampleInputEmail1" className="form-label">Room identification #<i className="fa-solid fa-star-of-life " style={{ fontSize: "9px", color: "red" }}></i></label>
                                    <input type="number" className="form-control" id="exampleInputEmail1"
                                        // value={roomNumber}
                                        onChange={(e) => setroomNumber(e.target.value)}
                                        aria-describedby="emailHelp" />
                                </div>
                            </div>
                            <div className='col-lg-4 col-12'>
                                <div className="mb-3">
                                    <label for="exampleInputEmail1" className="form-label">Room capacity <i className="fa-solid fa-star-of-life " style={{ fontSize: "9px", color: "red" }}></i></label>
                                    <input type="number" className="form-control" id="exampleInputEmail1"
                                        value={livingCapacity}
                                        onChange={(e) => setlivingCapacity(e.target.value)}
                                        aria-describedby="emailHelp" />
                                </div>
                            </div>

                        </div>


                        <div className='row text-start mt-1 local-form'>
                            <div className='col-lg-4 col-12'>
                                <div className="mb-3">
                                    <label for="exampleInputEmail1" className="form-label">Independent short stay price <i className="fa-solid fa-star-of-life " style={{ fontSize: "9px", color: "red" }}></i></label>
                                    <input type="number" className="form-control" id="exampleInputEmail1"
                                        value={livingIndShortStayPrice}
                                        onChange={(e) => setlivingIndShortStayPrice(e.target.value)}
                                        aria-describedby="emailHelp" />
                                </div>
                            </div>
                            <div className='col-lg-4 col-12'>
                                <div className="mb-3">
                                    <label for="exampleInputEmail1" className="form-label">Independent long stay price <i className="fa-solid fa-star-of-life " style={{ fontSize: "9px", color: "red" }}></i></label>
                                    <input type="number" className="form-control" id="exampleInputEmail1"
                                        value={livingIndLongStayPrice}
                                        onChange={(e) => setlivingIndLongStayPrice(e.target.value)}
                                        aria-describedby="emailHelp" />
                                </div>
                            </div>
                            <div className='col-lg-4 col-12'>
                                <div className="mb-3">
                                    <label for="exampleInputEmail1" className="form-label">Shared short stay price<i className="fa-solid fa-star-of-life " style={{ fontSize: "9px", color: "red" }}></i></label>
                                    <input type="number" className="form-control" id="exampleInputEmail1"
                                        value={livingShareShortStayPrice}
                                        onChange={(e) => setlivingShareShortStayPrice(e.target.value)}
                                        aria-describedby="emailHelp" />
                                </div>
                            </div>


                            <div className='row text-start acc-form'>
                                <div className='col-lg-4 col-12'>
                                    <div className="mb-3">
                                        <label for="exampleInputEmail1" className="form-label">Shared long stay price <i className="fa-solid fa-star-of-life " style={{ fontSize: "9px", color: "red" }}></i></label>
                                        <input type="number" className="form-control" id="exampleInputEmail1"
                                            value={livingShareLongStayPrice}
                                            onChange={(e) => setlivingShareLongStayPrice(e.target.value)}
                                            aria-describedby="emailHelp" />
                                    </div>
                                </div>
                                <div className='col-lg-4 col-12'>
                                    <div className="mb-3">
                                        <label for="exampleInputEmail1" className="form-label">Select image <i className="fa-solid fa-star-of-life " style={{ fontSize: "9px", color: "red" }}></i></label>
                                        <input multiple type="file" className="form-control" id="exampleInputEmail1"
                                            // value={livingImages}
                                            onChange={(e) => setlivingImages(e.target.files)}
                                            aria-describedby="emailHelp" />
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className='row mt-3 btn-row'>
                            <div className='col d-flex align-items-start justify-content-start'>
                                <button className='btn btn-primary' onClick={postrooms}>Submit</button>
                            </div>
                        </div>


                        <div className='row mt-lg-0 mt-3 btn-row'>
                            <div className='col-lg-7'></div>
                            <div className='col-lg-3 col-6'>
                                <select className="form-select"
                                    value={filterid}
                                    onChange={(e) => setfilterid(e.target.value)}
                                    aria-label="Default select example">
                                    <option selected>Select accommodation</option>
                                    {
                                        accommodation.map((item) => {
                                            return <option value={item.postCode} key={item._id}> {item.accommodationCity}  ({item.postCode})</option>
                                        })
                                    }
                                </select>

                            </div>
                            <div className='col-lg-1 col-3 d-flex justify-content-end align-items-end' style={{ cursor: "pointer" }}>
                                <button className='btn btn-primary' onClick={Filterid}>Filter</button>
                            </div>
                            <div className='col-lg-1 col-3 d-flex justify-content-end align-items-end'>
                                <button className='btn btn-primary' onClick={clearfilter}>Clear</button>
                            </div>
                        </div>

                        <div className='row mt-3'>
                            <div className='col table11'>
                                {filterdata ? (
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th scope="col">Sr.</th>
                                                <th scope="col">Room id #</th>
                                                <th scope="col">Room capacity</th>
                                                <th scope="col">Room availability</th>
                                                <th scope="col">Independent short stay (£) </th>
                                                <th scope="col">Independent long stay (£) </th>
                                                <th scope="col">Shared short stay (£)</th>
                                                <th scope="col">Shared long stay (£) </th>
                                                <th scope="col">View images</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {filterdata.map((item, index) => {

                                                return <tr key={item._id}>
                                                    <th scope="row">{index + 1}</th>
                                                    <td>{item.roomNumber}</td>
                                                    <td>{item.livingCapacity}</td>
                                                    <td>{item.livingAvailability}</td>
                                                    <td>{item.livingIndShortStayPrice}</td>
                                                    <td>{item.livingIndLongStayPrice}</td>
                                                    <td>{item.livingShareShortStayPrice}</td>
                                                    <td>{item.livingShareLongStayPrice}</td>
                                                    <td style={{ cursor: "pointer" }} onClick={() => handleimg(item.livingImages)}><i className="fa-solid fa-eye"></i></td>

                                                    <td>
                                                        <li className="nav-item dropdown" style={{ listStyle: "none" }}>
                                                            <button className="btn btn-primary dropdown-toggle" style={{ backgroundColor: "#296013", border: "none", height: "30px" }} role="button" data-bs-toggle="dropdown" aria-expanded="false">

                                                            </button>
                                                            <ul className="dropdown-menu">
                                                                <li style={{ cursor: "pointer" }} onClick={() => roomdelete(item._id)}><td className="dropdown-item"><i class="fa-solid fa-trash me-2" style={{ color: "#B63A3A" }}></i> Delete</td></li>
                                                                <li style={{ cursor: "pointer" }}
                                                                    onClick={() => handleShow(item._id, item.livingCapacity, item.livingIndShortStayPrice, item.livingIndLongStayPrice, item.livingShareShortStayPrice, item.livingShareLongStayPrice)}
                                                                ><td className="dropdown-item"><i className="fa-solid fa-pen-to-square me-2"
                                                                    style={{ color: "#198BEC" }}></i> Update</td></li>
                                                            </ul>
                                                        </li>
                                                    </td>
                                                </tr>

                                            })}
                                        </tbody>
                                    </table>
                                ) : (
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th scope="col">Sr.</th>
                                                <th scope="col">City</th>
                                                <th scope="col">Postal code</th>
                                                <th scope="col">Room id #</th>
                                                <th scope="col">Room capacity</th>
                                                <th scope="col">Room availability</th>
                                                <th scope="col">Independent short stay (£) </th>
                                                <th scope="col">Independent long stay (£) </th>
                                                <th scope="col">Shared short stay (£)</th>
                                                <th scope="col">Shared long stay (£) </th>
                                                <th scope="col">View images</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {roonsdata.map((item, index) => {

                                                return <tr key={item._id}>
                                                    <th scope="row">{index + 1}</th>
                                                    <td>{item.accommodationId.accommodationCity}</td>
                                                    <td>{item.accommodationId.postCode}</td>
                                                    <td>{item.roomNumber}</td>
                                                    <td>{item.livingCapacity}</td>
                                                    <td>{item.livingAvailability}</td>
                                                    <td>{item.livingIndShortStayPrice}</td>
                                                    <td>{item.livingIndLongStayPrice}</td>
                                                    <td>{item.livingShareShortStayPrice}</td>
                                                    <td>{item.livingShareLongStayPrice}</td>
                                                    <td style={{ cursor: "pointer" }} onClick={() => handleimg(item.livingImages)}><i className="fa-solid fa-eye"></i></td>

                                                    <td>
                                                        <li className="nav-item dropdown" style={{ listStyle: "none" }}>
                                                            <button className="btn btn-primary dropdown-toggle" style={{ backgroundColor: "#296013", border: "none", height: "30px" }} role="button" data-bs-toggle="dropdown" aria-expanded="false">

                                                            </button>
                                                            <ul className="dropdown-menu">
                                                                <li style={{ cursor: "pointer" }} onClick={() => roomdelete(item._id)}><td className="dropdown-item"><i class="fa-solid fa-trash me-2" style={{ color: "#B63A3A" }}></i> Delete</td></li>
                                                                <li style={{ cursor: "pointer" }}
                                                                    onClick={() => handleShow(item._id, item.livingCapacity, item.livingIndShortStayPrice, item.livingIndLongStayPrice, item.livingShareShortStayPrice, item.livingShareLongStayPrice)}
                                                                ><td className="dropdown-item"><i className="fa-solid fa-pen-to-square me-2"
                                                                    style={{ color: "#198BEC" }}></i> Update</td></li>
                                                            </ul>
                                                        </li>
                                                    </td>
                                                </tr>

                                            })}
                                        </tbody>
                                    </table>

                                )

                                }
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Addroom
