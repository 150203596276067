import { useNavigate } from "react-router-dom";
import "./Quest.css";

function Quest() {

    const navigate = useNavigate();

  return (
    <div className="main-quest">
      <div className="container">
        <div className="row">
          <div className="col-md-7">
            <div className="quest-content text-start pe-md-5">
              <div className="row">
                <div className="text-line1">
                  <h4>COMMON FAQS</h4>
                </div>
              </div>

              <div className="row pt-2">
                <h2>Frequently Asked Questions?</h2>
              </div>

              <div className="row">
                <div className="quiz-drop pt-4">
                  <div className="accordion" id="accordionExample">
                    <div className="accordion-item">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                        >
                          What is a Direct Immigration Services?
                        </button>
                      </h2>
                      <div
                        id="collapseOne"
                        className="accordion-collapse collapse show"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          There are many variations of passages the majority
                          have suffered alteration in some fo injected humour,
                          or randomised words believable.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                        >
                          What are the Benefits of Visa Consultancy?
                        </button>
                      </h2>
                      <div
                        id="collapseTwo"
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          There are many variations of passages the majority
                          have suffered alteration in some fo injected humour,
                          or randomised words believable.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                        >
                          What We should do If our Visa get Rejection?
                        </button>
                      </h2>
                      <div
                        id="collapseThree"
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          There are many variations of passages the majority
                          have suffered alteration in some fo injected humour,
                          or randomised words believable.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapsefour"
                          aria-expanded="false"
                          aria-controls="collapsefour"
                        >
                          Which Documents are Required to Apply Visa Online?
                        </button>
                      </h2>
                      <div
                        id="collapsefour"
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          There are many variations of passages the majority
                          have suffered alteration in some fo injected humour,
                          or randomised words believable.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col pt-3 pt-md-0">
            <div className="quest-img d-flex justify-content-center align-items-center">
              <div className="container text-center">
                <div className="row">
                  <h3>Global Learn & Earn</h3>
                </div>
                <div className="row px-md-5">
                  <h2 className="guest-h2">
                  Is the most trusted accommodation service provider in UK
                  </h2>
                </div>
                <div className="row pt-3 d-flex justify-content-center align-items-center">
                  <button className="guest-btn" onClick={() =>navigate("/locations")}>Discover More</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Quest;
