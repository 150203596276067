import React, { useEffect, useState } from 'react'
import Ssidebar from '../Sidebar/Ssidebar';
import Studentnav from '../Sidebar/Studentnav';
import Swal from 'sweetalert2';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'
import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css'
import './Studentexperience.css'
import Navbar from '../../compunent/Navbar';

const Studentexperience = () => {
    const [toggle, setToggle] = useState(true);
    const navigate = useNavigate();
    const Toggle = () => {
        setToggle(!toggle);
    };

    const token = localStorage.getItem('studenttoken')

    useEffect(() => {
        getstudents()

    }, [])


    const [isExperience,setisExperience] = useState("")
    const [jobType,setjobType] = useState("")
    const [jobExperienceStart,setjobExperienceStart] = useState("")
    const [jobExperienceEnd,setjobExperienceEnd] = useState("")
    const [selectIndustry,setselectIndustry] = useState("")
    const [allSkills,setallSkills] = useState([])


    const handlesave = () => {
 
        console.warn()

        const previousJobs = [
            {
                jobType: jobType,
                jobExperienceStart: jobExperienceStart,
                jobExperienceEnd: jobExperienceEnd,
            },
        ]
        if (allSkills && isExperience && selectIndustry) {
            axios.post("https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/student/StudentExperienceInfo", {  allSkills,selectIndustry,isExperience,previousJobs}, {
                headers: {
                    Authorization: token,
                },
            }).then((res) => {
                if (res.data.message === "experience information is added") {
                    Swal.fire({
                        title: "Thank you",
                        text: "Experience information is added",
                        icon: "success"
                    });

                    getstudents()
                } else {
                    console.log(res.data)

                }
            }).catch((e) => {
                console.log(e)
            })
        } else {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "please fill input",
            });
        }
    }

    const formatDate = (mongoDBDate) => {
        const date = new Date(mongoDBDate);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        console.warn(`${day}/${month}/${year}`)


        return `${year}-${month}-${day}`;
    };

    const getstudents = () => {
        axios.get("https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/student/StudentProfile", {
            headers: {
                Authorization: token,
            },
        })
            .then((res) => {
                console.log(res.data.data)
                if (res.data.message === "Student profile") {
                    // setstudentdata(res.data.data)
                    setisExperience(res.data.data[0].experience.isExperience)
                    setselectIndustry(res.data.data[0].experience.selectIndustry)
                    setallSkills(res.data.data[0].experience.allSkills)
                    setjobType(res.data.data[0].experience.previousJobs[0].jobType)
                    setjobExperienceStart(formatDate(res.data.data[0].experience.previousJobs[0].jobExperienceStart))
                    setjobExperienceEnd(formatDate(res.data.data[0].experience.previousJobs[0].jobExperienceEnd))
                    setallSkills(res.data.data[0].experience.allSkills)

                } else {
                    navigate("/")
                }

            }).catch((e) => {
                console.log(e)
            })

    }
    return (
       <div>
        <Navbar/>
         <div className="container-fluid">
            <div className="row">
                {toggle && (
                    <div
                        className="col-12 col-md-3 vh-100 position-fixed"
                        style={{ zIndex: 1000 }}
                    >
                        <Ssidebar Toggle={Toggle} />
                    </div>
                )}

                {toggle && <div className="col-12 col-lg-3"></div>}
                <div className="col">
                    {/* <Studentnav Toggle={Toggle} /> */}
                    <div className='container-fluid experience-main'>
                        <div className='row mt-3 text-start'>
                            <h2>{toggle?"":<i className="fa-solid fa-bars" onClick={Toggle} style={{cursor: "pointer"}}></i>} Student experience</h2>
                        </div>
                        <div>
                            <div className='row text-start mt-4 acc-form'>
                       
                                <div className='col-lg-4 col-12'>
                                    <div className="mb-3">
                                        <label for="exampleInputEmail1" className="form-label">Industry</label>
                                        <select className="form-select"
                                            value={selectIndustry} onChange={(e) => setselectIndustry(e.target.value)}
                                            aria-label="Default select example">
                                            <option selected>select industry</option>
                                            <option value="Information Technology">Information Technology</option>
                                            <option value="Market research">Market research</option>
                                            <option value="Machine">Machine</option>
                                            <option value="Telecommunications">Telecommunications</option>
                                        </select>
                                    </div>
                                </div>
                                <div className='col-lg-4 col-12'>
                                    <div className="mb-3">
                                        <label for="people" className="form-label">Skills</label>

                                        <TagsInput
                                            value={allSkills} onChange={setallSkills}
                                        />
                                    </div>
                                </div>
                                <div className='col-lg-4 col-12'>
                                    <div className="mb-3">
                                        <label for="exampleInputEmail1" className="form-label">Experience</label>
                                        <select className="form-select"
                                            value={isExperience} onChange={(e) => setisExperience(e.target.value)}
                                            aria-label="Default select example">
                                            <option selected>Select</option>
                                            <option value="yes">Yes</option>
                                            <option value="no">No</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            {
                                isExperience === "yes" ?<>


                                <div className='row mb-3'>
                                    <div className='col text-start'>
                                        <h2>Previous jobs</h2>
                                    </div>
                                </div>
                                    
                            <div className='row text-start acc-form'>
                            <div className='col-lg-4 col-12'>
                                    <div className="mb-3">
                                        <label for="people" className="form-label">Job type</label>
                                        <input type="text" className="form-control" id="people"
                                            value={jobType} onChange={(e) => setjobType(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className='col-lg-4 col-12'>
                                    <div className="mb-3">
                                        <label for="people" className="form-label">Job experience start</label>
                                        <input type="date" className="form-control" id="people"
                                            value={jobExperienceStart} onChange={(e) => setjobExperienceStart(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className='col-lg-4 col-12'>
                                    <div className="mb-3">
                                        <label for="people" className="form-label">Job experience end</label>
                                        <input type="date" className="form-control" id="people"
                                            value={jobExperienceEnd} onChange={(e) => setjobExperienceEnd(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>

                     
                                </>:""
                            }

                        </div>

                        <div className='row mt-3 btn-row'>
                            <div className='col d-flex align-items-start justify-content-start'>
                                <button className='btn btn-primary' onClick={handlesave}>Save</button>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </div>
       </div>
    )
}

export default Studentexperience
