import React, { useEffect, useState } from 'react'
import Adminnav from '../admin nav/Adminnav'
import Sidebar from '../sidebar/Sidebar'
import './Local.css'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { allCountries } from '../../result'
import Select from 'react-select';
import Loading from '../../Loading'


const Local = () => {
  const [toggle, setToggle] = useState(true);
  const token = localStorage.getItem('admintokenglobal')
  const [localdata, setlocaldata] = useState([])
  const navigate = useNavigate();
  const [ProgramTypes, setProgramTypes] = useState([])
  const [citydata, setcitydata] = useState([])
  const [loadersubmit, setloadersubmit] = useState(false)

  // locals post state
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [searchcity, setcity] = useState("");
  const [address, setaddress] = useState("");
  const [country, setcountry] = useState("");
  const [bankName, setbankName] = useState("");
  const [accountTitle, setaccountTitle] = useState("");
  const [accountNumber, setaccountNumber] = useState("");
  const [IBAN, setIBAN] = useState("");
  const [ni, setni] = useState("");
  const [phone, setphone] = useState("");


  const Toggle = () => {
    setToggle(!toggle);
  };

  useEffect(() => {
    getlocals();

    const typeArrange = allCountries[145].cities.map((row) => {
      return {
        value: `${row}`,
        label: `${row}`,
        name: `${row}`,
      };
    });
    setProgramTypes(typeArrange);

  }, [])

  const getlocals = () => {
    // setloadersubmit(true)
    axios.post("https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/manualuser/GetManualUser", {}, {
      headers: {
        Authorization: token,
      },
    })
      .then((res) => {
        // console.log(res.data)
        if (res.data.message === "manual user profiles") {
          setlocaldata(res.data.data)
          setcitydata(res.data.data)
          setloadersubmit(false)
        } else {
          navigate("/")
        }

      }).catch((e) => {
        console.log(e)
        setloadersubmit(false)
      })
  }

  const postlocals = () => {
    // console.warn(name,email,address,phone,ni,accountNumber,accountTitle,bankName,city,country,IBAN)
    const city = searchcity.value;
    if (name && email && address && phone && ni && accountNumber && accountTitle && bankName && city && country && IBAN) {
      setloadersubmit(true)
      axios.post("https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/manualuser/AddManualUserAccommodation", {
        name, email, address, phone, ni, accountNumber, accountTitle, bankName, city, country, IBAN
      }, {
        headers: {
          Authorization: token,
        },
      }).then((res) => {
        console.log(res.data)
        if (res.data.message === "Successfully user is added") {
          Swal.fire({
            title: "Thank you",
            text: "Successfully local is added",
            icon: "success"
          });
          getlocals()
          setname("")
          setemail("")
          setaddress("")
          setphone("")
          setni("")
          setaccountNumber("")
          setaccountTitle("")
          setbankName("")
          setcity("")
          setcountry("")
          setIBAN("")
          setloadersubmit(false)
        }
      }).catch((e) => {
        console.log(e)
        if (e.response.data.message === "User is already exist") {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Locals is already exist",
          });
          setloadersubmit(false)
        } else {
          console.log(e)
          setloadersubmit(false)
        }
      })

    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please fill input",
      });
    }
  }

  const localdelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then((result) => {
      if (result.isConfirmed) {
        axios.delete(`https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/manualuser/DeleteManualUser?id=` + id, {
          headers: {
            Authorization: token,
          },
        }).then((res) => {
          // console.log(res.data)
          if (res.data.message === "User deleted successfully") {
            Swal.fire({
              title: "Deleted!",
              text: "Your local has been deleted.",
              icon: "success"
            });
            getlocals()
          }
        }).catch((e) => {
          console.log(e)
        })

      }
    });

  }

  // update locals 
  const [uname, setuname] = useState("");
  const [uemail, setuemail] = useState("");
  const [ucity, setucity] = useState("");
  const [uaddress, setuaddress] = useState("");
  const [ucountry, setucountry] = useState("");
  const [ubankName, setubankName] = useState("");
  const [uaccountTitle, setuaccountTitle] = useState("");
  const [uaccountNumber, setuaccountNumber] = useState("");
  const [uIBAN, setuIBAN] = useState("");
  const [uni, setuni] = useState("");
  const [uphone, setuphone] = useState("");
  const [Uid, setUid] = useState("");
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);


  const handleShow = (id, n, e, p, c, ad, co, ba, at, an, ni, ib) => {
    // console.log(n, e)
    setUid(id)
    setuname(n)
    // setuemail(e)
    setuphone(p)
    setucity(c)
    setuaddress(ad)
    setucountry(co)
    setubankName(ba)
    setuaccountTitle(at)
    setuaccountNumber(an)
    // setuni(ni)
    setuIBAN(ib)
    setShow(true)
  };

  const handleupdate = () => {
    const name = uname;
    const email = uemail;
    const phone = uphone;
    const city = ucity.value;
    const address = uaddress;
    const country = ucountry;
    const bankName = ubankName;
    const accountNumber = uaccountNumber;
    const accountTitle = uaccountTitle;
    const ni = uni;
    const IBAN = uIBAN;
    setloadersubmit(true)
    axios.put(`https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/manualuser/UpdateManualUser?id=` + Uid, { name, email, phone, address, city, country, bankName, accountTitle, accountNumber, ni, IBAN }, {
      headers: {
        Authorization: token,
      },
    }).then((res) => {
      console.log(res.data)
      if (res.data.message === "User data updated successfully") {
        Swal.fire({
          title: "Thank you",
          text: "Local data updated successfully",
          icon: "success"
        });
        getlocals()
        handleClose()
        setloadersubmit(false)
      }
    }).catch((e) => {
      console.log(e)
      if (e.response.data.message === "Email already exist") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Email already exist",
        });
        setloadersubmit(false)
      } else if (e.response.data.message === "NI already exist") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "NI already exist",
        });
        setloadersubmit(false)
      } else {
        console.log(e)
        setloadersubmit(false)

      }
    })

  }


  const [filterid, setfilterid] = useState("");
  const [cityfilter, setcityfilter] = useState("");

  const Filterid = () => {
    const city = cityfilter.value;

    axios.post("https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/manualuser/GetManualUser", { city }, {
      headers: {
        Authorization: token,
      },
    })
      .then((res) => {
        console.log(res.data.data)
        // if (res.data.message === "Accommodation found") {
        setlocaldata(res.data.data)

        // } else {
        //     navigate("/")
        // }

      }).catch((e) => {
        console.log(e)
      })
  }

  const clearfilter = () => {
    getlocals()
    setfilterid("")

  }

  if (loadersubmit) {
    return (
      <Loading />
    )
  }

  return (
    <div className="container-fluid">
      <div className="row">
        {toggle && (
          <div
            className="col-12 col-md-3 vh-100 position-fixed"
            style={{ zIndex: 1000 }}
          >
            <Sidebar Toggle={Toggle} />
          </div>
        )}

        {toggle && <div className="col-12 col-lg-3"></div>}
        <div className="col">
          <Adminnav Toggle={Toggle} />
          <div>
            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title style={{ color: "#296013" }}>Update locals</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <div className='container-fluid'>
                    <div className='row'>
                      <div className='col-lg col-12'>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                          <Form.Label className='fw-bold' style={{ color: "gray" }}>Name</Form.Label>
                          <Form.Control
                            type="text"
                            autoFocus
                            value={uname}
                            onChange={(e) => setuname(e.target.value)}
                          />
                        </Form.Group>
                      </div>
                      <div className='col'>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                          <Form.Label className='fw-bold' style={{ color: "gray" }}>Email</Form.Label>
                          <Form.Control
                            type="text"
                            autoFocus
                            value={uemail}
                            onChange={(e) => setuemail(e.target.value)}
                          />
                        </Form.Group>
                      </div>
                    </div>

                    <div className='row'>
                      <div className='col-lg col-12'>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                          <Form.Label className='fw-bold' style={{ color: "gray" }}>Phone</Form.Label>
                          <Form.Control
                            type="text"
                            autoFocus
                            value={uphone}
                            onChange={(e) => setuphone(e.target.value)}
                          />
                        </Form.Group>
                      </div>
                      <div className='col'>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                          <Form.Label className='fw-bold' style={{ color: "gray" }}>Country </Form.Label>
                          <select className="form-select"
                            value={ucountry}
                            onChange={(e) => setucountry(e.target.value)}
                            aria-label="Default select example">
                            <option selected>Select country</option>
                            <option value="United Kingdom">United Kingdom</option>
                          </select>
                          {/* <Form.Control
                            type="text"
                            autoFocus
                         
                          /> */}
                        </Form.Group>
                      </div>

                    </div>

                    <div className='row'>
                      <div className='col-lg col-12'>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                          <Form.Label className='fw-bold' style={{ color: "gray" }}>Address </Form.Label>
                          <Form.Control
                            type="text"
                            autoFocus
                            value={uaddress}
                            onChange={(e) => setuaddress(e.target.value)}
                          />
                        </Form.Group>
                      </div>
                      <div className='col'>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                          <Form.Label className='fw-bold' style={{ color: "gray" }}>City</Form.Label>
                          {/* <Form.Control
                            type="text"
                            autoFocus
                            value={ucity}
                            onChange={(e) => setucity(e.target.value)}
                          /> */}
                          <Select
                            value={ucity}
                            isSearchable
                            onChange={setucity}
                            options={ProgramTypes}
                          />
                        </Form.Group>
                      </div>

                    </div>

                    <div className='row'>
                      <div className='col-lg col-12'>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                          <Form.Label className='fw-bold' style={{ color: "gray" }}>Bank name </Form.Label>
                          <Form.Control
                            type="text"
                            autoFocus
                            value={ubankName}
                            onChange={(e) => setubankName(e.target.value)}
                          />
                        </Form.Group>
                      </div>
                      <div className='col'>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                          <Form.Label className='fw-bold' style={{ color: "gray" }}>Account title </Form.Label>
                          <Form.Control
                            type="text"
                            autoFocus
                            value={uaccountTitle}
                            onChange={(e) => setuaccountTitle(e.target.value)}
                          />
                        </Form.Group>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-lg col-12'>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                          <Form.Label className='fw-bold' style={{ color: "gray" }}>Account # </Form.Label>
                          <Form.Control
                            type="text"
                            autoFocus
                            value={uaccountNumber}
                            onChange={(e) => setuaccountNumber(e.target.value)}
                          />
                        </Form.Group>
                      </div>
                      <div className='col'>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                          <Form.Label className='fw-bold' style={{ color: "gray" }}>NI# </Form.Label>
                          <Form.Control
                            type="text"
                            autoFocus
                            value={uni}
                            onChange={(e) => setuni(e.target.value)}
                          />
                        </Form.Group>
                      </div>
                    </div>

                    <div className='row'>
                      <div className='col-lg col-12'>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                          <Form.Label className='fw-bold' style={{ color: "gray" }}>IBAN </Form.Label>
                          <Form.Control
                            type="text"
                            autoFocus
                            value={uIBAN}
                            onChange={(e) => setuIBAN(e.target.value)}
                          />
                        </Form.Group>
                      </div>

                    </div>

                  </div>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" className='fw-bold' style={{ backgroundColor: "#F1BB44", border: "none", color: "black" }} onClick={handleClose}>
                  Close
                </Button>
                <Button variant="primary" onClick={handleupdate} style={{ backgroundColor: "#296013", border: "none" }}>
                  Save Changes
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
          <div className='container-fluid local-main pb-4'>
            <div className='row mt-3 text-start'>
              <h2>Add locals</h2>
            </div>

            <div className='row text-start mt-4 local-form'>
              <div className='col-lg-4 col-12'>
                <div className="mb-3">
                  <label for="exampleInputEmail1" className="form-label">Name <i className="fa-solid fa-star-of-life " style={{ fontSize: "9px", color: "red" }}></i></label>
                  <input type="text" className="form-control" id="exampleInputEmail1"
                    value={name}
                    onChange={(e) => setname(e.target.value)}
                    aria-describedby="emailHelp" />
                </div>
              </div>
              <div className='col-lg-4 col-12'>
                <div className="mb-3">
                  <label for="exampleInputEmail1" className="form-label">Email <i className="fa-solid fa-star-of-life " style={{ fontSize: "9px", color: "red" }}></i></label>
                  <input type="email" className="form-control" id="exampleInputEmail1"
                    value={email}
                    onChange={(e) => setemail(e.target.value)}
                    aria-describedby="emailHelp" />
                </div>
              </div>
              <div className='col-lg-4 col-12'>
                <div className="mb-3">
                  <label for="exampleInputEmail1" className="form-label">Phone <i className="fa-solid fa-star-of-life " style={{ fontSize: "9px", color: "red" }}></i></label>
                  <input type="text" className="form-control" id="exampleInputEmail1"
                    value={phone}
                    onChange={(e) => setphone(e.target.value)}
                    aria-describedby="emailHelp" />
                </div>
              </div>
            </div>


            <div className='row text-start mt-1 local-form'>
              <div className='col-lg-4 col-12'>
                <div className="mb-3">
                  <label for="exampleInputEmail1" className="form-label">Country <i className="fa-solid fa-star-of-life " style={{ fontSize: "9px", color: "red" }}></i></label>
                  <select className="form-select"
                    value={country}
                    onChange={(e) => setcountry(e.target.value)}
                    aria-label="Default select example">
                    <option selected>Select country</option>
                    <option value="United Kingdom">United Kingdom</option>
                  </select>
                </div>
              </div>
              <div className='col-lg-4 col-12'>
                <div className="mb-3">
                  <label for="exampleInputEmail1" className="form-label">City <i className="fa-solid fa-star-of-life " style={{ fontSize: "9px", color: "red" }}></i></label>
                  {/* <input type="text" className="form-control" id="exampleInputEmail1"
                    value={city}
                    onChange={(e) => setcity(e.target.value)}
                    aria-describedby="emailHelp" /> */}
                  <Select
                    value={searchcity}
                    isSearchable
                    onChange={setcity}
                    options={ProgramTypes}
                  />
                </div>
              </div>
              <div className='col-lg-4 col-12'>
                <div className="mb-3">
                  <label for="exampleInputEmail1" className="form-label">Address <i className="fa-solid fa-star-of-life " style={{ fontSize: "9px", color: "red" }}></i></label>
                  <input type="text" className="form-control" id="exampleInputEmail1"
                    value={address}
                    onChange={(e) => setaddress(e.target.value)}
                    aria-describedby="emailHelp" />
                </div>
              </div>

            </div>

            <div className='row text-start mt-1 local-form'>
              <div className='col-lg-4 col-12'>
                <div className="mb-3">
                  <label for="exampleInputEmail1" className="form-label">Bank name <i className="fa-solid fa-star-of-life " style={{ fontSize: "9px", color: "red" }}></i></label>
                  <input type="text" className="form-control" id="exampleInputEmail1"
                    value={bankName}
                    onChange={(e) => setbankName(e.target.value)}
                    aria-describedby="emailHelp" />
                </div>
              </div>
              <div className='col-lg-4 col-12'>
                <div className="mb-3">
                  <label for="exampleInputEmail1" className="form-label">Account title <i className="fa-solid fa-star-of-life " style={{ fontSize: "9px", color: "red" }}></i></label>
                  <input type="text" className="form-control" id="exampleInputEmail1"
                    value={accountTitle}
                    onChange={(e) => setaccountTitle(e.target.value)}
                    aria-describedby="emailHelp" />
                </div>
              </div>
              <div className='col-lg-4 col-12'>
                <div className="mb-3">
                  <label for="exampleInputEmail1" className="form-label">Account # <i className="fa-solid fa-star-of-life " style={{ fontSize: "9px", color: "red" }}></i></label>
                  <input type="text" className="form-control" id="exampleInputEmail1"
                    value={accountNumber}
                    onChange={(e) => setaccountNumber(e.target.value)}
                    aria-describedby="emailHelp" />
                </div>
              </div>
            </div>

            <div className='row text-start mt-1 local-form'>
              <div className='col-lg-4 col-12'>
                <div className="mb-3">
                  <label for="exampleInputEmail1" className="form-label">NI# <i className="fa-solid fa-star-of-life " style={{ fontSize: "9px", color: "red" }}></i></label>
                  <input type="text" className="form-control" id="exampleInputEmail1"
                    value={ni}
                    onChange={(e) => setni(e.target.value)}
                    aria-describedby="emailHelp" />
                </div>
              </div>
              <div className='col-lg-4 col-12'>
                <div className="mb-3">
                  <label for="exampleInputEmail1" className="form-label">IBAN <i className="fa-solid fa-star-of-life " style={{ fontSize: "9px", color: "red" }}></i></label>
                  <input type="email" className="form-control" id="exampleInputEmail1"
                    value={IBAN}
                    onChange={(e) => setIBAN(e.target.value)}
                    aria-describedby="emailHelp" />
                </div>
              </div>
            </div>

            <div className='row mt-3 btn-row'>
              <div className='col d-flex align-items-start justify-content-start'>
                <button className='btn btn-primary' onClick={postlocals}>Submit</button>
              </div>
            </div>

            <div className='row mt-lg-0 mt-3 btn-row'>
              <div className='col-lg-7'></div>
              <div className='col-lg-3 col-6'>
                {/* <select className="form-select"
                  value={filterid}
                  onChange={(e) => setfilterid(e.target.value)}
                  aria-label="Default select example">
                  <option selected>Select city</option>
                  {
                    citydata.map((item) => {
                      return <option value={item.city} key={item._id}> {item.city}</option>
                    })
                  }
                </select> */}
                <Select
                  value={cityfilter}
                  isSearchable
                  onChange={setcityfilter}
                  options={ProgramTypes}
                />

              </div>
              <div className='col-lg-1 col-3 d-flex justify-content-end align-items-end' style={{ cursor: "pointer" }}>
                <button className='btn btn-primary'
                  onClick={Filterid}
                >Filter</button>
              </div>
              <div className='col-lg-1 col-3 d-flex justify-content-end align-items-end'>
                <button className='btn btn-primary'
                  onClick={clearfilter}
                >
                  Clear</button>
              </div>
            </div>

            <div className='row mt-3'>
              <div className='col table11'>
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">Sr.</th>
                      <th scope="col">Name</th>
                      <th scope="col">Email</th>
                      <th scope="col">Phone</th>
                      <th scope="col">City</th>
                      <th scope="col">Address</th>
                      <th scope="col">Country</th>
                      <th scope="col">Bank name</th>
                      <th scope="col">Account title</th>
                      <th scope="col">Account #</th>
                      <th scope="col">IN #</th>
                      <th scope="col">IBAN</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {localdata ?
                      localdata.map((item, index) => {
                        return <tr key={item._id}>
                          <th scope="row">{index + 1}</th>
                          <td>{item.name}</td>
                          <td>{item.email}</td>
                          <td>{item.phone}</td>
                          <td>{item.city}</td>
                          <td>{item.address}</td>
                          <td>{item.country}</td>
                          <td>{item.bankName}</td>
                          <td>{item.accountTitle}</td>
                          <td>{item.accountNumber}</td>
                          <td>{item.ni}</td>
                          <td>{item.IBAN}</td>
                          <td>
                            <li className="nav-item dropdown" style={{ listStyle: "none" }}>
                              <button className="btn btn-primary dropdown-toggle" style={{ backgroundColor: "#296013", border: "none", height: "30px" }} role="button" data-bs-toggle="dropdown" aria-expanded="false">

                              </button>
                              <ul className="dropdown-menu">
                                <li className='' onClick={() => localdelete(item._id)}><td className="dropdown-item"><i class="fa-solid fa-trash me-2" style={{ color: "#B63A3A" }}></i> Delete</td></li>
                                <li style={{ cursor: "pointer" }}
                                  onClick={() => handleShow(item._id, item.name, item.email, item.phone, item.city, item.address, item.country, item.bankName, item.accountTitle, item.accountNumber, item.ni, item.IBAN)}
                                ><td className="dropdown-item"><i className="fa-solid fa-pen-to-square me-2"
                                  style={{ color: "#198BEC" }}></i> Update</td></li>
                              </ul>
                            </li>
                          </td>
                        </tr>
                      }) : ""

                    }


                  </tbody>
                </table>
              </div>
            </div>



          </div>
        </div>
      </div>
    </div>
  )
}

export default Local
