import React, { useEffect, useState } from 'react'
// import Ssidebar from '../Sidebar/Ssidebar';
import Agentnav from '../sideandnav/Agentnav';
import Agentside from '../sideandnav/Agentside';
import './Agentpersonal.css'
import Swal from 'sweetalert2';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'

const Agentpersonal = () => {
    const [toggle, setToggle] = useState(true);
    const navigate = useNavigate();
    const Toggle = () => {
        setToggle(!toggle);
    };

    const token = localStorage.getItem('agenttoken')

    useEffect(() => {
        getagent()
    }, [])


    const [firstName, setfirstName] = useState("")
    const [lastName, setlastName] = useState("")
    const [gender, setgender] = useState("")

    const handlesave = () => {
        // console.warn(firstName,lastName,gender)

        if (firstName && lastName && gender) {
            axios.post("https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/consultant/ChannelPartnerPersonalInfo", { firstName , lastName , gender }, {
                headers: {
                    Authorization: token,
                },
            }).then((res) => {
                if (res.data.message === "Personal information is added") {
                    Swal.fire({
                        title: "Thank you",
                        text: "Personal details is added",
                        icon: "success"
                    });
                } else {
                    console.log(res.data)

                }
            }).catch((e) => {
                console.log(e)
            })
        } else {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "please fill input",
            });
        }
    }

    const getagent = () => {
        axios.get("https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/consultant/ChannelPartnerProfile", {
            headers: {
                Authorization: token,
            },
        })
            .then((res) => {
                console.log(res.data)
                if (res.data.message === "Channel Parnter profile") {
                    setfirstName(res.data.data.personalInfo.firstName)
                    setlastName(res.data.data.personalInfo.lastName)
                    setgender(res.data.data.personalInfo.gender)
              
                } else {
                    navigate("/")
                }

            }).catch((e) => {
                console.log(e)
            })
    }

    const formatDate = (mongoDBDate) => {
        const date = new Date(mongoDBDate);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        console.warn(`${day}/${month}/${year}`)


        return `${year}-${month}-${day}`;
    };

  return (
    <div className="container-fluid">
    <div className="row">
        {toggle && (
            <div
                className="col-12 col-md-3 vh-100 position-fixed"
                style={{ zIndex: 1000 }}
            >
                <Agentside Toggle={Toggle} />
            </div>
        )}

        {toggle && <div className="col-12 col-lg-3"></div>}
        <div className="col">
            <Agentnav Toggle={Toggle} />
            <div className='container-fluid personalAgent-main'>
                <div className='row mt-3 text-start'>
                    <h2>Personal details</h2>
                </div>
                <div>
                    <div className='row text-start mt-4 acc-form'>
                        <div className='col-lg-4 col-12'>
                            <div className="mb-3">
                                <label for="people" className="form-label">First name</label>
                                <input type="text" className="form-control" id="people"
                                    value={firstName} onChange={(e) => setfirstName(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='col-lg-4 col-12'>
                            <div className="mb-3">
                                <label for="people" className="form-label">Last name</label>
                                <input type="text" className="form-control" id="people"
                                    value={lastName} onChange={(e) => setlastName(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='col-lg-4 col-12'>
                            <div className="mb-3">
                                <label for="exampleInputEmail1" className="form-label">Gender</label>
                                <select className="form-select"
                                    value={gender} onChange={(e) => setgender(e.target.value)}
                                    aria-label="Default select example">
                                    <option selected>Select gender</option>
                                    <option value="male">Male</option>
                                    <option value="female">Female</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='row mt-3 btn-row'>
                    <div className='col d-flex align-items-start justify-content-start'>
                        <button className='btn btn-primary' onClick={handlesave}>Save</button>
                    </div>
                </div>


            </div>
        </div>
    </div>
</div>
  )
}

export default Agentpersonal
