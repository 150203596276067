
import './App.css';
// import Navbar from './compunent/Navbar';
// import Footer from './compunent/Footer';
import Routers from './route/Routers';


function App() {
  return (
    <div className="App">

      {/* <Navbar /> */}
      <Routers />

      {/* <Footer /> */}

    </div>
  );
}

export default App;
