import React, { useEffect, useState } from "react";
import "./Register.css";
import Navbar from "../../compunent/Navbar";
import Footer from "../../compunent/Footer";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import Swal from "sweetalert2";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { allCountries } from "../../result";
import Select from "react-select";

const StudentRegister = () => {
  const token = localStorage.getItem("studenttoken");
  const token2 = localStorage.getItem("agenttoken");

  useEffect(() => {
    if (token || token2) {
      navigate("/");
    } else {
      // navigate("")
      getdata();
    }

    // console.warn(searchaccommodationCity)
  }, []);

  const getdata = () => {
    const typeArrange = allCountries[145].cities.map((row) => {
      return {
        value: `${row}`,
        label: `${row}`,
      };
    });
    setProgramTypes(typeArrange);
  };

  const [email, setemail] = useState("");
  const [foreName, setforeName] = useState("");
  const [password, setpassword] = useState("");
  const [phone, setphone] = useState();
  const [isAgree, setisAgree] = useState("");
  const [isuAgree, setuisAgree] = useState("");
  const [utoWhichCity, settoWhichCity] = useState("");
  const [serviceType, setserviceType] = useState([]);
  const [accommodationType, setaccommodationType] = useState("");
  const [a, seta] = useState(0);
  const navigate = useNavigate();
  const [ProgramTypes, setProgramTypes] = useState([]);

  const handletroggle = (e) => {
    e.preventDefault();
    const value = e.target.value;
    if (value === "Student") {
      seta(0);
    } else if (value === "Agent") {
      seta(1);
    } else {
      seta(0);
    }
  };
  const studentsubmit = () => {
    // e.preventDefault()
    console.warn(isuAgree);
    if (isuAgree === "on") {
      // setisAgree("yes")
      const isAgree = "yes";
      const toWhichCity = utoWhichCity.value;

      console.log(
        isAgree,
        toWhichCity,
        email,
        phone,
        password,
        accommodationType,
        serviceType,
        foreName
      );

      if (password) {
        axios
          .post(
            "https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/student/StudentSignup",
            {
              email,
              phone,
              password,
              isAgree,
              toWhichCity,
              accommodationType,
              serviceType,
              foreName,
            }
          )
          .then((res) => {
            console.log(res.data);
            if (res.data.token) {
              navigate("/sign");
            } else {
              console.log(res.data);
            }
          })
          .catch((e) => {
            if (e.response.data.message === "Student is already exist") {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Student is already exist",
              });
            } else {
              console.log(e);
            }
          });
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "please fill input",
        });
      }
    } else {
      setisAgree("");
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "please fill input",
      });
    }
    // console.warn(isAgree, email, password, phone)
  };

  const [Aemail, setAemail] = useState("");
  const [name, setname] = useState("");
  const [Apassword, setApassword] = useState("");
  const [Aphone, setAphone] = useState();
  const [AisAgree, setAisAgree] = useState("");

  const agentsubmit = () => {
    // e.preventDefault()
    console.log(AisAgree);
    if (AisAgree === "on") {
      // setisAgree("yes")
      const isAgree = "yes";
      const email = Aemail;
      const password = Apassword;
      const phone = Aphone;
      console.warn(isAgree, email, password, phone);

      // if (name&&email && isAgree && phone && password ) {
      axios
        .post(
          "https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/consultant/ChannelPartnerSignup",
          { email, phone, password, isAgree, name }
        )
        .then((res) => {
          if (res.data.token) {
            Swal.fire({
              title: "Thank you",
              text: "Address information is added",
              icon: "success",
            });

            setAemail("");
            setAisAgree("");
            setApassword("");
            setAphone("");
            setname("");
          } else {
            console.log(res.data);
          }
        })
        .catch((e) => {
          if (e.response.data.message === "Channel Partner is already exist") {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Agent is already exist",
            });
          } else {
            console.log(e);
          }
        });
      // } else {
      //   Swal.fire({
      //     icon: "error",
      //     title: "Oops...",
      //     text: "please fill input",
      //   });
      // }
    } else {
      setisAgree("");
    }
    console.warn(isAgree, email, password, phone);
  };

  return (
    <div className="main-register">
      <Navbar />
      <div className="container py-lg-5 py-4">
        <div className="row">
          <div className="col-lg-6 px-lg-5">
            <div className="form-box p-4">
              <div className="row text-start">
                <h2>Create your free account</h2>
              </div>

              <div className="row text-start mt-4 mb-2">
                <form>
                  <div
                    style={{
                      height: "300px",
                      overflow: "hidden",
                      overflowY: "scroll",
                    }}
                  >
                    <div className="mb-3">
                      <label for="sdfsdfds" className="form-label">
                        Name
                      </label>
                      <input
                        required
                        type="text"
                        className="form-control"
                        id="sdfsdfds"
                        value={foreName}
                        onChange={(e) => setforeName(e.target.value)}
                      />
                      {/* <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div> */}
                    </div>
                    <div className="mb-3">
                      <label for="exampleInputEmail1" className="form-label">
                        Email address
                      </label>
                      <input
                        required
                        type="email"
                        className="form-control"
                        id="exampleInputEmail1"
                        value={email}
                        onChange={(e) => setemail(e.target.value)}
                        aria-describedby="emailHelp"
                      />
                    </div>
                    <div className="mb-3">
                      <label for="exampleInputPassword1" className="form-label">
                        Password
                      </label>
                      <input
                        required
                        type="password"
                        className="form-control"
                        value={password}
                        onChange={(e) => setpassword(e.target.value)}
                        id="exampleInputPassword1"
                      />
                    </div>

                    <div className="mb-3">
                      <label for="exampleInputEmail1" className="form-label">
                        Phone number
                      </label>

                      <PhoneInput
                        className=""
                        country={"pk"}
                        value={phone}
                        onChange={(value) => setphone(value)}
                      />
                    </div>
                    <div className="mb-3">
                      <label for="exampleInputEmail1" className="form-label">
                        Where are you going
                      </label>
                      <Select
                        // value={searchaccommodationCity}
                        isSearchable
                        onChange={settoWhichCity}
                        options={ProgramTypes}
                      />
                    </div>

                    <div className="mb-3">
                      <label for="exampleInputEmail1" className="form-label">
                        Service type
                      </label>
                      <select
                        className="form-select"
                        value={serviceType}
                        onChange={(e) => setserviceType(e.target.value)}
                        aria-label="Default select example"
                      >
                        <option selected>Select type</option>
                        <option value="accommodation">Accommodation</option>
                        <option value="cab">Cab</option>
                        <option value="job">Job</option>
                      </select>
                    </div>

                    <div className="mb-3">
                      <label for="exampleInputEmail1" className="form-label">
                        Accommodation type
                      </label>
                      <select
                        className="form-select"
                        value={accommodationType}
                        onChange={(e) => setaccommodationType(e.target.value)}
                        aria-label="Default select example"
                      >
                        <option selected>Select type</option>
                        <option value="share">Shared</option>
                        <option value="independent">Independent</option>
                      </select>
                    </div>

                    <div className="mb-3 form-check">
                      <input
                        required
                        type="checkbox"
                        className="form-check-input"
                        checked={isuAgree}
                        onChange={(e) => setuisAgree(e.target.value)}
                        id="exampleCheck1"
                      />
                      <label
                        className="form-check-label ms-2"
                        for="exampleCheck1"
                        style={{ fontSize: "1rem" }}
                      >
                        Agree to terms and conditions
                      </label>
                    </div>
                  </div>
                  <button
                    className="btn btn-primary"
                    onClick={() => studentsubmit()}
                  >
                    Create account
                  </button>
                </form>
              </div>
            </div>
          </div>

          <div className="col-lg-6 px-lg-5 mt-3 mt-lg-0">
            <div className="form-box p-4 text-start">
              <div className="row">
                <h2>Already have an account?</h2>
              </div>
              <div className="row">
                <h4>Sign in and continue your learn & earn journey</h4>
              </div>
              <button
                className="btn btn-primary mt-3"
                onClick={() => navigate("/sign")}
              >
                Sign in
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default StudentRegister;
