import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'

const Adminroute = () => {

    const auth = localStorage.getItem('admintokenglobal')
  return auth? <Outlet/>:<Navigate to="/" />
}

export default Adminroute
