import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import Ssidebar from '../Sidebar/Ssidebar';
import Studentnav from '../Sidebar/Studentnav';
import './History.css'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import Navbar from '../../compunent/Navbar';
import Footer from '../../compunent/Footer';
import Loading from '../../Loading';

const History = () => {

    const [toggle, setToggle] = useState(true);
    const token = localStorage.getItem('studenttoken')
    const [loadersubmit, setloadersubmit] = useState(false)
    const navigate = useNavigate();
    const Toggle = () => {
        setToggle(!toggle);
    };

    useEffect(() => {
        gethistory()
    }, [])

    const [approvaldata, setapprovaldata] = useState([]);

    const gethistory = () => {
        setloadersubmit(true)
        axios.get("https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/cart/StudentHistory", {
            headers: {
                Authorization: token,
            },
        })
            .then((res) => {
                console.log(res.data)
                if (res.data.message === "Services are found") {
                    setapprovaldata(res.data.data)
                    setloadersubmit(false)
                } else {
                    navigate("/")
                }

            }).catch((e) => {
                console.log(e)
                setloadersubmit(false)
            })
    }




    const formatDate = (mongoDBDate) => {
        const date = new Date(mongoDBDate);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        console.warn(`${day}/${month}/${year}`)


        return `${year}-${month}-${day}`;
    };

    if (loadersubmit) {
        return (
            <Loading />
        )
    }


    return (
        <div className='history-main'>
            <Navbar />
            <div className="container mb-4 historybox">
                <div className="row">
                    {/* {toggle && (
                    <div
                        className="col-12 col-md-3 vh-100 position-fixed"
                        style={{ zIndex: 1000 }}
                    >
                        <Ssidebar Toggle={Toggle} />
                    </div>
                )}

                {toggle && <div className="col-12 col-lg-3"></div>} */}
                    <div className="col">
                        {/* <Studentnav Toggle={Toggle} /> */}

                        <div className='container-fluid'>
                            <div className='row mt-3 text-start'>
                                <h2>History</h2>
                            </div>
                            <div className='row mt-3'>
                                <div className='col table11'>
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th scope="col">Sr.</th>
                                                {/* <th scope="col">Role</th> */}
                                                <th scope="col">Name</th>
                                                <th scope="col">Accommodation type</th>
                                                <th scope="col">Duration</th>
                                                <th scope="col">Room capacity</th>
                                                <th scope="col">Check in date</th>
                                                <th scope="col">Check out date</th>
                                                <th scope="col">Booking status</th>
                                                <th scope="col">Discount amount</th>
                                                <th scope="col">Total amount</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {approvaldata ?
                                                approvaldata.map((item, index) => {
                                                    return <tr key={item._id}>
                                                        <th scope="row">{index + 1}</th>
                                                        <td>{item.name ? item.name : "N/A"}</td>
                                                        <td>{item.accommodationType ? item.accommodationType : "N/A"}</td>
                                                        <td>{item.duration ? item.duration : "N/A"}</td>
                                                        <td>{item.livingId.livingCapacity ? item.livingId.livingCapacity : "N/A"}</td>
                                                        <td>{formatDate(item.checkInDate ? item.checkInDate : "N/A")}</td>
                                                        <td>{formatDate(item.checkOutDate ? item.checkOutDate : "N/A")}</td>
                                                        <td>{item.bookingStatus ? item.bookingStatus : "N/A"}</td>
                                                        <td>{item.discountAmount ? item.discountAmount : "N/A"}</td>
                                                        <td>{item.totalAmountPrice ? item.totalAmountPrice : "N/A"}</td>
                                                        {/* <td>
                                                <li className="nav-item dropdown" style={{ listStyle: "none" }}>
                                                    <button className="btn btn-primary dropdown-toggle" style={{ backgroundColor: "#296013", border: "none", height: "30px" }} role="button" data-bs-toggle="dropdown" aria-expanded="false">

                                                    </button>
                                                    <ul className="dropdown-menu">
                                                        <li style={{ cursor: "pointer" }} onClick={() => localdelete(item._id)}><td className="dropdown-item"><i class="fa-solid fa-trash me-2" style={{ color: "#B63A3A" }}></i> Delete</td></li>
                                                        <li style={{ cursor: "pointer" }}
                                                            onClick={() => handleShow(item._id, item.bankName, item.accountTitle, item.accountNumber, item.IBAN)}
                                                        ><td className="dropdown-item"><i className="fa-solid fa-pen-to-square me-2"
                                                            style={{ color: "#198BEC" }}></i> Update</td></li>
                                                    </ul>
                                                </li>
                                            </td> */}
                                                    </tr>
                                                }) : ""

                                            }


                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default History
