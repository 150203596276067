
import './Easysteps.css';
import checking from '../assiet/img/cloud-computing_892311.png';
import document from '../assiet/img/agreement_3188168.png';
import done from '../assiet/img/accept_2550322.png';

function Easysteps() {
    return (
        <div className='main-easy d-flex justify-content-center align-items-center'>
            <div className='container py-5'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <h4 className='easy-h4'>Global Learn And Earn</h4>
                    </div>
                </div>

                <div className='row'>
                    <h2 className='easy-h2'>Get your UK Accommodation Sorted <br />in 03 Easy
                        Steps</h2>
                </div>

                <div className='row mt-5'>
                    <div className='col-lg-4 col-12 col-sm-6 px-5'>
                       <div className='px-3'>
                       <div className='easy-card1 pt-5'>
                            <img className='easy-img1' src={document} alt=''></img>
                            <h4 className='card1-h4 pt-3'>Step 01</h4>
                            <h2 className='card1-h2 pt-2'> Register</h2>
                        </div>
                       </div>
                    </div>
                    <div className='col-lg-4 col-12 col-sm-6 px-5 mt-4 mt-lg-0'>
                    <div className='px-3'>
                       <div className='easy-card1 pt-5'>
                            <img className='easy-img1' src={checking} alt=''></img>
                            <h4 className='card1-h4 pt-3'>Step 02</h4>
                            <h2 className='card1-h2 pt-2'>Upload Your<br/> Documents </h2>
                        </div>
                       </div>
                    </div>
                    <div className='col-lg-4 col-12 col-sm-12 px-5 mt-4 mt-lg-0'>
                    <div className='px-3 '>
                       <div className='easy-card1 pt-5'>
                            <img className='easy-img1' src={done} alt=''></img>
                            <h4 className='card1-h4 pt-3'>Step 03</h4>
                            <h2 className='card1-h2 pt-2'>Done</h2>
                        </div>
                       </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default Easysteps;