
import './Contactus.css';


function Contactus() {
    return (
        <div className='contact1-main'>
            <div className='content pt-5'>
                <h2>Contact Us</h2>
                {/* <p>It is a long established fact that a
                    reader will be<br /> distracted by the readable content of a page when looking</p> */}
            </div>

            <div className='container pt-5'>
                <div className='row pb-5'>
                    <div className='col-md-4 ps-lg-5'>

                        <div className='box23 pt-lg-5 '>
                            <div className='icon23'>
                                <i className='bi bi-geo-alt-fill'></i>
                            </div>
                            <div className='text23'>
                                <h3 className='text-start'>Address</h3>
                                <p className='text-start'>Wellmedow Street Paisley PA2EG</p>
                            </div>
                        </div>
                        <div className='box23'>
                            <div className='icon23'>
                                <i className='bi bi-telephone-fill'></i>
                            </div>
                            <div className='text23'>
                                <h3 className='text-start'>Contact</h3>
                                <p className='text-start'>+44 7908 444004</p>
                            </div>
                        </div>
                        <div className='box23'>
                            <div className='icon23'>
                                <i className='bi bi-envelope-fill'></i>
                            </div>
                            <div className='text23'>
                                <h3 className='text-start'>Email</h3>
                                <p className='text-start'>globallearnandearn@gmail.com<br/>info@globallearnandearn.com</p>
                            </div>
                        </div>

                    </div>




                    <div className='col-md-8 px-lg-5 pt-4 pt-lg-0'>
                        <div className='form-box'>
                            <form className='text-start py-4 px-4'>
                                <h2 className=''>Send Message</h2>
                                <div className='input-box2'>
                                    <input type='text' name='' required="required"></input>
                                    <span>Full Name</span>
                                </div>
                                <div className='input-box2'>
                                    <input type='email' name='' required="required"></input>
                                    <span>Email</span>
                                </div>
                                <div className='input-box2'>
                                    <input type='number' name='' required="required"></input>
                                    <span>Phone</span>
                                </div>
                                <div className='input-box2'>
                                    <textarea required="required"></textarea>
                                    <span>Type your Message...</span>
                                </div>
                                <div className='input-box2 '>
                                    <input type='submit' name='' value="Send"></input>
                                </div>
                            </form>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}


export default Contactus;