import React, { useEffect, useState } from 'react'
import Ssidebar from '../Sidebar/Ssidebar';
import Swal from 'sweetalert2';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'
import 'react-tagsinput/react-tagsinput.css'
import './Document.css'
import Navbar from '../../compunent/Navbar';

const Document = () => {
    const [toggle, setToggle] = useState(true);
    const navigate = useNavigate();
    const Toggle = () => {
        setToggle(!toggle);
    };

    const token = localStorage.getItem('studenttoken')

    useEffect(() => {
        getstudents()
    }, [])

    const [frontPage, setfrontPage] = useState()
    const [backPage, setbackPage] = useState()
    const [visaCopy, setvisaCopy] = useState()
    const [resumeCV, setresumeCV] = useState()
    const [IELTSCopy, setIELTSCopy] = useState()
    const [CASLetter, setCASLetter] = useState()
    const [covidCertificate, setcovidCertificate] = useState()
    const [TBCertificate, setTBCertificate] = useState()
    const [viewimg, setviewimg] = useState([])
    const [viewfront, setviewfront] = useState("")
    const [viewback, setviewback] = useState("")


    const handlesave = () => {
        // console.warn(bankName, accountNumber, accountTitle, IBAN)
        // if (bankName && accountNumber && accountTitle && IBAN) {
        const formdata = new FormData();
        formdata.append("frontPage", frontPage);
        formdata.append("backPage", backPage);
        formdata.append("visaCopy", visaCopy);
        formdata.append("resumeCV", resumeCV);
        formdata.append("IELTSCopy", IELTSCopy);
        formdata.append("CASLetter", CASLetter);
        formdata.append("covidCertificate", covidCertificate);
        formdata.append("TBCertificate", TBCertificate);
        axios.post("https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/student/StudentDocumentsAndAttachments", formdata, {
            headers: {
                Authorization: token,
            },
        }).then((res) => {
            if (res.data.message === "Documents are added successfully") {
                Swal.fire({
                    title: "Thank you",
                    text: "Documents information is added",
                    icon: "success"
                });

                getstudents()
            } else {
                console.log(res.data)

            }
        }).catch((e) => {
            console.log(e)
        })
        // } else {
        //     Swal.fire({
        //         icon: "error",
        //         title: "Oops...",
        //         text: "please fill input",
        //     });
        // }
    }

    const getstudents = () => {
        axios.get("https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/student/StudentProfile", {
            headers: {
                Authorization: token,
            },
        })
            .then((res) => {
                console.log(res.data.data)
                if (res.data.message === "Student profile") {
                    setviewimg(res.data.data[0].documentsAndAttachments)
                    setviewfront(res.data.data[0].documentsAndAttachments.passportScan[0].frontPage)
                    setviewback(res.data.data[0].documentsAndAttachments.passportScan[0].backPage)
                } else {
                    navigate("/")
                }

            }).catch((e) => {
                console.log(e)
            })
    }
    return (
        <div>
            <Navbar />
            <div className="container-fluid">
                <div className="row">
                    {toggle && (
                        <div
                            className="col-12 col-md-3 vh-100 position-fixed"
                            style={{ zIndex: 1000 }}
                        >
                            <Ssidebar Toggle={Toggle} />
                        </div>
                    )}

                    {toggle && <div className="col-12 col-lg-3"></div>}
                    <div className="col">
                        {/* <Studentnav Toggle={Toggle} /> */}
                        <div className='container-fluid document-main mb-3'>
                            <div className='row mt-3 text-start'>
                                <div className='col'>
                                    <h2 style={{ fontSize: "1.6rem" }}>{toggle?"":<i className="fa-solid fa-bars" onClick={Toggle} style={{cursor: "pointer"}}></i>} Document & attachments (Maximum size 2 mb)</h2>
                                </div>
                            </div>
                            <div>

                                <div className='row mt-4 mb-2'>
                                    <div className='col text-start'>
                                        <h2 style={{ fontSize: "1.4rem" }}>Passport scan</h2>
                                    </div>
                                </div>
                                <div className='row text-start acc-form'>
                                    <div className='col-lg-4 col-12'>
                                        <div className="mb-3">
                                            <label for="formFile" className="form-label">Front page</label>
                                            <input className="form-control" type="file" onChange={(e) => setfrontPage(e.target.files[0])} id="formFile" />
                                        </div>
                                    </div>
                                    <div className='col-lg-4 col-12'>
                                        <div className="mb-3">
                                            <label for="formFile" className="form-label">Back page</label>
                                            <input className="form-control" type="file" onChange={(e) => setbackPage(e.target.files[0])} id="formFile" />
                                        </div>
                                    </div>
                                </div>

                                <div className='row text-start acc-form'>
                                    <div className='col-lg-4 col-12'>
                                        <div className="mb-3">
                                            <label for="formFile" className="form-label">Visa copy</label>
                                            <input className="form-control" type="file" onChange={(e) => setvisaCopy(e.target.files[0])} id="formFile" />
                                        </div>
                                    </div>

                                    <div className='col-lg-4 col-12'>
                                        <div className="mb-3">
                                            <label for="formFile" className="form-label">Resume copy</label>
                                            <input className="form-control" type="file" onChange={(e) => setresumeCV(e.target.files[0])} id="formFile" />
                                        </div>
                                    </div>

                                    <div className='col-lg-4 col-12'>
                                        <div className="mb-3">
                                            <label for="formFile" className="form-label">IELTS result</label>
                                            <input className="form-control" type="file" onChange={(e) => setIELTSCopy(e.target.files[0])} id="formFile" />
                                        </div>
                                    </div>
                                </div>

                                <div className='row text-start acc-form'>
                                    <div className='col-lg-4 col-12'>
                                        <div className="mb-3">
                                            <label for="formFile" className="form-label">CAS letter</label>
                                            <input className="form-control" type="file" onChange={(e) => setCASLetter(e.target.files[0])} id="formFile" />
                                        </div>
                                    </div>

                                    <div className='col-lg-4 col-12'>
                                        <div className="mb-3">
                                            <label for="formFile" className="form-label">Covid certificate</label>
                                            <input className="form-control" type="file" onChange={(e) => setcovidCertificate(e.target.files[0])} id="formFile" />
                                        </div>
                                    </div>

                                    <div className='col-lg-4 col-12'>
                                        <div className="mb-3">
                                            <label for="formFile" className="form-label">TB certificate</label>
                                            <input className="form-control" type="file" onChange={(e) => setTBCertificate(e.target.files[0])} id="formFile" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='row mt-3 btn-row'>
                                <div className='col d-flex align-items-start justify-content-start'>
                                    <button className='btn btn-primary' onClick={handlesave}>Save</button>
                                </div>
                            </div>

                            <div className='row mt-4 mb-2'>
                                <div className='col text-start'>
                                    <h2 style={{ fontSize: "1.4rem" }}>View attachments</h2>
                                </div>
                            </div>

                            <div className='row mt-3 view-img'>
                                <div className='col-lg-4 col-12 mb-3'>
                                    {
                                        viewfront ? <>
                                            <div className='pdfbox'>
                                                <div className='row '>
                                                    <div className='col py-3'>
                                                        <h3>Front page</h3>
                                                    </div>
                                                    <div className='col py-3'>
                                                        <a href={`https://globallearnandearnapi.doctorsforhealth.co.uk/` + viewfront} target='blank'><i class="fa-solid fa-eye"></i> view</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </> : ""
                                    }

                                </div>
                                <div className='col-lg-4 mb-3'>

                                    {
                                        viewback ? <>
                                            <div className='pdfbox'>
                                                <div className='row '>
                                                    <div className='col py-3'>
                                                        <h3>Back page</h3>
                                                    </div>
                                                    <div className='col py-3'>
                                                        <a href={`https://globallearnandearnapi.doctorsforhealth.co.uk/` + viewback} target='blank'><i class="fa-solid fa-eye"></i> view</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </> : ""
                                    }

                                </div>
                                <div className='col-lg-4'>

                                    {
                                        viewimg.visaCopy ? <>
                                            <div className='pdfbox'>
                                                <div className='row '>
                                                    <div className='col py-3'>
                                                        <h3>Visa copy</h3>
                                                    </div>
                                                    <div className='col py-3'>
                                                        <a href={`https://globallearnandearnapi.doctorsforhealth.co.uk/` + viewimg.visaCopy} target='blank'><i class="fa-solid fa-eye"></i> view</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </> : ""
                                    }
                                </div>

                                {/* <div className='col-lg-4'>
                                    <img src={`https://globallearnandearnapi.doctorsforhealth.co.uk/` + viewimg.visaCopy} alt='' className='img-fluid'></img>
                                </div> */}
                            </div>
                            <div className='row mt-3 view-img'>
                         
                                <div className='col-lg-4 mb-3'>
                                {
                                    viewimg.resumeCV ? <>
                                        <div className='pdfbox'>
                                            <div className='row '>
                                                <div className='col py-3'>
                                                    <h3>Resume</h3>
                                                </div>
                                                <div className='col py-3'>
                                                    <a href={`https://globallearnandearnapi.doctorsforhealth.co.uk/` + viewimg.resumeCV} target='blank'><i class="fa-solid fa-eye"></i> view</a>
                                                </div>
                                            </div>
                                        </div>
                                    </> : ""
                                }
                                </div>
                                <div className='col-lg-4 mb-3'>
                                {
                                    viewimg.IELTSCopy ? <>
                                        <div className='pdfbox'>
                                            <div className='row '>
                                                <div className='col py-3'>
                                                    <h3>IELTS result</h3>
                                                </div>
                                                <div className='col py-3'>
                                                    <a href={`https://globallearnandearnapi.doctorsforhealth.co.uk/` + viewimg.IELTSCopy} target='blank'><i class="fa-solid fa-eye"></i> view</a>
                                                </div>
                                            </div>
                                        </div>
                                    </> : ""
                                }
                                    {/* <img src={`https://globallearnandearnapi.doctorsforhealth.co.uk/` + viewimg.IELTSCopy} alt='' className='img-fluid'></img> */}
                                </div>
                                <div className='col-lg-4'>
                                {
                                    viewimg.CASLetter ? <>
                                        <div className='pdfbox'>
                                            <div className='row '>
                                                <div className='col py-3'>
                                                    <h3>CAS letter</h3>
                                                </div>
                                                <div className='col py-3'>
                                                    <a href={`https://globallearnandearnapi.doctorsforhealth.co.uk/` + viewimg.CASLetter} target='blank'><i class="fa-solid fa-eye"></i> view</a>
                                                </div>
                                            </div>
                                        </div>
                                    </> : ""
                                }
                                    {/* <img src={`https://globallearnandearnapi.doctorsforhealth.co.uk/` + viewimg.CASLetter} alt='' className='img-fluid'></img> */}
                                </div>
                            </div>
                            <div className='row mt-3 view-img'>
                                <div className='col-lg-4 mb-3'>
                                {
                                    viewimg.covidCertificate ? <>
                                        <div className='pdfbox'>
                                            <div className='row '>
                                                <div className='col py-3'>
                                                    <h3>Covid certificate</h3>
                                                </div>
                                                <div className='col py-3'>
                                                    <a href={`https://globallearnandearnapi.doctorsforhealth.co.uk/` + viewimg.covidCertificate} target='blank'><i class="fa-solid fa-eye"></i> view</a>
                                                </div>
                                            </div>
                                        </div>
                                    </> : ""
                                }
                                    {/* <img src={`https://globallearnandearnapi.doctorsforhealth.co.uk/` + viewimg.covidCertificate} alt='' className='img-fluid'></img> */}
                                </div>
                                <div className='col-lg-4 mb-3'>
                                {
                                    viewimg.TBCertificate ? <>
                                        <div className='pdfbox'>
                                            <div className='row '>
                                                <div className='col py-3'>
                                                    <h3>TB certificate</h3>
                                                </div>
                                                <div className='col py-3'>
                                                    <a href={`https://globallearnandearnapi.doctorsforhealth.co.uk/` + viewimg.TBCertificate} target='blank'><i class="fa-solid fa-eye"></i> view</a>
                                                </div>
                                            </div>
                                        </div>
                                    </> : ""
                                }
                                    {/* <img src={`https://globallearnandearnapi.doctorsforhealth.co.uk/` + viewimg.TBCertificate} alt='' className='img-fluid'></img> */}
                                </div>

                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Document
