import React from 'react'
import './Cityslider.css'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
// import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import londonimg from '../assiet/img/london-card.jpg'
import card2 from '../assiet/img/card-2.jpg'
import card3 from '../assiet/img/card-3.jpg'
import { useNavigate } from 'react-router-dom'

const Cityslider = () => {
    const navigate = useNavigate();

    return (
        <div className='Cityslider-main my-4'>
            <div className='container'>
                <div className='row'>
                    <div className='col'>
                        <h1>We help you to find accomodation</h1>
                    </div>
                </div>
                <div className='row px-md-5'>
                    <div className='col line-text'></div>
                    <div className='col-5'>
                        <h3>through out UK</h3>
                    </div>
                    <div className='col line-text'></div>
                </div>

                <div className='row slider-card mt-2'>
                    <div>
                        <Swiper
                            slidesPerView={1}
                            spaceBetween={10}
                            autoplay={{
                                delay: 2500,
                                disableOnInteraction: false,
                              }}
                            pagination={{
                                clickable: true,
                            }}
                            
                            breakpoints={{
                                640: {
                                    slidesPerView: 2,
                                    spaceBetween: 20,
                                },
                                768: {
                                    slidesPerView: 4,
                                    spaceBetween: 40,
                                },
                                1024: {
                                    slidesPerView: 3,
                                    spaceBetween: 50,
                                },
                            }}
                            navigation={true}
                            modules={[Autoplay, Navigation,Pagination]}
                            className="mySwiper"
                        >
                            <SwiperSlide className='px-md-4'>
                                <div className='card-1 g-0'onClick={() =>navigate("/locations")}>
                                    <div className='row'>
                                        <div className='col'>
                                            <img src={londonimg} alt='' className='img-fluid'></img>
                                        </div>
                                    </div>

                                    <div className='row text-center mt-3'>
                                        <h2>London</h2>
                                    </div>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide className='px-md-4'>
                                <div className='card-1 g-0'>
                                    <div className='row'>
                                        <div className='col'>
                                            <img src={card2} alt='' className='img-fluid'></img>
                                        </div>
                                    </div>

                                    <div className='row text-center mt-3'>
                                        <h2>Birmingham</h2>
                                    </div>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide className='px-md-4'>
                                <div className='card-1 g-0'>
                                    <div className='row'>
                                        <div className='col'>
                                            <img src={card3} alt='' className='img-fluid'></img>
                                        </div>
                                    </div>

                                    <div className='row text-center mt-3'>
                                        <h2>Manchester</h2>
                                    </div>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide className='px-md-4'>
                                <div className='card-1 g-0'>
                                    <div className='row'>
                                        <div className='col'>
                                            <img src={londonimg} alt='' className='img-fluid'></img>
                                        </div>
                                    </div>

                                    <div className='row text-center mt-3'>
                                        <h2>London</h2>
                                    </div>
                                </div>
                            </SwiperSlide>
                 
                        </Swiper>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Cityslider
