import "./Navbar.css";
import logo from "../assiet/img/learn-and-earn-logo.png";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

function Navbar() {
  const navigate = useNavigate();
  const [auths, setauths] = useState("");
  const [auth2, setauth1] = useState("");
  const [auth3, setauth3] = useState("");

  useEffect(() => {
    handlecondition();
  }, []);

  const handlecondition = () => {
    const auth = localStorage.getItem("studenttoken");
    const auth2 = localStorage.getItem("agenttoken");
    const auth3 = localStorage.getItem("lettingtoken");
    setauths(auth);
    setauth1(auth2);
    setauth3(auth3);
  };

  const logout = () => {
    localStorage.clear();
    navigate("/sign");
  };

  return (
    <div className="main-nav">
      {/* <nav className="navbar navbar-expand-lg  bg-white">
                <div className="container">
                    <img
                        className="navbar-brand"
                        src={logo}
                        alt="logo"
                        onClick={() => navigate("/")}
                    ></img>
                    <button
                        className="navbar-toggler"
                        type="button"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasNavbar"
                        aria-controls="offcanvasNavbar"
                    >
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div
                        className="offcanvas offcanvas-end"
                        tabIndex="-1"
                        id="offcanvasNavbar"
                        aria-labelledby="offcanvasNavbarLabel"
                    >
                        <div className="offcanvas-header">
                            <h5 className="offcanvas-title" id="offcanvasNavbarLabel">
                                <img src={logo} alt="logo" />
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="offcanvas"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="offcanvas-body">
                            <ul className="navbar-nav justify-content-end align-items-center fs-5 flex-grow-1 pe-3">
                                <li className="nav-home nav-item">
                                    <NavLink to="/" className="nav-link" aria-current="page">
                                        Home
                                    </NavLink>
                                </li>
                                <li className="nav-item dropdown">
                                    <a
                                        href="/"
                                        className="nav-link dropdown-toggle"
                                        role="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        Services
                                    </a>
                                    <ul className="dropdown-menu">
                                       
                                        <li>
                                            <NavLink to="/accommodation" className="dropdown-item">
                                                Accommodation
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/job-finding" className="dropdown-item">
                                                Job finding
                                            </NavLink>
                                        </li>
                                    </ul>
                                </li>
                                <li className="nav-item nav-home">
                                    <NavLink to="/locations" className="nav-link">
                                        Locations
                                    </NavLink>
                                </li>
            
                                <li className="nav-item nav-home">
                                    <NavLink to="/about" className="nav-link">
                                        About
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to="/contact" className="nav-link">
                                        Contact
                                    </NavLink>
                                </li>
                            </ul>
                            {auths ? (
                                <>
                                    <div className="dropdown btn-2 mt-1">
                                        <button
                                            className="btn btn-secondary dropdown-toggle"
                                            type="button"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                        >
                                            <i className="fa-solid fa-gauge me-1"></i> Dashboard
                                        </button>
                                        <ul className="dropdown-menu">
                                            <li>
                                                <NavLink
                                                    className="dropdown-item"
                                                    to="/personal-information"
                                                >
                                                    Profile
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink
                                                    className="dropdown-item"
                                                    to="/view-accommodation"
                                                >
                                                    Accommodation
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink className="dropdown-item" to="/my-orders">
                                                    My orders
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink className="dropdown-item" to="/history">
                                                    History
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink className="dropdown-item" to="/promo-code">
                                                    Promo code
                                                </NavLink>
                                            </li>
                                        </ul>
                                    </div>
                                   
                                    <div className="btn-3 d-flex justify-content-center align-items-center ms-md-3 mt-2 mt-md-0">
                                        <button className="btn btn-primary" onClick={logout}>
                                            <i className="fa-solid fa-right-from-bracket"></i> Logout
                                        </button>
                                    </div>
                                </>
                            ) : auth2 ? (
                                <>
                                    <div className="btn-1 d-flex justify-content-center align-items-center ">
                                        <NavLink
                                            to="/personal-details"
                                            className="px-3 py-1 btn-td"
                                        >
                                            <i className="fa-solid fa-user me-2"></i> welcome
                                        </NavLink>
                                    </div>
                                </>
                            ) : auth3 ? (
                                <>
                                    <div className="btn-1 d-flex justify-content-center align-items-center ">
                                        <NavLink to="/bid-post" className="px-3 py-1 btn-td">
                                            <i className="fa-solid fa-user me-2"></i>Welcome
                                        </NavLink>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="btn-1 d-flex justify-content-center align-items-center gap-3">
                                        <NavLink to="/sign" className="px-3 py-1 btn-td">
                                            Sign in
                                        </NavLink>
                                        <li className="nav-item dropdown">
                                         

                                            <button
                                                className="btn btn-secondary dropdown-toggle px-3 py-1 btn-td nav-link"
                                                type="button"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                            >
                                                Register
                                            </button>
                                            <ul className="dropdown-menu">
                                      
                                                <li>
                                                    <NavLink
                                                        to="/agent-register"
                                                        className="dropdown-item"
                                                        href="#"
                                                    >
                                                        Agent
                                                    </NavLink>
                                                </li>
                                                <li>
                                                    <NavLink
                                                        to="/letting-agent-register"
                                                        className="dropdown-item"
                                                        href="#"
                                                    >
                                                        Letting agent
                                                    </NavLink>
                                                </li>
                                            </ul>
                                        </li>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </nav> */}
      <div className="navbar2 ">
        <nav className="navbar navbar-expand-lg bg-white">
          <div className="container">
            <img
              src={logo}
              alt=""
              className="my-2 navbar-brand"
              onClick={() => navigate("/")}
            ></img>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0 me-3">
                <li className="nav-home nav-item">
                  <NavLink to="/" className="nav-link" aria-current="page">
                    Home
                  </NavLink>
                </li>
                <li className="nav-item dropdown">
                  <a
                    href="/"
                    className="nav-link dropdown-toggle"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Services
                  </a>
                  <ul className="dropdown-menu">
                    <li>
                      <NavLink to="/accommodation" className="dropdown-item">
                        Accommodation
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/job-finding" className="dropdown-item">
                        Job Finding
                      </NavLink>
                    </li>
                  </ul>
                </li>
                <li className="nav-item nav-home">
                  <NavLink to="/locations" className="nav-link">
                    Locations
                  </NavLink>
                </li>

                <li className="nav-item nav-home">
                  <NavLink to="/about" className="nav-link">
                    About
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/contact" className="nav-link">
                    Contact
                  </NavLink>
                </li>
              </ul>
              {auths ? (
                <>
                  <div className="dropdown btn-2 mt-1">
                    <button
                      className="btn btn-secondary dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i className="fa-solid fa-gauge me-1"></i> Dashboard
                    </button>
                    <ul className="dropdown-menu">
                      <li>
                        <NavLink
                          className="dropdown-item"
                          to="/personal-information"
                        >
                          Profile
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          className="dropdown-item"
                          to="/view-accommodation"
                        >
                          Accommodation
                        </NavLink>
                      </li>
                      <li>
                        <NavLink className="dropdown-item" to="/my-orders">
                          My orders
                        </NavLink>
                      </li>
                      <li>
                        <NavLink className="dropdown-item" to="/history">
                          History
                        </NavLink>
                      </li>
                      <li>
                        <NavLink className="dropdown-item" to="/promo-code">
                          Promo code
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                  <div className="btn-3 d-flex justify-content-center align-items-center ms-md-3 mt-2 mt-md-0">
                    <button className="btn btn-primary" onClick={logout}>
                      <i className="fa-solid fa-right-from-bracket"></i> Logout
                    </button>
                  </div>
                </>
              ) : auth2 ? (
                <>
                  <div className="btn-1 d-flex justify-content-center align-items-center ">
                    <NavLink
                      to="/personal-details"
                      className="px-3 py-1 btn-td"
                    >
                      <i className="fa-solid fa-user me-2"></i> welcome
                    </NavLink>
                  </div>
                </>
              ) : auth3 ? (
                <>
                  <div className="btn-1 d-flex justify-content-center align-items-center ">
                    <NavLink to="/bid-post" className="px-3 py-1 btn-td">
                      <i className="fa-solid fa-user me-2"></i>Welcome
                    </NavLink>
                  </div>
                </>
              ) : (
                <>
                  <div className="btn-1 d-flex justify-content-center align-items-center gap-3">
                    <NavLink to="/sign" className="px-3 py-1 btn-td">
                      Sign in
                    </NavLink>
                    <li className="nav-item dropdown">
                      <button
                        className="btn btn-secondary dropdown-toggle px-3 py-1 btn-td nav-link"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Register
                      </button>
                      <ul className="dropdown-menu">
                        <li>
                          <NavLink to="/student-register" className="dropdown-item">
                            Student
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="/agent-register"
                            className="dropdown-item"
                          >
                            Agent
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="/letting-agent-register"
                            className="dropdown-item"
                          >
                            Letting agent
                          </NavLink>
                        </li>
                      </ul>
                    </li>
                  </div>
                </>
              )}
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
}

export default Navbar;
