
import React, { useEffect, useState } from 'react'
import './Register.css'
import Navbar from '../../compunent/Navbar'
import Footer from '../../compunent/Footer'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
import Swal from 'sweetalert2'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { allCountries } from '../../result'
import Select from 'react-select';

const Register = () => {

  const token = localStorage.getItem('agenttoken')

  useEffect(() => {
    if (token) {
      navigate("/")
    } else {
      // navigate("")
      // getdata()
    }

    // console.warn(searchaccommodationCity)
  }, [])

  // const getdata = () => {
  //   const typeArrange = allCountries[145].cities.map((row) => {
  //     return {
  //       value: `${row}`,
  //       label: `${row}`,
  //     };
  //   });
  //   setProgramTypes(typeArrange);
  // }

  const navigate = useNavigate();


  const [email, setemail] = useState("");
  const [name, setname] = useState("");
  const [password, setpassword] = useState("");
  const [phone, setphone] = useState();
  const [isAgree, setisAgree] = useState("");

  const agentsubmit = () => {

    console.log(email,isAgree,password,phone,name)
    if (isAgree, name, email, password, phone) {
      axios.post("https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/consultant/ChannelPartnerSignup", { email, phone, password, isAgree, name })
        .then((res) => {
          if (res.data.token) {
            Swal.fire({
              title: "Thank you",
              text: "Successful",
              icon: "success"
            })
            navigate("/sign")
          } else {
            console.log(res.data)

          }
        }).catch((e) => {
          if (e.response.data.message === "Channel Partner is already exist") {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Agent is already exist",
            });
          } else {
            console.log(e)

          }
        })
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "please fill input",
      });
    }



  }

  return (
    <div className='main-register'>
      <Navbar />
      <div className='container py-lg-5 py-4'>
        <div className='row'>
          <div className='col-lg-6 px-lg-5'>
            <div className='form-box p-4'>
              <div className='row text-start'>
                <h2>Create your agent account</h2>
              </div>

              <div className='row text-start mt-4 mb-2'>
                <form>
                  <div className="mb-3">
                    <label for="exampleInputEmail1" className="form-label">Name</label>
                    <input required type="text" className="form-control" id="exampleInputEmail1"
                      value={name} onChange={(e) => setname(e.target.value)}
                      aria-describedby="emailHelp" />
                    {/* <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div> */}
                  </div>
                  <div className="mb-3">
                    <label for="exampleInputEmail1" className="form-label">Email address</label>
                    <input required type="email" className="form-control" id="exampleInputEmail1"
                      value={email} onChange={(e) => setemail(e.target.value)}
                      aria-describedby="emailHelp" />
                    {/* <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div> */}
                  </div>
                  <div className="mb-3">
                    <label for="exampleInputEmail1" className="form-label">Phone number</label>
                    <PhoneInput
                      className=""
                      country={'pk'}
                      value={phone}
                      onChange={(value) => setphone(value)}
                    />
                  </div>
                  <div className="mb-3">
                    <label for="exampleInputPassword1" className="form-label">Password</label>
                    <input required type="password" className="form-control"
                      value={password} onChange={(e) => setpassword(e.target.value)}
                      id="exampleInputPassword1" />
                  </div>
                  <div className="mb-3 form-check">
                    <input required type="checkbox" className="form-check-input"
                      value="yes"
                      checked={isAgree}
                      onChange={(e) => setisAgree(e.target.value)}
                      id="exampleCheck1" />
                    <label className="form-check-label ms-2" for="exampleCheck1" style={{ fontSize: "1rem" }}>Agree to terms and conditions</label>
                  </div>

                  <button className="btn btn-primary" onClick={agentsubmit}>Create account</button>




                </form>
              </div>


            </div>
          </div>


          <div className='col-lg-6 px-lg-5 mt-3 mt-lg-0'>
            <div className='form-box p-4 text-start'>
              <div className='row'>
                <h2>Already have an account?</h2>
              </div>
              <div className='row'>
                <h4>Sign in and continue your learn & earn journey</h4>
              </div>
              <button className="btn btn-primary mt-3" onClick={() => navigate("/sign")}>Sign in</button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Register
