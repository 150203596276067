import React, { useEffect } from 'react'
import { useState } from 'react'
import Adminnav from '../admin nav/Adminnav'
import Sidebar from '../sidebar/Sidebar'
import './Term.css'
import Swal from 'sweetalert2'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';


const Term = () => {

    const [toggle, setToggle] = useState(true);
    const [userRole, setuserRole] = useState("")
    const [termsAndCondition, settermsAndCondition] = useState("")
    const [terms, setterms] = useState("")
    const token = localStorage.getItem('admintokenglobal')
    const navigate = useNavigate();


    const Toggle = () => {
        setToggle(!toggle);
    };

    const handlesubmit = () => {
        if (userRole, termsAndCondition) {
            axios.post("https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/terms/AddTermsAndConditions", { userRole, termsAndCondition }, {
                headers: {
                    Authorization: token,
                },
            }).then((res) => {
                console.log(res.data)
                if (res.data.message === "Successfully Terms And Conditions are added") {
                    Swal.fire({
                        title: "Thank you",
                        text: "Successfully terms and conditions is added",
                        icon: "success"
                    });
                    setuserRole("")
                    settermsAndCondition("")
                    getterms()
                }
            }).catch((e) => {
                console.log(e)
                if (e.response.data.message === "terms And Condition for this userRole is already exist") {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Terms and condition is already exist",
                    });
                }else if(e.response.data.message === "Invalid data"){
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Invalid data",
                    });
                } else {
                    console.log(e)
                }
                
            })
        } else {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Please fill input",
            });
        }
    }

    useEffect(() => {
        getterms()
    }, [])

    const getterms = () => {
        axios.get("https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/terms/GetAddTermsAndConditions", {
            headers: {
                Authorization: token,
            },
        })
            .then((res) => {
                console.log(res.data)
                if (res.data.message === "Successfully  found") {
                    setterms(res.data.data)
                } else {
                    navigate("/")
                }

            }).catch((e) => {
                console.log(e)
            })
    }

    const localdelete = (id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then((result) => {
            if (result.isConfirmed) {
                axios.delete(`https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/terms/DeleteTermsAndConditions?id=` + id, {
                    headers: {
                        Authorization: token,
                    },
                }).then((res) => {
                    console.log(res.data)
                    if (res.data.message === "Successfully TermsAndConditions deleted") {
                        Swal.fire({
                            title: "Deleted!",
                            text: "Successfully terms And conditions deleted.",
                            icon: "success"
                        });
                        getterms()
                    }
                }).catch((e) => {
                    console.log(e)
                })

            }
        });

    }

    const handleClose = () => setShow(false);
    const [show, setShow] = useState(false);
    const [uuserRole, setuuserRole] = useState("")
    const [termid, settermid] = useState("")
    const [utermsAndCondition, setutermsAndCondition] = useState("")
    const handleShow = (id, n, e,) => {
        settermid(id)
        // setuuserRole(n)
        setutermsAndCondition(e)
        setShow(true)
    };
    const handleupdate = () => { 
        const userRole = uuserRole;
        const termsAndCondition = utermsAndCondition;
        // if(uuserRole === "")
            axios.put(`https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/terms/UpdateTermsAndConditions?id=` + termid, { userRole, termsAndCondition }, {
                headers: {
                  Authorization: token,
                },
              }).then((res) => {
                console.log(res.data)
                if (res.data.message === "TermsAndConditions data updated successfully") {
                  Swal.fire({
                    title: "Thank you",
                    text: "Terms & conditions updated successfully",
                    icon: "success"
                  });
                  handleClose()
                  getterms()
                }
              }).catch((e) => {
                console.log(e)
                if (e.response.data.message === "userRole already exist") {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "User role already exist",
                  });
                } else {
                  console.log(e)
          
                }
              })
    

    
      }
    

    return (
        <div className="container-fluid">
            <div className="row">
                {toggle && (
                    <div
                        className="col-12 col-md-3 vh-100 position-fixed"
                        style={{ zIndex: 1000 }}
                    >
                        <Sidebar Toggle={Toggle} />
                    </div>
                )}

                {toggle && <div className="col-12 col-lg-3"></div>}
                <div className="col">
                    <div>
                        <Modal show={show} onHide={handleClose}>
                            <Modal.Header closeButton>
                                <Modal.Title style={{ color: "#296013" }}>Update locals</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form>
                                    <div className='container-fluid'>
                                        <div className='row'>
                                            <div className='col'>
                                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                    <Form.Label className='fw-bold' style={{ color: "gray" }}>Role </Form.Label>
                                                    <select className="form-select"
                                                        value={uuserRole}
                                                        onChange={(e) => setuuserRole(e.target.value)}
                                                        aria-label="Default select example">
                                                        <option selected>Select role</option>
                                                        <option value="student">Student</option>
                                                        <option value="channelpartner">Channel partner</option>
                                                    </select>
                                                </Form.Group>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-lg col-12'>
                                                <div class="mb-3">
                                                    <label for="exampleFormControlTextarea1" class="form-label">Terms & conditions</label>
                                                    <textarea style={{ height: "300px" }} className="form-control"
                                                        value={utermsAndCondition}
                                                        onChange={(e) => setutermsAndCondition(e.target.value)}
                                                        id="exampleFormControlTextarea1" rows="3"></textarea>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </Form>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" className='fw-bold' style={{ backgroundColor: "#F1BB44", border: "none", color: "black" }} onClick={handleClose}>
                                    Close
                                </Button>
                                <Button variant="primary" onClick={handleupdate} style={{ backgroundColor: "#296013", border: "none" }}>
                                    Save Changes
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                    <Adminnav Toggle={Toggle} />

                    <div className='container-fluid term-main'>
                        <div className='row mt-3 text-start'>
                            <h2>Terms & conditions</h2>
                        </div>

                        <div>
                            <div className='row text-start mt-4 acc-form'>
                                <div className='col-lg-4 col-12'>
                                    <div class="mb-3">
                                        <label for="exampleInputEmail1" class="form-label">Role</label>
                                        <select class="form-select" aria-label="Default select example"
                                            value={userRole}
                                            onChange={(e) => setuserRole(e.target.value)}
                                        >
                                            <option selected>Select role</option>
                                            <option value="student">Student</option>
                                            <option value="channelpartner">Channel partner</option>
                                        </select>
                                    </div>
                                </div>

                            </div>


                            <div className='row text-start mt-1 acc-form'>
                                <div className='col-lg-10 col-12'>
                                    <div class="mb-3">
                                        <label for="exampleFormControlTextarea1" class="form-label">Terms & conditions</label>
                                        <textarea style={{ height: "300px" }} className="form-control"
                                            value={termsAndCondition}
                                            onChange={(e) => settermsAndCondition(e.target.value)}
                                            id="exampleFormControlTextarea1" rows="3"></textarea>
                                    </div>
                                </div>

                            </div>


                        </div>

                        <div className='row mt-3 btn-row'>
                            <div className='col d-flex align-items-start justify-content-start'>
                                <button className='btn btn-primary' onClick={handlesubmit}>Submit</button>
                            </div>
                        </div>

                        <div className='row mt-3'>
                            <div className='col table11'>
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">Sr.</th>
                                            <th scope="col">Role</th>
                                            <th scope="col">Terms & conditions</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {terms ?
                                            terms.map((item, index) => {
                                                return <tr key={item._id}>
                                                    <th scope="row">{index + 1}</th>
                                                    <td>{item.userRole}</td>
                                                    <td>{item.termsAndCondition}</td>
                                                    <td>
                                                        <li className="nav-item dropdown" style={{ listStyle: "none" }}>
                                                            <button className="btn btn-primary dropdown-toggle" style={{ backgroundColor: "#296013", border: "none", height: "30px" }} role="button" data-bs-toggle="dropdown" aria-expanded="false">

                                                            </button>
                                                            <ul className="dropdown-menu">
                                                                <li style={{ cursor: "pointer" }} onClick={() => localdelete(item._id)}><td className="dropdown-item"><i class="fa-solid fa-trash me-2" style={{ color: "#B63A3A" }}></i> Delete</td></li>
                                                                <li style={{ cursor: "pointer" }}
                                                                    onClick={() => handleShow(item._id, item.userRole, item.termsAndCondition)}
                                                                ><td className="dropdown-item"><i className="fa-solid fa-pen-to-square me-2"
                                                                    style={{ color: "#198BEC" }}></i> Update</td></li>
                                                            </ul>
                                                        </li>
                                                    </td>
                                                </tr>
                                            }) : ""

                                        }


                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    )
}

export default Term
