import React, { useEffect } from 'react'
import './Addbank.css'
import { useState } from 'react'
import Adminnav from '../admin nav/Adminnav'
import Sidebar from '../sidebar/Sidebar'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

const Addbank = () => {

    const [toggle, setToggle] = useState(true);
    const token = localStorage.getItem('admintokenglobal')
    const [bankdata, setbankdata] = useState([])
    const navigate = useNavigate();

    const Toggle = () => {
        setToggle(!toggle);
    };

    useEffect(() => {
        getbank()
    }, [])

    const getbank = () => {
        axios.get("https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/bank/GetBankDetail", {
            headers: {
                Authorization: token,
            },
        })
            .then((res) => {
                console.log(res.data)
                if (res.data.message === "Successfully bank account found") {
                    setbankdata(res.data.data)
                } else {
                    navigate("/")
                }

            }).catch((e) => {
                console.log(e)
            })
    }

    const localdelete = (id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then((result) => {
            if (result.isConfirmed) {
                axios.delete(`https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/bank/DeleteBankDetail?id=` + id, {
                    headers: {
                        Authorization: token,
                    },
                }).then((res) => {
                    console.log(res.data)
                    if (res.data.message === "Successfully deleted bank account") {
                        Swal.fire({
                            title: "Deleted!",
                            text: "Successfully deleted bank account",
                            icon: "success"
                        });
                        getbank()
                    }
                }).catch((e) => {
                    console.log(e)
                })

            }
        });

    }

    const [bankName, setbankName] = useState("")
    const [accountTitle, setaccountTitle] = useState("")
    const [accountNumber, setaccountNumber] = useState("")
    const [IBAN, setIBAN] = useState("")

    const handlesubmit = () => {
        if (bankName, accountNumber, accountTitle, IBAN) {
            axios.post("https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/bank/AddBankDetail", { bankName, accountNumber, accountTitle, IBAN }, {
                headers: {
                    Authorization: token,
                },
            }).then((res) => {
                console.log(res.data)
                if (res.data.message === "Successfully account is added") {
                    Swal.fire({
                        title: "Thank you",
                        text: "Successfully account is added",
                        icon: "success"
                    });
                    setIBAN("")
                    setaccountNumber("")
                    setaccountTitle("")
                    setbankName("")
                    getbank()
                }
            }).catch((e) => {
                console.log(e)
                if (e.response.data.message === "User is already exist") {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "User is already exist",
                    });
                } else {
                    console.log(e)
                }

            })
        } else {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Please fill input",
            });
        }
    }

    const handleClose = () => setShow(false);
    const [show, setShow] = useState(false);
    const [ubankName, setubankName] = useState("")
    const [uaccountTitle, setuaccountTitle] = useState("")
    const [uaccountNumber, setuaccountNumber] = useState("")
    const [uIBAN, setuIBAN] = useState("")
    const [ubankid, setubankid] = useState("")

    const handleShow = (id, n, e,num,IBAN) => {
        setubankid(id)
        setubankName(n)
        setuaccountTitle(e)
        // setuaccountNumber(num)
        // setuIBAN(IBAN)
        setShow(true)
    };
    const handleupdate = () => {
        const bankName = ubankName;
        const accountTitle = uaccountTitle; 
        const accountNumber = uaccountNumber; 
        const IBAN = uIBAN; 

        axios.put(`https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/bank/UpdateBankDetail?id=` + ubankid, { bankName,accountNumber,accountTitle,IBAN }, {
            headers: {
              Authorization: token,
            },
          }).then((res) => {
            console.log(res.data)
            if (res.data.message === "Bank data updated successfully") {
              Swal.fire({
                title: "Thank you",
                text: "Bank data updated successfully",
                icon: "success"
              });
              handleClose()
            getbank()
            }
          }).catch((e) => {
            console.log(e)
            if (e.response.data.message === "Account Number already exist") {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Account number already exist",
              });
            }else if(e.response.data.message === "findBank already exist"){
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "IBAN already exist",
                  });
            } else {
              console.log(e)

            }
          })
    }


    return (
        <div className="container-fluid">
            <div className="row">
                {toggle && (
                    <div
                        className="col-12 col-md-3 vh-100 position-fixed"
                        style={{ zIndex: 1000 }}
                    >
                        <Sidebar Toggle={Toggle} />
                    </div>
                )}

                {toggle && <div className="col-12 col-lg-3"></div>}
                <div className="col">
                    <div>
                        <Modal show={show} onHide={handleClose}>
                            <Modal.Header closeButton>
                                <Modal.Title style={{ color: "#296013" }}>Update bank account</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form>
                                    <div className='container-fluid'>
                                        <div>
                                            <div className='row text-start acc-form'>
                                                <div className='col-lg-12'>
                                                    <div className="mb-3">
                                                        <label for="people" className="form-label fw-bold" style={{ color: "gray" }}>Bank name</label>
                                                        <input type="text" className="form-control" id="people"
                                                            value={ubankName} onChange={(e) => setubankName(e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='col-12'>
                                                    <div className="mb-3">
                                                        <label for="people" className="form-label fw-bold" style={{ color: "gray" }}>Account title</label>
                                                        <input type="text" className="form-control" id="people"
                                                            value={uaccountTitle} onChange={(e) => setuaccountTitle(e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='col-12'>
                                                    <div className="mb-3">
                                                        <label for="people" className="form-label fw-bold" style={{ color: "gray" }}>Account number</label>
                                                        <input type="text" className="form-control" id="people"
                                                            value={uaccountNumber} onChange={(e) => setuaccountNumber(e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='row text-start acc-form'>
                                                <div className='col-12'>
                                                    <div className="mb-3">
                                                        <label for="people" className="form-label fw-bold" style={{ color: "gray" }}>IBAN</label>
                                                        <input type="text" className="form-control" id="people"
                                                            value={uIBAN} onChange={(e) => setuIBAN(e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" className='fw-bold' style={{ backgroundColor: "#F1BB44", border: "none", color: "black" }} onClick={handleClose}>
                                    Close
                                </Button>
                                <Button variant="primary" onClick={handleupdate} style={{ backgroundColor: "#296013", border: "none" }}>
                                    Save Changes
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                    <Adminnav Toggle={Toggle} />

                    <div className='container-fluid addbank-main'>
                        <div className='row mt-3 text-start'>
                            <h2>Add bank account</h2>
                        </div>
                        <div>
                            <div className='row text-start mt-4 acc-form'>
                                <div className='col-lg-4 col-12'>
                                    <div className="mb-3">
                                        <label for="people" className="form-label">Bank name</label>
                                        <input type="text" className="form-control" id="people"
                                            value={bankName} onChange={(e) => setbankName(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className='col-lg-4 col-12'>
                                    <div className="mb-3">
                                        <label for="people" className="form-label">Account title</label>
                                        <input type="text" className="form-control" id="people"
                                            value={accountTitle} onChange={(e) => setaccountTitle(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className='col-lg-4 col-12'>
                                    <div className="mb-3">
                                        <label for="people" className="form-label">Account number</label>
                                        <input type="text" className="form-control" id="people"
                                            value={accountNumber} onChange={(e) => setaccountNumber(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className='row text-start acc-form'>
                                <div className='col-lg-4 col-12'>
                                    <div className="mb-3">
                                        <label for="people" className="form-label">IBAN</label>
                                        <input type="text" className="form-control" id="people"
                                            value={IBAN} onChange={(e) => setIBAN(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='row mt-3 btn-row'>
                            <div className='col d-flex align-items-start justify-content-start'>
                                <button className='btn btn-primary' onClick={handlesubmit}>Submit</button>
                            </div>
                        </div>

                        <div className='row mt-3'>
                            <div className='col table11'>
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">Sr.</th>
                                            <th scope="col">Bank name</th>
                                            <th scope="col">Account title</th>
                                            <th scope="col">Account number</th>
                                            <th scope="col">IBAN</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {bankdata ?
                                            bankdata.map((item, index) => {
                                                return <tr key={item._id}>
                                                    <th scope="row">{index + 1}</th>
                                                    <td>{item.bankName}</td>
                                                    <td>{item.accountTitle}</td>
                                                    <td>{item.accountNumber}</td>
                                                    <td>{item.IBAN}</td>
                                                    <td>
                                                        <li className="nav-item dropdown" style={{ listStyle: "none" }}>
                                                            <button className="btn btn-primary dropdown-toggle" style={{ backgroundColor: "#296013", border: "none", height: "30px" }} role="button" data-bs-toggle="dropdown" aria-expanded="false">

                                                            </button>
                                                            <ul className="dropdown-menu">
                                                                <li style={{ cursor: "pointer" }} onClick={() => localdelete(item._id)}><td className="dropdown-item"><i class="fa-solid fa-trash me-2" style={{ color: "#B63A3A" }}></i> Delete</td></li>
                                                                <li style={{ cursor: "pointer" }}
                                                                    onClick={() => handleShow(item._id, item.bankName, item.accountTitle, item.accountNumber, item.IBAN)}
                                                                ><td className="dropdown-item"><i className="fa-solid fa-pen-to-square me-2"
                                                                    style={{ color: "#198BEC" }}></i> Update</td></li>
                                                            </ul>
                                                        </li>
                                                    </td>
                                                </tr>
                                            }) : ""

                                        }


                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    )
}

export default Addbank
