import React, { useEffect } from 'react'
import { useState } from 'react'
import Adminnav from '../admin nav/Adminnav'
import Sidebar from '../sidebar/Sidebar'
import './Profile.css'
import axios from 'axios'
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom'
import Loading from '../../Loading'

const Profile = () => {

    const [toggle, setToggle] = useState(true);
    const [name, setname] = useState("");
    const [email, setemail] = useState("");
    const [password, setpassword] = useState("");
    const navigate = useNavigate();
    const [loadersubmit, setloadersubmit] = useState(false)
    const token = localStorage.getItem('admintokenglobal')
    const Toggle = () => {
        setToggle(!toggle);
    };

    useEffect(() => {
        getadmindata();
    }, [])

    const getadmindata = () => {
        // setloadersubmit(true)
        axios.get("https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/admin/GetSuperuserProfile", {
            headers: {
                Authorization: token,
            },
        }).then((res) => {
            // console.log(res.data)
            if (res.data.message === "Profile found successfully") {
                setname(res.data.data.name)
                setemail(res.data.data.email)
                setpassword(res.data.data.password)
                setloadersubmit(false)
            } else {
                navigate("/")
            }

        }).catch((e) => {
            console.log("profile api error", e)
        })

    }

    const updateprofile = () => {
        axios.put("https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/admin/UpdateSuperuserProfile", { name, email, password }, {
            headers: {
                Authorization: token,
            },
        }).then((res) => {
            console.log(res.data)
            if (res.data.message === "Profile updated successfully") {
                Swal.fire({
                    title: "Thank you",
                    text: "Profile updated successfully",
                    icon: "success"
                });

                getadmindata()
            }
        }).catch((e) => {
            console.log(e)
        })
    }

    if (loadersubmit) {
        return (
            <Loading />
        )
    }

    return (
        <div className="container-fluid">
            <div className="row">
                {toggle && (
                    <div
                        className="col-12 col-md-3 vh-100 position-fixed"
                        style={{ zIndex: 1000 }}
                    >
                        <Sidebar Toggle={Toggle} />
                    </div>
                )}

                {toggle && <div className="col-12 col-lg-3"></div>}
                <div className="col">
                    <Adminnav Toggle={Toggle} />
                    <div className='container-fluid profile-main'>
                        <div className='row mt-3 text-start'>
                            <h2>Profile</h2>
                        </div>

                        <div>
                            <div className='row text-start mt-4 acc-form'>
                                <div className='col-lg-4 col-12'>
                                    <div class="mb-3">
                                        <label for="duration" class="form-label">Name</label>
                                        <input type="text" class="form-control" id="duration" value={name} onChange={(e) => setname(e.target.value)} />
                                    </div>
                                </div>

                            </div>


                            <div className='row text-start mt-1 acc-form'>
                                <div className='col-lg-4 col-12'>
                                    <div class="mb-3">
                                        <label for="people" className="form-label">Email</label>
                                        <input type="email" className="form-control" id="people" value={email} onChange={(e) => setemail(e.target.value)} />
                                    </div>
                                </div>
                            </div>

                            <div className='row text-start acc-form'>
                                <div className='col-lg-4 col-12'>
                                    <div class="mb-3">
                                        <label for="people" className="form-label">Password</label>
                                        <input type="text" className="form-control" id="people" value={password} onChange={(e) => setpassword(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='row mt-3 btn-row'>
                            <div className='col d-flex align-items-start justify-content-start'>
                                <button className='btn btn-primary' onClick={updateprofile}>Update</button>
                            </div>
                        </div>

                    </div>


                </div>
            </div>
        </div>
    )
}

export default Profile
