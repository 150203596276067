import React, { useEffect, useState } from 'react'
// import Ssidebar from '../Sidebar/Ssidebar';
import Agentnav from '../sideandnav/Agentnav';
import Agentside from '../sideandnav/Agentside';
import Swal from 'sweetalert2';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'
import './Businessaddress.css'
import Select from 'react-select';
import { allCountries } from '../../result'


const Businessaddress = () => {
    const [toggle, setToggle] = useState(true);
    const navigate = useNavigate();
    const Toggle = () => {
        setToggle(!toggle);
    };

    const token = localStorage.getItem('agenttoken')

    const [countryname, setcountryname] = useState([]);
    const [filtercity, setfiltercity] = useState([]);

    useEffect(() => {
        getagentdata()
        const typeArrange = allCountries.map((row) => {
            return {
                value: `${row.name}`,
                label: `${row.name}`,
                name: `${row.name}`,
            };
        });
        //   console.warn(typeArrange)
        setcountryname(typeArrange)
    }, [])

    const handlecityfilter = (e) => {
        const value = e;
        setcountry(value)
        const cities = allCountries.find(ctr => ctr.name === value.value).cities
        // console.warn(filtercity)
        const typeArrange = cities.map((row) => {
            return {
                value: `${row}`,
                label: `${row}`,
                name: `${row}`,
            };
        });
        setfiltercity(typeArrange)

    }

    const [country, setcountry] = useState("")
    const [cityu, setcity] = useState("")
    const [province, setprovince] = useState("")
    const [postCode, setpostCode] = useState("")
    const [street, setstreet] = useState("")

    const handlesave = () => {
        const city = cityu.value;
        // console.warn(city, country, province, postCode, region)

        if (country && city && province && postCode && street) {
            axios.post("https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/consultant/ChannelPartnerBusinessAddressInfo", { country: country.value, city, province, postCode,street }, {
                headers: {
                    Authorization: token,
                },
            }).then((res) => {
                console.warn(res.data)
                if (res.data.message === "address information is added") {
                    Swal.fire({
                        title: "Thank you",
                        text: "Business address is added",
                        icon: "success"
                    });

                    getagentdata()
                } else {
                    console.log(res.data)

                }
            }).catch((e) => {
                console.log(e)
            })
        } else {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "please fill input",
            });
        }
    }

    const getagentdata = () => {
        axios.get("https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/consultant/ChannelPartnerProfile", {
            headers: {
                Authorization: token,
            },
        })
            .then((res) => {
                console.log(res.data)
                if (res.data.message === "Channel Parnter profile") {
                    // setstudentdata(res.data.data)
                    // setforeName(res.data.data[0].personalInfo.foreName)
                    const countryvalue = res.data.data.businessAddress.country;
                    const cityvalue = res.data.data.businessAddress.city;
                    console.log({
                        value: countryvalue,
                        label: countryvalue,
                        name: countryvalue,
                    })
                    setcountry(
                        {
                            value: countryvalue,
                            label: countryvalue,
                            name: countryvalue,
                        }
                    )
                    setcity(
                        {
                            value: cityvalue,
                            label: cityvalue,
                            name: cityvalue,
                        }
                    )
                    setprovince(res.data.data.businessAddress.province)
                    setstreet(res.data.data.businessAddress.street)
                    setpostCode(res.data.data.businessAddress.postCode)

                } else {
                    navigate("/")
                }

            }).catch((e) => {
                console.log(e)
            })

        // const ={value: ""}
    }

    return (
        <div className="container-fluid">
            <div className="row">
                {toggle && (
                    <div
                        className="col-12 col-md-3 vh-100 position-fixed"
                        style={{ zIndex: 1000 }}
                    >
                        <Agentside Toggle={Toggle} />
                    </div>
                )}

                {toggle && <div className="col-12 col-lg-3"></div>}
                <div className="col">
                    <Agentnav Toggle={Toggle} />
                    <div className='container-fluid addressAgent-main'>
                        <div className='row mt-3 text-start'>
                            <h2>Business address</h2>
                        </div>
                        <div>
                            <div className='row text-start mt-4 acc-form'>
                                <div className='col-lg-4 col-12'>
                                    <div className="mb-3">
                                        <label for="exampleInputEmail1" className="form-label">Country</label>
                                        <Select
                                            value={country}
                                            onChange={handlecityfilter}
                                            options={countryname}
                                            isSearchable
                                        />
                                    </div>
                                </div>
                                <div className='col-lg-4 col-12'>
                                    <div className="mb-3">
                                        <label for="exampleInputEmail1" className="form-label">City</label>
                                        <Select
                                            value={cityu}
                                            onChange={setcity}
                                            options={filtercity}
                                            isSearchable
                                        />
                                    </div>
                                </div>
                                <div className='col-lg-4 col-12'>
                                    <div className="mb-3">
                                        <label for="people" className="form-label">Province</label>
                                        <input type="text" className="form-control" id="people"
                                            value={province} onChange={(e) => setprovince(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className='row text-start acc-form'>
                                <div className='col-lg-4 col-12'>
                                    <div className="mb-3">
                                        <label for="people" className="form-label">Street</label>
                                        <input type="text" className="form-control" id="people"
                                            value={street} onChange={(e) => setstreet(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className='col-lg-4 col-12'>
                                    <div className="mb-3">
                                        <label for="people" className="form-label">Postal code</label>
                                        <input type="text" className="form-control" id="people"
                                            value={postCode} onChange={(e) => setpostCode(e.target.value)}
                                        />
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className='row mt-3 btn-row'>
                            <div className='col d-flex align-items-start justify-content-start'>
                                <button className='btn btn-primary' onClick={handlesave}>Save</button>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </div>

    )
}

export default Businessaddress
