import React, { useEffect, useState } from 'react'
import './Sign.css'
import Navbar from '../../compunent/Navbar'
import Footer from '../../compunent/Footer'
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import Loading from '../../Loading'

const AdminLogin = () => {

    const [email, setemail] = useState("");
    const [password, setpassword] = useState("");
    const [a, seta] = useState(0);
    const navigate = useNavigate();
    const [loadersubmit, setloadersubmit] = useState(false)

    const token = localStorage.getItem('admintokenglobal')

    useEffect(() => {
        if (token) {
            navigate("/admin-dashboard")

        } else {
            // navigate("/")

        }
    })

    const adminsubmit = (e) => {
        e.preventDefault()
        if (email && password) {
            setloadersubmit(true)
            axios.post("https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/admin/adminsignin", { email, password })
                .then((res) => {
                    console.log(res.data)
                    if (res.data.token) {
                        localStorage.setItem("admintokenglobal", res.data.token)
                        setloadersubmit(false)
                        navigate("/admin-dashboard")
                    }
                }).catch((e) => {
                    console.log("login error", e)
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                    });
                    setloadersubmit(false)

                })
        } else {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "please fill input",
            });
        }
    }


    if (loadersubmit) {
        return (
            <Loading />
        )
    }

    return (
        <div className='sign-main'>
            <Navbar />
            <div className='container py-lg-5 py-4'>
                <div className='row'>
                    <div className='col-lg-6 px-lg-5'>
                        <div className='form-box p-4'>
                            <div className='row text-start'>
                                <h2>Sign in to your account</h2>
                            </div>

                            <div className='row text-start mt-4 mb-2'>
                                <form>
                                    <div className="mb-3">
                                        <label for="exampleInputEmail1" className="form-label">Email address</label>
                                        <input required type="email" className="form-control" id="exampleInputEmail1"
                                            value={email}
                                            onChange={(e) => setemail(e.target.value)}
                                            aria-describedby="emailHelp" />
                                    </div>
                                    <div className="mb-3">
                                        <label for="exampleInputPassword1" className="form-label">Password</label>
                                        <input required type="password" className="form-control"
                                            value={password}
                                            onChange={(e) => setpassword(e.target.value)}
                                            id="exampleInputPassword1" />
                                    </div>
                                    <button className="btn btn-primary mt-3"
                                        onClick={adminsubmit}
                                    >Sign in</button>
                                </form>
                            </div>
                        </div>
                    </div>


                    <div className='col-lg-6 px-lg-5 mt-4 mt-lg-0'>
                        <div className='form-box p-4 text-start'>
                            <div className='row'>
                                <h2>Don't have an account?</h2>
                            </div>
                            <div className='row'>
                                <h4>Register for free today and start your learn & earn journey</h4>
                            </div>
                            <button className="btn btn-primary mt-3">Register for free</button>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default AdminLogin
