import React, { useEffect } from 'react'
import './Sidebar.css'
import { NavLink } from 'react-router-dom'
import logo from '../../assiet/img/learn-and-earn-logofdff.png'

const Sidebar = ({ Toggle }) => {
    // useEffect(() => {
    //     gettotal()
    // })

    const pendingtotal = localStorage.getItem("pendingTotal")
    const confirmedTotal = localStorage.getItem("confirmedTotal")
    const approvalTotal = localStorage.getItem("approvalTotal")
    const detached = localStorage.getItem("detached")


    // const pendingtotal = useSelector((state) => state.pendingOrder);
    // console.warn("hello",pendingtotal)

    return (
        <div className='sidebaraa pt-3'>
            <div className='py-0 my-0 '>
                <div className='container'>
                    <div className='row text-center logo-row' onClick={Toggle}>
                        <img src={logo} className='img-fluid' alt='logo'></img>
                        {/* <h2>Global learn and earn</h2> */}
                    </div>

                    <div className='row text-start mt-4 ps-2 pt-3'>
                        <div className='row row-link'>
                            <NavLink to="/admin-dashboard" className="nav-l"><i className="fa-solid fa-gauge me-2"></i> Dashboard</NavLink>
                        </div>

                        <div className="row mt-2">
                            <span className='accordion-header' id="flush-headingOne" >
                                <button className="accordion-button collapsed row-link" style={{ fontSize: "19px", color: "white" }} type="button"
                                    data-bs-toggle="collapse" data-bs-target="#flush-collapseOne"
                                    aria-expanded="false" aria-controls="flush-collapseOne"
                                >
                                    <i className="fa-solid fa-gear me-3"></i>  Company settings <i class="fa-solid fa-chevron-down ms-2"></i>
                                </button>

                                <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                    <div className="accordion-body ps-lg- mt-2">
                                        <ul>
                                            <li className="nav-item" style={{ color: "#F1BB44" }}>
                                                <NavLink to="/add-bankaccount" className="nav-link" style={{ color: "white" }}>Add bank details</NavLink>
                                            </li>
                                            <li className="nav-item mt-2" style={{ color: "#F1BB44" }}>
                                                <NavLink to="/commission-formula" className="nav-link" style={{ color: "white" }}>Commission formula</NavLink>
                                            </li>

                                        </ul>
                                    </div>
                                </div>
                            </span>
                        </div>

                        <div className="row mt-2">
                            <span className='accordion-header' id="flush-headingTwo" >
                                <button className="accordion-button collapsed row-link" style={{ fontSize: "19px", color: "white" }} type="button"
                                    data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo"
                                    aria-expanded="false" aria-controls="flush-collapseTwo"
                                >
                                    <i className="fa-regular fa-calendar-check me-3"></i>  Bookings <i class="fa-solid fa-chevron-down ms-2"></i>
                                </button>

                                <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                    <div className="accordion-body ps-lg- mt-2">
                                        <ul>
                                            <li className="nav-item" style={{ color: "#F1BB44" }}>
                                                <NavLink to="/bookings" className="nav-link" style={{ color: "white" }}>Pendings ({pendingtotal})</NavLink>
                                            </li>
                                            <li className="nav-item mt-2" style={{ color: "#F1BB44" }}>
                                                <NavLink to="/waiting-for-approval" className="nav-link" style={{ color: "white" }}>Waiting for approvals ({approvalTotal})</NavLink>
                                            </li>
                                            <li className="nav-item mt-2" style={{ color: "#F1BB44" }}>
                                                <NavLink to="/confirmed" className="nav-link" style={{ color: "white" }}>Confirmeds ({confirmedTotal})</NavLink>
                                            </li>
                                            <li className="nav-item mt-2" style={{ color: "#F1BB44" }}>
                                                <NavLink to="/detached" className="nav-link" style={{ color: "white" }}>Detacheds ({detached})</NavLink>
                                            </li>

                                        </ul>
                                    </div>
                                </div>
                            </span>
                        </div>

                        <div className="row mt-2">
                            <span className='accordion-header' id="flush-headingThree" >
                                <button className="accordion-button collapsed row-link" style={{ fontSize: "19px", color: "white" }} type="button"
                                    data-bs-toggle="collapse" data-bs-target="#flush-collapseThree"
                                    aria-expanded="false" aria-controls="flush-collapseThree"
                                >
                                    <i className="fa-solid fa-user-tie me-3"></i>  Agents <i class="fa-solid fa-chevron-down ms-2"></i>
                                </button>

                                <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                    <div className="accordion-body ps-lg- mt-2">
                                        <ul>
                                            <li className="nav-item" style={{ color: "#F1BB44" }}>
                                                <NavLink to="/new-registrants" className="nav-link" style={{ color: "white" }}>New registrants </NavLink>
                                            </li>
                                            <li className="nav-item mt-2" style={{ color: "#F1BB44" }}>
                                                <NavLink to="/all-agents" className="nav-link" style={{ color: "white" }}>All agents</NavLink>
                                            </li>

                                        </ul>
                                    </div>
                                </div>
                            </span>
                        </div>
                        <div className='row row-link mt-2'>
                            <NavLink to="/leads" className="nav-l"><i className="fa-solid fa-list me-3"></i>Leads</NavLink>
                        </div>
                        <div className='row row-link mt-2'>
                            <NavLink to="/admin-bit" className="nav-l"><i className="fa-solid fa-list me-3"></i>Bid</NavLink>
                        </div>
                        <div className='row row-link mt-2'>
                            <NavLink to="/students" className="nav-l"><i className="fa-solid fa-graduation-cap me-2"></i> Students</NavLink>
                        </div>
                        <div className='row row-link mt-2'>
                            <NavLink to="/add-locals" className="nav-l"><i className="fa-solid fa-pen-to-square me-2"></i> Add locals</NavLink>
                        </div>
                        <div className='row row-link mt-2'>
                            <NavLink to="/add-accommodation" className="nav-l"><i className="fa-solid fa-house me-2"></i> Add accommodation</NavLink>
                        </div>
                        <div className='row row-link mt-2'>
                            <NavLink to="/add-rooms" className="nav-l"><i className="fa-solid fa-hotel me-2"></i> Add rooms</NavLink>
                        </div>
                        <div className='row row-link mt-2'>
                            <NavLink to="/allocate-accommodation" className="nav-l"><i className="fa-solid fa-house-chimney-user me-2"></i> Allocate accommodation</NavLink>
                        </div>
                        {/* <div className='row row-link mt-2'>
                            <NavLink to="/add-bankaccount" className="nav-l"><i className="fa-solid fa-building-columns me-2"></i> Add bank account</NavLink>
                        </div> */}
                        <div className='row row-link mt-2'>
                            <NavLink to="/terms-&-conditions" className="nav-l"><i className="fa-solid fa-file-contract me-2"></i> Terms & conditions</NavLink>
                        </div>
                        <div className='row row-link mt-2'>
                            <NavLink to="/profile" className="nav-l"><i className="fa-solid fa-user me-2"></i> Profile</NavLink>
                        </div>
                    </div>

                </div>
            </div>



        </div>
    )
}

export default Sidebar
