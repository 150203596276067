import React from "react";
import "./Content2.css";
import line from "../assiet/img/stuent.jpg";
import lettingagent from "../assiet/img/letting-agent.jpg";
import { useNavigate } from "react-router-dom";

const Content2 = () => {
  const navigate = useNavigate();

  return (
    <div className="Content2-main my-4">
      <div className="container">
        <div className="row mt-4">
          <div className="col-md-6 mt-3 mt-lg-0">
            <div className="rignt-content text-md-start p">
              <div className="row">
                <h3>Great sales partnership opportunity for </h3>
              </div>
              <div className="row">
                <h2>
                  Join Our Team at Global Learn & Earn: Expand Your Reach and
                  Impact in Education!
                </h2>
              </div>
              <div className="row mt-2">
                <p className="">
                  Sign up as a business partner with Global Learn & Earn and
                  collaborate on innovative educational projects! Connect with a
                  global network of students and be part of a thriving community
                  dedicated to shaping brighter futures through education.<br /><span className="fw-bold">
                  Our
                  platform offers unique opportunities for:
                    </span> <br />
                  Educational Collaboration: Engage in partnerships that
                  innovate learning methods and materials.
                  <br />
                  <span className="fw-bold">Global Networking:</span><br /> Connect with educators, institutions, and
                  learners from around the world.
                  <br />
                  <span className="fw-bold">Community Impact:</span><br /> Contribute to meaningful educational
                  projects that make a difference.
                  <br />
                  <span className="fw-bold">Professional Growth:</span><br /> Expand your business and reach in the
                  educational sector.
                </p>
              </div>

              <div className="row">
                <div className="col">
                  <button
                    className="btn btn-primary"
                    onClick={() => navigate("/agent-register")}
                  >
                    Register Now
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6 my-4 my-md-0">
            <img src={line} alt="" className="img-fluid img-1"></img>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Content2;
