import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'
import 'react-tagsinput/react-tagsinput.css'
import './PromoCode.css'
import Navbar from '../../compunent/Navbar';
import Footer from '../../compunent/Footer';

const PromoCode = () => {

    const [toggle, setToggle] = useState(true);
    const navigate = useNavigate();
    const Toggle = () => {
        setToggle(!toggle);
    };

    const token = localStorage.getItem('studenttoken')

    // useEffect(() => {
    //     getstudents()
    // }, [])

    const [promocode, setpromocode] = useState("")


    const handlesave = () => {
        // console.warn(bankName,accountNumber,accountTitle,IBAN)
        if (promocode) {
            axios.post("https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/student/EnterPromocode", { promocode }, {
                headers: {
                    Authorization: token,
                },
            }).then((res) => {
                if (res.data.message === "Successfully promocode added") {
                    Swal.fire({
                        title: "Thank you",
                        text: "Successfully promo code added",
                        icon: "success"
                    });

                    // getstudents()
                } else {
                    console.log(res.data)


                }
            }).catch((e) => {
                console.log(e)
                if (e.response.data.message === "Channel Partner not found or already used") {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Your promo code invalid",
                    });
                } else {
                    console.log(e)

                }
            })
        } else {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "please fill input",
            });
        }
    }
    return (
        <div >
            <Navbar />
            <div className='promo-main'>
                <div className="container promo-box py-4">

                    <div className='row text-start'>
                        <h2>Enter agents promo code</h2>
                    </div>
                    <div>
                        <div className='row text-start mt-4 acc-form'>
                            <div className='col-lg-4 col-12'>
                                <div className="mb-3">
                                    <label for="people" className="form-label">Promo code</label>
                                    <input type="text" className="form-control" id="people"
                                        value={promocode} onChange={(e) => setpromocode(e.target.value)}
                                    />
                                </div>
                            </div>

                        </div>

                    </div>

                    <div className='row mt-3 btn-row'>
                        <div className='col d-flex align-items-start justify-content-start'>
                            <button className='btn btn-primary' onClick={handlesave}>Save</button>
                        </div>
                    </div>



                </div>
            </div>
            <Footer />
        </div>

    )
}

export default PromoCode
