import React, { useEffect, useState } from 'react'
import Adminnav from '../admin nav/Adminnav'
import Sidebar from '../sidebar/Sidebar'
import './Mdash.css'
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis } from 'recharts'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'

const Mdash = () => {

  const [toggle, setToggle] = useState(true);
  const [pendingtotal, setpendingtotal] = useState("");
  const [confirmedtotal, setconfirmedtotal] = useState("");
  const [approvaltotal, setapprovaltotal] = useState("");
  const [propertiestotal, setpropertiestotal] = useState("");
  const navigate = useNavigate();
  const token = localStorage.getItem('admintokenglobal')

  const Toggle = () => {
    setToggle(!toggle);
  };

  useEffect(() => {
    getpending()
    getconfirmed()
    getapproval()
    detached()
    getaccommodation()
  })

  const getpending = () => {
    axios.post("https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/manageaccommodation/ViewServiceRequestPending", {}, {
      headers: {
        Authorization: token,
      },
    })
      .then((res) => {
        // console.log(res.data.total)
        if (res.data.message === "Services are found") {
          // dispatch(pending(res.data))
          setpendingtotal(res.data.total)
          localStorage.setItem("pendingTotal", res.data.total)
        } else {
          navigate("/")
        }

      }).catch((e) => {
        console.log(e)
      })
  }

  const getconfirmed = () => {
    axios.post("https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/manageaccommodation/ViewServiceRequestConfirmed", {}, {
      headers: {
        Authorization: token,
      },
    })
      .then((res) => {
        console.log(res.data)
        if (res.data.message === "Services are found") {
          setconfirmedtotal(res.data.total)
          localStorage.setItem("confirmedTotal", res.data.total)
        } else {
          navigate("/")
        }

      }).catch((e) => {
        console.log(e)
      })
  }

  const getapproval = () => {
    axios.post("https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/manageaccommodation/ViewServiceRequestApproval", {}, {
      headers: {
        Authorization: token,
      },
    })
      .then((res) => {
        console.log(res.data)
        if (res.data.message === "Services are found") {
          setapprovaltotal(res.data.total)
          localStorage.setItem("approvalTotal", res.data.total)

        } else {
          navigate("/")
        }

      }).catch((e) => {
        console.log(e)
      })
  }

  const detached = () => {
    axios.post("https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/manageaccommodation/ViewServiceRequestDetached", {}, {
        headers: {
            Authorization: token,
        },
    })
        .then((res) => {
            console.log(res.data)
            if (res.data.message === "Services are found") {
                // setorderdata(res.data.data)
          localStorage.setItem("detached", res.data.total)

            } else {
                navigate("/")
            }

        }).catch((e) => {
            console.log(e)
        })
}

const getaccommodation = () => {
  axios.post("https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/accommodation/GetAccommodation", {}, {
      headers: {
          Authorization: token,
      },
  })
      .then((res) => {
          // console.log(res.data)
          if (res.data.message === "Accommodation found") {
            setpropertiestotal(res.data.totalNumberOfAccommodation)
          } else {
              navigate("/")
          }

      }).catch((e) => {
          console.log(e)
      })
}

  return (
    <div className="container-fluid">
      <div className="row">
        {toggle && (
          <div
            className="col-12 col-md-3 vh-100 position-fixed"
            style={{ zIndex: 1000 }}
          >
            <Sidebar Toggle={Toggle} />
          </div>
        )}

        {toggle && <div className="col-12 col-lg-3"></div>}
        <div className="col">
          <Adminnav Toggle={Toggle} />
          <div className='container-fluid dash-main'>
            <div className='row mt-lg-5 mt-4'>
              <div className='col-lg col-12 px-4'>
                <div className='card1 py-2'>
                  <div className='row d-flex justify-content-center'>
                    <div className='icon-circle'>
                      <i className="fa-solid fa-users"></i>
                    </div>
                  </div>

                  <div className='row mt-1'>
                    <h3>Total Students</h3>
                  </div>

                  <div className='row mt-1'>
                    <h3>100</h3>
                  </div>
                </div>
              </div>

              <div className='col-lg col-12 px-4 mt-lg-0 mt-3'>
                <div className='card1 py-2'>
                  <div className='row d-flex justify-content-center'>
                    <div className='icon-circle'>
                      <i className="fa-solid fa-users"></i>
                    </div>
                  </div>

                  <div className='row mt-1'>
                    <h3>Total Agents</h3>
                  </div>

                  <div className='row mt-1'>
                    <h3>100</h3>
                  </div>
                </div>
              </div>

              <div className='col-lg col-12 px-4 mt-lg-0 mt-3'>
                <div className='card1 py-2'>
                  <div className='row d-flex justify-content-center'>
                    <div className='icon-circle'>
                      <i className="fa-solid fa-house "></i>
                    </div>
                  </div>

                  <div className='row mt-1'>
                    <h3>Total services</h3>
                  </div>

                  <div className='row mt-1'>
                    <h3>100</h3>
                  </div>
                </div>
              </div>

            </div>

            <div className='row mt-lg-4 mt-4'>
              <div className='col-lg col-12 px-4'>
                <div className='card1 py-2'>
                  <div className='row d-flex justify-content-center'>
                    <div className='icon-circle'>
                      <i className="fa-solid fa-city"></i>
                    </div>
                  </div>

                  <div className='row mt-1'>
                    <h3>Total city</h3>
                  </div>

                  <div className='row mt-1'>
                    <h3>100</h3>
                  </div>
                </div>
              </div>

              <div className='col-lg col-12 px-4 mt-lg-0 mt-3'>
                <div className='card1 py-2'>
                  <div className='row d-flex justify-content-center'>
                    <div className='icon-circle'>
                      <i className="fa-solid fa-house "></i>
                    </div>
                  </div>

                  <div className='row mt-1'>
                    <h3>Total properties</h3>
                  </div>

                  <div className='row mt-1'>
                    <h3>{propertiestotal}</h3>
                  </div>
                </div>
              </div>

              <div className='col-lg col-12 px-4 mt-lg-0 mt-3'>
                <div className='card1 py-2'>
                  <div className='row d-flex justify-content-center'>
                    <div className='icon-circle'>
                      <i className="fa-solid fa-circle-check"></i>
                    </div>
                  </div>

                  <div className='row mt-1'>
                    <h3>Confirmed orders</h3>
                  </div>

                  <div className='row mt-1'>
                    <h3>{confirmedtotal}</h3>
                  </div>
                </div>
              </div>

            </div>

            <div className='row mt-lg-4 mt-4'>
              <div className='col-lg col-12 px-4'>
                <div className='card1 py-2'>
                  <div className='row d-flex justify-content-center'>
                    <div className='icon-circle'>
                      <i className="fa-solid fa-users"></i>
                    </div>
                  </div>

                  <div className='row mt-1'>
                    <h3>Pending orders</h3>
                  </div>

                  <div className='row mt-1'>
                    <h3> {pendingtotal} </h3>
                  </div>
                </div>
              </div>

              <div className='col-lg col-12 px-4 mt-lg-0 mt-3'>
                <div className='card1 py-2'>
                  <div className='row d-flex justify-content-center'>
                    <div className='icon-circle'>
                      <i className="fa-solid fa-users"></i>
                    </div>
                  </div>

                  <div className='row mt-1'>
                    <h3>Total revenue</h3>
                  </div>

                  <div className='row mt-1'>
                    <h3>100</h3>
                  </div>
                </div>
              </div>

              <div className='col-lg col-12 px-4 mt-lg-0 mt-3'>
                <div className='card1 py-2'>
                  <div className='row d-flex justify-content-center'>
                    <div className='icon-circle'>
                      <i className="fa-solid fa-house "></i>
                    </div>
                  </div>

                  <div className='row mt-1'>
                    <h3>Total commission</h3>
                  </div>

                  <div className='row mt-1'>
                    <h3>100</h3>
                  </div>
                </div>
              </div>

            </div>


            {/* <div className='row mt-5 chart-row pe-lg-5'>
              <div className='col pe-lg-5 mt-lg-4'>
                <ResponsiveContainer width={"100%"} aspect={3}>
                  <BarChart data={Arry} width={500} height={500} margin={{ top: 5, bottom: 5 }}>
                    <XAxis dataKey={"name"} />
                    <YAxis />
                    <Bar dataKey={"student"} fill='#F1BB44' />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Mdash
