
import React from 'react'
import './Acmodation.css'
import Navbar from '../../compunent/Navbar'
import Footer from '../../compunent/Footer'

const Acmodation = () => {
    return (
        <div className='aco-main'>
            <Navbar />
            <div className='banner-a'>
                {/* <div className='container'>
                    <div className='row'>
                        <div className='col'>
                            <h2>Book your stay with learn and earn </h2>
                        </div>
                    </div>
                </div> */}
            </div>

            <div className='container my-4'>
                <div className='row'>
                    <div className='col-lg-7 form-a'>
                        <div className='row row-g text-lg-start'>
                            <h3>Services</h3>
                        </div>

                        <div className='row form-h2 text-lg-start'>
                            <h2>Accommodation</h2>
                        </div>

                        <div className='row text-lg-start mt-2'>
                            <p className='fw-bold'>Welcome to London - Your Home Away from Home!</p>
                            <p>
                                Studying in London is an exciting adventure, and finding the perfect accommodation
                                is a crucial part of your journey. We understand that a
                                comfortable and secure living space is essential for a successful academic experience.
                            </p>
                            <p>
                                <p className='fw-bold' >Explore Your Options:</p>
                                Global learn & Earn offers a diverse range of accommodation options to suit every student's preferences and budget.
                                From modern student residences to shared flats and private apartments, there's something for everyone. Take the
                                time to explore your
                                options and find the accommodation that best fits your needs.
                            </p>
                            <p>
                                <p className='fw-bold' >Private Rentals:</p>
                                If you prefer more independence, you can explore private rentals. We can
                                 provide you different opportunities , including shared flats and studios.
                                  Ensure you start your search
                                 well in advance and carefully review terms and conditions.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default Acmodation
