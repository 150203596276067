
import React from 'react'
import './Job.css'
import Navbar from '../../compunent/Navbar'
import Footer from '../../compunent/Footer'

const Jobfinding = () => {
    return (
        <div className='job-main'>
            <Navbar />
            <div className='banner-a'>
                {/* <div className='container'>
            <div className='row'>
                <div className='col'>
                    <h2>Book your stay with learn and earn </h2>
                </div>
            </div>
        </div> */}
            </div>

            <div className='container my-4'>
                <div className='row'>
                    <div className='col-lg-7 form-a'>
                        <div className='row row-g text-lg-start'>
                            <h3>Services</h3>
                        </div>

                        <div className='row form-h2 text-lg-start'>
                            <h2>Job Finding</h2>
                        </div>

                        <div className='row text-lg-start mt-2'>
                            <p className='fw-bold'>	Job Finding Services:</p>
                            <p>
                                Introducing our esteemed web portal, dedicated to providing exemplary professional consultancy services for job seekers both within and outside the UK. In an era where fraudulent practices and unjustified charges plague the job-seeking landscape, our platform stands as a beacon of trust and reliability.
                                We understand the complexities and challenges faced by individuals seeking skilled job opportunities abroad and within the UK. With our unwavering commitment to integrity, we ensure that all our services are tailored to meet your specific needs, without any hidden agendas.
                                For those aspiring to find employment beyond the UK borders, our expert consultants will guide you through the entire process, offering up-to-date and accurate information. Rest assured, we are fully equipped to facilitate the arrangement of skilled job opportunities outside the UK in a transparent and ethical manner.
                            </p>


                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Jobfinding
