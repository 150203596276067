import React, { useEffect } from 'react'
import { useState } from 'react'
import './AddADT.css'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { allCountries } from '../../result'
import Select from 'react-select';
import Loading from '../../Loading'
import LettingSidebar from '../Sidebar And Navbar/LettingSidebar'
import LettingNav from '../Sidebar And Navbar/LettingNav'

const AddADT = () => {

    const [toggle, setToggle] = useState(true);
    const token = localStorage.getItem('lettingtoken')
    const [ProgramTypes, setProgramTypes] = useState([])
    const [loadersubmit, setloadersubmit] = useState(false)

    const navigate = useNavigate();
    const Toggle = () => {
        setToggle(!toggle);
    };

    // accommodation post state
    const [searchaccommodationCity, setaccommodationCity] = useState("");
    const [accommodationAddress, setaccommodationAddress] = useState("");
    const [postCode, setpostCode] = useState("");
    const [locationUrl, setlocationUrl] = useState("");
    const [numberOfLivings, setnumberOfLivings] = useState();
    const [description, setdescription] = useState("");
    const [accommodation, setaccommodation] = useState([]);
    const [accommodationImages, setaccommodationImages] = useState([]);

    useEffect(() => {
        getaccommodation()
        const typeArrange = allCountries[145].cities.map((row) => {
            return {
                value: `${row}`,
                label: `${row}`,
            };
        });
        setProgramTypes(typeArrange);
        console.warn(searchaccommodationCity)

    }, [])

    const getaccommodation = () => {
        setloadersubmit(false)
        axios.post("https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/lettingaccommodation/GetMyAccommodation", {}, {
            headers: {
                Authorization: token,
            },
        })
            .then((res) => {
                console.log(res.data)
                if (res.data.message === "Accommodation found") {
                    setaccommodation(res.data.data)
                    setloadersubmit(false)
                } else {
                    navigate("/")
                }
            }).catch((e) => {
                console.log(e)
            })
    }

    const postaccomodation = () => {
        console.log(searchaccommodationCity)
        const accommodationCity = searchaccommodationCity.value;
        // if(accommodationCity){
        if (accommodationAddress && accommodationImages && description && postCode && locationUrl && numberOfLivings) {
            setloadersubmit(true)


            const adddata = new FormData();
            adddata.append("accommodationAddress", accommodationAddress);
            adddata.append("accommodationCity", accommodationCity);
            adddata.append("locationUrl", locationUrl);
            adddata.append("numberOfLivings", numberOfLivings);
            adddata.append("postCode", postCode);
            adddata.append("description", description);
            for (let i = 0; i < accommodationImages.length; i++) {
                adddata.append("lettingAccommodationImages", accommodationImages[i]);
            }
            console.log(...adddata)
            axios.post("https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/lettingaccommodation/AddAccommodation", adddata, {
                headers: {
                    Authorization: token,
                },
            }).then((res) => {
                console.log(res.data)
                if (res.data.message === "Accommodation successfully added") {
                    Swal.fire({
                        title: "Thank you",
                        text: "Accommodation successfully added",
                        icon: "success"
                    });
                    setaccommodationAddress("")
                    setaccommodationCity("")
                    setdescription("")
                    setlocationUrl("")
                    setnumberOfLivings("")
                    setpostCode("")
                    getaccommodation()
                    setloadersubmit(false)

                }
            }).catch((e) => {
                console.log(e)
                if (e.response.data.message === "This postCode already added in past") {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "This postal code already exist",
                    });
                    setloadersubmit(false)
                } else {
                    console.log(e)
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: e.response.data.message,
                    });
                    setloadersubmit(false)
                }
            })
        } else {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Please fill input",
            });
        }
        // }
    }

    const accommodationdelete = (id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then((result) => {
            if (result.isConfirmed) {
                axios.delete(`https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/accommodation/DeleteAccommodation?id=` + id, {
                    headers: {
                        Authorization: token,
                    },
                }).then((res) => {
                    console.log(res.data)
                    if (res.data.message === "Accommodation Deleted") {
                        Swal.fire({
                            title: "Deleted!",
                            text: "Accommodation Deleted",
                            icon: "success"
                        });
                        getaccommodation()
                    }
                }).catch((e) => {
                    console.log(e)
                })

            }
        });

    }

    // update locals 
    const [uaccommodationCity, setuaccommodationCity] = useState("");
    const [uaccommodationAddress, setuaccommodationAddress] = useState("");
    const [upostCode, setupostCode] = useState("");
    const [ulocationUrl, setulocationUrl] = useState("");
    const [unumberOfLivings, setunumberOfLivings] = useState("");
    const [udescription, setudescription] = useState("");
    const [uaccommodationImages, setuaccommodationImages] = useState([]);
    const [Uid, setUid] = useState("");
    const [viewImages, setviewImages] = useState([]);

    const handleClose = () => setShow(false);
    const [show, setShow] = useState(false);

    const handleShow = (id, n, e, p, c, ad, co,) => {
        // console.log(n, e)
        setUid(id)
        setuaccommodationCity(
            {
                value: n,
                label: n,
            }
        )
        setuaccommodationAddress(e)
        // setupostCode(p)
        setunumberOfLivings(c)
        setudescription(ad)
        setulocationUrl(co)
        setShow(true)
    };

    const handleupdate = () => {
        setloadersubmit(true)
        const formdata = new FormData();
        formdata.append("accommodationAddress", uaccommodationAddress);
        formdata.append("accommodationCity", uaccommodationCity.value);
        formdata.append("locationUrl", ulocationUrl);
        formdata.append("numberOfLivings", unumberOfLivings);
        formdata.append("postCode", upostCode);
        formdata.append("description", udescription);
        for (let i = 0; i < uaccommodationImages.length; i++) {
            formdata.append("accommodationImages", uaccommodationImages[i]);
        }
        axios.put(`https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/accommodation/UpdateAccommodation?id=` + Uid, formdata, {
            headers: {
                Authorization: token,
            },
        }).then((res) => {
            console.log(res.data)
            if (res.data.message === "Accommodation successfully updated") {
                Swal.fire({
                    title: "Thank you",
                    text: "Accommodation successfully updated",
                    icon: "success"
                });
                getaccommodation()
                handleClose()
                setloadersubmit(false)
            }
        }).catch((e) => {
            console.log(e)
            if (e.response.data.message === "PostCode already exist or added in past") {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "This postal code already exist",
                });
                setloadersubmit(false)
            } else if (e.response.data.message === "NI already exist") {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "NI already exist",
                });
                setloadersubmit(false)
            } else {
                console.log(e)
                setloadersubmit(false)
            }
        })

    }

    const [lgShow, setLgShow] = useState(false);

    const handleimg = (data) => {
        console.log(data)
        setviewImages(data)
        setLgShow(true)
    }

    if (loadersubmit) {
        return (
            <Loading />
        )
    }

    return (
        <div className="container-fluid">
            <div className="row">
                {toggle && (
                    <div
                        className="col-12 col-md-3 vh-100 position-fixed"
                        style={{ zIndex: 1000 }}
                    >
                        <LettingSidebar Toggle={Toggle} />
                    </div>
                )}

                {toggle && <div className="col-12 col-lg-3"></div>}
                <div className="col">
                    <LettingNav Toggle={Toggle} />

                    <div>
                        <Modal
                            size="lg"
                            show={lgShow}
                            onHide={() => setLgShow(false)}
                            aria-labelledby="example-modal-sizes-title-lg"
                        >
                            <Modal.Header closeButton>
                                <Modal.Title id="example-modal-sizes-title-lg">
                                    Accommodation images
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body style={{ height: "550px", overflow: "hidden", overflowY: "scroll", padding: "10px" }}>
                                <div className='container-fluid'>
                                    {
                                        viewImages.map((item, index) => {
                                            return <div className='row mt-3'>
                                                <div className='col'>
                                                    <img src={`https://globallearnandearnapi.doctorsforhealth.co.uk/` + item} alt='img' className='img-fluid'></img>

                                                </div>
                                            </div>
                                        })
                                    }

                                </div>
                            </Modal.Body>
                        </Modal>
                    </div>

                    <div>
                        <Modal show={show} onHide={handleClose}>
                            <Modal.Header closeButton>
                                <Modal.Title style={{ color: "#296013" }}>Update accommodation</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form>
                                    <div className='container-fluid'>
                                        <div className='row'>
                                            <div className='col-lg col-12'>
                                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                    <Form.Label className='fw-bold' style={{ color: "gray" }}>City</Form.Label>
                                                    <Select
                                                        value={uaccommodationCity}
                                                        isSearchable
                                                        onChange={setuaccommodationCity}
                                                        options={ProgramTypes}
                                                    />
                                                </Form.Group>
                                            </div>
                                            <div className='col'>
                                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                    <Form.Label className='fw-bold' style={{ color: "gray" }}>Address</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        autoFocus
                                                        value={uaccommodationAddress}
                                                        onChange={(e) => setuaccommodationAddress(e.target.value)}
                                                    />
                                                </Form.Group>
                                            </div>
                                        </div>

                                        <div className='row'>
                                            <div className='col-lg col-12'>
                                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                    <Form.Label className='fw-bold' style={{ color: "gray" }}>Postal code</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        autoFocus
                                                        value={upostCode}
                                                        onChange={(e) => setupostCode(e.target.value)}
                                                    />
                                                </Form.Group>
                                            </div>
                                            <div className='col'>
                                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                    <Form.Label className='fw-bold' style={{ color: "gray" }}>Number of rooms</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        autoFocus
                                                        value={unumberOfLivings}
                                                        onChange={(e) => setunumberOfLivings(e.target.value)}
                                                    />

                                                </Form.Group>
                                            </div>

                                        </div>

                                        <div className='row'>
                                            <div className='col-lg col-12'>
                                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                    <Form.Label className='fw-bold' style={{ color: "gray" }}>Location url</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        autoFocus
                                                        value={ulocationUrl}
                                                        onChange={(e) => setulocationUrl(e.target.value)}
                                                    />
                                                </Form.Group>
                                            </div>
                                            <div className='col'>
                                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                    <Form.Label className='fw-bold' style={{ color: "gray" }}>Select image </Form.Label>
                                                    <input multiple type="file" className="form-control" id="exampleInputEmail1"
                                                        onChange={(e) => setuaccommodationImages(e.target.files)}
                                                        aria-describedby="emailHelp" />
                                                </Form.Group>
                                            </div>

                                        </div>

                                        <div className='row'>
                                            <div className='col-lg col-12'>
                                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                    <Form.Label className='fw-bold' style={{ color: "gray" }}>Description </Form.Label>
                                                    <textarea className="form-control" id="exampleFormControlTextarea1"
                                                        value={udescription}
                                                        onChange={(e) => setudescription(e.target.value)}
                                                        rows="3"></textarea>
                                                </Form.Group>
                                            </div>

                                        </div>
                                    </div>
                                </Form>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" className='fw-bold' style={{ backgroundColor: "#F1BB44", border: "none", color: "black" }} onClick={handleClose}>
                                    Close
                                </Button>
                                <Button variant="primary" onClick={handleupdate} style={{ backgroundColor: "#296013", border: "none" }}>
                                    Save Changes
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </div>

                    <div className='container-fluid accommodation-main'>
                        <div className='row mt-3 text-start'>
                            <h2>Add accommodation</h2>
                        </div>

                        <div>
                            <div className='row text-start mt-4 acc-form'>
                                <div className='col-lg-4 col-12'>
                                    <div className="mb-3">
                                        <label for="exampleInputEmail1" className="form-label">City <i className="fa-solid fa-star-of-life " style={{ fontSize: "9px", color: "red" }}></i></label>
                                        <Select
                                            value={searchaccommodationCity}
                                            isSearchable
                                            onChange={setaccommodationCity}
                                            options={ProgramTypes}
                                        />
                                    </div>
                                </div>
                                <div className='col-lg-4 col-12'>
                                    <div className="mb-3">
                                        <label for="exampleInputEmail1" className="form-label">Address <i className="fa-solid fa-star-of-life " style={{ fontSize: "9px", color: "red" }}></i></label>
                                        <input type="email" className="form-control" id="exampleInputEmail1"
                                            value={accommodationAddress}
                                            onChange={(e) => setaccommodationAddress(e.target.value)}
                                            aria-describedby="emailHelp" />
                                    </div>
                                </div>
                                <div className='col-lg-4 col-12'>
                                    <div className="mb-3">
                                        <label for="exampleInputEmail1" className="form-label">Postal code <i className="fa-solid fa-star-of-life " style={{ fontSize: "9px", color: "red" }}></i></label>
                                        <input type="text" className="form-control" id="exampleInputEmail1"
                                            value={postCode}
                                            onChange={(e) => setpostCode(e.target.value)}
                                            aria-describedby="emailHelp" />
                                    </div>
                                </div>
                            </div>


                            <div className='row text-start mt-1 acc-form'>
                                <div className='col-lg-4 col-12'>
                                    <div className="mb-3">
                                        <label for="exampleInputEmail1" className="form-label">Number of rooms<i className="fa-solid fa-star-of-life " style={{ fontSize: "9px", color: "red" }}></i></label>
                                        <input type="number" className="form-control" id="exampleInputEmail1"
                                            value={numberOfLivings}
                                            onChange={(e) => setnumberOfLivings(e.target.value)}
                                            aria-describedby="emailHelp" />
                                    </div>
                                </div>
                                <div className='col-lg-4 col-12'>
                                    <div className="mb-3">
                                        <label for="exampleInputEmail1" className="form-label">Location url <i className="fa-solid fa-star-of-life " style={{ fontSize: "9px", color: "red" }}></i></label>
                                        <input type="text" className="form-control" id="exampleInputEmail1"
                                            value={locationUrl}
                                            onChange={(e) => setlocationUrl(e.target.value)}
                                            aria-describedby="emailHelp" />
                                    </div>
                                </div>
                                <div className='col-lg-4 col-12'>
                                    <div className="mb-3">
                                        <label for="exampleFormControlTextarea1" className="form-label">Description <i className="fa-solid fa-star-of-life " style={{ fontSize: "9px", color: "red" }}></i></label>
                                        <textarea className="form-control" id="exampleFormControlTextarea1"
                                            value={description}
                                            onChange={(e) => setdescription(e.target.value)}
                                            rows="3"></textarea>
                                    </div>
                                </div>
                            </div>

                            <div className='row text-start acc-form'>
                                <div className='col-lg-4 col-12'>
                                    <div className="mb-3">
                                        <label for="exampleInputEmail1" className="form-label">Select image <i className="fa-solid fa-star-of-life " style={{ fontSize: "9px", color: "red" }}></i></label>
                                        <input multiple type="file" className="form-control" id="exampleInputEmail1"
                                            onChange={(e) => setaccommodationImages(e.target.files)}
                                            aria-describedby="emailHelp" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='row mt-3 btn-row'>
                            <div className='col d-flex align-items-start justify-content-start'>
                                <button className='btn btn-primary' onClick={postaccomodation}>Submit</button>
                            </div>
                        </div>

                        <div className='row mt-3'>
                            <div className='col table11'>
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">Sr.</th>
                                            <th scope="col">City</th>
                                            <th scope="col">Address</th>
                                            <th scope="col">Postal code</th>
                                            <th scope="col">Number of rooms</th>
                                            <th scope="col">Description</th>
                                            <th scope="col">Location url</th>
                                            <th scope="col">View images</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            accommodation.map((item, index) => {
                                                return <tr key={item._id}>
                                                    <th scope="row">{index + 1}</th>
                                                    <td>{item.accommodationCity}</td>
                                                    <td>{item.accommodationAddress}</td>
                                                    <td>{item.postCode}</td>
                                                    <td>{item.numberOfLivings}</td>
                                                    <td>{item.description}</td>
                                                    <td>{item.locationUrl}</td>
                                                    <td style={{ cursor: "pointer" }} onClick={() => handleimg(item.accommodationImages)}><i className="fa-solid fa-eye"></i></td>
                                                    <td>
                                                        <li className="nav-item dropdown" style={{ listStyle: "none" }}>
                                                            <button className="btn btn-primary dropdown-toggle" style={{ backgroundColor: "#296013", border: "none", height: "30px" }} role="button" data-bs-toggle="dropdown" aria-expanded="false">

                                                            </button>
                                                            <ul className="dropdown-menu">
                                                                <li style={{ cursor: "pointer" }} onClick={() => accommodationdelete(item._id)}><td className="dropdown-item"><i className="fa-solid fa-trash me-2" style={{ color: "#B63A3A" }}></i> Delete</td></li>
                                                                <li style={{ cursor: "pointer" }}
                                                                    onClick={() => handleShow(item._id, item.accommodationCity, item.accommodationAddress, item.postCode, item.numberOfLivings, item.description, item.locationUrl)}
                                                                ><td className="dropdown-item"><i className="fa-solid fa-pen-to-square me-2"
                                                                    style={{ color: "#198BEC" }}></i> Update</td></li>
                                                            </ul>
                                                        </li>
                                                    </td>
                                                </tr>
                                            })

                                        }


                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    )
}

export default AddADT
