import React, { useEffect, useState } from 'react'
import Ssidebar from '../Sidebar/Ssidebar';
import Studentnav from '../Sidebar/Studentnav';
import Swal from 'sweetalert2';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'
import 'react-tagsinput/react-tagsinput.css'
import './Viewdetail.css'
import { useParams } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Navbar from '../../compunent/Navbar';
import Footer from '../../compunent/Footer';


const Viewdetail = () => {
    const [toggle, setToggle] = useState(true);
    const params = useParams();
    const navigate = useNavigate();
    const Toggle = () => {
        setToggle(!toggle);
    };

    const token = localStorage.getItem('studenttoken')
    const [roomdata, setroomdata] = useState([])
    const [accommodationimg, setaccommodationimg] = useState([])

    useEffect(() => {
        getaccommodation();
        console.warn(params.id)
    }, [])

    const getaccommodation = () => {
        const postCode = params.id;
        axios.post("https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/accommodation/GetAccommodation", { postCode }, {
            headers: {
                Authorization: token,
            },
        })
            .then((res) => {
                console.log(res.data.data[0].livingDetails)
                // setfiltercity(res.data.data.accommodationCity)
                if (res.data.message === "Accommodation found") {
                    // setaccommodationdata(res.data.data[0])
                    setaccommodationimg(res.data.data[0].accommodationImages)
                    // setfiltercity(res.data.data)
                    setroomdata(res.data.data[0].livingDetails)
                } else {
                    navigate("/")
                }

            }).catch((e) => {
                console.log(e)
            })
    }

    const [livingId, setlivingId] = useState("");
    const [accommodationType, setaccommodationType] = useState("");
    const [checkInDate, setcheckInDate] = useState();
    const [checkOutDate, setcheckOutDate] = useState();
    const [duration, setduration] = useState(0);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = (id) => {
        // console.warn(id)
        setlivingId(id)
        setShow(true)
    };

    const booknow = () => {

        if (accommodationType && checkInDate && checkOutDate && duration) {

            axios.post("https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/cart/AddToCart", { livingId, accommodationType, checkInDate, checkOutDate, duration }, {
                headers: {
                    Authorization: token,
                },
            })
                .then((res) => {
                    console.log(res.data)

                    if (res.data.message === "Successfully added to cart but There is no agreement in place to reserve this. ") {
                        Swal.fire({
                            title: "Thank you",
                            text: "Successfully added to your cart",
                            icon: "success"
                        });
                        handleClose()
                        getaccommodation()
                        setaccommodationType("")
                        setcheckInDate("")
                        setcheckOutDate("")
                        setlivingId("")
                        setduration("")
                    }

                }).catch((e) => {
                    if (e.response.data.message === "You can't add it into cart due to Houseful") {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "House full",
                        });
                    } else {
                        console.log(e)

                    }
                })

        } else {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "please fill input",
            });
        }

    }


    return (

        <div>
            <Navbar/>
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div>
                            <Modal show={show} onHide={handleClose}>
                                <Modal.Header closeButton>
                                    <Modal.Title style={{ color: "#296013" }}>Add to my order</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Form>
                                        <div className='container-fluid'>
                                            <div>
                                                <div className='row text-start acc-form'>
                                                    <div className='col-lg-12'>
                                                        <div className="mb-3">
                                                            <label for="exampleInputEmail1" className="form-label fw-bold" style={{ color: "gray" }}>Accommodation type</label>
                                                            <select className="form-select"
                                                                value={accommodationType}
                                                                onChange={(e) => setaccommodationType(e.target.value)}
                                                                aria-label="Default select example">
                                                                <option selected>Select type</option>
                                                                <option value="share">Shared</option>
                                                                <option value="independent">Independent</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className='col-12'>
                                                        <div className="mb-3">
                                                            <label for="people" className="form-label fw-bold" style={{ color: "gray" }}>Check in date</label>
                                                            <input type="date" className="form-control" id="people"
                                                                value={checkInDate} onChange={(e) => setcheckInDate(e.target.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='col-12'>
                                                        <div className="mb-3">
                                                            <label for="people" className="form-label fw-bold" style={{ color: "gray" }}>Check out date</label>
                                                            <input type="date" className="form-control" id="people"
                                                                value={checkOutDate} onChange={(e) => setcheckOutDate(e.target.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='row text-start acc-form'>
                                                    <div className='col-12'>
                                                        <div className="mb-3">
                                                            <label for="people" className="form-label fw-bold" style={{ color: "gray" }}>Duration (in months)</label>
                                                            <input type="number" className="form-control" id="people" placeholder='month'
                                                                onChange={(e) => setduration(e.target.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Form>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" className='fw-bold' style={{ backgroundColor: "#F1BB44", border: "none", color: "black" }} onClick={handleClose}>
                                        Close
                                    </Button>
                                    <Button variant="primary" onClick={booknow} style={{ backgroundColor: "#296013", border: "none" }}>
                                        Save Changes
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </div>

                        <div className='container-fluid detail-main'>
                            <div className='row mt-3 text-start'>
                                <h2>Accommodation details</h2>
                            </div>


                            <div className='row mt-4 acco-img'>
                                {
                                    accommodationimg ?

                                        accommodationimg.map((item) => {
                                            return <div className='col-lg-4 mb-3' key={item._id}>
                                                <img src={`https://globallearnandearnapi.doctorsforhealth.co.uk/` + item} alt='' className='img-fluid'></img>
                                            </div>
                                        }) : ""
                                }

                            </div>

                            <div className='row mt-3 text-start'>
                                <h2 style={{ fontSize: "1.7rem" }} >Choose your room</h2>
                            </div>

                            <div className='row mt-3 room-detail mb-4'>
                                {
                                    roomdata.map((item) => {
                                        return <div className='col-lg-4 mb-3'>
                                            <div className='room-cart'>
                                                <div className='row'>
                                                    <img src={`https://globallearnandearnapi.doctorsforhealth.co.uk/` + item.livingImages[0]} alt='' className='img-fluid'></img>
                                                </div>
                                                <div className='row mt-2 text-start px-3'>
                                                    <h4>{item.roomNumber}</h4>
                                                </div>
                                                <div className='row px-3'>
                                                    <div className='col text-start'>
                                                        <h5>Room capacity</h5>
                                                    </div>
                                                    <div className='col text-end'>
                                                        <h5 className='fw-bold' style={{ fontSize: "1.2rem" }}>{item.livingCapacity}</h5>
                                                    </div>
                                                </div>
                                                {/* <div className='row px-3'>
                                                <div className='col text-start'>
                                                    <h5>Room availability</h5>
                                                </div>
                                                <div className='col text-end'>
                                                    <h5 className='fw-bold' style={{ fontSize: "1.2rem" }}>{item.livingAvailability}</h5>
                                                </div>
                                            </div> */}

                                                {/* <div className='row px-3'>
                                                <div className='col text-start'>
                                                    <h5>Shared short stay price</h5>
                                                </div>
                                                <div className='col text-end'>
                                                    <h5 className='fw-bold' style={{fontSize: "rem"}}>{item.livingShareShortStayPrice}</h5>
                                                </div>
                                            </div> */}

                                                <div className='row px-3'>
                                                    <div className='col text-start'>
                                                        <h5>Shared stay price</h5>
                                                    </div>
                                                    <div className='col text-end'>
                                                        <h5 className='fw-bold' style={{ fontSize: "1.4rem" }}>{item.livingShareLongStayPrice}</h5>
                                                    </div>
                                                </div>


                                                <div className='row px-3'>
                                                    <div className='col text-start'>
                                                        <h5>Independent stay price</h5>
                                                    </div>
                                                    <div className='col text-end'>
                                                        <h5 className='fw-bold' style={{ fontSize: "1.4rem" }}>{item.livingIndLongStayPrice}</h5>
                                                    </div>
                                                </div>

                                                <div className='row my-3 px-3'>
                                                    <div className='col-lg-5 d-flex align-items-center'>
                                                        <button className='btn btn-primary' onClick={() => handleShow(item._id)}>Book now</button>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    })
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer/>
        </div>
    )
}

export default Viewdetail
