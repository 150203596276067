import React, { useEffect, useState } from 'react'
import Ssidebar from '../Sidebar/Ssidebar';
import Studentnav from '../Sidebar/Studentnav';
import Swal from 'sweetalert2';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'
import Select from 'react-select';
import { allCountries } from '../../result'
import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css'
import './Academic.css'
import Navbar from '../../compunent/Navbar';


const Academic = () => {

    const [toggle, setToggle] = useState(true);
    const navigate = useNavigate();
    const Toggle = () => {
        setToggle(!toggle);
    };

    const token = localStorage.getItem('studenttoken')
    const [countryname, setcountryname] = useState([]);

    useEffect(() => {
        getstudents()
        const typeArrange = allCountries.map((row) => {
            return {
                value: `${row.name}`,
                label: `${row.name}`,
                name: `${row.name}`,
            };
        });
        //   console.warn(typeArrange)
        setcountryname(typeArrange)
    }, [])

    const formatDate = (mongoDBDate) => {
        const date = new Date(mongoDBDate);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        console.warn(`${day}/${month}/${year}`)


        return `${year}-${month}-${day}`;
    };

    const [universityApplying, setuniversityApplying] = useState([])
    const [lastQualification, setlastQualification] = useState("")
    const [lastSubject, setlastSubject] = useState("")
    const [ucountryApplying, setcountryApplying] = useState("")
    const [englishTest, setenglishTest] = useState("")
    const [score, setscore] = useState("")
    const [ulastEducationCountry, setlastEducationCountry] = useState("")
    const [caseCreationDate, setcaseCreationDate] = useState("")


    const handlesave = () => {
        const countryApplying = ucountryApplying.value;
        const lastEducationCountry = ulastEducationCountry.value;
        console.warn(countryApplying,lastEducationCountry,universityApplying,lastQualification,englishTest,score,caseCreationDate,lastSubject)
        if (countryApplying && lastEducationCountry && universityApplying && lastQualification && englishTest && score && caseCreationDate && lastSubject) {
        axios.post("https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/student/StudentAcademicInfo", { countryApplying,lastEducationCountry,universityApplying,lastQualification,englishTest,score,caseCreationDate,lastSubject }, {
            headers: {
                Authorization: token,
            },
        }).then((res) => {
            if (res.data.message === "academic information is added") {
                Swal.fire({
                    title: "Thank you",
                    text: "Academic information is added",
                    icon: "success"
                });

                getstudents()
            } else {
                console.log(res.data)

            }
        }).catch((e) => {
            console.log(e)
        })
        } else {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "please fill input",
            });
        }
    }

    const getstudents = () => {
        axios.get("https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/student/StudentProfile", {
            headers: {
                Authorization: token,
            },
        })
            .then((res) => {
                console.log(res.data.data)
                if (res.data.message === "Student profile") {
                    // setstudentdata(res.data.data)
                    const countryvalue = res.data.data[0].academicInfo.countryApplying;
                    const lastvalue = res.data.data[0].academicInfo.academicBackground.lastEducationCountry;

                    setuniversityApplying(res.data.data[0].academicInfo.universityApplying)
                    setlastQualification(res.data.data[0].academicInfo.academicBackground.lastQualification)
                    setlastSubject(res.data.data[0].academicInfo.academicBackground.lastSubject)
                    setlastEducationCountry(
                        {
                            value: lastvalue,
                            label: lastvalue,
                            name: lastvalue,
                        }
                    )
                    setcountryApplying(
                        {
                            value: countryvalue,
                            label: countryvalue,
                            name: countryvalue,
                        }
                    )
                    setenglishTest(res.data.data[0].academicInfo.englishProficiency.englishTest)
                    setscore(res.data.data[0].academicInfo.englishProficiency.score)
                    setcaseCreationDate(formatDate(res.data.data[0].academicInfo.englishProficiency.caseCreationDate))
                    // setscore(res.data.data[0].address.englishProficiency)
         
                } else {
                    navigate("/")
                }

            }).catch((e) => {
                console.log(e)
            })
    }


    return (
     <div>
        <Navbar />
           <div className="container-fluid">
            <div className="row">
                {toggle && (
                    <div
                        className="col-12 col-md-3 vh-100 position-fixed"
                        style={{ zIndex: 1000 }}
                    >
                        <Ssidebar Toggle={Toggle} />
                    </div>
                )}

                {toggle && <div className="col-12 col-lg-3"></div>}
                <div className="col">
                    {/* <Studentnav Toggle={Toggle} /> */}
                    <div className='container-fluid academic-main'>
                        <div className='row mt-3 text-start'>
                            <h2>{toggle?"":<i className="fa-solid fa-bars" onClick={Toggle} style={{cursor: "pointer"}}></i>} Academic details</h2>
                        </div>
                        <div>
                            <div className='row text-start mt-4 acc-form'>
                                <div className='col-lg-4 col-12'>
                                <div className="mb-3">
                                        <label for="people" className="form-label">Apply university</label>
{/*                                         
                                        <input type="text" className="form-control" id="people"
                                            value={universityApplying} onChange={(e) => setuniversityApplying(e.target.value)}
                                        /> */}
                                        <TagsInput
                                         value={universityApplying} onChange={setuniversityApplying} 
                                        />
                                    </div>
                                </div>
                                <div className='col-lg-4 col-12'>
                                <div className="mb-3">
                                        <label for="people" className="form-label">Last qualification</label>
                                        <input type="text" className="form-control" id="people"
                                            value={lastQualification} onChange={(e) => setlastQualification(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className='col-lg-4 col-12'>
                                    <div className="mb-3">
                                        <label for="people" className="form-label">Last subject</label>
                                        <input type="text" className="form-control" id="people"
                                            value={lastSubject} onChange={(e) => setlastSubject(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className='row text-start acc-form'>
                                <div className='col-lg-4 col-12'>
                                    <div className="mb-3">
                                        <label for="exampleInputEmail1" className="form-label">Applying country</label>
                                        <Select
                                            value={ucountryApplying}
                                            onChange={setcountryApplying}
                                            options={countryname}
                                            isSearchable
                                        />
                                    </div>
                                </div>
                                <div className='col-lg-4 col-12'>
                                    <div className="mb-3">
                                        <label for="people" className="form-label">English test</label>
                                        <input type="text" className="form-control" id="people"
                                            value={englishTest} onChange={(e) => setenglishTest(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className='col-lg-4 col-12'>
                                    <div className="mb-3">
                                        <label for="people" className="form-label">Score</label>
                                        <input type="text" className="form-control" id="people"
                                            value={score} onChange={(e) => setscore(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className='row text-start acc-form'>
                                <div className='col-lg-4 col-12'>
                                    <div className="mb-3">
                                        <label for="exampleInputEmail1" className="form-label">Last education country</label>
                                        <Select  
                                            value={ulastEducationCountry}                               
                                            onChange={setlastEducationCountry}
                                            options={countryname}
                                            isSearchable
                                        />
                                    </div>
                                </div>
                                <div className='col-lg-4 col-12'>
                                    <div className="mb-3">
                                        <label for="people" className="form-label">Case creation date</label>
                                        <input type="date" className="form-control" id="people"
                                            value={caseCreationDate} onChange={(e) => setcaseCreationDate(e.target.value)}
                                        />
                                    </div>
                                </div>
                                
                            </div>
                        </div>

                        <div className='row mt-3 btn-row'>
                            <div className='col d-flex align-items-start justify-content-start'>
                                <button className='btn btn-primary' onClick={handlesave}>Save</button>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </div>
     </div>

    )
}

export default Academic
