import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'

const Studentroute = () => {

    const auth = localStorage.getItem('studenttoken')
  return auth? <Outlet/>:<Navigate to="/" />
}

export default Studentroute
