import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Adminnav from "../admin nav/Adminnav";
import Sidebar from "../sidebar/Sidebar";
import "./Leads.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Loading from "../../Loading";
import Swal from "sweetalert2";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

const Leads = () => {
    const [toggle, setToggle] = useState(true);
    const token = localStorage.getItem("admintokenglobal");
    const [loadersubmit, setloadersubmit] = useState(false);
    const navigate = useNavigate();
    const Toggle = () => {
        setToggle(!toggle);
    };

    useEffect(() => {
        getorders();
    }, []);

    const [orderdata, setorderdata] = useState([]);
    const [accommodationType, setaccommodationType] = useState("");
    const [toWhichCity, setcityName] = useState("");
    const [serviceType, setserviceType] = useState("");
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [forWhichStudents, setforWhichStudents] = useState([])
    const handleShow = () => {
        setShow(true)
    };

    const [city, setcity] = useState("");
    const [bserviceType, setbserviceType] = useState("");
    const [baccommodationType, setbaccommodationType] = useState("");
    const [travelPlanDate, settravelPlanDate] = useState("");
    const [desc, setdesc] = useState("");

    const handleCheckboxChange = (
        event,
        id
    ) => {
        const isChecked = event.target.checked
        console.log(id, event)
        if (isChecked) {
            // If checked, add the id to forWhichStudents
            setforWhichStudents([...forWhichStudents, id])
        } else {
            // If unchecked, remove the id from forWhichStudents
            setforWhichStudents(forWhichStudents.filter((rowId) => rowId !== id))
        }
    }

    const PostBid = () => {
        console.warn(city, bserviceType, baccommodationType, travelPlanDate, desc)
        const serviceType = bserviceType;
        const accommodationType = baccommodationType;
        if (city && bserviceType && baccommodationType && travelPlanDate && desc) {
            axios
                .post(
                    "https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/BidRequest/AddBidPost",
                    {
                        city,
                        serviceType,
                        accommodationType,
                        travelPlanDate,
                        desc,
                        forWhichStudents
                    },
                    {
                        headers: {
                            Authorization: token,
                        },
                    }
                )
                .then((res) => {
                    console.log(res.data);
                    if (res.data.message === "Successfully request is added") {
                        Swal.fire({
                            title: "Thank you",
                            text: "Successfully request is added",
                            icon: "success",
                        });
                        handleClose()
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        } else {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Please fill input",
            });
        }
    }

    const getorders = () => {
        // setloadersubmit(true)
        axios
            .post(
                "https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/lead/GetLeadForWhichCity",
                {},
                {
                    headers: {
                        Authorization: token,
                    },
                }
            )
            .then((res) => {
                console.log(res.data);
                if (res.data.message === "Student profile") {
                    const reversedData = res.data.data.reverse();
                    setorderdata(reversedData);
                    setloadersubmit(false);
                } else {
                    navigate("/");
                }
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const pendingfilter = () => {
        axios
            .post(
                "https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/lead/GetLeadForWhichCity",
                { accommodationType, toWhichCity, serviceType },
                {
                    headers: {
                        Authorization: token,
                    },
                }
            )
            .then((res) => {
                console.log(res.data);
                // if (res.data.data._id) {
                setorderdata(res.data.data);

                // } else {
                //     setorderdata()

                // }
            })
            .catch((e) => {
                console.log(e);
            });
    };

    if (loadersubmit) {
        return <Loading />;
    }

    const bidpost = (city, serviceType, accommodationType, travelPlanDate) => {
        console.log(city, serviceType[0], accommodationType, travelPlanDate);

        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes",
        }).then((result) => {
            if (result.isConfirmed) {
                axios
                    .post(
                        "https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/BidRequest/AddBidPost",
                        {
                            city,
                            serviceType: serviceType[0],
                            accommodationType,
                            travelPlanDate,
                        },
                        {
                            headers: {
                                Authorization: token,
                            },
                        }
                    )
                    .then((res) => {
                        console.log(res.data);
                        if (res.data.message === "Successfully request is added") {
                            Swal.fire({
                                title: "Thank you",
                                text: "Successfully request is added",
                                icon: "success",
                            });
                            // window.location.reload(false);
                            // Filterid()
                        }
                    })
                    .catch((e) => {
                        console.log(e);
                    });
            }
        });
    };

    const clearfilter = () => {
        getorders();
        setaccommodationType("");
        setserviceType("");
        setcityName("");
    };

    const formatDate = (mongoDBDate) => {
        const date = new Date(mongoDBDate);
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();

        return `${year}/${month}/${day}`;
    };

    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

    console.log(forWhichStudents)


    return (
        <div className="container-fluid">
            <div className="row">
                {toggle && (
                    <div
                        className="col-12 col-md-3 vh-100 position-fixed"
                        style={{ zIndex: 1000 }}
                    >
                        <Sidebar Toggle={Toggle} />
                    </div>
                )}

                {toggle && <div className="col-12 col-lg-3"></div>}
                <div className="col">
                    <Adminnav Toggle={Toggle} />

                    <div>
                        <Modal show={show} onHide={handleClose}>
                            <Modal.Header closeButton>
                                <Modal.Title style={{ color: "#296013" }}>Bit post</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form>
                                    <div className='container-fluid'>
                                        <div className='row'>
                                            <div className='col-12 '>
                                                <Form.Group className="mb-2" controlId="exampleForm.ControlInput1">
                                                    <Form.Label className='fw-bold' style={{ color: "gray" }}>City </Form.Label>
                                                    <select className="form-select"
                                                        value={city}
                                                        onChange={(e) => setcity(e.target.value)}
                                                        aria-label="Default select example">
                                                        <option selected>Select city</option>
                                                        <option value="London">London</option>
                                                    </select>
                                                </Form.Group>
                                            </div>
                                            <div className='col-12 '>
                                                <Form.Group className="mb-2" controlId="exampleForm.ControlInput1">
                                                    <Form.Label className='fw-bold' style={{ color: "gray" }}>Service type </Form.Label>
                                                    <select className="form-select"
                                                        value={bserviceType}
                                                        onChange={(e) => setbserviceType(e.target.value)}
                                                        aria-label="Default select example">
                                                        <option selected>Select service type</option>
                                                        <option value="Accommodation">Accommodation</option>
                                                    </select>
                                                </Form.Group>
                                            </div>
                                            <div className='col-12 '>
                                                <Form.Group className="mb-2" controlId="exampleForm.ControlInput1">
                                                    <Form.Label className='fw-bold' style={{ color: "gray" }}>Accommodation type</Form.Label>
                                                    <select className="form-select"
                                                        value={baccommodationType}
                                                        onChange={(e) => setbaccommodationType(e.target.value)}
                                                        aria-label="Default select example">
                                                        <option selected>Select accommodation type</option>
                                                        <option value="Independent">Independent</option>
                                                    </select>
                                                </Form.Group>
                                            </div>
                                            <div className='col-12'>
                                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                    <Form.Label className='fw-bold' style={{ color: "gray" }}>Travel plan date</Form.Label>
                                                    <Form.Control
                                                        type="date"
                                                        autoFocus
                                                        value={travelPlanDate}
                                                        onChange={(e) => settravelPlanDate(e.target.value)}
                                                    />
                                                </Form.Group>
                                            </div>
                                            <div className='col-12'>
                                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                    <Form.Label className='fw-bold' style={{ color: "gray" }}>Description</Form.Label>
                                                    <textarea className="form-control" value={desc} onChange={(e) => setdesc(e.target.value)}></textarea>
                                                </Form.Group>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" className='fw-bold' style={{ backgroundColor: "#F1BB44", border: "none", color: "black" }} onClick={handleClose}>
                                    Close
                                </Button>
                                <Button variant="primary" onClick={PostBid} style={{ backgroundColor: "#296013", border: "none" }}>
                                    Save
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </div>

                    <div className="container-fluid leads-main">
                        <div className="row mt-3 text-start">
                            <h2>Leads</h2>
                        </div>
                        <div>
                            <div className="row text-start mt-4 acc-form">
                                <div className="col-lg-4 col-12">
                                    <div className="mb-3">
                                        <label for="exampleInputEmail1" className="form-label">
                                            City{" "}
                                        </label>
                                        <select
                                            className="form-select"
                                            value={toWhichCity}
                                            onChange={(e) => setcityName(e.target.value)}
                                            aria-label="Default select example"
                                        >
                                            <option selected>Select type</option>
                                            <option value="London">London</option>
                                            <option value="City of London">City of London</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-12">
                                    <div className="mb-3">
                                        <label for="exampleInputEmail1" className="form-label">
                                            Service type{" "}
                                        </label>
                                        <select
                                            className="form-select"
                                            value={serviceType}
                                            onChange={(e) => setserviceType(e.target.value)}
                                            aria-label="Default select example"
                                        >
                                            <option selected>Select type</option>
                                            <option value="accommodation">Accommodation</option>
                                            {/* <option value="independent">Independent</option> */}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-12">
                                    <div className="mb-3">
                                        <label for="exampleInputEmail1" className="form-label">
                                            Accommodation type{" "}
                                        </label>
                                        <select
                                            className="form-select"
                                            value={accommodationType}
                                            onChange={(e) => setaccommodationType(e.target.value)}
                                            aria-label="Default select example"
                                        >
                                            <option selected>Select type</option>
                                            <option value="share">Shared</option>
                                            <option value="independent">Independent</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-3 btn-row">
                            <div className="col-lg-1 col-6 d-flex align-items-start justify-content-start">
                                <button className="btn btn-primary" onClick={pendingfilter}>
                                    Filter
                                </button>
                            </div>
                            <div className="col-lg-1 col-6 d-flex align-items-start justify-content-start">
                                <button className="btn btn-primary" onClick={clearfilter}>
                                    Clear
                                </button>
                            </div>
                            {
                                forWhichStudents.length > 0 ? <>
                                    <div className="col-lg-2 col-6 d-flex align-items-start justify-content-start">
                                        <button className="btn btn-primary" onClick={handleShow}>
                                            Bit post
                                        </button>
                                    </div>
                                </> : ""
                            }

                            <div className="col-lg"></div>
                        </div>

                        <div className="row mt-3">
                            <div className="col table11">
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">Sr.</th>
                                            <th scope="col">Check</th>
                                            <th scope="col">Status</th>
                                            <th scope="col">Is registered by consultant</th>
                                            <th scope="col">Name</th>
                                            <th scope="col">Email</th>
                                            <th scope="col">Password</th>
                                            <th scope="col">Phone</th>
                                            <th scope="col">City</th>
                                            <th scope="col">Service type</th>
                                            <th scope="col">Accommodation type</th>
                                            <th scope="col">Travel plan date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {orderdata
                                            ? orderdata.map((item, index) => {
                                                return (
                                                    <tr key={item._id}>
                                                        <th scope="row">{index + 1}</th>
                                                        <td className="">
                                                            <input className="form-check-input" style={{ height: "25px", width: "25px", cursor: "pointer" }} type="checkbox" onChange={(event) => handleCheckboxChange(event, item._id)}
                                                                checked={forWhichStudents.includes(item._id)} />
                                                        </td>
                                                        <td>
                                                            {item.leadStatus ? item.leadStatus : "N/A"}
                                                        </td>
                                                        <td>
                                                            {item.isRegisteredByConsultant === true
                                                                ? "Agent"
                                                                : item.isRegisteredByConsultant === false
                                                                    ? "My self"
                                                                    : ""}
                                                        </td>
                                                        <td>{item.foreName ? item.foreName : "N/A"}</td>
                                                        <td>{item.email ? item.email : "N/A"}</td>
                                                        <td>{item.password ? item.password : "N/A"}</td>
                                                        <td>{item.phone ? item.phone : "N/A"}</td>
                                                        <td>
                                                            {item.toWhichCity ? item.toWhichCity : "N/A"}
                                                        </td>
                                                        <td>
                                                            {item.serviceType[0]
                                                                ? item.serviceType[0]
                                                                : "N/A"}
                                                        </td>
                                                        <td>
                                                            {item.accommodationType
                                                                ? item.accommodationType
                                                                : "N/A"}
                                                        </td>
                                                        <td>
                                                            {formatDate(
                                                                item.travelPlanDate ? item.travelPlanDate : ""
                                                            )}
                                                        </td>
                                                        {/* <td>
                                                            <button
                                                                className="btn btn-primary bid-btn"
                                                                onClick={() =>
                                                                    bidpost(
                                                                        item.toWhichCity,
                                                                        item.serviceType,
                                                                        item.accommodationType,
                                                                        formatDate(item.travelPlanDate)
                                                                    )
                                                                }
                                                            >
                                                                Bid post
                                                            </button>
                                                        </td> */}
                                                        {/* <td>
                                                        <li className="nav-item dropdown" style={{ listStyle: "none" }}>
                                                            <button className="btn btn-primary dropdown-toggle" style={{ backgroundColor: "#296013", border: "none", height: "30px" }} role="button" data-bs-toggle="dropdown" aria-expanded="false">

                                                            </button>
                                                            <ul className="dropdown-menu">
                                                                <li style={{ cursor: "pointer" }} onClick={() => localdelete(item._id)}><td className="dropdown-item"><i class="fa-solid fa-trash me-2" style={{ color: "#B63A3A" }}></i> Delete</td></li>
                                                                <li style={{ cursor: "pointer" }}
                                                                    onClick={() => handleShow(item._id, item.bankName, item.accountTitle, item.accountNumber, item.IBAN)}
                                                                ><td className="dropdown-item"><i className="fa-solid fa-pen-to-square me-2"
                                                                    style={{ color: "#198BEC" }}></i> Update</td></li>
                                                            </ul>
                                                        </li>
                                                    </td> */}
                                                    </tr>
                                                );
                                            })
                                            : ""}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Leads;
