import "./Hero.css";
import lodge from "../assiet/img/Lodge.png";
import briefcase from "../assiet/img/Briefcase.png";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import Select from "react-select";
import { allCountries } from "../result";
import { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import Loading from "../Loading";
import Modal from "react-bootstrap/Modal";

function Hero() {
  const typeArrange = allCountries[145].cities.map((row) => {
    return {
      value: `${row}`,
      label: `${row}`,
    };
  });

  useEffect(() => {
    getdata();
  }, []);

  const getdata = () => {
    // setloadersubmit(true)
    axios
      .get(
        `https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/terms/GetAddTermsAndConditions?userRole=` +
        "student"
      )
      .then((res) => {
        if (res.data.message === "Successfully  found") {
          // console.log(res.data)
          settermdata(res.data.data.termsAndCondition);
          // setloadersubmit(false)
        } else {
          console.log(res.data);
          // setloadersubmit(false)
        }
      })
      .catch((e) => {
        console.log(e);
        setloadersubmit(false);
      });
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // Student register process

  const [email, setemail] = useState("");
  const [loadersubmit, setloadersubmit] = useState(false);
  const [foreName, setforeName] = useState("");
  const [password, setpassword] = useState("");
  const [phone, setphone] = useState();
  const [isAgsssree, setisAgree] = useState("");
  const navigate = useNavigate();
  const [isuAgree, setuisAgree] = useState("");
  const [utoWhichCity, settoWhichCity] = useState("");
  const [travelPlanDate, settravelPlanDate] = useState();
  const [serviceType, setserviceType] = useState("");
  const [accommodationType, setaccommodationType] = useState("");
  const token = localStorage.getItem("studenttoken");
  const token2 = localStorage.getItem("agenttoken");
  const token3 = localStorage.getItem("lettingtoken");
  const [termdata, settermdata] = useState("");
  const [fieldChange, setfieldChange] = useState(0);

  const submitStudentdata = () => {
    if (isuAgree === "on") {
      const isAgree = "yes";
      const toWhichCity = utoWhichCity.value;
      if (
        password &&
        isAgree &&
        toWhichCity &&
        email &&
        phone &&
        password &&
        accommodationType &&
        serviceType &&
        foreName &&
        travelPlanDate
      ) {
        console.log(
          email,
          phone,
          password,
          isAgree,
          toWhichCity,
          accommodationType,
          serviceType,
          foreName,
          travelPlanDate
        );
        axios
          .post(
            "https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/student/StudentSignup",
            {
              email,
              phone,
              password,
              isAgree,
              toWhichCity,
              accommodationType,
              serviceType,
              foreName,
              travelPlanDate,
            }
          )
          .then((res) => {
            // console.log(res.data)
            if (res.data.token) {
              navigate("/sign");
              setloadersubmit(false);
            } else {
              console.log(res.data);
            }
          })
          .catch((e) => {
            if (e.response.data.message === "Student is already exist") {
              //  setloadersubmit(false)
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Student is already exist",
              });
            } else {
              console.log(e);
            }
          });
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "please fill input",
        });
      }
    } else {
      setisAgree("");
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "please fill input",
      });
    }
  };

  // agent register process
  const [emailA, setemailA] = useState("");
  const [nameA, setnameA] = useState("");
  const [passwordA, setpasswordA] = useState("");
  const [phoneA, setphoneA] = useState();
  const [isAgreeA, setisAgreeA] = useState("");

  const agentsubmit = () => {
    if ((isAgreeA, nameA, emailA, passwordA, phoneA)) {
      axios
        .post(
          "https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/consultant/ChannelPartnerSignup",
          {
            email: emailA,
            phone: phoneA,
            password: passwordA,
            isAgree: isAgreeA,
            name: nameA,
          }
        )
        .then((res) => {
          if (res.data.token) {
            Swal.fire({
              title: "Thank you",
              text: "Successful",
              icon: "success",
            });
            navigate("/sign");
          } else {
            // console.log(res.data);
          }
        })
        .catch((e) => {
          if (e.response.data.message === "Channel Partner is already exist") {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Agent is already exist",
            });
          } else {
            console.log(e);
          }
        });
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "please fill input",
      });
    }
  };

  // Letting agent register process

  const [namel, setnamel] = useState("");
  const [emaill, setemaill] = useState("");
  const [passwordl, setpasswordl] = useState("");
  const [phonel, setphonel] = useState();
  const [isAgreel, setisAgreel] = useState("");
  const [cityl, setcityl] = useState("");

  const lettingagentSubmit = () => {
    console.log({
      email: emaill,
      phone: phonel,
      password: passwordl,
      isAgree: isAgreel,
      name: namel,
      city: cityl.value,
    })
    if (namel && emaill && isAgreel && phonel && passwordl && cityl.value) {
      axios
        .post(
          "https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/lettingagent/LettingAgentSignup",
          {
            email: emaill,
            phone: phonel,
            password: passwordl,
            isAgree: isAgreel,
            name: namel,
            city: cityl.value,
          }
        )
        .then((res) => {
          if (res.data.token) {
            Swal.fire({
              title: "Thank you",
              text: "Successful",
              icon: "success",
            });
            navigate("/sign");
            console.log(res.data);
          } else {
            console.log(res.data);
          }
        })
        .catch((e) => {
          if (e.response.data.message === "Letting Agent is already exist") {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Letting Agent is already exist",
            });
            console.log(e);
          } else {
            console.log(e);
          }
        });
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "please fill input",
      });
    }
  };

  if (loadersubmit) {
    return <Loading />;
  }

  const handleType = (e) => {
    const value = e.target.value;
    if (value === "Student") {
      setfieldChange(1);
    } else if (value === "Agent") {
      setfieldChange(2);
    } else if (value === "Letting Agent") {
      setfieldChange(3);
    } else {
      setfieldChange(0);
    }
  };

  return (
    <div className="main-hero">
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="fs-5">Register</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            className="box-modal"
            style={{ height: "490px", overflow: "hidden", overflowY: "scroll" }}
          >
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="mb-3 text-start">
                    <label for="sdfsdfds" className="form-label fw-bold">
                      Select Type
                    </label>
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      onChange={handleType}
                    >
                      <option selected>Select menu</option>
                      <option value="Student">Student</option>
                      <option value="Agent">Agent</option>
                      <option value="Letting Agent">Letting Agent</option>
                    </select>
                  </div>
                </div>
                {fieldChange === 1 ? (
                  <>
                    <div>
                      <div className="col-12">
                        <div className="mb-2 text-start">
                          <label for="sdfsdfds" className="form-label">
                            Name
                          </label>
                          <input
                            required
                            type="text"
                            className="form-control"
                            id="sdfsdfds"
                            value={foreName}
                            onChange={(e) => setforeName(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="mb-2 text-start">
                          <label for="sdfsdfds" className="form-label">
                            Email
                          </label>
                          <input
                            required
                            type="email"
                            className="form-control"
                            id="sdfsdfds"
                            value={email}
                            onChange={(e) => setemail(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="mb-2 text-start">
                          <label for="sdfsdfds" className="form-label">
                            Phone Number
                          </label>
                          <PhoneInput
                            className=""
                            country={"pk"}
                            value={phone}
                            onChange={(value) => setphone(value)}
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="mb-2 text-start">
                          <label for="sdfsdfds" className="form-label">
                            Password
                          </label>
                          <input
                            required
                            type="password"
                            className="form-control"
                            id="sdfsdfds"
                            value={password}
                            onChange={(e) => setpassword(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="mb-3 text-start">
                          <label for="sdfsdfds" className="form-label">
                            Where are you going
                          </label>
                          <Select
                            isSearchable
                            options={typeArrange}
                            onChange={settoWhichCity}
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="mb-3 text-start">
                          <label for="sdfsdfds" className="form-label">
                            Service type
                          </label>
                          <select
                            className="form-select"
                            aria-label="Default select example"
                            value={serviceType}
                            onChange={(e) => setserviceType(e.target.value)}
                          >
                            <option selected>Select menu</option>
                            <option value="accommodation">Accommodation</option>
                            <option value="job">Job</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="mb-3 text-start">
                          <label for="sdfsdfds" className="form-label">
                            Accommodation type
                          </label>
                          <select
                            class="form-select"
                            aria-label="Default select example"
                            value={accommodationType}
                            onChange={(e) =>
                              setaccommodationType(e.target.value)
                            }
                          >
                            <option selected>Select menu</option>
                            <option value="share">Shared</option>
                            <option value="independent">Independent</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="mb-3 text-start">
                          <label for="sdfsdfds" className="form-label">
                            Travel plan date
                          </label>
                          <input
                            required
                            type="date"
                            className="form-control"
                            id="sdfsdfds"
                            value={travelPlanDate}
                            onChange={(e) => settravelPlanDate(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="mb-3 form-check text-start">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="exampleCheck1"
                            required
                            checked={isuAgree}
                            onChange={(e) => setuisAgree(e.target.value)}
                          />
                          <label
                            className="form-check-label"
                            for="exampleCheck1"
                          >
                            Agree to terms and conditions
                          </label>
                        </div>
                      </div>
                    </div>
                  </>
                ) : fieldChange === 2 ? (
                  <>
                    <div>
                      <div className="col-12">
                        <div className="mb-2 text-start">
                          <label for="sdfsdfds" className="form-label">
                            Name
                          </label>
                          <input
                            required
                            type="text"
                            className="form-control"
                            id="sdfsdfds"
                            value={nameA}
                            onChange={(e) => setnameA(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="mb-2 text-start">
                          <label for="sdfsdfds" className="form-label">
                            Email
                          </label>
                          <input
                            required
                            type="email"
                            className="form-control"
                            id="sdfsdfds"
                            value={emailA}
                            onChange={(e) => setemailA(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="mb-2 text-start">
                          <label for="sdfsdfds" className="form-label">
                            Phone Number
                          </label>
                          <PhoneInput
                            className=""
                            country={"pk"}
                            value={phone}
                            onChange={(value) => setphoneA(value)}
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="mb-2 text-start">
                          <label for="sdfsdfds" className="form-label">
                            Password
                          </label>
                          <input
                            required
                            type="password"
                            className="form-control"
                            id="sdfsdfds"
                            value={passwordA}
                            onChange={(e) => setpasswordA(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="mb-3 form-check text-start">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="exampleCheck1"
                            value="yes"
                            checked={isAgreeA}
                            onChange={(e) => setisAgreeA(e.target.value)}
                          />
                          <label
                            className="form-check-label"
                            for="exampleCheck1"
                          >
                            Agree to terms and conditions
                          </label>
                        </div>
                      </div>
                    </div>
                  </>
                ) : fieldChange === 3 ? (
                  <>
                    <div>
                      <div className="col-12">
                        <div className="mb-2 text-start">
                          <label for="sdfsdfds" className="form-label">
                            Name
                          </label>
                          <input
                            required
                            type="text"
                            className="form-control"
                            id="sdfsdfds"
                            value={namel}
                            onChange={(e) => setnamel(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="mb-2 text-start">
                          <label for="sdfsdfds" className="form-label">
                            Email
                          </label>
                          <input
                            required
                            type="email"
                            className="form-control"
                            id="sdfsdfds"
                            value={emaill}
                            onChange={(e) => setemaill(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="mb-2 text-start">
                          <label for="sdfsdfds" className="form-label">
                            Phone Number
                          </label>
                          <PhoneInput
                            className=""
                            country={"pk"}
                            value={phonel}
                            onChange={(value) => setphonel(value)}
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="mb-3 text-start">
                          <label for="sdfsdfds" className="form-label">
                            City
                          </label>
                          <Select
                            isSearchable
                            options={typeArrange}
                            onChange={setcityl}
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="mb-2 text-start">
                          <label for="sdfsdfds" className="form-label">
                            Password
                          </label>
                          <input
                            required
                            type="password"
                            className="form-control"
                            id="sdfsdfds"
                            value={passwordl}
                            onChange={(e) => setpasswordl(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="mb-3 form-check text-start">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="exampleCheck1"
                            value="yes"
                            checked={isAgreel}
                            onChange={(e) => setisAgreel(e.target.value)}
                          />
                          <label
                            className="form-check-label"
                            for="exampleCheck1"
                          >
                            Agree to terms and conditions
                          </label>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          {fieldChange === 1 ? (
            <>
              <button
                type="button"
                className="btn11 fw-bold"
                style={{
                  width: "100%",
                  borderRadius: "30px",
                  height: "40px",
                  background: "#F4BB44",
                  border: "none",
                  letterSpacing: "1px",
                }}
                onClick={submitStudentdata}
              >
                Student Register
              </button>
            </>
          ) : fieldChange === 2 ? (
            <>
              <button
                type="button"
                className="btn11 fw-bold"
                style={{
                  width: "100%",
                  borderRadius: "30px",
                  height: "40px",
                  background: "#F4BB44",
                  border: "none",
                  letterSpacing: "1px",
                }}
                onClick={agentsubmit}
              >
                Agent Register
              </button>
            </>
          ) : fieldChange === 3 ? (
            <>
              <button
                type="button"
                className="btn11 fw-bold"
                style={{
                  width: "100%",
                  borderRadius: "30px",
                  height: "40px",
                  background: "#F4BB44",
                  border: "none",
                  letterSpacing: "1px",
                }}
                onClick={lettingagentSubmit}
              >
                Letting Agent Register
              </button>
            </>
          ) : (
            ""
          )}
        </Modal.Footer>
      </Modal>
      <div className="container py-4">
        <div className="row">
          <div className="col-lg-7 my-4 side-content">
            <div className="row">
              <div className="col text-md-start">
                <h1>
                  If you're coming to the UK, we offer comprehensive services
                  for finding both accommodation and employment. Let us simplify
                  your transition!
                </h1>
              </div>
            </div>

            <div className="row text-md-start mt-3">
              <div className="col-lg-6 text-md-start">
                <h3>
                  <i class="fa-solid fa-file me-2"></i> Upload Documents
                </h3>
              </div>
              <div className="col text-md-start">
                <h3>
                  <i class="fa-solid fa-circle-check me-2"></i> Done
                </h3>
              </div>
              <div className="col-md- text-md-start">{/* <h3>Done</h3> */}</div>
            </div>

            <div className="row mt-3 ">
              <div className="col-md-3">
                <button className="btn btn-primary" onClick={handleShow}>
                  Register Now
                </button>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-md-6 mb-4 mb-lg-0">
                <div className="box-1 pb-5">
                  <h2 className="pt-5 pt-sm-4 pt-lg-4 text-h2">
                    Arrange your accommodation
                  </h2>
                  <div className="icons-1 pt-lg-3 pt-3">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="40"
                      height="40"
                      fill="#F4BB44"
                      className="bi bi-1-circle-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0ZM9.283 4.002H7.971L6.072 5.385v1.271l1.834-1.318h.065V12h1.312V4.002Z" />
                    </svg>
                  </div>
                  <div className="img-car pt-lg-3 pt-3">
                    <img className="house-img" src={lodge} alt=""></img>
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="box-1">
                  <h2 className="pt-5 pt-sm-4 pt-lg-4">
                    Help you finding the job in UK
                  </h2>
                  <div className="icons-1 pt-lg-3 pt-3">
                    {/* <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="#F4BB44" className="bi bi-2-circle-fill" viewBox="0 0 16 16">
                                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0Zm-8.082.414c.92 0 1.535.54 1.541 1.318.012.791-.615 1.36-1.588 1.354-.861-.006-1.482-.469-1.54-1.066H5.104c.047 1.177 1.05 2.144 2.754 2.144 1.653 0 2.954-.937 2.93-2.396-.023-1.278-1.031-1.846-1.734-1.916v-.07c.597-.1 1.505-.739 1.482-1.876-.03-1.177-1.043-2.074-2.637-2.062-1.675.006-2.59.984-2.625 2.12h1.248c.036-.556.557-1.054 1.348-1.054.785 0 1.348.486 1.348 1.195.006.715-.563 1.237-1.342 1.237h-.838v1.072h.879Z" />
                                        </svg> */}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="40"
                      height="40"
                      fill="#F4BB44"
                      className="bi bi-1-circle-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0ZM6.646 6.24c0-.691.493-1.306 1.336-1.306.756 0 1.313.492 1.313 1.236 0 .697-.469 1.23-.902 1.705l-2.971 3.293V12h5.344v-1.107H7.268v-.077l1.974-2.22.096-.107c.688-.763 1.287-1.428 1.287-2.43 0-1.266-1.031-2.215-2.613-2.215-1.758 0-2.637 1.19-2.637 2.402v.065h1.271v-.07Z" />
                    </svg>
                  </div>
                  <div className="img-car pt-4">
                    <img className="brief-img" src={briefcase} alt=""></img>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="col-lg-5 right-content mb-4 mb-lg-0">
            {token || token2 || token3 ? (
              ""
            ) : (
              <>
                <div className="ps-md-4">
                  <div className="form-box py-2">
                    <div className="row">
                      <h2>Book Accommodation</h2>
                    </div>

                    <div className="scroll-box">
                      <div className="row px-3 py-2 text-start">
                        <div className="col-12">
                          <div className="mb-2">
                            <label for="sdfsdfds" className="form-label">
                              Name
                            </label>
                            <input
                              required
                              type="text"
                              className="form-control"
                              id="sdfsdfds"
                              value={foreName}
                              onChange={(e) => setforeName(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="mb-3">
                            <label for="sdfsdfds" className="form-label">
                              Email
                            </label>
                            <input
                              required
                              type="email"
                              className="form-control"
                              id="sdfsdfds"
                              value={email}
                              onChange={(e) => setemail(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="mb-3">
                            <label for="sdfsdfds" className="form-label pb-3">
                              Phone
                            </label>
                            <PhoneInput
                              className=""
                              country={"pk"}
                              value={phone}
                              onChange={(value) => setphone(value)}
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="mb-3">
                            <label for="sdfsdfds" className="form-label">
                              Password
                            </label>
                            <input
                              required
                              type="password"
                              className="form-control"
                              id="sdfsdfds"
                              value={password}
                              onChange={(e) => setpassword(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="mb-3">
                            <label for="sdfsdfds" className="form-label">
                              Where are you going
                            </label>
                            <Select
                              isSearchable
                              options={typeArrange}
                              onhangCe={settoWhichCity}
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="mb-3">
                            <label for="sdfsdfds" className="form-label">
                              Service type
                            </label>
                            <select
                              class="form-select"
                              aria-label="Default select example"
                              value={serviceType}
                              onChange={(e) => setserviceType(e.target.value)}
                            >
                              <option selected>Open this select menu</option>
                              <option value="accommodation">
                                Accommodation
                              </option>
                              <option value="job">Job</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="mb-3">
                            <label for="sdfsdfds" className="form-label">
                              Accommodation type
                            </label>
                            <select
                              class="form-select"
                              aria-label="Default select example"
                              value={accommodationType}
                              onChange={(e) =>
                                setaccommodationType(e.target.value)
                              }
                            >
                              <option selected>Open this select menu</option>
                              <option value="share">Shared</option>
                              <option value="independent">Independent</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="mb-3">
                            <label for="sdfsdfds" className="form-label">
                              Travel plan date
                            </label>
                            <input
                              required
                              type="date"
                              className="form-control"
                              id="sdfsdfds"
                              value={travelPlanDate}
                              onChange={(e) =>
                                settravelPlanDate(e.target.value)
                              }
                            />
                          </div>
                        </div>

                        <div className="col-12">
                          <div className="termbox">
                            <p>{termdata}</p>
                          </div>
                          <div className="mb-3 mt-1 form-check">
                            <input
                              required
                              type="checkbox"
                              className="form-check-input"
                              id="exampleCheck1"
                              checked={isuAgree}
                              onChange={(e) => setuisAgree(e.target.value)}
                            />
                            <label
                              className="form-check-label ms-2"
                              for="exampleCheck1"
                              style={{ fontSize: "1rem" }}
                            >
                              Agree to terms and conditions
                            </label>
                          </div>
                        </div>
                        <button
                          className="btn btn-primary"
                          onClick={submitdata}
                        >
                          Register Now
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default Hero;
