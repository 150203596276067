import { NavLink } from "react-router-dom";
import "./Footer.css";

function Footer() {
  return (
    <div className="main-footer bg-dark text-white pt-5 pb-4 mt-">
      <div className="container text-center text-md-start">
        <div className="row text-center text-md-start">
          <div className="col-md-3 col-lg-3 col-xl-3 mx-auto mt-3">
            <h5 className="text-uppercase text-weight-bold text-warning">
              Global Learn And Earn
            </h5>
            <p>
              Partner with Global Learn & Earn to expand your UK letting agency
              and join innovative educational projects. Connect globally,
              innovate in learning, and grow your business. Start now to
              redefine success in real estate and education.
            </p>
          </div>

          <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mt-3">
            <h5 className="text-uppercase text-weight-bold text-warning mb-4">
              Services
            </h5>
            <p>
              <NavLink className="text-white" to="/accommodation">
                Accommodation
              </NavLink>
            </p>
            <p>
              <NavLink className="text-white" to="/job-finding">
                Job finding
              </NavLink>
            </p>
          </div>

          <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mt-3">
            <h5 className="text-uppercase text-weight-bold text-warning mb-4">
              Useful link
            </h5>
            <p>
              <a className="text-white" href="/">
                Your Account
              </a>
            </p>
            <p>
              <a className="text-white" href="/">
                Become an Affiliates
              </a>
            </p>
            <p>
              <a className="text-white" href="/">
                Shipping Rates
              </a>
            </p>
            <p>
              <a className="text-white" href="/">
                Help
              </a>
            </p>
          </div>

          <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mt-3">
            <h5 className="text-uppercase text-weight-bold text-warning mb-4">
              Contact
            </h5>
            <p className="row">
              <div className="col-1">
                <i className="fas fa-home "></i>
              </div>
              <div className="col">
                {/* 277 Nitsdale Road Glasgow Scotland United Kingdom G415LX */}
                Wellmedow Street Paisley PA2EG
              </div>
            </p>
            
            <p>
              <i className="fas fa-envelope me-1"></i>{" "}
              globallearnandearn@gmail.com
            </p>
            <p>
              <i className="fa-brands fa-whatsapp me-1"></i> +92-304-8112223
            </p>
            <p>
              <i className="fa-solid fa-mobile-screen-button me-1"></i> +44 7908
              444004
            </p>
          </div>

          <hr className="mb-4"></hr>
          <div className="row align-atems-center">
            <div className="col-md-7 col-md-8">
              <p className="fw-bold">© 2023 - Global Learn And Earn</p>
              <div className="d-flex gap-3">
                <p>Terms & conditions</p>
                <p>Cookies</p>
                <p>Privacy</p>
                {/* <p>Complaints & compliments</p> */}
              </div>
            </div>
            <div className="col-md-5 col-lg-4">
              <div className="text-center text-md-end">
                <ul className="list-unstyled list-inline">
                  <li className="list-inline-item">
                    <a
                      href="/"
                      className="btn-floating btn-sm text-white a-icon"
                    >
                      <i className="fab fa-facebook"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a
                      href="/"
                      className="btn-floating btn-sm text-white a-icon"
                    >
                      <i className="fab fa-twitter"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a
                      href="/"
                      className="btn-floating btn-sm text-white a-icon"
                    >
                      <i className="fab fa-youtube"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a
                      href="/"
                      className="btn-floating btn-sm text-white a-icon"
                    >
                      <i className="fab fa-instagram"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a
                      href="/"
                      className="btn-floating btn-sm text-white a-icon"
                    >
                      <i className="fab fa-linkedin"></i>
                    </a>
                  </li>
                </ul>
              </div>

              <p className="text-lg-end">
                Designed by{" "}
                <a
                  href="https://www.cloudlabweb.com/"
                  style={{ color: "white" }}
                  target="blank"
                >
                  Cloud Lab Private Ltd
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
