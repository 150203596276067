import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'

const Agentroute = () => {

    const auth = localStorage.getItem('agenttoken')
  return auth? <Outlet/>:<Navigate to="/" />
}

export default Agentroute
