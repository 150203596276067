import "./Hero1.css";

function Hero1() {
  return (
    <div className="main-hero1 mb-5">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="ratio ratio-21x9 hero1-video">
              {/* <iframe
                width="auto"
                src="https://www.youtube.com/embed/IDXFumrqseM?si=0_0s2y5MHHTQF7kn"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay;
                          clipboard-write; encrypted-media;
                         gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe> */}
              <iframe
                width="auto"
                src="https://www.youtube.com/embed/QSKq2Egy2EA?si=yesuOWmP-EByguSR"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hero1;
