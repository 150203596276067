import React, { useEffect, useState } from 'react'
import Ssidebar from '../Sidebar/Ssidebar';
import Studentnav from '../Sidebar/Studentnav';
import Swal from 'sweetalert2';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'
import 'react-tagsinput/react-tagsinput.css'
import './Emergency.css'
import Navbar from '../../compunent/Navbar';

const Emergency = () => {

    const [toggle, setToggle] = useState(true);
    const navigate = useNavigate();
    const Toggle = () => {
        setToggle(!toggle);
    };

    const token = localStorage.getItem('studenttoken')

    useEffect(() => {
        getstudents()
    }, [])

    const [bankName, setbankName] = useState("")
    const [accountNumber, setaccountNumber] = useState("")
    const [IBAN, setIBAN] = useState("")
    const [accountTitle, setaccountTitle] = useState("")

    const [nokName, setnokName] = useState("")
    const [nokPhoneNumber, setnokPhoneNumber] = useState("")
    const [nokRelationship, setnokRelationship] = useState("")
    const [nokEmail, setnokEmail] = useState("")
    const [anyUkPersonName, setanyUkPersonName] = useState("")
    const [anyUkNumber, setanyUkNumber] = useState("")



    const handlesave = () => {
        // console.warn(bankName, accountNumber, accountTitle, IBAN)
        if (nokName && nokEmail && nokPhoneNumber && nokRelationship && anyUkNumber && anyUkPersonName) {
            axios.post("https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/student/StudentEmergencyContact", { nokName,nokEmail,nokPhoneNumber,nokRelationship,anyUkNumber,anyUkPersonName }, {
                headers: {
                    Authorization: token,
                },
            }).then((res) => {
                if (res.data.message === "emergencyContact information is added") {
                    Swal.fire({
                        title: "Thank you",
                        text: "Emergency contact information is added",
                        icon: "success"
                    });

                    getstudents()
                } else {
                    console.log(res.data)

                }
            }).catch((e) => {
                console.log(e)
            })
        } else {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "please fill input",
            });
        }
    }

    const getstudents = () => {
        axios.get("https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/student/StudentProfile", {
            headers: {
                Authorization: token,
            },
        })
            .then((res) => {
                console.log(res.data.data)
                if (res.data.message === "Student profile") {

                    setnokName(res.data.data[0].emergencyContact.nokName)
                    setnokPhoneNumber(res.data.data[0].emergencyContact.nokPhoneNumber)
                    setnokEmail(res.data.data[0].emergencyContact.nokEmail)
                    setnokRelationship(res.data.data[0].emergencyContact.nokRelationship)
                    setanyUkNumber(res.data.data[0].emergencyContact.anyUkNumber)
                    setanyUkPersonName(res.data.data[0].emergencyContact.anyUkPersonName)
           

                } else {
                    navigate("/")
                }

            }).catch((e) => {
                console.log(e)
            })
    }


    return (
   <div>
    <Navbar/>
         <div className="container-fluid">
            <div className="row">
                {toggle && (
                    <div
                        className="col-12 col-md-3 vh-100 position-fixed"
                        style={{ zIndex: 1000 }}
                    >
                        <Ssidebar Toggle={Toggle} />
                    </div>
                )}

                {toggle && <div className="col-12 col-lg-3"></div>}
                <div className="col">
                    {/* <Studentnav Toggle={Toggle} /> */}
                    <div className='container-fluid emmergency-main'>
                        <div className='row mt-3 text-start'>
                            <h2>{toggle?"":<i className="fa-solid fa-bars" onClick={Toggle} style={{cursor: "pointer"}}></i>} Emergency contact (Next of Kin)</h2>
                        </div>
                        <div>
                            <div className='row text-start mt-4 acc-form'>
                                <div className='col-lg-4 col-12'>
                                    <div className="mb-3">
                                        <label for="people" className="form-label">Nok name</label>
                                        <input type="text" className="form-control" id="people"
                                            value={nokName} onChange={(e) => setnokName(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className='col-lg-4 col-12'>
                                    <div className="mb-3">
                                        <label for="people" className="form-label">Nok phone #</label>
                                        <input type="text" className="form-control" id="people"
                                            value={nokPhoneNumber} onChange={(e) => setnokPhoneNumber(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className='col-lg-4 col-12'>
                                    <div className="mb-3">
                                        <label for="people" className="form-label">Nok relationship </label>
                                        <input type="text" className="form-control" id="people"
                                            value={nokRelationship} onChange={(e) => setnokRelationship(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className='row text-start acc-form'>
                                <div className='col-lg-4 col-12'>
                                    <div className="mb-3">
                                        <label for="people" className="form-label">Nok email</label>
                                        <input type="email" className="form-control" id="people"
                                            value={nokEmail} onChange={(e) => setnokEmail(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className='col-lg-4 col-12'>
                                    <div className="mb-3">
                                        <label for="people" className="form-label">Any Uk person name</label>
                                        <input type="email" className="form-control" id="people"
                                            value={anyUkPersonName} onChange={(e) => setanyUkPersonName(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className='col-lg-4 col-12'>
                                    <div className="mb-3">
                                        <label for="people" className="form-label">Any Uk number</label>
                                        <input type="email" className="form-control" id="people"
                                            value={anyUkNumber} onChange={(e) => setanyUkNumber(e.target.value)}
                                        />
                                    </div>
                                </div>

                            </div>


                        </div>

                        <div className='row mt-3 btn-row'>
                            <div className='col d-flex align-items-start justify-content-start'>
                                <button className='btn btn-primary' onClick={handlesave}>Save</button>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </div>
   </div>

    )
}

export default Emergency
