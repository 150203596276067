import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import LettingNav from '../Sidebar And Navbar/LettingNav'
import LettingSidebar from '../Sidebar And Navbar/LettingSidebar'
import './Bidpost.css'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import Loading from '../../Loading'
import Swal from 'sweetalert2'
import Modal from 'react-bootstrap/Modal';

const Bidpost = () => {

    const [toggle, setToggle] = useState(true);
    const token = localStorage.getItem('lettingtoken')
    const [loadersubmit, setloadersubmit] = useState(false)
    const navigate = useNavigate();
    const Toggle = () => {
        setToggle(!toggle);
    };

    useEffect(() => {
        getdata()
        getrooms()
    }, [])

    const [orderdata, setorderdata] = useState([]);
    const [accommodationType, setaccommodationType] = useState("");
    const [toWhichCity, setcityName] = useState("")
    const [serviceType, setserviceType] = useState("")
    const [lgShow, setLgShow] = useState(false);
    const [roonsdata, setroonsdata] = useState([])
    const [bitId,setbitId] = useState("")

    const getdata = () => {
        setloadersubmit(false)
        axios.post("https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/BidRequest/GetBidPost", {}, {
            headers: {
                Authorization: token,
            },
        })
            .then((res) => {
                console.log("bids", res.data)
                if (res.data.message === "Bid found") {
                    setorderdata(res.data.data)
                    setloadersubmit(false)
                } else {
                    // navigate("/")
                }

            }).catch((e) => {
                console.log(e)
            })
    }

    const pendingfilter = () => {
        axios.post("https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/lead/GetLeadForWhichCity", { accommodationType, toWhichCity, serviceType }, {
            headers: {
                Authorization: token,
            },
        })
            .then((res) => {
                console.log(res.data)
                // if (res.data.data._id) {
                setorderdata(res.data.data)

                // } else {
                //     setorderdata()

                // }

            }).catch((e) => {
                console.log(e)
            })
    }

    if (loadersubmit) {
        return (
            <Loading />
        )
    }

    const clearfilter = () => {
        getdata()
        setaccommodationType("")
        setserviceType("")
        setcityName("")
    }

    const formatDate = (mongoDBDate) => {
        const date = new Date(mongoDBDate);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();

        return `${day}/${month}/${year}`;
    };


    const getrooms = () => {
        axios.post("https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/lettingaccommodation/GetMyAccommodation", {}, {
            headers: {
                Authorization: token,
            },
        })
            .then((res) => {
                // console.log("room data", res.data)
                if (res.data.message === "Accommodation found") {
                    setroonsdata(res.data.data)
                } else {
                    // navigate("/")
                }

            }).catch((e) => {
                console.log(e)
            })
    }


    const bitnow = (lettingLivingdetailsId) => {
        // console.warn(bitId,lettingLivingdetailsId)
        axios.post(`https://globallearnandearnapi.doctorsforhealth.co.uk/api/globallearnandearn/BidRequest/BidNow?bitId=`+bitId, {lettingLivingdetailsId}, {
            headers: {
                Authorization: token,
            },
        })
            .then((res) => {
                console.log(res.data)
                if (res.data.message === "Successfully bid") {
                    Swal.fire({
                        title: "Thank you",
                        text: "Successfully",
                        icon: "success"
                    });
                    getdata()
                    setLgShow(false)
                } else {
                    // navigate("/")
                }

            }).catch((e) => {
              
                if (e.response.data.message === "Already icluded this property") {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "This property already exist",
                    });
                    

                } else {
                    console.log(e)
                    
                }
            })
    }
  
    return (
        <div className="container-fluid">
            <div className="row">
                {toggle && (
                    <div
                        className="col-12 col-md-3 vh-100 position-fixed"
                        style={{ zIndex: 1000 }}
                    >
                        <LettingSidebar Toggle={Toggle} />
                    </div>
                )}

                {toggle && <div className="col-12 col-lg-3"></div>}
                <div className="col">
                    <LettingNav Toggle={Toggle} />

                    <div>
                        <Modal
                            size="lg"
                            show={lgShow}
                            onHide={() => setLgShow(false)}
                            aria-labelledby="example-modal-sizes-title-lg"
                        >
                            <Modal.Header closeButton>
                                <Modal.Title id="example-modal-sizes-title-lg">
                                    Bit post
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body style={{overflowX:"scroll", height: "550px", overflow: "hidden", overflowY: "scroll", padding: "10px" }}>
                                <div className='container-flu' style={{ overflow: "hidden",overflowX:"scroll",height: "500px" }}>
                                    <div className='ro'>
                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Sr.</th>
                                                    <th scope="col">City</th>
                                                    <th scope="col">Postal code</th>
                                                    <th scope="col" className='text-start'>Room details</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {roonsdata.map((item, index) => {

                                                    return <tr key={item._id}>
                                                        <th scope="row">{index + 1}</th>
                                                        <td>{item.accommodationCity}</td>
                                                        <td>{item.postCode}</td>
                                                        <td>
                                                            <table class="table">
                                                                <thead>
                                                                    <tr>
                                                                        <th scope="col">Sr.</th>
                                                                        <th scope="col">Room id #</th>
                                                                        <th scope="col">Room capacity</th>
                                                                        <th scope="col">Room availability</th>
                                                                        <th scope="col">Independent short stay (£)</th>
                                                                        <th scope="col">Independent long stay (£)</th>
                                                                        <th scope="col">Shared short stay (£)</th>
                                                                        <th scope="col">Shared long stay (£)</th>
                                                                        <th scope="col">Action</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        item.livingDetails.map((data, index) => {
                                                                            return <tr key={index}>
                                                                                <th scope="row">{index + 1}</th>
                                                                                <td>{data.roomNumber}</td>
                                                                                <td>{data.livingCapacity}</td>
                                                                                <td>{data.livingAvailability}</td>
                                                                                <td>{data.livingIndShortStayPriceByLettingAgent}</td>
                                                                                <td>{data.livingIndLongStayPriceByLettingAgent
                                                                                }</td>
                                                                                <td>{data.livingShareShortStayPriceByLettingAgent
                                                                                }</td>
                                                                                <td>{data.livingShareLongStayPriceByLettingAgent
                                                                                }</td>

                                                                                <td><button className='btn btn-primary' style={{width: "90px"}} onClick={() => bitnow(data._id)}>Bit now</button></td>
                                                                            </tr>
                                                                        })
                                                                    }


                                                                </tbody>
                                                            </table>
                                                        </td>

                                                    </tr>

                                                })}
                                            </tbody>
                                        </table>

                                    </div>
                                </div>
                            </Modal.Body>
                        </Modal>
                    </div>

                    <div className='container-fluid bid-main'>
                        <div className='row mt-3 text-start'>
                            <h2>View Bid</h2>
                        </div>
                        <div>
                            <div className='row text-start mt-4 acc-form'>
                                <div className='col-lg-4 col-12'>
                                    <div className="mb-3">
                                        <label for="exampleInputEmail1" className="form-label">City </label>
                                        <select className="form-select"
                                            value={toWhichCity}
                                            onChange={(e) => setcityName(e.target.value)}
                                            aria-label="Default select example">
                                            <option selected>Select type</option>
                                            <option value="London">London</option>
                                            <option value="City of London">City of London</option>
                                        </select>
                                    </div>
                                </div>
                                <div className='col-lg-4 col-12'>
                                    <div className="mb-3">
                                        <label for="exampleInputEmail1" className="form-label">Accommodation type </label>
                                        <select className="form-select"
                                            value={accommodationType}
                                            onChange={(e) => setaccommodationType(e.target.value)}
                                            aria-label="Default select example">
                                            <option selected>Select type</option>
                                            <option value="share">Shared</option>
                                            <option value="independent">Independent</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row mt-3 btn-row'>
                            <div className='col-lg-1 col-6 d-flex align-items-start justify-content-start'>
                                <button className='btn btn-primary' onClick={pendingfilter}>Filter</button>
                            </div>
                            <div className='col-lg-1 col-6 d-flex align-items-start justify-content-start'>
                                <button className='btn btn-primary' onClick={clearfilter}>Clear</button>
                            </div>
                            <div className='col-lg'></div>
                        </div>

                        <div className='row mt-3'>
                            <div className='col table11'>
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">Sr.</th>
                                            <th scope="col">City</th>
                                            <th scope="col">Accommodation type</th>
                                            {/* <th scope="col">Description</th> */}
                                            <th scope="col">Travel plan date</th>
                                            <th scope="col">Total bids</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {orderdata ?
                                            orderdata.map((item, index) => {
                                                return <tr key={item._id}>
                                                    <th scope="row">{index + 1}</th>
                                                    <td>{item.city ? item.city : "N/A"}</td>
                                                    <td>{item.accommodationType ? item.accommodationType : "N/A"}</td>
                                                    {/* <td>{item.desc ? item.desc : "N/A"}</td> */}
                                                    <td>{formatDate(item.travelPlanDate ? item.travelPlanDate : "N/A")}</td>
                                                    <td>{item.bitBylettingAgents ? item.bitBylettingAgents.length : "N/A"}</td>
                                                    <td><button className='btn btn-primary' onClick={() => [setLgShow(true),setbitId(item._id)]}>Bit</button></td>
                                                    {/* <td>
                                                <li className="nav-item dropdown" style={{ listStyle: "none" }}>
                                                    <button className="btn btn-primary dropdown-toggle" style={{ backgroundColor: "#296013", border: "none", height: "30px" }} role="button" data-bs-toggle="dropdown" aria-expanded="false">

                                                    </button>
                                                    <ul className="dropdown-menu">
                                                        <li style={{ cursor: "pointer" }} onClick={() => localdelete(item._id)}><td className="dropdown-item"><i class="fa-solid fa-trash me-2" style={{ color: "#B63A3A" }}></i> Delete</td></li>
                                                        <li style={{ cursor: "pointer" }}
                                                            onClick={() => handleShow(item._id, item.bankName, item.accountTitle, item.accountNumber, item.IBAN)}
                                                        ><td className="dropdown-item"><i className="fa-solid fa-pen-to-square me-2"
                                                            style={{ color: "#198BEC" }}></i> Update</td></li>
                                                    </ul>
                                                </li>
                                            </td> */}
                                                </tr>
                                            }) : ""

                                        }


                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>

    )
}

export default Bidpost
